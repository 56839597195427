import axios from '@configs/axios'

const modes = {
    PRODUCTION: 'LIVE',
    SANDBOX: 'SANDBOX',
}

export const getTransactionsAPI = async(selectedApp, filters) => {
    let url = `/transactions/all/by-mode?transaction_mode=${ modes[selectedApp?.mode]?.toUpperCase() }&app_id=${ selectedApp?.id }`

    filters && Object.keys(filters)?.forEach(filterKey => {
        const filterValue = filters[filterKey]
        if(filterValue) url = `${url}&${ filterKey }=${filterValue}`
    })

    const response = await axios.get(url)
    return response?.data
}

export const exportTransactionsAPI = async(input) => {
    const response = await axios.post(`/exports/transactions`, { 
        application_id: input.selectedApp?.id,
        start_date:input.start_date, 
        end_date:input.end_date, 
        mode: modes[input.selectedApp?.mode]?.toUpperCase()})
    return response?.data
}
  
export const getExportedTransactionsAPI = async(selectedApp) => {
    const response = await axios.get(`/exports?type=TRANSACTION&application_id=${ selectedApp?.id }`)
    return response?.data
}
import * as Yup from 'yup';

export const posConfigSchema = Yup.object().shape({
   name:Yup.string().required('name is required'),
   location:Yup.string().required('location is required')
})
export const posUpdateConfigSchema = Yup.object().shape({
    name:Yup.string().required('name is required'),
    location:Yup.string().required('location is required'),
    pos_ref:Yup.string().required('pos_ref is required')
 })

export const posAgentSchema = Yup.object().shape({
    name:Yup.string().required('name is required'),
    email:Yup.string().required('email is required'),
    username:Yup.string().required('username is required')
})

export const posAgentUpdateSchema = Yup.object().shape({
    username:Yup.string().required('username is required'),
    email:Yup.string().required('email is required'),
    status:Yup.string().required('status is required')
})
import React, { useEffect, useState } from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedUniqueValues,
} from '@tanstack/react-table'
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import DatagridSearch from './datagrid-search';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Box, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import DatagridHead from './datagrid-head'
import DatagridFilter from './datagrid-filter';
import DatagridPagination from './datagrid-pagination'
import MobileDataTable from '@components/MobileDataTable';
import PulseText from '../PulseText';
import LoadingTable from '../LoadingTable';

const DataGrid = (props) => {
  const { data, head, paginationOptions, searchOptions, boxShadow, isLoading, filterOptionsList, dateFilterList, column, onClick, tableSize = 'small', rowSize = 'medium', caption, dataType, hidePadding = false, showPagination = true, searchTitle = 'Search', query } = props
  const dataTable = React.useMemo(() => data, [data])
  const finalColumDef = React.useMemo(() => column, [column])
  const [columnFilters, setColumnFilters] = useState([])
  const tableLoaderColumns = []
  column?.forEach((column) => {
    tableLoaderColumns.push(
      {
        id: column?.id,
        header: column?.header ?? null,
        accessorKey: column?.accessorKey ?? 'undefined',
        cell: <Skeleton animation='wave' height={40} variant='text' />,
      }
    )
  })

  const smallScreen = useMediaQuery('(max-width: 900px)')

  const table = useReactTable({
    data: dataTable,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    columns: finalColumDef,
    state: { columnFilters },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })
  const mobileColumn = []
  const mobileDatas = []

  useEffect(() => {
    if (!isLoading) {
      table?.getHeaderGroups()[0]?.headers?.map((header) => {
        if (header?.id !== "select") {
          mobileColumn.push({
            id: header?.id,
            label: flexRender(
              header.column.columnDef.header,
              header.getContext()
            )
          })
          return true
        }
        return false
      })

      table?.getRowModel()?.rows?.map((row) => {
        mobileDatas.push(row?.original)
        return true
      })
    }
  }, [isLoading])

  return (
    <Paper
      sx={{
        py: hidePadding || (data && data.length < 10) ? 0 : 2,
        boxShadow: boxShadow,
      }}
    >
      {caption && (
        <Typography variant="h6" ml={4}>
          <PulseText>{caption}</PulseText>
        </Typography>
      )}
      {head && (
        <Typography
          variant="h5"
          sx={{ fontWeight: "700", p: "1rem", ml: "1rem" }}
        >
          {head}
        </Typography>
      )}

      <Stack
        direction="row"
        sx={{ pb: 2 }}
        py={1}
        px={3}
        justifyContent="space-between"
      >
        {(query || searchOptions) && (
          <DatagridSearch
            table={table}
            query={query}
            searchTitle={searchTitle}
            searchOptions={searchOptions}
          />
        )}
        {(filterOptionsList ||
          dateFilterList) && (
            <>
              {!query && !searchOptions && <Box />}
              <Stack direction="row" gap={2} justifyContent="center" alignItems={"end"}>
                {filterOptionsList && filterOptionsList?.map((filterOptions, index) => (
                  <DatagridFilter key={index} filterOptions={filterOptions} />
                ))}
                {dateFilterList && dateFilterList?.map((options, index) => (
                  <Box key={index}>{React.cloneElement(options?.element)}</Box>
                ))}
              </Stack>
            </>
          )}
      </Stack>

      {smallScreen ? (
        data?.length > 0 ? (
          <MobileDataTable
            columns={mobileColumn}
            datas={mobileDatas}
            dataType={dataType}
            onClick={onClick}
          />
        ) : (
          <Typography variant="h5" textAlign="center">
            No data found
          </Typography>
        )
      ) : (
        <TableContainer >
          {isLoading ? (
            <LoadingTable tableLoaderColumns={tableLoaderColumns} />
          ) : data?.length > 0 ? (
            <Table size={tableSize}>
              <DatagridHead table={table} />
              <TableBody>
                {table?.getRowModel()?.rows.map((row, key) => (
                  <TableRow key={row?.id ?? key}>
                    {row?.getVisibleCells()?.map((cell, index) => (
                      <TableCell key={index} size={rowSize}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="h5" textAlign="center">
              No data found
            </Typography>
          )}
        </TableContainer>
      )}
      {showPagination && (
        <DatagridPagination
          paginationOptions={paginationOptions}
          table={table}
        />
      )}
    </Paper>
  );
}

export default DataGrid
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Stack, FormControl, IconButton, InputAdornment, Typography, useMediaQuery, Alert } from '@mui/material'
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { errorHandler } from '@configs/errorConfig';
import { changePasswordValidation } from '../utils/validations';
import { useChangePassword } from '../hooks/useChangePassword';

const ChangePasswordForm = () => {
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const smallScreen = useMediaQuery('(max-width:900px)');
	const { mutate, isLoading } = useChangePassword()

	const handleShowOnClick = (state) => state((show) => !show)
	const handleMouseDownPassword = (event) => event.preventDefault();

	const formik = useFormik({
		initialValues: {
			old_password: '',
			password: '',
			confirm_password: '',
		},
		validationSchema: changePasswordValidation,
		onSubmit: values => {
			const data = {
				old_password: values.old_password,
				new_password: values.password
			}
			mutate(data, {
				onSuccess: () => {
					toast.success(`Password successfully updated !`)
					setErrorMessage('')
				},
				onError: error => setErrorMessage(errorHandler(error, false))
			})
		}
	})

	return (
		<>
			<Typography variant="h6" mb={2} textAlign={smallScreen ? 'center' : 'initial'}>Change Password</Typography>
			{errorMessage && <Alert sx={{ mb: 4 }} severity="error">{errorMessage}</Alert>}

			<Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
				<FormControl fullWidth variant="outlined">

					<CustomInput
						name='old_password'
						type={showOldPassword ? 'text' : 'password'}
						label="Old password"
						placeholder='Enter your password'
						value={formik.values.old_password}
						error={formik.touched.old_password && !!formik.errors.old_password}
						helpertext={formik.touched.old_password && formik.errors.old_password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => { handleShowOnClick(setShowOldPassword) }}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showOldPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
				<FormControl fullWidth variant="outlined">
					<CustomInput
						name='password'
						type={showPassword ? 'text' : 'password'}
						label="Password"
						placeholder='Enter your password'
						value={formik.values.password}
						error={formik.touched.password && !!formik.errors.password}
						helpertext={formik.touched.password && formik.errors.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => { handleShowOnClick(setShowPassword) }}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
				<FormControl fullWidth variant="outlined">
					<CustomInput
						name='confirm_password'
						type={showConfirmPassword ? 'text' : 'password'}
						label="Confirm password"
						placeholder='Confirm password'
						value={formik.values.confirm_password}
						error={formik.touched.confirm_password && !!formik.errors.confirm_password}
						helpertext={formik.touched.confirm_password && formik.errors.confirm_password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => { handleShowOnClick(setShowConfirmPassword) }}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>

				<CustomButton
					type='submit'
					loading={isLoading}
					fullWidth
					size='large'
					sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
				>
					{isLoading ? 'Updating...' : 'Change password'}
				</CustomButton>
			</Stack>
		</>
	)
}

export default ChangePasswordForm
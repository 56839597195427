

import { useQuery } from 'react-query';
import { errorHandler } from "@configs/errorConfig"
import { Disbursement } from '../utils/api';

export const useSupportedProviderDeposit = () => {

    const disburseApi = new Disbursement()

    const queryKey = ["getSupportedCountriesDeposit"];
    const query = useQuery({
        queryKey,
        queryFn: disburseApi.getSupportedApiDeposit,
        onError: (error) => errorHandler(error)
    })

    return query
}

import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { loginAPI } from "../utils/api";

export const useLogin = () => { 
  const dispatch = useDispatch()

  const mutation = useMutation({
    mutationKey: 'login',
    mutationFn: loginAPI,
    onSuccess: (data) => {
      localStorage.setItem('token', data?.session_key)
      
      const user = data?.data?.user?.data;
      // Store user datas in redux
      dispatch({ 
        type: 'SET_USER_DATA', 
        payload: { userData : user }
      })
    },
    
  })

  return mutation
}



import React, { Fragment, useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { toast, ToastContainer } from 'react-toastify';
import { Stack, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { usePayouts } from '../hooks/usePayouts';
import CustomInput from '@components/CustomInput';
import { FileUpload } from '@components/FIleUpload';
import CustomButton from '@components/CustomButton';
import { useAddPayout } from '../hooks/useAddPayout';
import { regexProvider, countryNames } from '@utils';
import { useGetAssociatedPsp } from '../../application/hooks/useGetAssociatedPsp';

export default function PayoutForm({ onClose }) {
	const [countries, setCountries] = useState([])
	const [allProviders, setAllProviders] = useState([])
	const [payoutTypeProviders, setPayoutTypeProviders] = useState([])
	const [payoutType, setPayoutType] = useState()
	const [cniFile, setCniFile] = useState([])
	const [schema, setSchema] = useState({})
	const [providerType, setProviderType] = useState()
	const [selectedCountry, setSelectedCountry] = useState('');
	const application = useSelector((store) => store.data.selectedApp)
	const user = useSelector((store) => store.auth.userData)

	const { data: psps } = useGetAssociatedPsp(application.id)
	const { mutate, isLoading } = useAddPayout()
	const { refetch } = usePayouts()

	//================== country & providers on render =================

	useEffect(() => {

		setPayoutTypeProviders([])
		setCountries([])
		setSelectedCountry()
		setPayoutType()

		const countriesWithoutWorld = psps?.data?.countries?.filter(country => country !== 'WORLD')
		const providers = psps?.data?.providers
		setAllProviders(providers)
		setPayoutTypeProviders(providers)
		setCountries(
			countriesWithoutWorld?.map((country) => {
				return Object.assign({ label: countryNames[country], value: country })
			}))

	}, [providerType, application.id])


	// =============== providers =============================


	const ProviderTypeList = ['MOBILE_MONEY', 'BANK_TRANSFER']

	const ProviderTypes = ProviderTypeList.map((provider) => ({
		label: provider.replace('_', ' '),
		value: provider
	}))

	//=============== upload section =============================

	const { getInputProps } = useDropzone({
		onDrop: acceptedFiles => {
			// Restrict file size to 5mb
			acceptedFiles.forEach((file) => {
				const fileExtention = file.type.split('/')[0]

				if (fileExtention !== 'application') {
					toast.error('Please only documents are accepted')
					return
				}

				if (Math.round(file.size / 100) / 10 > 5120) {
					toast.error('Please your file size should not exceed 5mb')

				} else {
					// Replace an existing file having the same document type in the same country
					setCniFile(file)
				}
			})
		},
		multiple: false
	})

	// ============== validation  =============================

	const getSMSSchema = yup.object().shape(schema)

	const updateSchema = (provider = providerType, payoutType) => {
		if (provider === 'MOBILE_MONEY') {
			setSchema({
				payout_number: yup.string()
					.required("Enter the payout number")
					.matches(regexProvider[payoutType], { message: 'Please enter a valid number for your provider' }),
				customer_mobile_money_name: yup.string().required("Enter the Customer mobile name"),
				payout_name: yup.string().required("Enter the payout name"),
				payout_type: yup.string().required('Please, select a payout type')
			})

		} else {
			setSchema({
				bank_customer_name: yup.string().required("Enter the owner's name"),
				bank_rib: yup.string().required("Enter the rib"),
				bank_name: yup.string().required("Enter the bank name")
			})
		}
	}

	// ========= change handlers =================

	const handleSelectProviderType = (providerType) => {
		setProviderType(providerType)
		updateSchema(providerType)
	}

	const handleSelectPayoutType = (payoutType) => {
		setPayoutType(payoutType)
		formik.setFieldValue('payout_type', payoutType)
		updateSchema(providerType, payoutType)
	}

	const handleCountryChange = (country) => {
		setSelectedCountry(country)

		const filteredProviders = allProviders.filter(psp => {
			return (psp?.country_code === country)
		})

		setPayoutTypeProviders(filteredProviders?.map((psp) => {
			return Object.assign({ label: psp.name, value: psp.shortcode })
		}))

		setPayoutType({ label: 'Select a payout type', value: '' })

	}

	// ================= request Payout ===============

	const handleGetSmsCode = async (values) => {

		if (providerType === 'BANK_TRANSFER' && (!cniFile || cniFile === '' || cniFile === null)) {
			toast.error('Please upload your CNI')
			return
		}

		let global = {
			provider_type: providerType,
			application_id: application?.id,
			application_name: application?.name,
			user_id: user.id,
			payout_name: values.payout_name
		}

		const globalWithMobile = {
			country: selectedCountry,
			payout_type: payoutType,
			payout_data: values.payout_number,
			customer_mobile_money_name: values.customer_mobile_money_name
		}

		const globalWithBank = {
			customer_name: values.bank_customer_name,
			payout_data: values.bank_rib,
			bank_name: values.bank_name,
			cni: cniFile
		}

		const formData = new FormData()

		global = providerType === 'MOBILE_MONEY' ? Object.assign(global, globalWithMobile) : Object.assign(global, globalWithBank)

		for (const key in global) {
			formData.append(key, global[key])
		}

		mutate(formData, {
			onSuccess: () => {
				toast.success(`Payout ${values.payout_name} created successfully!`)
				refetch()
				onClose()
			}
		})
	}

	const formik = useFormik({
		initialValues: {
			payout_number: '',
			payout_type: '',
			country: '',
			application_id: '',
			application_name: '',
			application_description: '',
			application_type: '',
			payout_name: '',
			customer_mobile_money_name: '',
			bank_customer_name: '',
			bank_rib: '',
			bank_name: ''
		},
		validationSchema: getSMSSchema,
		onSubmit: values => handleGetSmsCode(values)
	})


	return (
		<Stack component="form" gap={2} >
			<ToastContainer />
			<CustomInput
				name="payout_name"
				value={formik.values.payout_name}
				error={formik.touched.payout_name && !!formik.errors.payout_name}
				helpertext={formik.touched.payout_name && formik.errors.payout_name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				placeholder="Enter Payout Name"
				label="Payout Name"
			/>
			<FormControl fullWidth >
				<InputLabel id="provider_type" size="small">Provider Type</InputLabel>
				<Select
					size="small"
					defaultValue=""
					labelId="Provider Type"
					id="provider_type"
					value={providerType}
					label="Provider Type"
					onChange={(option) => handleSelectProviderType(option.target.value)}
				>
					{ProviderTypes?.map((payout, key) => (
						<MenuItem key={key} value={payout.value}>{payout.label}</MenuItem>
					))}
				</Select>
			</FormControl>
			{providerType === "MOBILE_MONEY" ? (
				<Fragment>
					<FormControl fullWidth >
						<InputLabel id="country" size="small">Country</InputLabel>
						<Select
							size="small"
							labelId="country"
							id="country"
							value={selectedCountry}
							label="Country"
							onChange={(e) => handleCountryChange(e.target.value)}
						>
							{countries?.map((country, key) => (
								<MenuItem key={key} value={country.value}>{country.label}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth >
						<InputLabel id="payout_type" size="small">Payout Type</InputLabel>
						<Select
							size="small"
							labelId="payout_type"
							id="payout_type"
							name='payout_type'
							value={payoutType}
							label="Payout Type"
							onChange={(e) => handleSelectPayoutType(e.target.value)}
						>
							{payoutTypeProviders
								?.filter((value, i) => payoutTypeProviders.indexOf(value) === i)
								?.map((provider, key) => (
									<MenuItem key={key} value={provider.value}>{provider.label}</MenuItem>
								))}
						</Select>
					</FormControl>
					<CustomInput
						name="customer_mobile_money_name"
						placeholder="Customer Mobile Name"
						label="Customer Mobile Name"
						value={formik.values.customer_mobile_money_name}
						error={formik.touched.customer_mobile_money_name && !!formik.errors.customer_mobile_money_name}
						helpertext={formik.touched.customer_mobile_money_name && formik.errors.customer_mobile_money_name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<CustomInput
						name="payout_number"
						placeholder="Enter Payout Number"
						label="Payout Number" payout_name
						error={formik.touched.payout_number && !!formik.errors.payout_number}
						helpertext={formik.touched.payout_number && formik.errors.payout_number}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
				</Fragment>
			)
				: providerType === "BANK_TRANSFER" ? (
					<Fragment>
						<CustomInput
							name="bank_customer_name"
							placeholder="Enter Owner's Name"
							value={formik.values.bank_customer_name}
							error={formik.touched.bank_customer_name && !!formik.errors.bank_customer_name}
							helpertext={formik.touched.bank_customer_name && formik.errors.bank_customer_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label="Owner's Name"
						/>
						<CustomInput
							name="bank_name"
							placeholder="Enter Bank Name"
							label="Bank Name"
							value={formik.values.bank_name}
							error={formik.touched.bank_name && !!formik.errors.bank_name}
							helpertext={formik.touched.bank_name && formik.errors.bank_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<CustomInput
							name="bank_rib"
							placeholder="Enter Rib Number"
							label="Rib Number"
							type='string'
							value={formik.values.bank_rib}
							error={formik.touched.bank_rib && !!formik.errors.bank_rib}
							helpertext={formik.touched.bank_rib && formik.errors.bank_rib}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}

						/>
						<Stack gap={1}>
							<Typography variant="h6" fontSize="medium" color="GrayText">CNI (front and back)</Typography>
							<FileUpload setFiles={setCniFile} {...getInputProps()} />
							{cniFile ?
								<Stack gap={1}>
									{<Typography color="primary" >{cniFile.name}</Typography>}
								</Stack> : null}
						</Stack>
					</Fragment>
				) : null
			}
			<CustomButton
				onClick={() => formik.handleSubmit()}
				loading={isLoading}
				fullWidth
				size='large'
				sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
			>
				{isLoading ? 'creating...' : 'create'}
			</CustomButton>
		</Stack>
	)
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useDeletePosConfig from '../utils/hooks/useDeletePosConfig';
import { toast } from 'react-toastify';

const DeletePosConfigModal = ({open,handleClose}) => {
    console.log({open})

    const {mutate, data, isLoading, isSuccess} = useDeletePosConfig()

    if(isSuccess){
        toast.success('the config has been deleted')
        handleClose()
    }
  return (
    <Fragment>
        <Dialog
        open={open.open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontWeight:600}}>{`Delete POS Config`}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display:"flex", flexDirection:"column", alignItems:"center"}} id="alert-dialog-slide-description">
            <WarningAmberIcon sx={{fontSize:"3rem"}} color="secondary" />
            <Typography variant='h5' color="secondary" sx={{mt:".8rem"}}>
                Warning!!!
            </Typography>
            <Typography sx={{textAlign:"center", mt:"1rem"}}>
                You are about to delete this POS Config <br />
                Are you sure you want to proceed?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>cancel</Button>
          <Button color='error' variant='contained' onClick={()=>{
            mutate(open.uuid )
          }}>Delete</Button>
        </DialogActions>
      </Dialog> 
    </Fragment>
   
  )
}

export default DeletePosConfigModal
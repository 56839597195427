import React, { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import PaidIcon from '@mui/icons-material/Paid';

import { Alert, AlertTitle, Autocomplete, Button, Card, CardContent, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { ClassNames } from '@emotion/react';
import { AccountBalanceWalletOutlined, AttachMoney } from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';
import { countries, selectValues } from '../../../modules/Inter-transfer/utils';
import InterTransForm from '../../../modules/Inter-transfer/components/interTransForm';

export default function BalanceTransfert() {
    const [accountBalance, setAccountBalance] = useState('');
    const [amountToTransfer, setAmountToTransfer] = useState('');
    const [beneficiaryNumber, setBeneficiaryNumber] = useState('');
    const [beneficiaryName, setBeneficiaryName] = useState('');
    const [beneficiaryDescription, setBeneficiaryDescription] = useState('');
    const [inputValue, setInputValue] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);

    const handleTransfer = () => {
        console.log('Transferring...');
        console.log('Amount: ', amountToTransfer);
        console.log('Beneficiary Number: ', beneficiaryNumber);
        console.log('Beneficiary Name: ', beneficiaryName);
        console.log('Beneficiary Description: ', beneficiaryDescription);
    };
    const [selectedBank, setSelectedBank] = useState('');

    const handleChangeBank = (event) => {
        setSelectedBank(event.target.value);
    };

    const { appID } = useParams();
    const fee = 5;


    const breadCrumb = [
        {
            label: 'Balance Transfert',
            link: `/${appID}/disbursement`,
            icon: <PaidIcon fontSize="small" />
        }
    ]
    // ================= COMPONENT =================
    return (
      <Fragment>
        <Stack
          direction="row"
          mb={4}
          mt={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomizedBreadcrumbs links={breadCrumb} />
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          {/* <Typography mb={5} fontSize={20} fontWeight="600" x textAlign="center" >Mobile transfer</Typography> */}

          <Grid
            container
            justifyContent="center"
            height={"100"}
            alignItems="baseline"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Card
              item
              xs={6}
              sx={{
                m: 2,
                width: 700,
              }}
              elevation={4}
              shadow={5}
              className={ClassNames.content}
            >
              <CardContent sx={{ p: 4 }} fullWidth>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                <Typography variant='h4' mb={4} sx={{fontsize:'5rem', fontWeight:'700'}}>Inter-Transfer</Typography>
                <Typography  mb={4} sx={{fontsize:'5rem', fontWeight:'700'}}>Transfer between currency balance</Typography>
                </div>
               <InterTransForm />
              </CardContent>
            </Card>
          </Grid>
        </Stack>
      </Fragment>
    );
}

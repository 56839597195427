import React from "react";
import { useMutation } from "react-query";
import { posAgentApi } from "../exports";
import { useSelector } from "react-redux";

const useRegeneratePassword = (uuid) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useMutation({
    mutationKey: ["RegeneratePassword"],
    mutationFn: (data) => {
      return posAgentApi.regeneratePassword(uuid, {
        ...data,
        application_id: selectedApp?.id,
      });
    },
  });
};

export default useRegeneratePassword;

import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import PulseText from '../../../components/PulseText';

const BalanceItem = ({ color, amount, duration, currency }) => {
  return (

    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" justifyContent="space-between" color={color} alignItems="center">
        {color === "green" ? <AddIcon fontSize='inherit' /> : < RemoveIcon fontSize='inherit' />}
        <Typography color={color}>
          <PulseText>
            {amount.replace('-', '')}
          </PulseText>
          {' '} 
          <PulseText>{currency}</PulseText>
        </Typography>
      </Stack>
      <Typography color="GrayText">
        <PulseText>{duration}</PulseText>
      </Typography>
    </Stack>
  )
}

export default BalanceItem
import React, { Fragment, useEffect, useState } from 'react'
import useGetAllPosAgents from '../utils/hooks/useGetAllPosAgents';
import DataGrid from "@components/datagrid";
import { posColumn } from '../utils/columns';
import { Box, Button, Stack, Typography } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import { useParams } from 'react-router-dom';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CreateAgent from './createAgent';
import DeletePosAgent from './deletePosConfig';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import { getCurrentDate } from '../utils';

const AgentHome = () => {

    const { appID } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

    
  const { data: all_Pos_Agent_Data, isLoading: isLoading_Pos_Agent_Data, refetch } =
  useGetAllPosAgents();

  const [isCreateAgent, setisCreateAgent] = useState({
    open: false,
    pos_id: "",
  });
  const closeCreateAgent = () =>
    setisCreateAgent((prev) => ({
      ...prev,
      open: false,
    }));

    const [isDeleteAgent, setIsDeleteAgent] = useState({
      open:false,
      uuid:""
    })

    const closeDeleteModal = () => setIsDeleteAgent(prev => ({
      ...prev,
      open:false
    }))

    useEffect(() => {
      refetch()
    }, [isDeleteAgent,isCreateAgent])
    
    const paginationOptions = {
        currentPage,
        rowsPerPage,
        onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
        onNextPage: () => setCurrentPage((page) => page + 1),
        onPreviousPage: () => setCurrentPage((page) => page - 1),
        onFirstPage: () => setCurrentPage(0),
        onLastPage: () =>
          setCurrentPage(all_Pos_Agent_Data?.data?.data?.response?.meta?.totalPages - 1),
        count: all_Pos_Agent_Data?.data?.data?.response?.meta?.totalItems || 1,        
      }

      
      const deleteModalProps = {
        modal:isDeleteAgent,
        handleClose:closeDeleteModal
      }

      const createAgentProps = {
        swippable: isCreateAgent,
        onClose: closeCreateAgent,
      };

      const columnProps = {
       setIsDeleteAgent
      };

      const breadCrumb = [
        {
          label: "POS",
          link: `/${appID}/pos`,
          icon: <AccountTreeOutlinedIcon fontSize="small" />,
        },
        {
          label: "Agents",
          link: `#`,
          icon: <SupportAgentIcon fontSize="small" />,
        },
      ];

  return (
    <Fragment>
      <ToastContainer />
        <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <DeletePosAgent {...deleteModalProps} />
        <CustomizedBreadcrumbs links={breadCrumb} />
        <Stack gap={1} direction="row">
        <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              setisCreateAgent((prev) => ({
                ...prev,
                open: true,
              }))
            }
            sx={{ boxShadow: "none" }}
          >
            Add Agent
          </Button>
        </Stack>
      </Stack>
      <CreateAgent {...createAgentProps} />
        {( all_Pos_Agent_Data?.data && all_Pos_Agent_Data?.data?.data?.response?.items.length > 0) || isLoading_Pos_Agent_Data 
       ? (
        <DataGrid
          data={all_Pos_Agent_Data?.data?.data?.response?.items}
          isLoading={isLoading_Pos_Agent_Data}
          paginationOptions={paginationOptions}
          column={posColumn({ ...columnProps })}
          head={"POS Agents"}
        />
      ) : (
        <Box textAlign="center" py="8rem">
          <Typography variant="h5">No agents Available</Typography>
          <CustomButton
            onClick={()=>{}}
            loading={isLoading_Pos_Agent_Data}
            startIcon={<RefreshIcon fontSize="large" />}
            size="large"
            variant="contained"
            sx={{ mt: 2, fontSize: 13 }}
          >
            Refresh
          </CustomButton>
        </Box>
      )}
    </Fragment>
  )
}

export default AgentHome
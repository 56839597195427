

import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { errorHandler } from "@configs/errorConfig"
import { getAppBalanceCurrency, getBalance, getBalancePerCurrency, getRate } from '../utils/api';

export const useGetRate = (data) => {
    // const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();
  const queryKey = "useBalance";
  const query = useQuery({
    queryKey,    
    queryFn: async () => await getRate(data),
    onError: (error) => errorHandler(error) 
  })

  
  // useEffect(() => {    
  //   queryClient.invalidateQueries(queryKey);
  // }, [selectedApp]);
  return query
}


import { useMutation, useQueryClient } from 'react-query';
import { verifyCashoutAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useVerifyCashout = () => {

	const client = useQueryClient()
  const mutation = useMutation({
    mutationKey: 'verify_cashout',
    mutationFn:verifyCashoutAPI,
    onSuccess: () => {
			client.invalidateQueries()
		},
    onError: (error) => errorHandler(error) 
  })

return mutation
 }
import { VisibilityOutlined, } from "@mui/icons-material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, Stack, Typography } from "@mui/material"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header"


export const productColumns = (getProduct) => [
  {
    id: 'name',
    header: 'Product Name',
    accessorKey: 'name',
    cell: ({ row }) => {
      return <Typography
        textTransform="capitalize"
      >{row.original.name}
      </Typography>
    }
  },
  {
    id: 'amount',
    header: ({ column }) => (
      <DatagridColumnHeader label="Amount" column={column} />
    ),
    accessorKey: 'amount',
    cell: ({ row }) => {
      const amount = row.original.amount;
      return `${new Intl.NumberFormat().format(amount)}`
    },
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'category',
    header: 'Category',
    accessorKey: 'category',
    cell: ({ row }) => {
      const category_name = row.original?.category?.name;
      return <Typography
        fontWeight="bold"
        textTransform="uppercase"
      >{category_name}
      </Typography>
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'image_url',
    header: 'Product Image',
    accessorKey: 'image_url',
    cell: ({ row }) => {
      const image_url = row.original.image_url;
      return <img src={image_url} width={35} height={35} alt={image_url} />
    },
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <IconButton title="View details" onClick={() => getProduct(row.original, true, false, false)}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
          <IconButton title="Edit product" onClick={() => getProduct(row.original, false, true, false)}>
            <ModeEditIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            title="Delete this product"
            color="primary"
            onClick={() => getProduct(row.original, false, false, true)}
          >
            <DeleteOutlineIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  },
]


export const categoryColumns = (getCategory) => [
  {
    id: 'name',
    header: 'Category Name',
    accessorKey: 'name',
    cell: ({ row }) => {
      return <Typography
        textTransform="capitalize"
      >{row.original.name}
      </Typography>
    }
  },
  {
    id: 'slug',
    header: 'Slug',
    accessorKey: 'slug',
  },
  {
    id: 'count',
    header: 'Product Count',
    accessorKey: 'product_count',
    cell: ({ row }) => {
      return <Stack>
        <Typography fontWeight="bold" noWrap>{row?.original?.product?.length}</Typography>
      </Stack>
    }
  },
  {
    id: 'description',
    header: 'Description',
    accessorKey: 'description',
    cell: ({ row }) => {
      return <Stack maxWidth="300px">
        <Typography noWrap>{row?.original?.description}</Typography>
      </Stack>
    }
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <IconButton title="View details" onClick={() => getCategory(row.original, true, false, false)}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
          <IconButton title="Edit" onClick={() => getCategory(row.original, false, true, false)}>
            <ModeEditIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            title="Delete this Category"
            color="primary"
            onClick={() => getCategory(row.original, false, false, true)}
          >
            <DeleteOutlineIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  },
]
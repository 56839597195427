import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Alert, Autocomplete, FormControl, Skeleton, Stack, TextField, Typography, useMediaQuery } from '@mui/material'

import { useMe } from '../hooks/useMe';
import { countryList } from '@utils/countries';
import CustomInput from '@components/CustomInput';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import { accountInfoValidation } from '../utils/validations';
import { useUpdateAccountInfo } from '../hooks/useUpdateInfo';

export default function AccountInfoForm() {
	const smallScreen = useMediaQuery('(max-width:900px)');
	const { isLoading: isLoadingUserInfo, refetch } = useMe()
	const user = useSelector((store) => store.auth.userData)
	const { mutate, isLoading } = useUpdateAccountInfo()
	const [errorMessage, setErrorMessage] = useState('');

	const formik = useFormik({
		initialValues: {
			first_name: '',
			last_name: '',
			phone_number: '',
			country: '',
		},
		validationSchema: accountInfoValidation,
		onSubmit: values => {
			mutate(values, {
				onSuccess: () => {
					toast.success(`Information successfully updated !`)
					setErrorMessage('')
					refetch()
				},
				onError: error => setErrorMessage(errorHandler(error, false))
			})
		}
	})

	useEffect(() => {
		if (user) {
			formik.setFieldValue('first_name', user?.first_name)
			formik.setFieldValue('last_name', user?.last_name)
			formik.setFieldValue('phone_number', user?.phone_number)
			formik.setFieldValue('country', user?.country)
		}
	}, [isLoadingUserInfo])

	return (
		<Fragment>

			<Typography mb={2} variant="h6" textAlign={smallScreen ? 'center' : 'initial'}>Account Information</Typography>
			{errorMessage && !isLoadingUserInfo && <Alert sx={{ mb: 4 }} severity="error">{errorMessage}</Alert>}

			<Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
				{
					(isLoadingUserInfo || !user) ?
						<Skeleton animation='wave' height={300} variant='rectangular' />
						:
						<>
							<CustomInput
								name="first_name"
								placeholder="First Name"
								label="First Name"
								value={formik.values.first_name}
								error={formik.touched.first_name && !!formik.errors.first_name}
								helperText={formik.touched.first_name && formik.errors.first_name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<CustomInput
								name="last_name"
								placeholder="Last Name"
								label="Last Name"
								value={formik.values.last_name}
								error={formik.touched.last_name && !!formik.errors.last_name}
								helperText={formik.touched.last_name && formik.errors.last_name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<CustomInput
								name="phone_number"
								placeholder="Phone number"
								label="Phone number"
								value={formik.values.phone_number}
								error={formik.touched.phone_number && !!formik.errors.phone_number}
								helperText={formik.touched.phone_number && formik.errors.phone_number}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<FormControl fullWidth>
								<Autocomplete
									value={formik.values.country ?? user?.country}
									onChange={(event, selectedOption) => { formik.setFieldValue('country', selectedOption?.label) }}
									onBlur={formik.handleBlur}
									error={formik.touched.country && formik.errors.country}
									helperText={formik.touched.country && formik.errors.country}
									id="country"
									options={countryList}
									size='small'
									fullWidth
									renderInput={(params) => <TextField {...params} placeholder='Country' />}
								/>
							</FormControl>

							<CustomButton
								type='submit'
								loading={isLoading}
								fullWidth
								size='large'
								sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
							>
								{isLoading ? 'Updating...' : 'Update information'}
							</CustomButton>
						</>
				}
			</Stack>
		</Fragment>
	)
}

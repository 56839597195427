import { useQuery, useQueryClient } from "react-query";
import { errorHandler } from "../../../../configs/errorConfig";
import { posTransactionApi } from "../exports";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useGetAllPOSTransactions = (filters) => {
 const selectedApp = useSelector((store) => store.data.selectedApp);

  const queryClient = useQueryClient();
  const queryKey = ["AllPOSTransaction",selectedApp];
  const query = useQuery({
    queryKey,
    queryFn:async()=> await posTransactionApi.getPOS_Transaction({...filters,application_id:selectedApp.id}),
    onError: (error) => errorHandler(error),
  });

    useEffect(() => {
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);
  

  return query
};

export default useGetAllPOSTransactions;

import React from 'react'
import DashboardSwipeable from '@components/DashboardSwipeable';
import AgentForm from './agent-form'

const CreateAgent = ({swippable, onClose}) => {
    const agentFormProps = {
        pos_id:swippable?.pos_id,
        data:swippable?.data,
        onClose
    }
  return (
    <DashboardSwipeable
    isOpen={swippable.open}
    title={swippable?.data ? "update Agent" : "create Agent"}
    onClose={onClose}
  >
    <AgentForm {...agentFormProps} />
  </DashboardSwipeable>
  )
}

export default CreateAgent
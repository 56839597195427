import * as  Yup from 'yup'

const amountRegex = /^[1-9][0-9]*$/

export const productValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required('A product name is required'),
    amount: Yup.string().required('Please enter an amount')
      .matches(amountRegex, { message: 'Amount  must be grater than or equal to 1' }),
    reference_code: Yup.string(),
    type: Yup.string().required('Type is required'),
    isAvailable: Yup.string()
      .required('Type is required')
      .test('isAvailable', 'isAvailable is required', value => {
        if (value && value === 'true') return true;
        else if (value && value === 'false') return false;
      }),
    category_id: Yup.string().required('Category is required'),
    description: Yup.string(),
  })
}

export const categoryValidation = () => {
  return Yup.object().shape({
    cat_name: Yup.string().required('A category name is required'),
    description: Yup.string(),
  })
}

import React, { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Alert, AlertTitle, Box, Button,  } from "@mui/material";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import useGetPosConfig from "../../../modules/pos/utils/hooks/useGetPosConfig";
import DeletePosConfig from "../../../modules/pos/components/deletePosConfig";
import PosConfig from "../../../modules/pos/components/posConfig";
import AllTransactions from "../../../modules/pos/components/AllTransactions";
import AgentCashBalance from "../../../modules/pos/components/AgentCashBalance";
import useGetPosTransactionStatistics from "../../../modules/pos/utils/hooks/useGetPosTransactionStatistics";
import { grey } from "@mui/material/colors";
import { ToastContainer } from "react-toastify";

const POS = () => {
  const { appID } = useParams();

  const {
    data: pos_Config_Data,
    isLoading: posConfigIsLoading,
    isError: posConfigisError,
    error: posConfigError,
    refetch,
  } = useGetPosConfig();



    const [refreshKey, setRefreshKey] = useState(0);

    const [filter, setFilter] = useState({
      agentId:null,
      refreshKey:null,
      start_date:null,
      end_date:null
    })

    useEffect(() => {
      setFilter(prev=>({
        ...prev,
        refreshKey
      }))
    }, [refreshKey])

   

    const { data:statsData, isLoading:statsLoading, isError:statsIsError, error:statsError, refetch:statsRefetch } =
    useGetPosTransactionStatistics(filter);

    const statisticsProps = {
      data:statsData, isLoading:statsLoading, isError:statsIsError,refreshKey, setRefreshKey, name:"P.O.S",setFilter
    }

    useEffect(() => {
      statsRefetch();
  }, [filter]);
  
  const [isPosConfig, setIsPosConfig] = useState({
    open: false,
    data: [],
  });
  
  

  useEffect(() => {
    setIsPosConfig((prev) => ({
      ...prev,
      data: pos_Config_Data?.data?.data?.items,
    }));
  }, [pos_Config_Data,posConfigIsLoading])
  
  
  
  


  const closePosConfig = () =>
    setIsPosConfig((prev) => ({
      ...prev,
      open: false,
    }));

  const posConfigProps = {
    swippable: isPosConfig ,
    onClose: closePosConfig,
    refetch:refetch
  };

  const [isDeleteConfigModal, setisDeleteConfigModal] = useState({
    id: "",
    open: false,
  });
  const closeDeleteConfigModal = () => {
    setisDeleteConfigModal({
      id: "",
      open: false,
    });
  };
  const isDeleteConfigModalProps = {
    modal: isDeleteConfigModal,
    handleClose: closeDeleteConfigModal,
  };

  

  const breadCrumb = [
    {
      label: "POS",
      link: `/${appID}/pos`,
      icon: <AccountTreeIcon fontSize="small" />,
    },
  ];

 
 

  const noDataFound = (
    <Fragment>
      <Box
        sx={{
          fontWeight: "600",
          bgcolor: "white",
          fontSize: "1.3rem",
          py: "10rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            my: "2rem",
            fontSize: "2.5rem",
            fontWeight: "600",
            color: grey[700],
            textAlign: "center",
          }}
        >
          Configure P.O.S <br />
        </Box>
        <Box start={{ textAlign: "start" }}>
          <Alert severity="warning">
            <AlertTitle>
              This Application does not have a P.O.S configured{" "}
            </AlertTitle>
            Please click the button below to configure the P.O.S{" "}
            {`(Point Of Sale)`}
          </Alert>
        </Box>

        <Button
          color="secondary"
          onClick={() =>
            setIsPosConfig((prev) => ({
              ...prev,
              open: true,
            }))
          }
          variant="contained"
          sx={{ boxShadow: "none", mt: "1rem" }}
        >
          create Pos config
        </Button>
      </Box>
    </Fragment>
  );

  
  
  return (
    <Fragment>
      <ToastContainer />
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <Stack gap={1} direction="row">
          <Button
            color="secondary"
            onClick={() =>
              setIsPosConfig((prev) => ({
                ...prev,
                open: true,
              }))
            }
            variant="contained"
            sx={{ boxShadow: "none" }}
          >
            Pos config
          </Button>
        </Stack>
      </Stack>
      <Box style={{ marginTop: "4rem" }}></Box>

      <DeletePosConfig {...isDeleteConfigModalProps} />
      <PosConfig {...posConfigProps} />

      <Box >
        {pos_Config_Data?.data?.data?.items.length <= 0 ? (
         <>{ noDataFound }</> 
        ) : (
          <Box sx={{ bgcolor: "white" }}>
            <AgentCashBalance {...statisticsProps} />
            <Box sx={{ mt: "2rem" }}>
              <AllTransactions />
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default POS;

import axios from "../../../configs/axios";

class AdditionalFeesApi {
  create(app_id, data) {
    console.log({ ...data, app_id });
    const result = axios.post(`/pos/additional-fees`, {
      ...data,
      application_id: app_id,
    });

    return result;
  }
  getAll(app_id, filter) {
    const params = {
      ...(app_id && { app_id }),
      ...(filter?.name && { name: filter?.name }),
      ...(filter?.type && { type: filter?.type }),
    };
    return axios.get(`/pos/additional-fees`, {
      params,
    });
  }

  getOne(uuid, app_id) {
    const params = {
      ...(app_id && { app_id }),
    };
    return axios.get(`/pos/additional-fees/` + uuid, {
      params,
    });
  }

  update(data, uuid) {
    return axios.patch(`/pos/additional-fees/` + uuid, data);
  }
  delete(app_id, uuid) {
    const params = {
      ...(app_id && { app_id }),
    };
    return axios.delete(`/pos/additional-fees/` + uuid, {
      params,
    });
  }
}

export const additionalFeesApi = new AdditionalFeesApi();

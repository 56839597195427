import Axios from "axios"
import { BASE_API_URL } from './base_url';

const axios = Axios.create({ baseURL: BASE_API_URL })

const authRequestInterceptor = async (config) => {
  const storage = await localStorage.getItem("token")
  config.headers = { ...config.headers }

  if (storage) config.headers.session_key = storage

  config.headers.Accept = "application/json"
  return config
}

axios.interceptors.request.use(authRequestInterceptor)

export default axios
import React from 'react'
import { Box, Typography } from '@mui/material'

const ApplicationNotFound = () => {
  return (
    <Box width="100%" my={8} textAlign="center">
      <Typography variant="h4" color="grey">No application found</Typography>
    </Box>
  )
}

export default ApplicationNotFound
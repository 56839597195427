import * as Yup from 'yup'

const passRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/
// const phoneRegex = /^((\+)|(00)|(\*)|())[0-9]{3,14}((\#)|())$/

export const loginValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Enter email address'),
    password: Yup.string().required('Enter password').min(8, 'Password should be at least 8 length')
})

export const registerValidation = Yup.object().shape({
    first_name: Yup.string().required('please enter your first name'),
    last_name: Yup.string().required('please enter your last name'),
    email: Yup.string().email('Please enter a valid email. Ex: example@email.com').required('An email is required to continue'),
    phone_number: Yup.string()
    // .matches(phoneRegex, { message: 'Please your enter a valid number eg +237679446807 or 00237679446807' })
    ,
    country: Yup.string().required('please select your country of residence'),
    password: Yup.string().min(8).matches(passRegex, { message: 'Please your password must meet the following: min 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 numeric digit, 1 special character' }).required('Please enter a password'),
    confirm_pass: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match').required('Please enter your password again')
})

export const verifyCodeValidation = Yup.object().shape({
    code: Yup.number().required('OTP code is required')
})

export const emailValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email. Ex: example@email.com').required('An email is required to continue')
})

export const resetPasswordValidation = Yup.object().shape({
    code: Yup.number().required('OTP code is required'),
    new_password: Yup.string().min(8).matches(passRegex, { message: 'Please your password must meet the following: min 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 numeric digit, 1 special character' }).required('Please enter a password'),
})

export const accountInfoValidation = Yup.object().shape({
    first_name: Yup.string().required('please enter your first name'),
    last_name: Yup.string().required('please enter your last name'),
    phone_number: Yup.string().required('please enter your phone number'),
    country: Yup.string().required('please select your country of residence'),
})

export const changePasswordValidation = Yup.object().shape({
    old_password: Yup.string().required("password is required"),
    password: Yup.string().min(8).matches(passRegex, { message: 'Please your password must meet the following: min 8 characters, atleast 1 uppercase letter, 1 lowercase letter, 1 numeric digit, 1 special character' }).required('New password is required'),
    confirm_password: Yup.string().min(8).matches(passRegex, { message: 'Please your password must meet the following: min 8 characters, atleast 1 uppercase letter, 1 lowercase letter, 1 numeric digit, 1 special character' }).required('Confirm password is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
})

import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { TransfertConfirmValues } from "../../disbursement/utils";
import { TransfertconfirmDeposit, confirmDeposit } from "../../disbursement/utils/validation";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SendIcon from '@mui/icons-material/Send';

import { VpnKeyOffOutlined } from "@mui/icons-material";
import { useResendCode } from "../../disbursement/hooks/useResendCode";
import { useConfirmTransfert } from "../../disbursement/hooks/useConfirmTransfert";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../configs/errorConfig";

const ModalConfirmTransfert = ({ open, handleClose }) => {
    const navigate = useNavigate()

    const [inputValue, setInputValue] = useState(null);
    const [resData, setresData] = useState(null)
    const resendOTP = useResendCode()
    const confirmTransfertMut = useConfirmTransfert()

    const selectedApp = useSelector((store) => store.data.selectedApp);
    const onSubmitConfirm = (data) => {

        const newData = {
            ...data,
            pay_token: open.data.pay_token,
            verification_code: data?.verification_code,
            application_id: selectedApp?.id || "",
        }

        confirmTransfertMut?.mutate(newData, {
            onSuccess: (res) => {
                toast.success(res?.data?.message)
                formikConfirm.resetForm()
                handleClose()
                navigate(`/${selectedApp?.id}/disbursement`)
            },
            onError: (err) => {
                if (err?.response?.data?.message) {
                    const errorMsg = err?.response?.data?.message
                    toast.error(errorMsg)
                } else
                    toast.error(err?.message)
            }
        })
    }

    const formikConfirm = useFormik({
        initialValues: TransfertConfirmValues,
        validationSchema: TransfertconfirmDeposit,
        onSubmit: onSubmitConfirm

    })
    const resendCode = () => {
        const newData = {
            pay_token: open.data.pay_token,
            application_id: selectedApp?.id || "",
        }
        resendOTP?.mutate(newData, {
            onSuccess: (res) => {
                toast.success("Code resent")
            },
            onError: (err) => {
                errorHandler(err)
            }
        })
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open.open}
            disableBackdropClick
            sx={{ w: '60%', backdropFilter: "blur(2px) hue-rotate(265deg)" }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" >
            <Box sx={style} >
                <form onSubmit={formikConfirm.handleSubmit}>
                    <Typography sx={{ fontSize: 24 }} textAlign="center">Confirm Transfer</Typography>
                    <Alert sx={{ marginBottom: 3 }} severity="info" fullWidth> An OTP (One-Time Password) has been sent to your registered mobile number or email address.
                        Please use the following code to validate your operation.</Alert>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            name="verification_code"
                            label="Verification Code"
                            type="text"
                            value={formikConfirm.values.verification_code}
                            onChange={(e) => formikConfirm.setFieldValue("verification_code", e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <VpnKeyOffOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formikConfirm.errors?.verification_code &&
                            formikConfirm.touched?.verification_code && (
                                <FormHelperText fullWidth error>
                                    <strong>{formikConfirm.errors?.verification_code}</strong>
                                </FormHelperText>
                            )}
                    </FormControl>
                    <Stack
                        direction="row"
                        fullWidth
                        justifyContent="flex-end"
                        alignItems="end"
                    >
                        <Typography
                            color="secondary"
                            as="button"
                            type="button"
                            onClick={resendCode}
                            sx={{
                                fontSize: 14,
                                display: 'block',
                                textDecoration: 'underline',
                                mt: 2,
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                border: 'none',
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                            textAlign="end"
                        >
                            No OTP received? Resend code
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        fullWidth
                        justifyContent="flex-end"
                        alignItems="end"
                    >
                        <Button
                            size="small"
                            sx={{ mt: 2, px: "2rem" }}
                            variant="text"
                            // color="secondary"
                            onClick={handleClose}
                        >
                            cancel
                        </Button>
                        <LoadingButton
                            endIcon={<SendIcon />}
                            color="primary"
                            size="large"
                            sx={{ mt: 2, textAlign: "end" }}
                            loading={confirmTransfertMut?.isLoading}
                            loadingPosition="end"
                            variant="contained"
                            type="submit"
                        >
                            <span>Confirm</span>
                        </LoadingButton>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
};

export default ModalConfirmTransfert;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import Close from "@mui/icons-material/Close";
import CustomInput from "../../../components/CustomInput";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { createAdditionalFeesValidationSchema } from "../utils/validation";
import useCreateAdditionalFees from "../hooks/useCreateAdditionalFees";
import { toast } from "react-toastify";
import useUpdateAdditionalFees from "../hooks/useUpdateAdditionalFees";

const CreateAdditionalFeesModal = ({ open, handleClose }) => {
  const { mutate, isLoading } = useCreateAdditionalFees();
  const { mutate: update, isLoading: updating } = useUpdateAdditionalFees(
    open?.data?.id
  );

  const _isUpdate = open?.data && Object.keys(open?.data)?.length > 0;

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
    },
    validationSchema: createAdditionalFeesValidationSchema,
    onSubmit: (data) => {
      if (_isUpdate) {
        update(data, {
          onSuccess: () => {
            toast.success("additional details Updated");
            formik.resetForm();
            handleClose();
          },
        });
      } else {
        mutate(data, {
          onSuccess: (data) => {
            toast.success("additional Fees created");
            formik.resetForm();
            handleClose();
            console.log(data?.data);
          },
        });
      }
    },
  });

  useEffect(() => {
    if (_isUpdate) {
      formik.setFieldValue("name", open?.data?.name);
      formik.setFieldValue("type", open?.data?.type);
    }
  }, [open?.data, _isUpdate]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open.open}
      onClose={handleClose}
      component="form"
      onSubmit={formik.handleSubmit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        textTransform="capitalize"
        fontSize="xl"
        id="alert-dialog-title"
      >
        {_isUpdate ? "update" : "Add New"} Additional Fees
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <FormControl
            fullWidth
            width="100%"
            sx={{
              my: 2,
            }}
          >
            <CustomInput
              name="name"
              label="Name"
              type="text"
              width="100%"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helpertext={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Agent Email"
            />
            {formik.errors?.name && formik.touched?.name && (
              <FormHelperText fullWidth error>
                <strong>{formik.errors?.name}</strong>
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="type"
              name="type"
              value={formik.values.type}
              label="Type"
              onChange={(e) => formik.setFieldValue("type", e.target.value)}
            >
              <MenuItem value={"DEBIT"}>DEBIT</MenuItem>
              <MenuItem value={"CREDIT"}>CREDIT</MenuItem>
            </Select>
            {formik.errors?.type && formik.touched?.type && (
              <FormHelperText fullWidth error>
                <strong>{formik.errors?.type}</strong>
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={[isLoading, updating]?.includes(true)}
          variant="contained"
          sx={{
            width: "100%",
          }}
          type="submit"
        >
          {_isUpdate ? "Update" : "Add"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAdditionalFeesModal;

import { useQuery, useQueryClient } from "react-query"
import { getOneKycAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useOneKycs = (kyc_id) => {  
  
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient(); // Utilisez useQueryClient pour accéder au client de requête

  // Utilisez une clé de requête dynamique pour suivre les modifications de selectedApp  
  const queryKey = ["oneKyc", selectedApp];

  const query = useQuery({
    queryKey,
    queryFn: async () => await getOneKycAPI(selectedApp, kyc_id),
    onError: (error) => errorHandler(error)
  })

  // Utilisez useEffect pour invalider manuellement la requête lorsque selectedApp change
  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);

  return query;

}

import * as React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const ErrorAlert = ({ open, handleClose }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title" color="red">
                Error
            </DialogTitle>
            <DialogContent>
                <Alert severity="error">Subtotal should be equal to the total amount of installments, please make the neccessary adjustments.</Alert>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose} autoFocus>
                    Ok, I understand
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorAlert
import { useQuery } from "react-query"
// import { errorHandler } from "@configs/errorConfig"
import { getCompanyAPI } from "../utils/api";

export const useGetCompany = (appID) => {
    const queryKey = ["company", appID];
    const query = useQuery({
        queryKey,
        queryFn: () => getCompanyAPI(appID),
        // onError: (error) => errorHandler(error)
    })

    return query;
}
import { dataReducer } from "./dataReducer"

import { combineReducers } from 'redux'
import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from "./authReducer"

const rootReducers = combineReducers({
    data: dataReducer,
    auth: authReducer,
})


export const store = configureStore({
    reducer: rootReducers
})
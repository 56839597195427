import { Fragment, useState, useRef } from "react"
import { styled } from '@mui/material/styles';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import Check from '@mui/icons-material/Check';
import StepLabel from '@mui/material/StepLabel';
import EastIcon from '@mui/icons-material/East';
import CustomButton from '@components/CustomButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import InvoiceInfo from "@modules/invoices/components/invoice-info";
import { Box, Button, Container, Paper, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { invoiceValidationSchema } from "@modules/invoices/utils/validation";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ComfirmInvoice from "../../../modules/invoices/components/comfirm-invoice";
import ErrorAlert from "../../../modules/invoices/components/error-alert";
import Spinner from "../../../components/Spinner";
import { useCreateInvoice } from "../../../modules/invoices/hooks/useCreateInvoice";
import { toast } from "react-toastify";

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex', height: 22, alignItems: 'center', ...(ownerState.active && { color: '#784af4', }),
  '& .QontoStepIcon-completedIcon': { color: '#784af4', zIndex: 1, fontSize: 18 },
  '& .QontoStepIcon-circle': { width: 8, height: 8, borderRadius: '50%', backgroundColor: 'currentColor' },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 95deg, #00BFB3 0%, green 50%, #00BFB3 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 95deg, #00BFB3 0%, green 50%, #00BFB3 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1, color: '#fff', width: 50, height: 50, display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, #00BFB3 0%, #00BFB3 50%, #00BFB3 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, #00BFB3 0%, #00BFB3 50%, #00BFB3 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <DescriptionIcon />,
    2: <TaskAltIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ['Invoice Information', 'Comfirm & Complete'];

const InvoiceForm = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [errorDialog, setErrorDialog] = useState(false)
  const [details, setDetails] = useState(null)
  const createInvoice = useCreateInvoice()
  const { appID } = useParams();
  // const { data: invoiceDetails } = useGetInvoice(invoiceID)

  const formikRef = useRef(null);

  const breadCrumb = [
    {
      label: 'Invoices',
      link: `/${appID}/invoices`,
      icon: <ReceiptIcon fontSize="small" />
    }
  ]

  const calculateRowAmount = (row) => {
    const price = row.price || 0;
    const quantity = isNaN(row.quantity) ? 1 : row.quantity;
    const discount = isNaN(row.discount) ? 0 : row.discount;
    const totalWithTax = (price * quantity * (100 - discount)) / 100;
    return totalWithTax
  };

  const removeIndividualFees = (payload) => {
    delete payload.vat;
    delete payload.discount;
    delete payload.shipping;
    delete payload.subtotal;
    delete payload.number_of_installments;
    return
  }

  const handleSubmit = (values) => {

    const changeStringFieldToNumber = values.items.map((i) => {
      return {
        name: i.name,
        amount: Number(i.price),
        quantity: Number(i.quantity),
      }
    })

    if (activeStep === 0) setDetails(values);

    if (activeStep === 1) {

      const checkFees = (payload) => {
        const fees = []
        if (payload?.vat > 0) {
          fees.push({
            type: "VAT",
            amount: values?.vat
          })
        }
        if (payload?.shipping > 0) {
          fees.push({
            type: "SHIPPING",
            amount: values?.shipping
          })
        }
        if (payload?.discount > 0) {
          fees.push({
            type: "DISCOUNT",
            amount: values?.discount
          })
        }
        return fees;
      }

      const fees = checkFees(values)

      if (values.type === "NORMAL") {
        delete values.installments;
        const payload = {
          ...values,
          due_date: values.due_date?.format("YYYY-MM-DD"),
          items: changeStringFieldToNumber,
          fees,
          application_id: appID,
        }

        if (!payload.fees?.length) delete payload.fees;

        removeIndividualFees(payload)
        createInvoice.mutate(payload, {
          onSuccess: () => {
            toast.success(`Invoice Created!`);
            navigate(`/${appID}/invoices`);
          }
        })
      }

      else {

        const totalInstallmentAmount = values?.installments.reduce((acc, invoice) => {
          return acc + parseFloat(invoice.amount);
        }, 0);

        const installmentData = values?.installments?.map((i) => {
          return {
            ...i,
            due_date: i.due_date?.format("YYYY-MM-DD")
          }
        })

        if (values.subtotal !== totalInstallmentAmount) {
          console.log(values.subtotal, totalInstallmentAmount)
          setErrorDialog(true)
          return;
        }

        const payload = {
          ...values,
          due_date: values.due_date?.format("YYYY-MM-DD"),
          items: changeStringFieldToNumber,
          installments: installmentData,
          fees,
          application_id: appID,
        }

        if (!payload.fees?.length) delete payload.fees;

        removeIndividualFees(payload)
        createInvoice.mutate(payload, {
          onSuccess: () => {
            toast.success(`Invoice Created!`)
            navigate(`/${appID}/invoices`);

          }
        })
      }
      // setIsLoading(true)

    } else {
      setActiveStep((active) => active + 1)
    }
  };

  const handleCustomSubmit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };


  const initialValues = {
    client_name: "",
    client_email: "",
    client_phone_number: "",
    type: "",
    currency: "",
    partial_payment: false,
    due_date: null,
    vat: 0,
    shipping: 0,
    discount: 0,
    items: [{ name: '', price: "", quantity: "", amount: "" }],
    installments: [{ title: '', due_date: null, amount: "" }],
    subtotal: 0
  }


  //================= component =================

  return (

    <Fragment>
      <ErrorAlert open={errorDialog} handleClose={() => setErrorDialog(false)} />
      <Stack mt={1} >
        <CustomizedBreadcrumbs links={breadCrumb} />
      </Stack>
      <Stepper sx={{ mt: 5, px: { xs: 0, md: "30%" } }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) =>
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        )}
      </Stepper>
      <Container maxWidth={"lg"}>

        <Paper sx={{ mt: 5, px: 2, py: 2, minHeight: 600, position: 'relative', display: "flex", alignItems: "center", justifyContent: "center" }}>

          {
            createInvoice.isLoading ?
              <Spinner />
              :
              <Formik
                innerRef={formikRef}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={invoiceValidationSchema(activeStep, details?.type)}
              >
                {({ errors, values, setFieldValue }) => (
                  <form>
                    {activeStep === 0 &&
                      <InvoiceInfo
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        calculateRowAmount={calculateRowAmount}
                      />}
                    {activeStep === 1 &&
                      <ComfirmInvoice
                        errors={errors}
                        details={details}
                        setFieldValue={setFieldValue}
                      />}

                    <Box mt={5} display='flex' justifyContent='flex-end' gap={2}>

                      <Button onClick={() => { if (activeStep > 0) setActiveStep(activeStep - 1) }} variant='outlined' color='primary' startIcon={<KeyboardBackspaceIcon />}>
                        Back
                      </Button>

                      <CustomButton
                        // loading={isLoading || isLoadingEdit}
                        onClick={handleCustomSubmit}
                        endIcon={activeStep !== 2 ? <EastIcon /> : <></>}
                        size='medium'
                        sx={{ textTransform: createInvoice.isLoading ? 'capitalize' : 'uppercase' }}
                      >
                        {activeStep === 2 ? 'Save Invoice' : 'Next'}
                      </CustomButton>

                    </Box>
                  </form>
                )}
              </Formik>
          }
        </Paper>
      </Container>


    </Fragment>
  )
}

export default InvoiceForm
import { errorHandler } from "../../../../configs/errorConfig"
import { POS_Config } from "../api"
import { useQuery, useQueryClient } from 'react-query'
import { posConfigApi } from "../exports"
import { useSelector } from "react-redux"
import { useEffect } from "react"

const useGetPosConfig = () => {
 const selectedApp = useSelector((store) => store.data.selectedApp);

const queryKey = [`posConfig`,selectedApp?.id]
const queryClient = useQueryClient();

const query = useQuery({
    queryKey,
    queryFn:async ()=> await posConfigApi.getPOS_Config({application_id:selectedApp.id}),
    onError:(error)=>{errorHandler(error)}
})

useEffect(() => {
  if (selectedApp) {
    queryClient.invalidateQueries('posConfig'); 
  }
}, [selectedApp, queryClient]);
return query
}


export default useGetPosConfig
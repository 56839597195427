import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material'
import { useApplications } from '@modules/application/hooks/useApplications';
import { errorHandler } from '../../configs/errorConfig';
import { useToggleActiveApplication } from '../../modules/home/hooks/useToggleActiveApplication';
import { toast } from 'react-toastify';

export default function ToggleStatusButton({ hideMargin = false }) {
    const {mutate: updateStatus, isLoading: isChangingStatus } = useToggleActiveApplication()
	const user = useSelector((store) => store.auth.userData)
	const selectedApp = useSelector((store) => store.data.selectedApp)
	const { refetch } = useApplications(user?.id)
	const smallScreen = useMediaQuery('(max-width:900px)');

	const onStatusChange = (status) => {
		if (status !== selectedApp?.status) {

            updateStatus(selectedApp, {
                onSuccess: () => { 
                    toast.success(`${selectedApp?.name} is now ${selectedApp?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`)
                    refetch()
                },
                onError: (err) => errorHandler(err)
            })
		}
	}

	// ================= COMPONENT =================
	return (
		selectedApp ?
			<ToggleButtonGroup
				disabled={isChangingStatus}
				value={selectedApp?.status}
				color='primary'
				sx={{ marginRight: hideMargin ? 0 : 2, height: 35, mt: hideMargin ? 0 : 1, filter: isChangingStatus ? 'grayScale(100%)' : 'initial' }}
				onChange={(option) => onStatusChange(option.target.value)}
				exclusive
				aria-label="Platform"
			>
				<ToggleButton sx={{ fontWeight: selectedApp?.mode === 'INACTIVE' ? 'bold' : 'normal', borderColor: selectedApp?.status === 'INACTIVE' ? 'initial' : 'lightgray' }} color='warning' value="INACTIVE">
					{smallScreen && !hideMargin ? <Badge color="warning" variant="dot" /> : 'Inactive'}
				</ToggleButton>
				<ToggleButton sx={{ fontWeight: selectedApp?.mode === 'ACTIVE' ? 'bold' : 'normal', borderColor: selectedApp?.status === 'ACTIVE' ? 'initial' : 'lightgray' }} color='success' value="ACTIVE">
					{smallScreen && !hideMargin ? <Badge color="success" variant="dot" /> : 'Active'}
				</ToggleButton>
			</ToggleButtonGroup>
			:
			null
	)
}

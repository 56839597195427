import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { toast, ToastContainer } from 'react-toastify';
import { TextField } from '@mui/material';
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { categoryValidation } from '../utils/validations';
import { useParams } from 'react-router-dom';
import { useCategories } from '../hooks/useCategories';
import { useUpdateCategory } from '../hooks/useUpdateCategory';
import { useCreateCategory } from '../hooks/useCreateCategory';

export default function ProductCategoryForm({ onClose, isClosed, data, isEdit }) {
    const { appID } = useParams();
    const { refetch } = useCategories()
    const { mutate , isLoading} = useCreateCategory() 
    const { mutate:update, isLoading: updateLoading} = useUpdateCategory()

  const handleCreateCategory= (values) => {

    const payloads = {
      name: values.cat_name,
      description: values.description,
      application_id: appID
    }

    if(data && isEdit){
        update({id:data.uuid,...payloads}, {
          onSuccess: () => {
            toast.success(`Category updated!`)
            refetch()
            onClose()
          }
        })
    }
    else {
        mutate(payloads, {
            onSuccess: () => {
              toast.success(`Category created!`)
              refetch()
              onClose()
            }
        })
    }
  }

  const formik = useFormik({
    initialValues: {
      cat_name: '',
      description: '',
    },
    validationSchema:categoryValidation ,
    onSubmit: values => handleCreateCategory(values)
  })

  useEffect(() => {
      formik.resetForm()
  },[onClose, isClosed]);

  useEffect(() => {
    if (data) {
      formik.setFieldValue('cat_name', data.name)
      formik.setFieldValue('description', data.description)
    }
  },[data]);

  //=================== COMPONENT =============================

  return (
    <Stack component="form" gap={2} >
      <CustomInput
        name="cat_name"
        id="cat_name"
        value={formik.values.cat_name}
        error={formik.touched.cat_name && !!formik.errors.cat_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Category Name"
        label="Category Name"
      />

      <TextField
        id="descripton"
        label="Category Description"
        name='description'
        value={formik.values.description}
        error={formik.touched.description && !!formik.errors.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        multiline
        rows={3}
      />

      <CustomButton
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
        fullWidth
        size='small'
        sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
      >
        {isLoading ? 'Adding...' :updateLoading? "Updating...": isEdit && data? 'Edit Category': 'Add Category'}
      </CustomButton>
    </Stack>
  )
}

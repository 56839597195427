import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  PersonOutlineOutlined,
} from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

import { useGetAllAppBalance } from "../hooks/useGetAllAppBalance";
import { useFormik } from "formik";
import {
  currencies_limit,
  filterObjectsByCurrency,
  getProviderObjects,
  momoInitialValues,
  transformToObjectArray,
} from "../utils";
import {
  countryNames,
  invertedCountryCurrencies,
  pspWithLogo,
  regexProvider,
} from "../../../utils";
import { useInitialiseDeposite } from "../hooks/useInitialiseDeposite";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput } from "mui-tel-input";
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";
import { useGetDepositFees } from "../hooks/useGetDepositFees";
import { useSupportedProviderDeposit } from "../hooks/useSupportedProviderDeposit";

export default function MobileMoney({ setIsConfirmDepositModal, handleClose }) {
  const create = useInitialiseDeposite();
  const getFees = useGetDepositFees();
  const { data: availableDataProvider } = useSupportedProviderDeposit()
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const navigate = useNavigate();
  const [defultCountry, setDefultCountry] = useState("CM");
  const [feesDeposit, setFeesDeposit] = useState("");
  const [currencyFilterArray, setCurrencyFilterArray] = useState([]);
  const [filteredProvider, setFilteredProvider] = useState([])
  const allCountriesProvider = availableDataProvider?.data?.data?.countries?.data

  console.log(availableDataProvider?.data?.data?.countries?.data);
  const [schema, setSchema] = useState({
    balance: yup.object().shape({
      currency: yup.string().required("Must select a balance"),
      Balance: yup.string().required(),
    }),
    toCurrency: yup.object().shape({
      currency: yup.string().required("please choose a currency"),
      code: yup.string().required(),
    }),
    amount: yup.string().required(),
    beneficiary_name: yup.string().required(),

    account_number: yup.string().required(),
    account_bank: yup.object().shape({
      name: yup.string().required("You must select a network provider"),
      code: yup.string()
    }),
    // account_bank: yup.string().required("please select a Network"),
  });

  const validationSchema = yup.object().shape(schema)




  const { data: AppBalance, isLoading, refetch } = useGetAllAppBalance();

  function generateTransactionId() {
    const timestamp = new Date().getTime();
    return "Trans-" + timestamp;
  }

  const onSubmit = (data) => {
    const newData = {
      ...data,
      account_number: parseInt(data?.account_number.split(" ").join("")),
      amount: parseInt(data?.amount),
      debit_currency: data?.balance.currency || "",
      destination_currency: data?.balance.currency || "",
      // destination_currency: data?.toCurrency.currency || "",
      deposit_type: "MOBILE_MONEY",
      country: data?.account_bank.code || "",
      application_id: selectedApp?.id || "",
      transaction_id: generateTransactionId() || "",
      account_bank: data?.account_bank.name || ""
    };


    create?.mutate(newData, {
      onSuccess: (res) => {
        toast.success("Deposit initiated successfully");
        setIsConfirmDepositModal({
          open: true,
          data: res?.data,
        });
        formik.resetForm()
        setFeesDeposit(null)
        // handleClose();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          const errorMsg = err?.response?.data?.message
          toast.error(errorMsg)
        } else
          toast.error(err?.message)
      },
    });
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: momoInitialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  let providerKey = [];
  if (formik.values.balance.currency) {
    // providerKey = getProviderObjects(formik.values.balance.currency);
    providerKey = getProviderObjects(formik.values.balance.currency).filter(item => allCountriesProvider.includes(item.code));

  }

  const arrayOfObjects = transformToObjectArray(invertedCountryCurrencies);

  const funcTest = () => {
    const test = invertedCountryCurrencies[formik.values.balance.currency]
    const filteredData = providerKey.filter(item => test.includes(item.code));
    setFilteredProvider(filteredData)
  }

  useEffect(() => {
    // if (formik.values.toCurrency?.code) {
    //   console.log(formik.values.toCurrency);
    //   setDefultCountry(formik.values.toCurrency?.code);
    // }
    funcTest()
    if (formik.values.account_bank.code) {
      setDefultCountry(formik.values.account_bank.code);
    }
    if (formik.values.balance.currency) {
      const filterData = filterObjectsByCurrency(
        arrayOfObjects,
        formik.values.balance.currency
      );
      setCurrencyFilterArray(filterData);
    }
    // if (formik.values.account_bank) {
    //   console.log(formik.values.account_bank, providerKey);

    // }
  }, [formik.values.toCurrency, formik.values.balance, formik.values.account_bank]);


  const goBack = () => {
    navigate(-1)
  }

  const updateSchema = () => {

    setSchema({
      balance: yup.object().shape({
        currency: yup.string().required("Must select a balance"),
        Balance: yup.string().required(),
      }),
      amount: yup.string().required(),
      beneficiary_name: yup.string().required(),
      account_number: yup.string().test("hasDataOrFile", "Must enter number related to your provider", function () {
        const { account_bank, account_number } = this.parent
        if (account_number && account_bank) {
          const phoneNum = account_number.split(" ")
          phoneNum.shift()
          return regexProvider[account_bank.name].test(phoneNum.join(""))
        }
        return false
      }),
      account_bank: yup.object().shape({
        name: yup.string().required("You must select a network provider"),
        code: yup.string()
      }),
    })
  }


  useEffect(() => {
    if (formik?.values?.account_bank?.code) {
      updateSchema(formik?.values?.account_bank)
    }
  }, [formik?.values?.account_bank])

  const feeData = {
    debit_currency: formik.values.balance.currency || "",
    deposit_type: "MOBILE_MONEY",
    amount: parseInt(formik.values.amount) || null,
    destination_currency: formik.values.balance.currency || "",
    application_id: selectedApp?.id || "",
    account_bank: formik?.values?.account_bank?.name || "",
    country: formik?.values?.account_bank?.code || "",
  }

  useEffect(() => {

    if (formik.values.amount && formik.values.balance && formik?.values?.account_bank) {

      getFees.mutate(feeData, {
        onSuccess: (data) => {
          setFeesDeposit(data?.data?.data?.fees)
          console.log(data?.data?.data?.fees);
        },
        onError: (err) => {
          setFeesDeposit(null)
        }
      }
      )
    }
  }, [formik.values.amount, formik.values.balance, formik?.values?.account_bank])


  const filteredCurrencyBalance = AppBalance?.data?.data?.filter(item => invertedCountryCurrencies[item.currency]);

  // ================= COMPONENT =================
  return (
    <form style={{ marginTop: "1rem" }} onSubmit={formik.handleSubmit}>
      <FormControl sx={{ mb: 1 }} fullWidth>
        <InputLabel id="balance">Choose Balance To Transfer From</InputLabel>
        <Select
          size="small"
          labelId="balance"
          id="balance"
          name="balance"
          label="Choose Balance To Transfer From"
          value={formik.values.balance}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.setFieldValue("balance", {
              currency: selectedValue.currency,
              Balance: selectedValue.Balance,
            });
          }}
          renderValue={(selected) => {
            return (
              <Box sx={{ fontSize: ".9rem" }}>
                {selected?.currency + " " + selected?.Balance}
              </Box>
            );
          }}
          sx={{
            "& .MuiOutlinedInput-input": {
              height: "28px", // Set your custom height
              // border: '1px solid #ced4da', // Set your custom border style
            },
          }}
        >
          {(filteredCurrencyBalance || []).map((items, i) => (
            <MenuItem sx={{ fontSize: ".9rem" }} key={i} value={items}>
              {items?.Balance} {items?.currency}
            </MenuItem>
          ))}
        </Select>
        {formik.errors?.balance?.currency &&
          formik.touched?.balance?.currency && (
            <FormHelperText fullWidth error>
              <strong>{formik.errors?.balance?.currency}</strong>
            </FormHelperText>
          )}
      </FormControl>

      <Grid sx={{ mb: 1, mt: "-8px" }} container direction={"row"} spacing={2}>
        {/* <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="toCurrency">Select Currency</InputLabel>
            <Select
              size="small"
              labelId="toCurrency"
              id="toCurrency"
              name="toCurrency"
              label="Select Currency"
              value={formik.values.toCurrency}
              onChange={(e, value) => {
                const selectedValue = e.target.value;
                formik.setFieldValue("toCurrency", {
                  currency: selectedValue.currency,
                  code: selectedValue.code,
                });
              }}
              renderValue={(selected) => {
                return (
                  <Box sx={{ fontSize: ".9rem" }}>{`${selected?.currency
                    } ${" "} ${selected?.code && `(${selected?.code})`}`}</Box>
                );
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  height: "28px", // Set your custom height
                  // border: '1px solid #ced4da', // Set your custom border style
                },
              }}
            >
              {currencyFilterArray.map((items, i) => (
                <MenuItem
                  sx={{ fontSize: ".9rem", display: "felx", gap: "8px" }}
                  key={i}
                  value={items}
                >
                  <ReactCountryFlag countryCode={items.code} svg />{" "}
                  {items?.currency} {`(${countryNames[items?.code]})`}
                </MenuItem>
              ))}
            </Select>

            {formik.errors?.toCurrency?.currency &&
              formik.touched?.toCurrency?.currency && (
                <FormHelperText fullWidth error>
                  <strong>{formik.errors?.toCurrency?.currency}</strong>
                </FormHelperText>
              )}
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              name="amount"
              label={`How Much Do You Want To Send : ${formik.values.balance.currency &&
                currencies_limit[formik.values.balance.currency] &&
                `max : ${currencies_limit[formik.values.balance.currency]
                  .max_deposit_amount
                } , min : ${currencies_limit[formik.values.balance.currency]
                  .min_deposit_amount
                }`
                }`}
              type="number"
              value={formik.values.amount}
              onChange={(e) => formik.setFieldValue("amount", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PaymentsOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  fontSize: ".9rem",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  height: "28px",
                },
              }}
            />
            {formik.errors?.amount && formik.touched?.amount && (
              <FormHelperText fullWidth error>
                <strong>{formik.errors?.amount}</strong>
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      {feesDeposit && (
        <Alert sx={{ my: 2 }} severity="info">
          <AlertTitle>Fees</AlertTitle>
          {getFees.isLoading ? (
            <Stack fullWidth >
              <LinearProgress sx={{ width: "100%" }} color="primary" />
            </Stack>
          ) : (
            <Typography>
              {feesDeposit.toFixed(2)
              } {formik.values.balance.currency}
            </Typography>
          )}
        </Alert>
      )}
      <Stack sx={{ mt: 4 }} spacing={{ xs: 1, sm: 2, md: 2 }}>
        <Divider>
          <Typography fontStyle="italic">Transfer to</Typography>
        </Divider>
      </Stack>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="account_bank" sx={{ fontSize: ".9rem" }}>
          Select Network
        </InputLabel>
        <Select
          size="small"
          labelId="account_bank"
          id="account_bank"
          name="account_bank"
          label="Select Network"
          value={formik.values.account_bank}
          // onChange={(e) => formik.setFieldValue("account_bank", e.target.value)}
          onChange={(e, value) => {
            const selectedValue = e.target.value;
            formik.setFieldValue("account_bank", {
              name: selectedValue.name,
              code: selectedValue.code,
            });
          }}
          renderValue={(selected) => {
            return <Box sx={{ fontSize: ".9rem" }}>{selected.name}</Box>;
          }}
          InputLabelProps={{
            inputProps: {
              sx: {
                fontSize: ".9rem",
              },
            },
          }}
          sx={{
            fontSize: ".9rem",
            "& .MuiOutlinedInput-input": {
              height: "28px",
            },
          }}
        >
          {filteredProvider.map((item, i) => (
            <MenuItem key={i} sx={{ fontSize: ".9rem" }} value={item}>
              {pspWithLogo[item.name]}
            </MenuItem>
          ))}
        </Select>
        {formik.errors?.account_bank && formik.touched?.account_bank && (
          <FormHelperText fullWidth error>
            <strong>{formik.errors?.account_bank}</strong>
          </FormHelperText>
        )}
      </FormControl>
      <Grid margin="normal" container my={0.5} direction={"row"} spacing={2}>
        <Grid item xs={6} sx={{ pt: "" }}>
          <FormControl fullWidth>
            <TextField
              size="small"
              name="beneficiary_name"
              label="Beneficiary Name"
              value={formik.values.beneficiary_name}
              onChange={(e) =>
                formik.setFieldValue("beneficiary_name", e.target.value)
              }
              InputProps={{
                inputProps: {
                  style: {
                    fontSize: ".9rem",
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: "1rem",
                "& .MuiOutlinedInput-input": {
                  height: "28px",
                },
              }}
            />
            {formik.errors?.beneficiary_name &&
              formik.touched?.beneficiary_name && (
                <FormHelperText fullWidth error>
                  <strong>{formik.errors?.beneficiary_name}</strong>
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="account_number" sx={{ fontSize: ".7rem" }}>
            Beneficiary Account Number
          </InputLabel>
          <FormControl fullWidth>
            <MuiTelInput
              size="small"
              name="account_number"
              value={formik.values.account_number}
              defaultCountry={defultCountry}
              variant="outlined"
              error={
                formik.touched.account_number && formik.errors.account_number
              }
              onChange={(newValue, data) => {

                formik.setFieldValue("account_number", newValue);
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  height: "28px",
                },
              }}
              InputProps={{
                inputProps: {
                  style: {
                    fontSize: ".9rem",
                  },
                },
              }}
            />
            {formik.errors?.account_number &&
              formik.touched?.account_number && (
                <FormHelperText fullWidth error>
                  <strong>{formik.errors?.account_number}</strong>
                </FormHelperText>
              )}

            {/* <TextField
                            size="small"
                            fullWidth
                            name="account_number"
                            label="Beneficiary Account Number"
                            type="number"
                            value={formik.values.account_number}
                            onChange={(e) => formik.setFieldValue("account_number", e.target.value)}
                            InputProps={{
                                inputProps: {
                                    style: {
                                        fontSize: ".9rem",
                                    },
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocalPhoneOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    height: '28px', // Set your custom height
                                    // border: '1px solid #ced4da', // Set your custom border style
                                }
                            }}
                        /> */}
          </FormControl>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        fullWidth
        justifyContent="flex-end"
        alignItems="end"
        spacing={2}
        mt={3}
      >
        <Button
          size="small"
          sx={{ mt: 2, px: "2rem" }}
          variant="text"
          // color="secondary"
          onClick={goBack}
        >
          cancel
        </Button>
        <LoadingButton
          size="small"
          endIcon={<SendIcon />}
          color="primary"
          sx={{ mt: 2, textAlign: "end" }}
          loading={create?.isLoading}
          loadingPosition="end"
          variant="contained"
          type="submit"
          disabled={!formik.isValid}
        >
          <span>Proceed</span>
        </LoadingButton>
      </Stack>
    </form>
  );
}

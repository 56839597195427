import { Fragment } from 'react'
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'

import AppInfoLoader from './appInfoLoader';
import AppTokenLoader from './appTokenLoader';
import CustomInput from '@components/CustomInput';
import AlertDialog from '@components/AlertDialog';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import ToggleModeButton from '@components/ToggleModeButton';
import { useRegenerateToken } from '../hooks/useRegenerateToken';
import TextInfo from '@modules/application-detail/components/text-info';
import { useDeleteApplication } from '../../home/hooks/useDeleteApplication';
import ToggleStatusButton from '../../../components/ToggleStatusButton';

export default function AppDetail() {
    const selectedApp = useSelector((store) => store.data.selectedApp)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteApplication, { error, loading }] = useDeleteApplication()
    const [regenerateToken, { error: errorToken, loading: regenerating }] = useRegenerateToken()
    const { appID } = useParams();
    const navigate = useNavigate()

    const onLiveKeyCopy = () => {
        toast.info(`Live key copied !`, {
            autoClose: 3000,
            theme: 'dark'
        })
    }

    const onSandboxKeyCopy = () => {
        toast.info(`Sandbox key copied !`, {
            autoClose: 3000,
            theme: 'dark'
        })
    }

    const onDelete = () => {
        deleteApplication({ variables: { id: selectedApp?.id } }).then(() => {
            setOpenDeleteDialog(false)
            toast.success(`Application ${selectedApp?.name} deleted successfully`, {
                autoClose: 2000
            })
            setTimeout(() => {
                navigate('/')
            }, 2000);

        }).catch(() => {
            errorHandler(error)
        })
    }


    const onRegenerateToken = () => {
        regenerateToken({ variables: { id: selectedApp?.id } }).then(() => {
            toast.success(`Tokens regenerated successfully`, {
                autoClose: 2000
            })

        }).catch(() => {
            errorHandler(errorToken)
        })
    }

    return (
        <Fragment>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">Application detail</Typography>
                <Button variant='contained' color='error' sx={{ width: 'fit-content', textTransform: "capitalize", }} onClick={() => setOpenDeleteDialog(true)}>Delete app</Button>
            </Stack>
            {
                !selectedApp ?
                    <AppInfoLoader />
                    :
                    <Grid container my={3} spacing={2}>
                        <TextInfo title="ID" value={selectedApp?.id ?? appID} />
                        <TextInfo title="Application name" value={selectedApp?.name ?? 'N/A'} />
                        <TextInfo title="Application type" value={selectedApp?.type ?? 'N/A'} />
                        <TextInfo title="Application status" value={<ToggleStatusButton />} />
                        <TextInfo title="Created date" value={new Date(selectedApp?.created_at)?.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} />
                        <TextInfo title="Last Update" value={new Date(selectedApp?.updated_at)?.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} />
                        <TextInfo title="Application mode" value={selectedApp?.mode ?? 'N/A'} />
                        <TextInfo title="Description" value={selectedApp?.description ?? 'N/A'} />
                        <TextInfo title="Mode" value={<ToggleModeButton hideMargin />} />
                    </Grid>
            }

            <Divider sx={{ mb: 2 }} />

            <Box>
                {
                    !selectedApp ?
                        <AppTokenLoader />
                        :
                        <>

                            <Typography fontWeight="bold">Token</Typography>
                            <Stack gap={2} component="form" mt={1} direction={{ md: "row" }}>
                                <Stack direction="row">

                                    <CustomInput
                                        name="live_key"
                                        placeholder="Live Key"
                                        value={selectedApp?.live_key}
                                        label="Live Key"
                                        readOnly
                                        disabled
                                    />
                                    <CopyToClipboard text={selectedApp?.live_key} onCopy={onLiveKeyCopy}>
                                        <IconButton color="primary" aria-label="copy to clipboard">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Stack>
                                <Stack direction="row">

                                    <CustomInput
                                        name="sandbox_key"
                                        placeholder="Sandbox Key"
                                        value={selectedApp?.sandbox_key}
                                        label="Sandbox Key"
                                        readOnly
                                        disabled
                                    />
                                    <CopyToClipboard text={selectedApp?.sandbox_key} onCopy={onSandboxKeyCopy}>
                                        <IconButton color="primary" aria-label="copy to clipboard">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Stack>

                                <CustomButton
                                    type='submit'
                                    size='small'
                                    color='primary'
                                    loading={regenerating}
                                    variant='contained'
                                    sx={{ textTransform: 'Capitalize', width: 'fit-content' }}
                                    onClick={onRegenerateToken}
                                >
                                    {regenerating ? 'Regenerating' : 'Regenerate'}
                                </CustomButton>
                            </Stack>
                        </>
                }
            </Box>

            <AlertDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                action={onDelete}
                loading={loading}
                color="error"
                text=" Are you sure you want to delete this application?"
            />
        </Fragment>
    )
}

import { Box, Paper, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Cards = ({heading,description, onClick,isClicked,i}) => {
  return (
    <Box sx={{ p: "" , cursor:"pointer"}}>
          <Paper 
          onClick={onClick}
            style={{ width: "20rem", height:"10rem" }}
            elevation={0}
            sx={{
              py: "1rem",
              px: "2rem",
              border: `1px solid`,
              borderColor: isClicked === i ? `#F49405`: grey[200],
              position:"relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                textTransform: "capitalize",
                mt:"1rem"
              }}
            >
              {heading}
            </Typography>
            <Typography sx={{ color: grey[600], mt:"1rem",  fontWeight: "600" }}>
              {description}
            </Typography>
            <Box sx={{position:"absolute", right:"1rem", top:"1rem", display:`${isClicked === i ? `inlineBlock` : `none`}`}}>
        <CheckCircleIcon sx={{fill:"#F49405"}} />
      </Box>
          </Paper>
          
        </Box>
  )
}

export default Cards
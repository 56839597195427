import React from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Container, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material';
import Logo from '@components/Logo';
import CustomLink from '@components/link';
import CustomButton from '@components/CustomButton';
import { errorHandler } from '@configs/errorConfig';
import { emailValidation } from '@modules/authentication/utils/validations';
import { useForgotPassword } from '@modules/authentication/hooks/useForgotPassword';

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { mutate, isLoading } = useForgotPassword()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: emailValidation,
    onSubmit: values => {
      const email = values.email
      const data = { email }
      mutate(data, {
        onSuccess: () => {
          toast.success("Your account has been successfully verified")
          setTimeout(() => {
            navigate(`/reset-password/${email}`)
          }, 3000)
        },
        onError: (error) => {
          errorHandler(error)
          toast.error(error?.response?.data.message)
        }
      })
    }
  })
  return (
    <section className='main-container'>
      <Container sx={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1em" }}>
        <Logo height={50} />

        <ToastContainer />

        <Box
          component="form"
          sx={{
            mb: 1, mt: 3, p: 4, background: '#ffffff', borderRadius: 2,
            width: {
              xs: '100%', sm: "400px",
              display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: ".5em"
            }
          }}
          noValidate
          autoComplete="off"
        >
          <Typography variant='h5' sx={{ textAlign: "center", fontWeight: "bold" }}>
            Forgot Password 🔒
          </Typography>
          <Typography textAlign="center" mb={1} sx={{ color: "GrayText" }}>
            Enter your email and we'll send you instructions to reset your password
          </Typography>
          <FormControl fullWidth>
            <InputLabel htmlFor="email" size='small'>Email</InputLabel>
            <OutlinedInput
              size='small'
              id="email"
              name="email"
              value={formik.values.email}
              error={!!formik.touched.email && !!formik.errors.email}
              helpertext={formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Email"
              placeholder='john@example.com'
            />
          </FormControl>
          <CustomButton
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
            loading={isLoading}
            fullWidth
            size='large'
            sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </CustomButton>
        </Box>
        <CustomLink color="secondary" to="/login" label="Back to login" />
      </Container>
    </section>
  )
}

export default ForgotPassword
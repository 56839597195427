import { useQuery, useQueryClient } from "react-query"
import { getCompaniesAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useGetCompanies = () => {

    const selectedApp = useSelector((store) => store.data.selectedApp);
    const queryClient = useQueryClient();

    const queryKey = ["companies", selectedApp?.id];

    const query = useQuery({
        queryKey,
        queryFn: () => getCompaniesAPI(selectedApp),
        onError: (error) => errorHandler(error)
    })

    useEffect(() => {
        queryClient.invalidateQueries(queryKey);
    }, [selectedApp?.id]);

    return query;

}
import React, { useState } from "react";

const useModals = () => {
  const [createModal, setCreateModal] = useState({
    open: false,
    data: null,
  });
  const [detailsModal, setDetailsModal] = useState({
    open: false,
    data: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });

  const handleModals = {
    openCreateModal: () =>
      setCreateModal({
        open: true,
        data: null,
      }),
    openUpdateModal: (data) =>
      setCreateModal({
        open: true,
        data,
      }),
    closeCreateModal: () =>
      setCreateModal({
        open: false,
        data: null,
      }),
    openDetailsModal: (data) =>
      setDetailsModal({
        open: true,
        data,
      }),
    closeDetailsModal: () =>
      setDetailsModal({
        open: false,
        data: null,
      }),
    openDeleteModal: (id) =>
      setDeleteModal({
        open: true,
        id,
      }),
    closeDeleteModal: () =>
      setDeleteModal({
        open: false,
        id: null,
      }),
  };

  return { createModal, deleteModal, detailsModal, handleModals };
};

export default useModals;

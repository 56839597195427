
const initialState = {
	userData: {},
}

// REDUCER
const authReducer = (state = initialState, action) => {

	const actions = {
		SET_USER_DATA: {
			...state,
			userData: action.payload?.userData
		},
	}
	
	return actions[action.type] ?? state
}

export { authReducer }
import * as yup from 'yup';

export const validationSchemaInterTrans = yup.object().shape({
  description: yup.string().max(40, 'Description must be at most 40 characters'),
  accountBalance: yup.object().shape({
    Balance: yup.string().required("please choose the balance you want to transfer from"),
  }),
  //   from_currency: yup.string().required(),
  amount: yup.number().positive().required(),
  //   to_currency: yup.string().required(),
  selectedCountry: yup.object().shape({
    currency: yup.string().notOneOf(['select'], 'please select currency to transfer to').required("please select currency to transfer to"),
  }),
});
import axios from "../../../configs/axios"

export const loginAPI = async(data)=>{
  const response = await axios.post('/users/v1/login', data)
  return response?.data
}

export const meAPI = async()=>{
  const response = await axios.get('/users/v1/me')
  return response?.data
}

export const registerAPI = async(data)=>{
  const response = await axios.post('users/v1/register', data)
  return response?.data
}

export const verifyUserAPI = async(data)=>{
  const response = await axios.post('/users/v1/verify', data)
  return response?.data
}

export const resendOtpAPI = async(email)=>{
  const response = await axios.post(`/users/v1/verify/resend/?email=${email}`)
  return response?.data
}

export const forgotPasswordAPI = async(email)=>{
  const response = await axios.post('/users/v1/password/reset', email)
  return response?.data
}

export const resetPasswordAPI = async(data)=>{
  const response = await axios.post('/users/v1/password/reset/confirm', data)
  return response?.data
}

export const updateAccountInfoAPI = async(data)=>{
  const response = await axios.patch('/users/v1', data)
  return response?.data
}

export const changePasswordAPI = async(data)=>{
  const response = await axios.post('/users/v1/password/change', data)
  return response?.data
}

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { chipColor, formatDate } from "../utils";
import { Field } from "formik";
import CardBox from "./CardBox";
import { additionalColumns, transactionItemsColumns } from "../utils/columns";
import SimpleTable from "./simpleTable";

const AgentTransDetailsModal = ({ open, handleClose }) => {
  const {
    amount,
    mode,
    reference_id,
    transaction_id,
    created_at,
    currency,
    phone_number,
    status,
    details,
    additional_details,
    additional_fees,
    transaction_items,
  } = open?.data;

  const _checkfees = additional_fees && additional_fees?.length;
  const _checkdetails = additional_details && additional_details?.length;

  console.log({ additional_details, additional_fees, transaction_items });

  const detailsArray = [
    {
      title: "Amount",
      value: amount,
    },
    {
      title: "Currency",
      value: currency,
    },
    {
      title: "phone number",
      value: phone_number ? phone_number : "N/A",
    },
    {
      title: "Mode",
      value: mode,
    },
    {
      title: "Reference Id",
      value: reference_id,
    },
    {
      title: "Transaction Id",
      value: transaction_id,
    },
    {
      title: "status",
      value: <Chip label={status} color={chipColor[status]} />,
    },
    {
      title: "created at",
      value: created_at,
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
  };
  return (
    <Modal
      open={open?.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack sx={{ my: ".9rem", px: 4, position: "relative" }}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            fontWeight={600}
          >
            Transaction Details
          </Typography>
          <Box
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              borderRadius: "4px",
              cursor: "pointer",
              top: "0",
              right: ".4rem",
              "&:hover": {
                border: "1px solid gray",
              },
            }}
          >
            <CloseIcon />
          </Box>
        </Stack>
        <Divider />

        <Grid container sx={{ my: "1rem", mx: 4 }} spacing={4}>
          {detailsArray.map((items, i) => (
            <Grid items xs={4} key={"details-array" + i} mt={2}>
              <Typography
                sx={{
                  fontSize: ".8rem",
                  color: grey[600],
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {items.title}
              </Typography>
              <Typography sx={{}}>{items.value}</Typography>
            </Grid>
          ))}
        </Grid>
        <Accordion defaultExpanded sx={{ boxShadow: "none", px: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Details
          </AccordionSummary>
          <AccordionDetails>
            <Paper>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "lg",
                  px: 4,
                }}
              >
                Transaction Items
              </Typography>
              <SimpleTable
                data={transaction_items}
                columns={transactionItemsColumns}
              />
            </Paper>
            {[_checkfees, _checkdetails].includes(true) ? (
              <Divider
                sx={{
                  my: 3,
                }}
              />
            ) : null}

            <Box display="flex" width="100%" gap={1}>
              {_checkfees ? (
                <Box mt={2} flex={1}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "lg",
                    }}
                  >
                    Additional Fees
                  </Typography>
                  <CardBox data={additional_fees} columns={additionalColumns} />
                </Box>
              ) : null}
              <Divider
                sx={{
                  my: 2,
                }}
                flexItem
                variant="middle"
                orientation="vertical"
              />
              {_checkdetails ? (
                <Box mt={2} flex={1}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "lg",
                    }}
                  >
                    Additional Details
                  </Typography>
                  <CardBox
                    data={additional_details}
                    columns={additionalColumns}
                  />
                </Box>
              ) : null}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider variant="" />
        <Stack direction={"row"} justifyContent={"flex-end"} my={2} px={4}>
          <Button
            color="primary"
            onClick={() => handleClose()}
            variant="outlined"
            sx={{ boxShadow: "none" }}
          >
            cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AgentTransDetailsModal;

import { useMutation } from 'react-query';
import { addPayoutAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useAddPayout = () => { 

  const mutation = useMutation({
    mutationKey: 'register_payout',
    mutationFn:addPayoutAPI,
    onError: (error) => errorHandler(error) 
  })

return mutation
 }
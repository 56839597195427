import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { FormControl, InputLabel, MenuItem, Select, Typography, } from '@mui/material';
import CustomInput from '@components/CustomInput';
import { FileUpload } from '@components/FIleUpload';
import CustomButton from '@components/CustomButton';
import { productValidation } from '../utils/validations';
import { useProducts } from '../hooks/useProducts';
import { useCategories } from '../hooks/useCategories';
import { useCreateProduct } from '../hooks/useCreateProduct';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useParams } from 'react-router-dom';
import { errorHandler } from "@configs/errorConfig"
import { useUpload } from '../hooks/useUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const types = [
  { label: "Product", value: "PRODUCT" },
  { label: "Service", value: "SERVICE" }
]
export default function ProductForm({ onClose, isClosed, isEdit, setImageUrl, updateImg, setUpdateImg, imageUrl, data }) {
  const { data: categoryData } = useCategories();
  const { refetch, data: products } = useProducts();
  const { mutate: uploadFile } = useUpload();
  const { appID } = useParams();
  const { mutate, isLoading } = useCreateProduct();
  const { mutate: update, isLoading: updateLoading } = useUpdateProduct(data?.uuid);
  const catData = categoryData?.data.items;
  const categories = catData?.map(c => {
    return {
      value: c.uuid,
      label: c.name
    }
  })



  const { getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      // Restrict file size to 5mb
      acceptedFiles.forEach((file) => {
        const fileExtention = file.type.split('/')[0]

        if (fileExtention !== 'image') {
          toast.error('Please only images are accepted')
          return
        }

        else if (Math.round(file.size / 100) / 10 > 5120) {
          toast.error('Please your file size should not exceed 5mb')
          return
        }
        else if (isEdit) {
          setImageUrl(undefined);
          setUpdateImg(file)
          return
        }
        else {
          setUpdateImg(undefined)
          setImageUrl(file);
        }
      })
    },
    multiple: false
  })

  const handleCreateProduct = (values) => {

    const global = {
      type: values.type,
      image: imageUrl,
      amount: values.amount,
      name: values.name,
      description: values.description,
      category_id: values.category_id,
      isAvailable: "true",
      reference_code: values.reference_code,
      application_id: appID
    }

    const formData = new FormData()
    const updateData = new FormData()
    updateData.append('image', updateImg)

    for (const key in global) {
      formData.append(key, global[key])
    }

    if (data && isEdit) {
      delete global.image
      update({ id: data?.uuid, values: global }, {
        onSuccess: () => {
          const regex = /https/i;
          if (regex.test(updateImg) === false) {
            uploadFile({ id: data?.uuid, file: updateData }, {
              onSuccess: () => {
                toast.success(`Product and Image Updated!`)
                refetch()
                onClose()
              },
              onError: (error) => {
                toast.error(`Image Failed to Upload`)
                refetch()
              }
            })
          }

          else toast.success(`Product Updated!`)
          onClose()
          refetch()
        },
        onError: (error) => {
          errorHandler(error)
          refetch()
        }
      })
    } else {
      mutate(formData, {
        onSuccess: () => {
          toast.success(`Product Create!`)
          onClose()
          refetch()
        }
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      type: '',
      amount: '',
      name: '',
      description: '',
      category_id: '',
      isAvailable: "true",
      reference_code: ''
    },
    validationSchema: productValidation,
    onSubmit: values => handleCreateProduct(values)
  })

  useEffect(() => {
    if (isClosed) {
      formik.resetForm()
    }
  }, [isClosed]);

  useEffect(() => {
    console.log(imageUrl, updateImg)
  }, [imageUrl, updateImg]);

  useEffect(() => {

    if (data) {
      const category = products?.data.items?.filter(p => p.category?.uuid === data.category?.uuid)
      const selectedCategory = category[0].category?.uuid

      setUpdateImg(data.image_url)
      formik.setFieldValue('type', data.type)
      formik.setFieldValue('amount', data.amount)
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('isAvailable', "true")
      formik.setFieldValue('category_id', selectedCategory)
      formik.setFieldValue('description', data.description)
      formik.setFieldValue('reference_code', data.reference_code)
    }

  }, [data]);

  //=================== COMPONENT =============================

  return (
    <Stack component="form" gap={2} >
      <CustomInput
        name="name"
        value={formik.values.name}
        error={formik.touched.name && !!formik.errors.name}
        helpertext={formik.touched.name && formik.errors.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Product Name"
        label={isEdit ? "" : "Product Name"}
      />

      <FormControl fullWidth >
        <InputLabel id="categories" size="small">Category</InputLabel>
        <Select
          size="small"
          labelId="categories"
          id="category_id"
          name="category_id"
          error={formik.touched.category_id && !!formik.errors.category_id}
          value={formik.values.category_id}
          label="Category"
          onChange={(option) => {
            formik.setFieldValue("category_id", option.target.value)
          }}
        >
          {categories?.map((category) => (
            <MenuItem key={category.value} value={category.value}>{category.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomInput
        name="amount"
        value={formik.values.amount}
        error={formik.touched.amount && !!formik.errors.amount}
        helpertext={formik.touched.amount && formik.errors.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Amount"
        label="Amount"
      />

      <CustomInput
        name="reference_code"
        value={formik.values.reference_code}
        error={formik.touched.reference_code && !!formik.errors.reference_code}
        helpertext={formik.touched.reference_code && formik.errors.reference_code}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter reference code (Optional)"
        label="Reference Code (Optional)"
      />

      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label" size="small">Type</InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="type"
          error={formik.touched.type && !!formik.errors.type}
          value={formik.values.type}
          label="Type"
          onChange={(option) => formik.setFieldValue("type", option.target.value)}
        >
          {types.map((type) => (
            <MenuItem key={type.label} value={type.value}>{type.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack gap={1}>
        <Typography variant="h6" fontSize="medium" color="GrayText">Product Image</Typography>

        <FileUpload
          accept="image/*"
          text="image"
          {...getInputProps()}
        />
        {imageUrl &&
          <Stack gap={1}>
            {<Typography color="primary" >{imageUrl?.name}</Typography>}
          </Stack>}
        {updateImg ? <Stack gap={1}>
          {<Typography color="primary" >{updateImg?.name}</Typography>}
        </Stack> : null}

      </Stack>

      <Stack>
        <Typography variant="h6" fontSize="medium" mb={1} color="GrayText">Description</Typography>
        <CKEditor
          editor={ClassicEditor}
          name="description"
          data={formik.values.description}
          onChange={(event, editor) => {
            const data = editor?.getData();
            formik?.setFieldValue("description", data);
          }}
          onError={formik.errors.description}
        />
      </Stack>

      <CustomButton
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
        fullWidth
        size='large'
        sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
      >
        {isLoading ? 'Adding...' : updateLoading ? "Updating..." : isEdit && data ? 'Edit Product' : 'Add Product'}
      </CustomButton>
    </Stack>
  )
}

import { getBalanceStatisticsAPI } from "../utils/api"
import { useQuery, useQueryClient } from "react-query"
import { errorHandler } from "@configs/errorConfig"
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useBalanceStatistics = (filters) => {  

	const selectedApp = useSelector((store) => store.data.selectedApp);
	const queryClient = useQueryClient(); // Utilisez useQueryClient pour accéder au client de requête

	// Utilisez une clé de requête dynamique pour suivre les modifications de selectedApp  
	const queryKey = [`balanceStatistics`, selectedApp];

	const query = useQuery({
		queryKey,    
		queryFn: async () => await getBalanceStatisticsAPI(selectedApp),
		onError: (error) => errorHandler(error) 
	})

	// Utilisez useEffect pour invalider manuellement la requête lorsque selectedApp change
	useEffect(() => {    
		queryClient.invalidateQueries(queryKey);
	}, [selectedApp]);

	return query;

}
 
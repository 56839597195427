import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { toast, ToastContainer } from 'react-toastify';
import DialogContent from '@mui/material/DialogContent';
import { Button, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { useVerifyCashout } from '../hooks/useVerifyCashout';
import { useResendCashoutOtp } from '../hooks/useResendCashoutOtp';


const VerifyCashout = ({ onClose, open, cashoutData }) => {

  const { mutate, isLoading } = useVerifyCashout()
  const { mutate: resendCode, isLoading: otpIsCodeLoading } = useResendCashoutOtp()

  const submitSMSValidation = yup.object().shape({
    sms_code: yup.string().min(6).max(6).required('OTP is required')
  })

  //================= RESENDING OTP REQUEST=================

  const resendOtp = (id) => {
    resendCode(id, {
      onSuccess: () => {
        toast.success('OTP code sent successfully')
      }
    })
  }

  //=============== SENDING OTP VALIDATION REQUEST ============================

  const handleSubmitCode = async (values) => {

    const payload = {
      cashout_id: cashoutData.id,
      code: values.sms_code
    }
    mutate(payload, {
      onSuccess: (data) => {
        toast.success(data?.message)
        onClose()
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      sms_code: ''
    },
    validationSchema: submitSMSValidation,
    onSubmit: (values) => handleSubmitCode(values)
  })

  //====================== COMPONENT =============================

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{ textAlign: 'center' }}
    >
      <ToastContainer />
      <DialogTitle id="update-payout-dialog-title">Verify Cashout Account</DialogTitle>
      <DialogContent>
        <Typography>check your mailbox to get this code</Typography>

        {
          otpIsCodeLoading ?
            <Stack sx={{ mt: 3, width: "100%", color: "gray" }} alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Stack> :
            <Stack gap={1} mt={.7} width={{ md: "400px" }}>
              <CustomInput
                label="OTP Code"
                id='payout_data'
                name="sms_code"
                value={formik.values.sms_code}
                placeholder="******"
                onChange={formik.handleChange}
                helpertext={formik.touched.sms_code && formik.errors.sms_code}
                onBlur={formik.handleBlur}
              />
              <Stack direction="row" alignItems="center" gap={0} justifyContent="right" sx={{ width: "100%" }}>
                <Typography fontSize="small">Didn't received the OTP code?</Typography>
                <Button color='secondary' sx={{ fontSize: "small", textTransform: "capitalize" }} onClick={() => { resendOtp(cashoutData?.id) }}>Resend</Button>
              </Stack>
              <Stack direction="row" gap={.5} justifyContent="end">
                <Button onClick={onClose} color="inherit" disabled={isLoading}>Cancel</Button>
                <CustomButton
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoading}
                  type='submit'
                  variant="contained"
                  loading={isLoading}
                  size='medium'
                  sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
                >
                  {isLoading ? 'Verifying...' : 'Verify'}
                </CustomButton>
              </Stack>
            </Stack>
        }
      </DialogContent>
    </Dialog >
  )
}

export default VerifyCashout

import React, { Fragment } from 'react'
import { Grid, Skeleton, useMediaQuery } from '@mui/material'

export default function AppTokenLoader() {
  const smallScreen = useMediaQuery('(max-width:900px)');

  return (
    <Fragment>
      <Grid container my={2}>
        {[1, 2].map(element =>
          <Grid key={element} item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} width={250} height={35} />
            <Skeleton variant="rectangular" sx={{ borderRadius: 1, ml: 1, mt: smallScreen ? 3 : 0 }} width={30} height={25} />
          </Grid>
        )}

        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" sx={{ borderRadius: 1, mt: 1 }} width={100} height={35} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

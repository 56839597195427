import React, { useState } from 'react'
import {
  Paper, Stack, Typography, Tabs, Tab, Box, Grid, Avatar, FormControl, Divider, IconButton, InputAdornment, Alert, Container, useMediaQuery
} from '@mui/material';
import theme from '@src/theme';
import { useSelector } from 'react-redux';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import CopyToClipboard from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import HomeHeader from '@components/HomeHeader';
import CustomInput from '@components/CustomInput';
import CustomTabPanel from '@components/CustomTabPanel';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import AccountInfoForm from '@modules/authentication/components/account-info-form';
import ChangePasswordForm from '@modules/authentication/components/change-password';


const AccountSetting = () => {
  const [value, setValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector((store) => store.auth.userData)

  const smallScreen = useMediaQuery('(max-width:900px)');

  const handleShowOnClick = (state) => state((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const breadCrumb = [
    {
      label: 'Settings',
      link: `/settings`,
      icon: <SettingsOutlinedIcon fontSize="small" />
    }
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onApiUsernameCopy = () => {
    toast.info(`API username copied !`, {
      autoClose: 3000,
      theme: 'dark'
    })
  }

  const onApiPasswordCopy = () => {
    toast.info(`API password copied !`, {
      autoClose: 3000,
      theme: 'dark'
    })
  }

  return (
    <section className='main-container'>
      <ToastContainer />

      <HomeHeader />

      <Container>
        <Stack direction="row" alignItems='center' justifyContent='space-between' mb={2} mt={3}>
          <CustomizedBreadcrumbs links={breadCrumb} />
        </Stack>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
        >
          <Tab icon={<PermIdentityIcon />} iconPosition="start" label="Account" />
          <Tab icon={<KeyIcon />} iconPosition="start" label="Api Credentials" />
        </Tabs>
        <Paper>
          <CustomTabPanel value={value} index={0}>
            <Grid container mt={2}>

              <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: smallScreen ? 'center' : 'initial' }}>
                <Avatar src="/broken-image.jpg" variant="rounded" sx={{ bgcolor: theme.palette.primary.main, width: 150, height: 150, maxheight: "100%", fontSize: "6rem" }} />
              </Grid>

              <Grid item xs={12} md={8}>
                <AccountInfoForm />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container mt={2}>

              <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: smallScreen ? 'center' : 'initial' }}>
                <Avatar variant="rounded" sx={{ bgcolor: theme.palette.primary.main, width: 150, height: 150, maxheight: "100%" }}>
                  <LockIcon sx={{ fontSize: 70 }} />
                </Avatar>
              </Grid>

              <Grid item xs={12} md={8}>
                <ChangePasswordForm />
              </Grid>
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Typography variant="h5" color='gray'>
              API Credentials
            </Typography>
            <Box my={2} maxWidth={{ xs: "100%", md: "400px" }}>
              <Alert severity="warning">Token credentials are private and should not be shared.</Alert>
            </Box>

            <Stack gap={4} my={3} maxWidth={{ xs: "100%", md: "450px" }}>
              <Stack direction="row">
                <CustomInput
                  name="api_username"
                  placeholder="Api Username"
                  value={user?.api_user?.api_username}
                  label="Api Username"
                  readOnly
                  disabled
                />
                <CopyToClipboard text={user?.api_user?.api_username} onCopy={onApiUsernameCopy}>
                  <IconButton color="primary" aria-label="copy to clipboard">
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Stack>
              <Stack direction="row">
                <FormControl fullWidth variant="outlined">
                  <CustomInput
                    id="apiPassword"
                    readOnly
                    disabled
                    value={user?.api_user?.api_password}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    placeholder='Enter your password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { handleShowOnClick(setShowPassword) }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <CopyToClipboard text={user?.api_user?.api_password} onCopy={onApiPasswordCopy}>
                  <IconButton color="primary" aria-label="copy to clipboard">
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Stack>
            </Stack>

          </CustomTabPanel>
        </Paper>
      </Container>
    </section>
  )
}

export default AccountSetting
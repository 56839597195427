import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import useDeleteAdditionalDetails from "../hooks/useDeleteAdditionalDetails";
import { grey } from "@mui/material/colors";
import { toast } from "react-toastify";

const DeleteDetailsModal = ({ open, handleClose, useDelete, label }) => {
  const { mutate, isLoading } = useDelete();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        textTransform="capitalize"
        fontSize="xl"
        id="alert-dialog-title"
        fontWeight="bold"
      >
        Delete additional {label}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography width="80%" mx="auto" textAlign="center" fontSize="lg">
          Are you sure you want to delete this additional {label}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          color="error"
          variant="contained"
          sx={{
            width: "100%",
          }}
          load
          onClick={() => {
            mutate(
              { uuid: open.id },
              {
                onSuccess: () => {
                  toast.success("additional aitem deleted");
                  handleClose();
                },
              }
            );
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDetailsModal;

import { useQuery, useQueryClient } from "react-query"
import { getPaymentLinksAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const usePaymentLink = () => {  
  
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient(); 

  const queryKey = ["payment_links", selectedApp];

  const query = useQuery({
    queryKey,
    queryFn: () => getPaymentLinksAPI(selectedApp),
    onError: (error) => errorHandler(error)
  })

  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);

  return query;

}
import { Button, Stack } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CustomizedBreadcrumbs from "../../../components/CustomBreadcrumbs";
import CreateDetailsModal from "../../../modules/pos-additional-details/components/CreateDetailsModal";
import useModals from "../../../modules/pos-additional-details/hooks/useModals";
import { useParams } from "react-router-dom";
import CreateAdditionalFeesModal from "../../../modules/pos-additional-fees/component/createAdditionalFeesModal";
import useGetAllAdditionalFees from "../../../modules/pos-additional-fees/hooks/useGetAllAdditionalFees";
import DataGrid from "@components/datagrid";
import { additionalFeesColumn } from "../../../modules/pos-additional-fees/utils/columns";
import DetailsModalFees from "../../../modules/pos-additional-fees/component/detailsModalFees";
import DeleteDetailsModal from "../../../modules/pos-additional-details/components/DeleteDetailsModal";
import useDeleteAdditionalFees from "../../../modules/pos-additional-fees/hooks/useDeleteAdditionalFees";

const PosAdditionalFees = () => {
  const { appID } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { createModal, detailsModal, deleteModal, handleModals } = useModals();

  const { data, isLoading, refetch } = useGetAllAdditionalFees({
    page: currentPage,
    limit: rowsPerPage,
  });

  useEffect(() => {
    refetch();
  }, [currentPage, createModal, detailsModal, deleteModal]);

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onFirstPage: () => setCurrentPage(0),
    onNextPage: () => setCurrentPage((page) => page + 1),
    count: data?.data?.data?.response?.meta?.totalItems || 1,
    onPreviousPage: () => setCurrentPage((page) => page - 1),
    onLastPage: () =>
      setCurrentPage(data?.data?.data?.response?.meta?.totalPages - 1),
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
  };

  console.log(data);

  const columnProps = {
    setEditModal: handleModals.openUpdateModal,
    setDeleteModal: handleModals.openDeleteModal,
    setDetailsModal: handleModals.openDetailsModal,
  };

  const breadCrumb = [
    {
      label: "Additional Fees",
      link: `/${appID}/pos/additional-fees`,
      icon: <CreditScoreIcon fontSize="small" />,
    },
  ];

  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            handleModals?.openCreateModal();
          }}
          sx={{ boxShadow: "none" }}
        >
          Add Additional Fees
        </Button>
      </Stack>
      {data?.data?.data &&
        data?.data?.data?.response &&
        data?.data?.data?.response?.items?.length > 0 ? (
        <DataGrid
          data={data?.data?.data?.response?.items}
          isLoading={isLoading}
          paginationOptions={paginationOptions}
          column={additionalFeesColumn({ ...columnProps })}
          head={"POS Additional Fees"}
        />
      ) : null}
      <CreateAdditionalFeesModal
        open={createModal}
        handleClose={handleModals?.closeCreateModal}
      />
      <DetailsModalFees
        open={detailsModal}
        handleClose={handleModals.closeDetailsModal}
      />
      <DeleteDetailsModal
        open={deleteModal}
        useDelete={useDeleteAdditionalFees}
        handleClose={handleModals?.closeDeleteModal}
        label="fees"
      />
    </Fragment>
  );
};

export default PosAdditionalFees;

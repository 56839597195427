import { Box, IconButton, Stack } from "@mui/material"
import { countryWithFlag } from "@utils";
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const docTypes = {
    selfie: 'Selfie',
    certificate_of_negotiation: 'Certificate of negotiation',
    tax_declaration: 'Tax declaration',
    passport_or_id_card: 'Passport or ID card',
    non_conviction: 'Non conviction',
    other: 'Other'
}

const renderFilePreview = file => {
    // S'il s'agit d'un fichier uploadé par le user
    return file.name ?
        file.type.startsWith('image') ?
            <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='25' width='25' />
            :
            <DescriptionOutlinedIcon size='25' />
        // S'il s'agit d'un ancien fichier deja enregistré
        :
        <DescriptionOutlinedIcon size='25' />
}

const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
        return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
    } else {
        return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
    }
}

export const uploadedFilesColumns = (handleRemoveFile) => [
    {
        id: 'document_type',
        header: 'Type',
        accessorKey: 'document_type',
        cell: document_type => docTypes[document_type.getValue()]
    },
    {
        id: 'country_code',
        header: 'Country',
        accessorKey: 'country_code',
        cell: ({ row }) => countryWithFlag[row.original.country_code]
    },
    {
        id: 'file',
        header: 'File',
        accessorKey: 'application',
        cell: ({ row }) => (
            <Box display='flex' alignItems='center'>
                <Box>{renderFilePreview(row?.original)}</Box>
                <Box sx={{ ml: 1 }}>
                    <Box>{row?.original?.name ?? row?.original?.document_type}</Box>
                    {row?.original?.size && <Box sx={{ color: 'gray' }}>{renderFileSize(row?.original?.size)}</Box>}
                </Box>
            </Box>
        )
    },
    {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
            <Stack direction="row">
                {/* <IconButton>
                    <VisibilityOutlinedIcon fontSize="small" color="secondary" />
                </IconButton> */}

                <IconButton onClick={() => handleRemoveFile(row?.original)}>
                    <CloseIcon fontSize="small" color="error" />
                </IconButton>
            </Stack>
        )
    },
]
import { useMutation, useQueryClient } from 'react-query';
import { validatePayoutAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useValidatePayout = () => {

	const client = useQueryClient()
  const mutation = useMutation({
    mutationKey: 'validate_payout',
    mutationFn:validatePayoutAPI,
    onSuccess: () => {
			client.invalidateQueries(["payouts"])
		},
    onError: (error) => errorHandler(error) 
  })

return mutation
 }
import * as React from 'react';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { FormControl, TextField } from '@mui/material';

const DatePickerComponent = ({date,setDate, label}) => {
    const [cleared, setCleared] = React.useState(false);

    React.useEffect(() => {
      if (cleared) {
        const timeout = setTimeout(() => {
          setCleared(false);
        }, 1500);
  
        return () => clearTimeout(timeout);
      }
      return () => {};
    }, [cleared]);
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl  size='small'>
          <DemoItem label={label}>
            <DatePicker
           size='small'
              sx={{ width: 200 }}
              value={date}
              onChange={(newValue) => setDate(newValue)}
              slotProps={{ textField: { size: 'small' } }}
            />
          </DemoItem>
  
          
        </FormControl>
      </LocalizationProvider>
    );
}

export default DatePickerComponent
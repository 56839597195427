import {
  DeleteOutlineOutlined,
  Edit,
  VisibilityOutlined,
} from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { formatDate } from "../../pos/utils";

export const additionalDetailsColumn = ({
  setDetailsModal,
  setDeleteModal,
  setEditModal,
}) => {
  return [
    {
      id: "name",
      header: "Detail Name",
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.original.name;
        return value ? value : "N/A";
      },
    },

    {
      id: "created_at",
      header: "Created At",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const value = row.original?.created_at;
        return value ? formatDate(value) : "N/A";
      },
    },
    {
      id: "lastChangedDateTime",
      header: "Created At",
      accessorKey: "lastChangedDateTime",
      cell: ({ row }) => {
        const value = row.original?.lastChangedDateTime;
        return value ? formatDate(value) : "N/A";
      },
    },

    {
      header: "actions",
      cell: ({ row }) => {
        const uuid = row.original.uuid;
        return (
          <Stack direction={"row"} alignItems={"center"} gap={0.4}>
            {/* <Navigate url={`${uuid}`} /> */}
            <IconButton
              onClick={() => setDetailsModal(row.original)}
              title="view details"
              sx={{ fontSize: ".8rem" }}
            >
              <VisibilityOutlined fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              onClick={() => setEditModal(row.original)}
              title="view details"
              sx={{ fontSize: ".8rem" }}
            >
              <Edit fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              onClick={() => setDeleteModal(row.original.id)}
              title="view details"
              sx={{ fontSize: ".8rem" }}
            >
              <DeleteOutlineOutlined fontSize="small" color="error" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
};

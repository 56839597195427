import React from 'react'
import { Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FacebookIcon, FacebookShareButton, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon, FacebookMessengerShareButton, FacebookMessengerIcon } from "react-share"

export default function ShareDialog({ open, onClose, link }) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      sx={{ textAlign: 'center' }}
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title">Share Link</DialogTitle>
        <Stack direction="row" gap={1}>
          <FacebookShareButton url={link}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={link}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <TelegramShareButton url={link}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
          <FacebookMessengerShareButton url={link}>
            <FacebookMessengerIcon size={40} round={true} />
          </FacebookMessengerShareButton>
          <TwitterShareButton url={link}>
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
        </Stack>
      </DialogContent>
    </Dialog>

  );
}
import { useMutation } from 'react-query';
import { resendOtpAPI } from "../utils/api";

export const useResendOtp = () => { 

  const mutation = useMutation({
    mutationKey: 'resend-otp',
    mutationFn:resendOtpAPI
  })

  return mutation
 }
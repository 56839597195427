import { IconButton, Stack } from "@mui/material"
import { VisibilityOutlined } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header"
import CashoutChips from "../components/cashout-chips";
import { pspWithLogo } from "@utils"

export const cashoutsColumns = (getCashout) => [
  {
    id: 'collect_provider_id',
    header: 'Provider',
    accessorKey: 'collect_provider_id',
    cell: (info) => {
      return pspWithLogo[info.getValue()] ?? '...'
    }
  },
  {
    id: 'amount',
    header: ({ column }) => (
      <DatagridColumnHeader label="Amount" column={column} />
    ),
    accessorKey: 'amount',
    cell: ({ row }) => {
      const amount = row.original.amount;
      const currency = row.original.currency;
      return `${new Intl.NumberFormat().format(amount)} ${currency}`
    },
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'cashout_fee',
    header: 'Fee',
    accessorKey: 'cashout_fee',
    cell: (info) => {
      return Math.round(info.getValue() * 10) / 10
    }
  },
  {
    id: 'cashout_status',
    header: 'Status',
    accessorKey: 'cashout_status',
    cell: (info) => {
      const status = info.getValue()
      return <CashoutChips status={status} />
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'create_time',
    header: ({ column }) => (
      <DatagridColumnHeader label="Date" column={column} />
    ),
    accessorKey: 'create_time',
    cell: (info) => {
      const date = info.getValue();
      return new Date(date)?.toLocaleDateString("en-US", {
        year: 'numeric', month: 'long', day: 'numeric'
      })
    }
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          {row.original.cashout_status === "NOT_VERIFIED" &&
            <IconButton
              color="primary"
              title="Verify this payout"
              onClick={() => getCashout(row.original, true)}
            >
              <CheckCircleOutlineIcon fontSize="small" />
            </IconButton>
          }
          <IconButton title="View details" onClick={() => getCashout(row.original, false)}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Stack>
      )
    }
  },
]
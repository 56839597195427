import React from 'react'
import { useMutation } from 'react-query'
import { posConfigApi } from '../exports'
import { useSelector } from 'react-redux'
import { errorHandler } from '../../../../configs/errorConfig'

const useUpdatePosConfig = (id) => {
 const mutationKey = ["updatePosConfig"]
 const selectedApp = useSelector((store) => store.data.selectedApp);

 const mutation = useMutation({
    mutationKey,
    mutationFn:async (data) => await posConfigApi.update_Pos_Config(id,{...data,application_id:selectedApp.id}),
    onError:(error)=>errorHandler(error)
 })

 return mutation
}

export default useUpdatePosConfig
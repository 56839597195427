import React, { Fragment, useState } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useParams } from 'react-router-dom';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';

import KycDetail from '@modules/kycs/components/KycDetail';
import { useKycs } from '@modules/kycs/hooks/useKycs';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LoaderKycDetail from '@modules/kycs/components/LoaderKycDetail';
import ViewModalKyc from '../../../modules/kycs/components/viewModalKyc';

const Kycs = () => {
  const { appID } = useParams()
  const { data, isLoading } = useKycs()
  const kyc = data?.data
  const smallScreen = useMediaQuery('(max-width:900px)');

  const [isModalDetails, setIsModalDetails] = useState({
    open: false,
    data: ""
  })

  const onDocModalClose = () => {
    setIsModalDetails({
      open: false,
      data: ""
    })
  }

  const loaderKycProps = {
    setIsModalDetails,
  };

  const kycDetailsProps = {
    onDocModalClose,
    isModalDetails,
    setIsModalDetails
  }

  const kycModalProps = {
    open:isModalDetails?.open,
    onClose:onDocModalClose,
    image:isModalDetails?.data

  }

  const breadCrumb = [
    {
      label: 'KYCs',
      link: `/${appID}/kycs`,
      icon: <AssignmentOutlinedIcon fontSize="small" />
    },
  ]

  // ================= COMPONENT =================
  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />

        {!isLoading &&
          <Link to={`/${appID}/kycs/manage`}>
            <Button
              color='primary'
              variant="contained"
              sx={{ boxShadow: "none" }}
              startIcon={kyc ? <EditIcon /> : <AddIcon />}
            >
              {kyc ? 'Edit kyc' : 'Add Kyc'}
            </Button>
          </Link>
        }

      </Stack>

      <Paper sx={{ py: 5, px: smallScreen ? 1 : 5 }}>
        {(isLoading || kyc) ?
          isLoading ?
            <LoaderKycDetail {...loaderKycProps} />
            :
            <KycDetail
              isLoading={isLoading}
              selectedKyc={kyc}
              {...kycDetailsProps}
            />
          :
          <Box width="100%" my={2} textAlign="center">
            <PlagiarismIcon fontSize='large' />
            <Typography variant="h5" color="grey">No KYC found for this application.</Typography>
          </Box>
        }
      </Paper>
      <ViewModalKyc {...kycModalProps} />
    </Fragment>
  )
}

export default Kycs
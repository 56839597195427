import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';


export default function NotifyAddInvoice({ open, onClose, action }) {

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      open={open}
      maxWidth="xs"
    >
      <DialogContent sx={{ textAlign: "center", mt: 2 }}>
        <Typography color="green" variant='h6'>
          Company has been created successfully.
        </Typography>
        <Typography>
          Would you like to create an <strong>invoice</strong> for this application now?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={action}>
          Yes, Please
        </Button>
        <Button
          autoFocus
          variant='text'
          color="inherit"
          onClick={onClose}
          sx={{ textTransform: "initial", fontWeight: "bold" }}
        >
          Skip for now
        </Button>
      </DialogActions>
    </Dialog>
  );
}
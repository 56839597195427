import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { formatDate } from '../../../utils';
import DetailInput from '@components/Input-Detail';
import SanitizedContent from '@components/SanitizedContent';


export default function ProductDetails({ open, onClose, productDetail }) {
  const { description, image_url, amount, name, reference_code, category, type, created_at } = productDetail;

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        fullWidth
        open={open}
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Product Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <img
                src={image_url ?? require('../../../assets/images/avatar/default_pic.jpg')}
                style={{
                  width: "300px",
                  height: "300px",
                  borderRadius: "8px"
                }}
                alt="product" />
            </Grid>
            <Grid item xs={12} md={7} height="100%">
              <Stack gap={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <DetailInput label="Product name" value={name} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailInput label="Category" value={category? category.name :"Uncategorized"} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DetailInput label="Amount" value={amount} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailInput label="Type" value={type} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DetailInput label="Reference Code" value={reference_code} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailInput label="Created" value={formatDate(created_at)} />
                  </Grid>
                </Grid>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{fontWeight:"bold"}}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Description
                  </AccordionSummary>
                  <AccordionDetails>
                  <SanitizedContent content={description ?? "None"}/>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' autoFocus onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
import { useMutation, useQueryClient } from 'react-query';
import { updateCompanyAPI } from "../utils/api"

export const useUpdateCompany = (companyID) => {
    const client = useQueryClient()

    const mutation = useMutation({
        mutationKey: ["updateCompany"],
        mutationFn: (data) => updateCompanyAPI(companyID, data),
        onSuccess: () => client.invalidateQueries({ queryKey: ["company"] }),
    })

    return mutation
}
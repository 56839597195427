import React from 'react'
import { flexRender } from '@tanstack/react-table';
import { TableCell, TableHead as THead, TableRow } from '@mui/material';

const DatagridHead = ({ table }) => {
  // ================= COMPONENT =================
  return (
    <THead>
      {table?.getHeaderGroups().map((headerGroup, key) => (
        <TableRow key={headerGroup?.id ?? key} sx={{ background: 'rgb(230,230,230)' }}>
          {headerGroup?.headers?.map((header, key) => {
            return (
              <TableCell key={header?.id ?? key} sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {header?.isPlaceholder
                  ? null
                  : flexRender(
                    header?.column?.columnDef?.header,
                    header?.getContext()
                  )}
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </THead>
  );
}

export default DatagridHead
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Badge, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material'

import { useApplications } from '@modules/application/hooks/useApplications';
import { useUpdateApplicationMode } from '@modules/application/hooks/useUpdateApplicationMode';

export default function ToggleModeButton({ hideMargin = false }) {
	const [changingMode, setChangingMode] = useState(false);
	const [updateMode] = useUpdateApplicationMode()
	const user = useSelector((store) => store.auth.userData)
	const selectedApp = useSelector((store) => store.data.selectedApp)
	const { refetch } = useApplications(user?.id)
	const smallScreen = useMediaQuery('(max-width:900px)');
	const { appID } = useParams();

	const onModeChange = (mode) => {
		if (mode !== selectedApp?.mode) {

			setChangingMode(true)

			updateMode({ variables: { id: appID, mode } }).then((res) => {
				refetch()
				setTimeout(() => setChangingMode(false), 1000);

			}).catch(() => {
				setChangingMode(false)
				toast.error(`You can not go live if the application is inactive or doesn't have validated KYCs or without subscribing to a psp !`)
			})
		}
	}

	// ================= COMPONENT =================
	return (
		selectedApp ?
			<ToggleButtonGroup
				disabled={changingMode}
				value={selectedApp?.mode}
				color='primary'
				sx={{ marginRight: hideMargin ? 0 : 2, height: 35, mt: hideMargin ? 0 : 1, filter: changingMode ? 'grayScale(100%)' : 'initial' }}
				onChange={(option) => onModeChange(option.target.value)}
				exclusive
				aria-label="Platform"
			>
				<ToggleButton sx={{ fontWeight: selectedApp?.mode === 'SANDBOX' ? 'bold' : 'normal', borderColor: selectedApp?.mode === 'SANDBOX' ? 'initial' : 'lightgray' }} color='primary' value="SANDBOX">
					{smallScreen && !hideMargin ? <Badge color="primary" variant="dot" /> : 'Sandbox'}
				</ToggleButton>
				<ToggleButton sx={{ fontWeight: selectedApp?.mode === 'PRODUCTION' ? 'bold' : 'normal', borderColor: selectedApp?.mode === 'PRODUCTION' ? 'initial' : 'lightgray' }} color='info' value="PRODUCTION">
					{smallScreen && !hideMargin ? <Badge color="info" variant="dot" /> : 'Live'}
				</ToggleButton>
			</ToggleButtonGroup>
			:
			null
	)
}

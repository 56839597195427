import React from "react";
import { useMutation } from "react-query";
import { additionalFeesApi } from "../utils/api";
import { useSelector } from "react-redux";
import { errorHandler } from "../../../configs/errorConfig";

const useDeleteAdditionalFees = () => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useMutation({
    mutationKey: ["DeleteAdditionalFees"],
    mutationFn: ({ uuid }) => additionalFeesApi?.delete(selectedApp?.id, uuid),
    onError: (error) => errorHandler(error),
  });
};

export default useDeleteAdditionalFees;

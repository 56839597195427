import React, { Fragment } from 'react'
import * as Flags from "country-flag-icons/react/3x2";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Box, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

import { pspWithLogo } from '@utils'
import { countryList } from "@utils/countries";
import PayoutChips from '@modules/payouts/components/payout-chips'
import CashoutChips from '@modules/cashouts/components/cashout-chips'
import TransactionChips from '@modules/transactions/components/transaction-chips'

export default function MobileDataTable({ datas, columns, onClick, dataType = 'transactions', removeLastElement = false }) {
  let tableColumns = [...columns]

  if (removeLastElement) tableColumns.pop()

  const performColumn = (data, column) => {

    const value = data[column?.id]

    const getCountry = () => {
      const country = countryList.filter(country => country.value === value);
      const code = country[0]?.value
      const label = country[0]?.label;
      const Flag = Flags[code]

      return (label === undefined && value) ?
        <Stack direction="row" gap={.4}>
          <LanguageOutlinedIcon fontSize="small" />
          <Typography>World</Typography>
        </Stack>
        :
        <Fragment><Flag width={20} style={{ marginRight: 10 }} title={label} /> {label}</Fragment>
    }

    const statusChips = {
      transactions: <TransactionChips status={value} />,
      cashouts: <CashoutChips status={value} />,
      validations: <CashoutChips status={value} />,
      payouts: <PayoutChips status={value} />
    }

    const performs = {
      gateway: pspWithLogo[value],
      transaction_gateway: pspWithLogo[value],
      collect_provider_id: pspWithLogo[value],
      provider_id: pspWithLogo[value],

      country: getCountry(),
      country_code: getCountry(),

      link: `${String(value).slice(0, 25)}...`,
      file_name: `${String(value).slice(0, 22)}...`,

      fee: `${value} ${data.currency}`,
      cashout_fee: `${value} ${data.cashout_currency ?? data.currency}`,
      transaction_fee: `${value} ${data.transaction_currency ?? data.currency}`,

      amount: `${value} ${data.currency}`,
      balance: `${value} ${data.currency}`,
      debit: `${value} ${data.currency}`,
      credit: `${value} ${data.currency}`,
      transaction_amount: `${value} ${data.transaction_currency ?? data.currency}`,
      cashout_amount: `${value} ${data.cashout_currency ?? data.currency}`,

      status: statusChips[dataType] ?? value,
      transaction_status: statusChips[dataType] ?? value,
      cashout_status: statusChips[dataType] ?? value,
      opt_verification_status: statusChips[dataType] ?? value,
      created_at: new Date(value)?.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }),
      create_time: new Date(value)?.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })
    }
    return performs[column?.id]
  }

  return (
    <Container sx={{ my: 1, py: 1 }}>
      {datas?.length && datas?.map((data, key) =>
        <Box onClick={() => onClick(data)} className='mobile-row' key={key} sx={{ width: '94%', mb: 1, px: '2%', mt: 2, py: 1, border: `1px solid lightgray`, borderRadius: 2 }}>
          {tableColumns?.map((column, key) => {
            if (column?.label === 'Actions' || column?.label === 'Action') return null

            return (
              <Box key={key}>
                <Grid container sx={{ mx: 'auto', py: 1 }}>
                  <Grid item xs={4} sx={{ fontWeight: 'bold' }}>
                    {column?.label}
                  </Grid>
                  <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end', textAlign: 'right' }}>
                    {data ?
                      (performColumn(data, column) ?? data[column?.id] ?? '...')
                      :
                      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Skeleton width={110} animation="wave" />
                      </Box>
                    }
                  </Grid>
                </Grid>
                {!(tableColumns.indexOf(column) === (tableColumns.length - 1)) && <Divider />}
              </Box>
            )
          })}
        </Box>
      )}
    </Container>
  )
}

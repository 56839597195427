import { useMutation } from 'react-query';
import { exportCashoutsAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useExportCashout = () => { 

    const mutation = useMutation({
        mutationKey: 'export_cashout',
        mutationFn: exportCashoutsAPI,
        onError: (error) => errorHandler(error) 
    })

    return mutation
}

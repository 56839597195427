import React from 'react'
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PulseText from '../PulseText';

export default function HelpAlert({ children, link, fullwidth = false, sx = {} }) {
	const navigate = useNavigate()
	// ================= COMPONENT =================
	return (
		<Alert
			onClick={() => navigate(link)}
			icon={<HelpOutlineOutlinedIcon
				fontSize="inherit" />}
			severity="info"
			sx={[{ width: fullwidth ? '100%' : 'fit-content' }, sx]}
		>
			<PulseText>{children}</PulseText>
		</Alert>
	)
}

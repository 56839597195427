import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ItemsInfo = ({ items }) => {

	//=============== Component ===================
	return (
		<Accordion defaultExpanded={true} variant="outlined">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel3-content"
				id="panel4-header"
			>
				<Typography fontWeight="bold">Items</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Table size="small">
					<TableHead sx={{ bgcolor: "#e6fffe" }}>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell sx={{ textAlign: "right" }}>Qty</TableCell>
							<TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items?.map((item, index) => (
							<TableRow key={index}>
								<TableCell sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
									{item?.name}
								</TableCell>
								<TableCell sx={{ textAlign: "right" }}>
									{item?.quantity}
								</TableCell>
								<TableCell sx={{ fontWeight: "bold", textAlign: "right" }}>
									{item?.amount}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</AccordionDetails>
		</Accordion>

	)
}
export default ItemsInfo;
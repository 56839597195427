import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
  return (
    <Stack sx={{ mt: 3, width: "100%", color: "gray" }} alignItems="center" justifyContent="center">
      <CircularProgress color="inherit" />
    </Stack>
  )
}

export default Spinner
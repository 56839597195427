import * as React from "react";
import { toast } from "react-toastify";

const countryNames = {
  CM: "Cameroon",
  GA: "Gabon",
  CI: "Ivory cost",
  SN: "Senegal",
  NG: "Nigeria",
  SL: "Sierra Leone",
  EG: "Egypt",
  KE: "Kenya",
  TZ: "Tanzania",
  UG: "Uganda",
  GH: "Ghana",
  BF: "Burkina faso",
  ZM: "Zambia",
  RW: "Rwanda",
};

export const supportedCurrencies = {
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  XAF: "XAF",
  XOF: "XOF",
  EGP: "EGP",
  SLE: "SLE",
  ZAR: "ZAR",
  MAD: "MAD",
  MWK: "MWK",
  MZN: "MZN",
  KES: "KES",
  TZS: "TZS",
  UGX: "UGX",
  GHS: "GHS",
  ZMW: "ZMW",
  RWF: "RWF",
};
export const currencyToCountryCode = {
  USD: "US",
  EUR: "EU",
  GBP: "GB",
  XAF: "CM",
  XOF: "SN",
  EGP: "EG",
  SLE: "SL",
  ZAR: "ZA",
  MAD: "MA",
  MWK: "MW",
  MZN: "MZ",
  KES: "KE",
  TZS: "TZ",
  UGX: "UG",
  GHS: "GH",
  ZMW: "ZM",
  RWF: "RW",
};

const countryFlags = {};
// See https://flagpedia.net/download/api for country flags img
Object.keys(countryNames).map((country) => {
  countryFlags[
    country
  ] = `https://flagcdn.com/w20/${country.toLowerCase()}.png`;
  return true;
});

const countryWithFlag = {};
Object.keys(countryNames).map((country) => {
  countryWithFlag[country] = (
    <>
      <img
        src={countryFlags[country]}
        width="16"
        height="12"
        alt={countryNames[country]}
      />{" "}
      {countryNames[country]}{" "}
    </>
  );
  return true;
});

countryWithFlag["ALL"] = <> All </>;
countryWithFlag["WORLD"] = <> World</>;

const pspLinks = {
  mtn: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  orange:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
};

const regexProvider = {
  CM_MTNMOMO:
    /^(?:237)?(:?6(:?(:?7)(:?\d){7}$))|(:?6(:?(:?5[0-4])(:?\d){6}$))|(:?6(:?(:?8)(:?\d){7}$))/,
  CM_ORANGE: /^(?:237)?(:?6(:?(:?9)(:?\d){7}$))|(:?6(:?(:?5[5-9])(:?\d){6}$))/,
  CI_MTN: /^(?:225)?(01|02|03|04|05)\d{7}$/,
  CI_ORANGE: /^(?:225)?(07|08|09)\d{7}$/,
  CI_MOOV: /^(?:225)?(01|05|07|08|40)\d{7}$/,
  SN_ORANGE: /^(?:221)?(76|77|78)\d{7}$/,
  SN_FREEMONEY: /^(?:221)?(76|77|78)\d{7}$/,
  SN_EMONEY: /^(?:221)?(76|77|78)\d{7}$/,
  RW_MTN: /^(?:250)?(78)\d{7}$/,
  RW_AIRTEL: /^(?:250)?(73)\d{7}$/,
  GA_MOOV: /^(?:241)?(:?0(:?(:?6)(:?\d){7}$))/,
  GA_AIRTEL: /^(?:241)?(:?0(:?(:?7)(:?\d){7}$))/,
  SL_ORANGE: /^(?:232)?(25|30|33|34|40|44|50|55|7[0-9])\d{6}$/,
  BF_ORANGE: /^(?:226)?(:?(:?(([5 - 7][4 - 7])|(0[5 - 7])))(:?\d){6}$)/,
  KE_MPESA: /^(?:254)?(:?\d){9}$/,
  BF_MOBICASH:
    /^(?:226)?(:?(:?7[0-3])(:?(:?0[0|5])|(:?1[0|9])|(:?2[0|2])|(:?9[0|1|9])|(:?4[0|5])|(:?5[0|5])|(:?60)|(:?81))(:?\d){4}$)/,
  TZ_AIRTEL: /^(?:255)?(:?(:?\d){9}$)/,
  TZ_TIGO: /^(?:255)?(:?(:?\d){9}$)/,
  TZ_HALOPESA: /^(?:255)?(:?(:?\d){9}$)/,
  TZ_VODAFONE: /^(?:255)?(:?(:?\d){9}$)/,
  UG_VODAFONE: /^(?:256)?(:?\d){9}$/,
  UG_MTN: /^(?:256)?(:?\d){9}$/,
  UG_AIRTEL: /^(?:256)?(:?\d){9}$/,
  GH_VODAFONE: /^(?:233)?(:?\d){9}$/,
  GH_MTN: /(^(?:233)?(:?\d){9}$)/,
  GH_TIGO: /^(?:233)?(:?\d){9}$/,
  ZM_AIRTEL: /(^(?:260)?(:?9)(:?\d){8}$)/,
  ZM_MTN: /(^(?:260)?(:?9)(:?\d){8}$)/,
  ZM_ZAMTEL: /(^(?:260)?(:?9)(:?\d){8}$)/,
};

const pspLogos = {
  CM_MTNMOMO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CM_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_MOBICASH:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mobicash.png",
  CI_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CI_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  CI_MOOV:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_MOOV:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  GH_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  GH_TIGO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  GH_VODAFONE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  KE_MPESA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mpesa.png",
  RW_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  RW_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  SN_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  SN_FREEMONEY:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/free-money.png",
  SN_EMONEY:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/e-money.png",
  SL_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  TZ_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  TZ_TIGO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/tigo.png",
  TZ_HALOPESA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/halopesa.png",
  TZ_VODAFONE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  UG_VODAFONE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  UG_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  UG_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  ZM_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  ZM_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  ZM_ZAMTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/zamtel.png",
  WORLD_PAYPAL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/paypal.png",
  WORLD_VISA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/cb.png",
};

const pspWithLogo = {
  CM_MTNMOMO: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["CM_MTNMOMO"]} width="25" alt="MTN" /> MTN MOMO CM
    </div>
  ),
  CM_ORANGE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["CM_ORANGE"]} width="25" alt="Orange" /> ORANGE MONEY
      CM
    </div>
  ),
  BF_ORANGE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["BF_ORANGE"]} width="25" alt="Orange BF" /> ORANGE
      MONEY BF
    </div>
  ),
  BF_MOBICASH: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["BF_MOBICASH"]} width="25" alt="MOBICASH BF" />{" "}
      MOBICASH BF
    </div>
  ),
  CI_MTN: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["CI_MTN"]} width="25" alt="MTN" /> MTN MOMO CI
    </div>
  ),
  CI_ORANGE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["CI_ORANGE"]} width="25" alt="Orange" /> ORANGE MONEY
      CI
    </div>
  ),
  CI_MOOV: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["CI_MOOV"]} width="25" alt="Moov" /> MOOV MONEY CI
    </div>
  ),
  GA_MOOV: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["GA_MOOV"]} width="25" alt="Moov" /> MOOV MONEY GA
    </div>
  ),
  GA_AIRTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["GA_AIRTEL"]} width="25" alt="Airtel" /> AIRTEL MONEY
      GA
    </div>
  ),
  GH_VODAFONE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["GH_VODAFONE"]} width="25" alt="VODAFONE MONEY GH" />{" "}
      VODAFONE MONEY GH
    </div>
  ),
  GH_MTN: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["GH_MTN"]} width="25" alt="MTN MOMO GH" /> MTN MOMO GH
    </div>
  ),
  GH_TIGO: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["GH_TIGO"]} width="25" alt="TIGO MONEY GH" /> TIGO
      MONEY GH
    </div>
  ),
  KE_MPESA: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["KE_MPESA"]} width="25" alt="Mpesa KE" /> MPESA KE
    </div>
  ),
  RW_MTN: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["RW_MTN"]} width="25" alt="MTN RW" /> MTN RW
    </div>
  ),
  RW_AIRTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["RW_AIRTEL"]} width="25" alt="AIRTEL RW" /> AIRTEL
      MONEY RW
    </div>
  ),
  SN_ORANGE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["SN_ORANGE"]} width="25" alt="Orange" /> ORANGE MONEY
      SN
    </div>
  ),
  SN_FREEMONEY: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["SN_FREEMONEY"]} width="25" alt="FreeMoney" /> FREE
      MONEY SN
    </div>
  ),
  SN_EMONEY: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["SN_EMONEY"]} width="25" alt="FreeMoney" /> E MONEY SN
    </div>
  ),
  SL_ORANGE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["SL_ORANGE"]} width="25" alt="Orange" /> ORANGE MONEY
      SL
    </div>
  ),
  TZ_AIRTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["TZ_AIRTEL"]} width="25" alt="AIRTEL MONEY TZ" />{" "}
      AIRTEL MONEY TZ
    </div>
  ),
  TZ_TIGO: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["TZ_TIGO"]} width="25" alt="TIGO MONEY TZ" /> TIGO
      MONEY TZ
    </div>
  ),
  TZ_HALOPESA: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["TZ_HALOPESA"]} width="25" alt="HALOPESA MONEY TZ" />{" "}
      HALOPESA MONEY TZ
    </div>
  ),
  TZ_VODAFONE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["TZ_VODAFONE"]} width="25" alt="VODAFONE MONEY TZ" />{" "}
      VODAFONE MONEY TZ
    </div>
  ),
  UG_VODAFONE: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["UG_VODAFONE"]} width="25" alt="VODAFONE MONEY UG" />{" "}
      VODAFONE MONEY UG
    </div>
  ),
  UG_MTN: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["UG_MTN"]} width="25" alt="MTN MONEY UG" /> MTN MONEY
      UG
    </div>
  ),
  UG_AIRTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["UG_AIRTEL"]} width="25" alt="AIRTEL MONEY UG" />{" "}
      AIRTEL MONEY UG
    </div>
  ),
  ZM_AIRTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["ZM_AIRTEL"]} width="25" alt="AIRTEL MONEY ZM" />{" "}
      AIRTEL MONEY ZM
    </div>
  ),
  ZM_MTN: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["ZM_MTN"]} width="25" alt="MTN MOMO ZM" /> MTN MOMO ZM
    </div>
  ),
  ZM_ZAMTEL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["ZM_ZAMTEL"]} width="25" alt="ZAMTEL MONEY ZM" />{" "}
      ZAMTEL MONEY ZM
    </div>
  ),
  WORLD_PAYPAL: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["WORLD_PAYPAL"]} width="25" alt="Paypal" /> PAYPAL
    </div>
  ),
  WORLD_VISA: (
    <div style={{ display: "flex", gap: ".4em", alignItems: "center" }}>
      <img src={pspLogos["WORLD_VISA"]} width="25" alt="Visa" /> VISA
    </div>
  ),
};
const countryValues = Object.keys(countryNames).map((country) => {
  return {
    label: countryWithFlag[country],
    value: country,
  };
});
countryValues.push({ label: countryWithFlag["WORLD"], value: "WORLD" });

const currencyList = [
  "XAF",
  "USD",
  "XOF",
  "EUR",
  "NGN",
  "EGP",
  "MAD",
  "MZN",
  "SLE",
  "ZAR",
  "KES",
  "TZS",
  "UGX",
  "GHS",
  "ZMW",
  "RWF",
];

const currencyListOptions = currencyList.map((currency) => {
  return { label: currency, value: currency };
});

const countryCurrencies = {
  CM: "XAF",
  GA: "XAF",
  BF: "XOF",
  CI: "XOF",
  SN: "XOF",
  SL: "SLE",
  KE: "KES",
  TZ: "TZS",
  UG: "UGX",
  GH: "GHS",
  ZM: "ZMW",
  RW: "RWF",
};
export const invertedCountryCurrencies = {
  XAF: ["CM", "GA"],
  XOF: ["BF", "CI", "SN"],
  SLE: ["SL"],
  KES: ["KE"],
  TZS: ["TZ"],
  UGX: ["UG"],
  GHS: ["GH"],
  ZMW: ["ZM"],
  RWF: ["RW"],
};

const worldCurrencies = ["USD", "MAD", "MZN", "ZAR", "NGN", "EGP"];

const kycCountryValues = countryValues.filter((country) => {
  return country.value !== "WORLD";
});
const verifyStatus = [
  { label: "NOT VERIFIED", value: "NOT_VERIFIED" },
  { label: "VERIFIED", value: "VERIFIED" },
];

const formatDate = (date) => {
  return new Date(date)?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const modes = [
  { value: "live", label: "Live" },
  { value: "test", label: "SandBox" },
];
const amountWithCurrency = (amount, currency) =>
  `${new Intl.NumberFormat().format(amount)} ${currency}`;

// ** Converts table to CSV
const convertArrayOfObjectsToCSV = (array) => {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object?.keys(array["items"]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array["items"].forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

// ** Downloads CSV
const downloadCSV = (array, prefix = "document-export") => {
  if (!array || array === null || array.length === 0) return;

  toast.info("The exportation is processing", { autoClose: 2000 });

  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null || csv === undefined) return;

  const filename = `${prefix}-${Date.now()}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const businessTypeOptions = [
  {
    label: "Association",
    value: "ASSOCIATION",
  },
  {
    label: "Company",
    value: "COMPANY",
  },
  {
    label: "Individual or self employed",
    value: "INDIVIDUAL_OR_SELF_EMPLOYED",
  },
];

const businessTypes = {
  ASSOCIATION: "Association",
  COMPANY: "Company",
  INDIVIDUAL_OR_SELF_EMPLOYED: "Individual or self employed",
};

export {
  verifyStatus,
  businessTypeOptions,
  businessTypes,
  countryNames,
  countryWithFlag,
  downloadCSV,
  currencyList,
  countryValues,
  kycCountryValues,
  regexProvider,
  pspLogos,
  worldCurrencies,
  countryCurrencies,
  currencyListOptions,
  pspWithLogo,
  formatDate,
  pspLinks,
  modes,
  amountWithCurrency,
  months,
};

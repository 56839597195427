import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { pspWithLogo } from "@src/utils";
import CashoutChips from "./cashout-chips";
import DetailInput from "@components/Input-Detail";

export default function CashoutDetails({ open, onClose, cashoutData }) {
  console.log({ cashoutData });
  const {
    email,
    create_time,
    cashout_status,
    cashout_data,
    cashout_fee,
    provider_id,
    amount,
    currency,
    cashout_note,
  } = cashoutData;

  const date = (time) => {
    return new Date(time)?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const amountWithCurrency = (amount, currency) =>
    `${new Intl.NumberFormat().format(amount)} ${currency}`;

  console.log({ cashout_fee });

  return (
    <Fragment>
      <Dialog onClose={onClose} fullWidth open={open} maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Cashout Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DetailInput label="email" value={email} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput
                label="amount"
                value={amountWithCurrency(amount, currency)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput
                label="Cashout Status"
                value={<CashoutChips status={cashout_status} />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput label="beneficiary" value={cashout_data ?? "None"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput
                label="provider"
                value={pspWithLogo[provider_id] ?? pspWithLogo["WORLD_VISA"]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput
                label="fee"
                value={amountWithCurrency(cashout_fee, currency)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailInput
                label="cashout note"
                value={cashout_note ?? "None"}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <DetailInput label="date" value={date(create_time)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

import * as  Yup from 'yup'

const amountRegex = /^[1-9][0-9]*$/

export const linkValidation = () => {
  return Yup.object().shape({
        product_name: Yup.string().required('A product name is required'),
        application_id: Yup.string(),
        mode: Yup.string().required('Mode must be selected'),
        amount: Yup.string().required('Please enter an amount')
        .matches(amountRegex, { message: 'Amount  must be grater than or equal to 1' })
        .test('is-greater-than-min-amount', 'Amount must be greater than or equal to min_amount', function(value) {
          const minAmount = this.resolve(Yup.ref('min_amount'));
          const isModifiable = this.resolve(Yup.ref('isModifiable'));
          if (isModifiable) {
            return Number(value) >= minAmount;
          }
          return true;
        }),
        isModifiable: Yup.boolean().default(false),
        currency: Yup.string().required('Currency is required'),
        min_amount: Yup.string().matches(amountRegex, { message: 'Min Amount  must be grater than or equal to 1' })
        .test('is-valid-min-amount', 'Minimum amount should be a positive number', function(value) {
          const isModifiable = this.resolve(Yup.ref('isModifiable'));
    
          if (isModifiable && !value) {
            return this.createError({ message: 'Minimum amount is required' });
          }
    
          return true;
        })
        .test('is-positive-min-amount', 'Minimum amount should be a positive number', function(value) {
          const isModifiable = this.resolve(Yup.ref('isModifiable'));
    
          if (isModifiable && parseInt(value) <= 0) {
            return this.createError({ message: 'Minimum amount should be a positive number' });
          }
    
          return true;
        })
        .test('is-less-than-amount', 'Minimum amount should not be greater than amount', function(value) {
          const amount = this.parent.amount;
          const isModifiable = this.parent.isModifiable;
    
          if (isModifiable && parseInt(value) >= amount) {
            return this.createError({ message: 'Minimum amount should not be greater than amount' });
          }
    
          return true;
        }),
        description: Yup.string(),
    })
}
 
import { useMutation } from 'react-query';
import { addKycsAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useAddKycs = () => { 

	const mutation = useMutation({
		mutationKey: 'addKycs',
		mutationFn: addKycsAPI,
		onError: (error) => errorHandler(error) 
	})

	return mutation
}
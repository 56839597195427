import React from "react";
import { useQuery } from "react-query";
import { additionalDetailsApi } from "../utils/api";
import { useSelector } from "react-redux";

const useGetAllAditionalDetails = (filter) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useQuery({
    queryKey: ["getAllAdditionalDetails"],
    queryFn: () => additionalDetailsApi.getAll(selectedApp?.id, filter),
  });
};

export default useGetAllAditionalDetails;

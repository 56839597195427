import { useQuery, useQueryClient } from "react-query"
import { getPayoutsAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const usePayouts = (appID , filters) => {  
  
  const queryClient = useQueryClient(); // Utilisez useQueryClient pour accéder au client de requête

  // Utilisez une clé de requête dynamique pour suivre les modifications de selectedApp  
  const queryKey = [`payouts-${appID}-${filters?.page}-${filters?.limit}-${filters?.search}`, appID];

  const query = useQuery({
    queryKey,
    queryFn: async () => await getPayoutsAPI(appID, filters),
    onError: (error) => errorHandler(error) 
  });

  // Utilisez useEffect pour invalider manuellement la requête lorsque selectedApp change
  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [appID]);

  return query;

}

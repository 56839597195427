import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { posAgentApi } from '../exports'
import { useSelector } from 'react-redux'
import { errorHandler } from '../../../../configs/errorConfig'
import { toast } from 'react-toastify'

const useUpdateAgent = (id) => {

  const queryClient = useQueryClient()
  const mutationKey = ["updateAgent"]
 const selectedApp = useSelector((store) => store.data.selectedApp);

  const mutation = useMutation({
    mutationKey,
    mutationFn:(data)=>posAgentApi.update_POS_Agents({
      updateAgent:{

        ...data,
      },
       application_id:selectedApp.id
      },id),
    onError:(error)=>errorHandler(error),
    onSuccess:()=>{
      toast.success('agent updated')
      queryClient.invalidateQueries()
    }
  })
  return mutation
}

export default useUpdateAgent
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const CardBox = ({ data, columns }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {data?.map((rowData, i) => (
        <Box bgcolor={grey[100]} borderRadius="8px" key={"data" + i}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                fontWeight: "500",
                width: "1.5rem",
                height: "1.5rem",
                border: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                m: 1,
              }}
            >
              {i + 1}
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                m: 1,
              }}
            >
              {columns?.map((columnsItems, index) => (
                <Box
                  key={index}
                  display="flex"
                  gap={3}
                  sx={{
                    width: "100%",
                    borderBottom: "1px",
                    borderColor: grey[100],
                    px: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "500",
                      width: 20 + "%",
                    }}
                  >
                    {columnsItems?.title}
                  </Box>
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      width: `80%`,
                    }}
                  >
                    {columnsItems?.cell(rowData)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CardBox;

import * as Yup from 'yup';

export const MobileMoneyValidationschema = Yup.object().shape({
  balance: Yup.object().shape({
    currency: Yup.string().required(),
    Balance: Yup.string().required(),
  }),
  toCurrency: Yup.object().shape({
    currency: Yup.string().required(),
    code: Yup.string().required(),
  }),
  amount: Yup.string().required(),
  beneficiary_name: Yup.string().required(),

  account_number: Yup.string().required(),
  //   country: Yup.string().required(),
  // country: Yup.object().shape({
  //   code: Yup.string().required(),
  //   country: Yup.string().required(),
  // }),
  account_bank: Yup.string().required(),

});

export const confirmDeposit = Yup.object().shape({
  verification_code: Yup.string().required(),
  desposit_message: Yup.string(),
});

export const TransfertconfirmDeposit = Yup.object().shape({
  verification_code: Yup.string().required(),
});
import React from "react";
import { useMutation } from "react-query";
import { additionalFeesApi } from "../utils/api";
import { useSelector } from "react-redux";
import { errorHandler } from "../../../configs/errorConfig";

const useCreateAdditionalFees = () => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useMutation({
    mutationKey: ["createAdditionalFees"],
    mutationFn: (data) => additionalFeesApi.create(selectedApp?.id, data),
    onError: (error) => errorHandler(error),
  });
};

export default useCreateAdditionalFees;

import { ApolloClient, InMemoryCache } from "@apollo/client";

import { BASE_API_URL } from "../configs/base_url";

export const apolloClient = new ApolloClient({
    uri: `${BASE_API_URL}/graphql`,
    cache: new InMemoryCache(),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-first"
      }
    }

});
import React from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'

export default function PromoterDetail({ formKYC }) {
  return (

    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_promoter_name'>Business promoter name</label>
          </Typography>
        </Box>
        <TextField
          required
          type='text'
          placeholder='Business promoter name'
          id='business_promoter_name'
          variant='outlined'
          size='small'
          fullWidth
          onChange={formKYC.handleChange}
          onBlur={formKYC.handleBlur}
          value={formKYC.values.business_promoter_name}
          error={formKYC.touched.business_promoter_name && formKYC.errors.business_promoter_name}
          helperText={formKYC.touched.business_promoter_name && formKYC.errors.business_promoter_name}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_promoter_phone'>Business promoter phone</label>
          </Typography>
        </Box>
        <TextField
          required
          type='tel'
          placeholder='Business promoter phone'
          id='business_promoter_phone'
          variant='outlined'
          size='small'
          fullWidth
          onChange={formKYC.handleChange}
          onBlur={formKYC.handleBlur}
          value={formKYC.values.business_promoter_phone}
          error={formKYC.touched.business_promoter_phone && formKYC.errors.business_promoter_phone}
          helperText={formKYC.touched.business_promoter_phone && formKYC.errors.business_promoter_phone}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_promoter_email'>Business promoter email</label>
          </Typography>
        </Box>
        <TextField
          required
          type='email'
          placeholder='Business promoter email'
          id='business_promoter_email'
          variant='outlined'
          size='small'
          fullWidth
          onChange={formKYC.handleChange}
          onBlur={formKYC.handleBlur}
          value={formKYC.values.business_promoter_email}
          error={formKYC.touched.business_promoter_email && formKYC.errors.business_promoter_email}
          helperText={formKYC.touched.business_promoter_email && formKYC.errors.business_promoter_email}
        />
      </Grid>

    </Grid>
  )
}

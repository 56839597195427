import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SimpleTable = ({ data, columns, sx }) => {
  return (
    <TableContainer>
      <Table sx={sx} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns?.map((items, i) => (
              <TableCell key={items?.key ?? i}>
                {items?.title?.replace("_", " ")}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data?.map((rowData, i) => (
          <TableBody key={i} className="text-[12px] ">
            {columns?.map((columnsItems, index) => (
              <TableCell key={index}>{columnsItems?.cell(rowData)}</TableCell>
            ))}
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;

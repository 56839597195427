import { Alert, AlertTitle, Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, SwipeableDrawer, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MobileMoney from "./mobileMoney";
import InterTransForm from "../../Inter-transfer/components/interTransForm";
import ModalConfirmDeposit from "./ModalConfirmDeposit";
import ModalConfirmTransfert from "../../Inter-transfer/components/ModalConfirm";
import { useSelector } from "react-redux";
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import { MoveDown } from '@mui/icons-material';

const DisburseData = () => {
  const navigate = useNavigate();

  const [isClicked, setIisClicked] = useState(null)

  const [isConfirmDepositModal, setIsConfirmDepositModal] = useState({
    open: false,
    data: {},
  });
  const [params] = useSearchParams();
  const type = params.get("type");

  const onCloseConfirmDeposit = () => {
    setIsConfirmDepositModal({
      data: {},
      open: false,
    });
  };
  const [isConfirmTransfertModal, setIsConfirmTransfertModal] = useState({
    open: false,
    data: {},
  });
  const onCloseConfirmTransfert = () => {
    setIsConfirmTransfertModal({
      data: {},
      open: false,
    });
  };

  const confirmDepositProps = {
    open: isConfirmDepositModal,
    handleClose: onCloseConfirmDeposit,
  };
  const momoProps = {
    setIsConfirmDepositModal,
  };
  const confirmTransfertProps = {
    open: isConfirmTransfertModal,
    handleClose: onCloseConfirmTransfert,
  };

  const intTransProps = {
    setIsConfirmTransfertModal,
  };

  const selectedApp = useSelector((store) => store.data.selectedApp);
  const [drawer, setDrawer] = useState({
    right: false
  })
  const toggleDrawer =
    (anchor, open) =>
      (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }

        setDrawer({ ...drawer, [anchor]: open });
      };

  const handleNavigate = () => {
    if (isClicked === 1) {
      navigate(`/${selectedApp?.id}/disbursement/new/single?type=mobilemoney`)
    } else if (isClicked === 2) {
      navigate(`/${selectedApp?.id}/disbursement/new/single?type=currencyTransfer`)
    }
  }


  useEffect(() => {
    handleNavigate()
  }, [isClicked])


  const list = (anchor) => (
    <Box
      sx={{ width: 350, mt: "5rem" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography variant="h6" sx={{ p: 2 }}>
        Other Transfers
      </Typography>
      <Divider sx={{ bgcolor: grey[100] }} />
      <List>
        {listData.map((text, index) => (
          <ListItem key={text.text} onClick={() => setIisClicked(index + 1)} sx={{}} disablePadding>
            <ListItemButton >
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  function navigateRoute() {
    if (type === `mobilemoney`) {
      return <MobileMoney {...momoProps} />;
    } else if (type === `currencyTransfer`) {
      return <InterTransForm {...intTransProps} />;
    }
  }

  if (!type) {
    return navigate(`/${selectedApp?.id}/disbursement/new`);
  }
  const goBack = () => {
    navigate(-1);
  };


  return (
    <Fragment>
      <div style={{ position: "relative", height: "100%" }}>
        <Box sx={{ height: "100%" }}>
          <Paper sx={{ height: "89vh" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                py: '1rem'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  textTransform: "capitalize",
                  px: "2rem",
                }}
              >
                Transfer{" "}
                {type === `mobilemoney`
                  ? `to Mobile money`
                  : `between Currency Balances`}
              </Typography>
              <Box sx={{ px: "2rem", gap: "1rem" }}>
                <Button
                  size="small"
                  sx={{ px: "2rem" }}
                  variant="text"
                  onClick={goBack}
                >
                  cancel
                </Button>
                <Button
                  size="small"
                  sx={{ px: "2rem" }}
                  variant="contained"
                  onClick={toggleDrawer("right", true)}
                >
                  Other Transfers
                </Button>
              </Box>



              <SwipeableDrawer
                anchor={"right"}
                open={drawer["right"]}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
                sx={{ backdropFilter: "blur(2px) hue-rotate(265deg)" }}
              >
                {list("right")}
              </SwipeableDrawer>



            </Box>
            <Divider sx={{ bgcolor: grey[50] }} />
            <Box
              sx={{
                height: "auto",
                width: "auto",

                padding: "2rem",
              }}
              display="flex"
              gap={8}
              alignItems="baseline"
            >
              <Box sx={{ width: "30rem" }}>{navigateRoute()}</Box>
              <Box sx={{ width: "30rem" }}>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  Before proceeding, please note the following:
                  <ul style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
                    <li><strong>Exchange Rates</strong>: Currency exchange rates may fluctuate and impact the amount you receive in the target currency. Ensure you are aware of the current rates.</li>
                    <li><strong>Fees and Charges</strong> : Transaction fees and currency conversion fees may apply. Check the terms and conditions of your financial institution or currency exchange provider.</li>
                    <li><strong>Confirm Details</strong>: Double-check the details of your transaction, including the amount, source currency, and target currency, to avoid any errors.</li>
                    <li><strong>Time Sensitivity</strong>: Exchange rates can change rapidly. Consider the timing of your transaction to optimize the value of your exchange.</li>
                  </ul>
                  By clicking 'Proceed,' you acknowledge and accept the above conditions. Ensure you are making an informed decision. If you have any doubts, please contact the support.

                </Alert>
              </Box>
            </Box>
          </Paper>
        </Box>
        <ModalConfirmTransfert {...confirmTransfertProps} />
        <ModalConfirmDeposit {...confirmDepositProps} />
      </div>
    </Fragment>
  );
};

const listData = [{ text: 'Transfer to mobile money', icon: <SendToMobileOutlinedIcon /> }, { text: 'Transfer between currencies', icon: <MoveDown /> },]

export default DisburseData;

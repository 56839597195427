import { useMutation, useQueryClient } from 'react-query';
import { Disbursement, toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';
import { useSelector } from 'react-redux';

export const useInitialiseDeposite = () => {
    const client = useQueryClient()
    const diburseapi = new Disbursement()
    const mutation = useMutation({
        mutationKey: 'initialDeposite',
        mutationFn: diburseapi.initializeDep,
        onSuccess: () => {
            client.invalidateQueries()
        },
    })

    return mutation
}
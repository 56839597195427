import React, { Fragment, useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/CustomBreadcrumbs";
import GradingIcon from "@mui/icons-material/Grading";
import { useParams } from "react-router-dom";
import { Button, LinearProgress, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateDetailsModal from "../../../modules/pos-additional-details/components/CreateDetailsModal";
import useModals from "../../../modules/pos-additional-details/hooks/useModals";
import DataGrid from "@components/datagrid";
import useGetAllAditionalDetails from "../../../modules/pos-additional-details/hooks/useGetAllAditionalDetails";
import { additionalDetailsColumn } from "../../../modules/pos-additional-details/utils/columns";
import DeleteDetailsModal from "../../../modules/pos-additional-details/components/DeleteDetailsModal";
import useDeleteAdditionalDetails from "../../../modules/pos-additional-details/hooks/useDeleteAdditionalDetails";
import DetailsModal from "../../../modules/pos-additional-details/components/detailsModal";

const PosAdditionalDetails = () => {
  const { appID } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { createModal, detailsModal, deleteModal, handleModals } = useModals();

  const { data, isLoading, refetch } = useGetAllAditionalDetails({
    page: currentPage,
    limit: rowsPerPage,
  });
  const _isLoading = [isLoading].includes(true);

  useEffect(() => {
    refetch();
  }, [currentPage, createModal, detailsModal, deleteModal]);

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onFirstPage: () => setCurrentPage(0),
    onNextPage: () => setCurrentPage((page) => page + 1),
    count: data?.data?.data?.response?.meta?.totalItems || 1,
    onPreviousPage: () => setCurrentPage((page) => page - 1),
    onLastPage: () =>
      setCurrentPage(data?.data?.data?.response?.meta?.totalPages - 1),
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
  };

  const columnProps = {
    setEditModal: handleModals.openUpdateModal,
    setDeleteModal: handleModals.openDeleteModal,
    setDetailsModal: handleModals.openDetailsModal,
  };

  const breadCrumb = [
    {
      label: "Additional Details",
      link: `/${appID}/pos/additional-details`,
      icon: <GradingIcon fontSize="small" />,
    },
  ];

  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            handleModals?.openCreateModal();
          }}
          sx={{ boxShadow: "none" }}
        >
          Add An Additional Detail
        </Button>
      </Stack>
      {data?.data?.data &&
      data?.data?.data?.response &&
      data?.data?.data?.response?.items?.length > 0 ? (
        <DataGrid
          data={data?.data?.data?.response?.items}
          isLoading={_isLoading}
          paginationOptions={paginationOptions}
          column={additionalDetailsColumn({ ...columnProps })}
          head={"POS Additional Details"}
        />
      ) : null}
      <CreateDetailsModal
        open={createModal}
        handleClose={handleModals?.closeCreateModal}
      />
      <DeleteDetailsModal
        open={deleteModal}
        useDelete={useDeleteAdditionalDetails}
        handleClose={handleModals?.closeDeleteModal}
        label="details"
      />
      <DetailsModal
        open={detailsModal}
        handleClose={handleModals.closeDetailsModal}
      />
    </Fragment>
  );
};

export default PosAdditionalDetails;

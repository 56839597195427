import { useMutation } from 'react-query';
import { registerAPI } from "../utils/api";

export const useRegister = () => { 

  const mutation = useMutation({
    mutationKey: 'register',
    mutationFn:registerAPI
  })

return mutation
 }
import React, { Fragment } from 'react';
import { ErrorMessage, Field, FieldArray } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField
} from '@mui/material';
import { Add } from '@mui/icons-material';


const DynamicFields = ({ values, setFieldValue, calculateRowAmount }) => {

  //=============== component =================== 

  return (
    <FieldArray name="items">
      {({ push, remove }) => (
        <Fragment>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Price(PU)</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values?.items?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ minWidth: "380px" }}>
                    <Field name={`items[${index}].name`}>
                      {({ field }) => (
                        <TextField variant="outlined" {...field} fullWidth size='small' />
                      )}
                    </Field>
                    <ErrorMessage className='err_message' name={`items[${index}].name`} component="div" />
                  </TableCell>
                  <TableCell sx={{ maxWidth: "100px" }}>
                    <Field name={`items[${index}].price`}>
                      {({ field }) => (
                        <TextField variant="outlined" type='number' {...field} size='small' onChange={(event) => {
                          const price = parseFloat(event.target.value) || 0;
                          const quantity = parseFloat(row.quantity);
                          const amount = calculateRowAmount({ price, quantity });
                          setFieldValue(`items[${index}].price`, price);
                          setFieldValue(`items[${index}].amount`, amount);
                          setFieldValue(`items[${index}].quantity`, 1);

                        }} />
                      )}
                    </Field>
                    <ErrorMessage className='err_message' name={`items[${index}].price`} component="div" />
                  </TableCell>
                  <TableCell sx={{ maxWidth: "50px" }}>
                    <Field name={`items[${index}].quantity`}>
                      {({ field }) => (
                        <TextField variant="outlined" type='number' {...field} size='small'
                          onChange={(event) => {
                            const price = parseFloat(row.price);
                            const quantity = parseFloat(event.target.value);
                            const amount = calculateRowAmount({ price, quantity });
                            setFieldValue(`items[${index}].quantity`, quantity);
                            setFieldValue(`items[${index}].amount`, amount);
                          }} />
                      )}
                    </Field>
                    <ErrorMessage className='err_message' name={`items[${index}].quantity`} component="div" />
                  </TableCell>
                  <TableCell sx={{ maxWidth: "100px" }}>
                    <Field name={`items[${index}].amount`}>
                      {({ field }) => (
                        <TextField variant="outlined" {...field} size='small' readOnly disabled />
                      )}
                    </Field>
                    <ErrorMessage className='err_message' name={`items[${index}].amount`} component="div" />
                  </TableCell>
                  <TableCell>
                    <IconButton type="button" onClick={() => remove(index)}>
                      <HighlightOffIcon fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button variant="contained" color='primary'
            type="button"
            size='small'
            sx={{ width: "fit-content", mt: 1, textTransform: "initial" }}
            startIcon={<Add fontSize="small" />}
            onClick={() => push({ name: '', price: '', quantity: '', amount: '' })}
          >
            Add Item
          </Button>
        </Fragment>
      )}
    </FieldArray>
  )
};

export default DynamicFields;
import React, { Fragment, useEffect, useState } from "react";
import CustomInput from "@components/CustomInput";
import CustomButton from "@components/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { posAgentSchema, posAgentUpdateSchema } from "../utils/validation";
import useCreateAgent from "../utils/hooks/useCreateAgent";
import useUpdateAgent from "../utils/hooks/useUpdateAgent";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import useGetAllPosAgents from "../utils/hooks/useGetAllPosAgents";
import TemporaryPasswordHandler from "./temporaryPasswordHandler";

const AgentForm = ({ pos_id, data, onClose }) => {
  const { mutate, isSuccess, isLoading, isError } = useCreateAgent(pos_id);
  const {
    mutate: updateMutate,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateAgent(data?.uuid);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const [getTemporaryKey, setGetTemporaryKey] = useState({
    open: false,
    temp: "",
  });

  const handleTempClose = () =>
    setGetTemporaryKey({
      open: false,
      temp: "",
    });

  const temporaryPasswordProps = {
    modal: getTemporaryKey,
    onClose: handleTempClose,
  };

  const onSubmit = (inputs) => {
    setCreateLoading(true);
    mutate(inputs, {
      onSuccess: (res) => {
        toast.success(`${res?.data?.message}`);
        setGetTemporaryKey({
          open: true,
          temp: res?.data?.data?.response?.temporaryPassword,
        });
      },
    });
  };
  const submitUpdate = (inputs) => {
    setUpdateLoading(true);
    updateMutate(inputs);
  };

  const formik = useFormik({
    initialValues: !data
      ? {
          name: "",
          email: "",
          username: "",
        }
      : {
          username: "",
          email: "",
          status: "",
        },
    validationSchema: !data ? posAgentSchema : posAgentUpdateSchema,
    onSubmit: data ? submitUpdate : onSubmit,
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("email", data?.email);
      formik.setFieldValue("status", data?.status);
      formik.setFieldValue("username", data?.username);
    }
  }, [data]);

  useEffect(() => {
    let isInterval;
    if (isUpdateSuccess || isUpdateError) {
      isInterval = setTimeout(() => {
        setUpdateLoading(false);
        formik.resetForm();
        onClose();
      }, 3000);
    }
    return () => {
      clearTimeout(isInterval);
    };
  }, [isUpdateSuccess || isUpdateError]);

  useEffect(() => {
    let isInterval;
    if (isSuccess || isError) {
      isInterval = setTimeout(() => {
        setCreateLoading(false);
        formik.resetForm();
        onClose();
      }, 3000);
    }
    return () => {
      clearTimeout(isInterval);
    };
  }, [isSuccess, isError]);

  return (
    <Fragment>
      <TemporaryPasswordHandler {...temporaryPasswordProps} />
      <Stack gap={2} component="form" onSubmit={formik.handleSubmit}>
        {!data && (
          <FormControl>
            <CustomInput
              name="name"
              label="Agent Name"
              type="text"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helpertext={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Agent Name"
            />
            {formik.errors?.name && formik.touched?.name && (
              <FormHelperText fullWidth error>
                <strong>{formik.errors?.name}</strong>
              </FormHelperText>
            )}
          </FormControl>
        )}

        <FormControl>
          <CustomInput
            name="email"
            label="Agent Email"
            type="email"
            value={formik.values.email}
            error={formik.touched.email && !!formik.errors.email}
            helpertext={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter Agent Email"
          />
          {formik.errors?.email && formik.touched?.email && (
            <FormHelperText fullWidth error>
              <strong>{formik.errors?.email}</strong>
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <CustomInput
            name="username"
            label="User Name"
            type="text"
            value={formik.values.username}
            error={formik.touched.username && !!formik.errors.username}
            helpertext={formik.touched.username && formik.errors.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter username"
          />
          {formik.errors?.username && formik.touched?.username && (
            <FormHelperText fullWidth error>
              <strong>{formik.errors?.username}</strong>
            </FormHelperText>
          )}
        </FormControl>

        {data && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">status</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="status"
              name="status"
              value={formik.values.status}
              label="status"
              onChange={(e) => formik.setFieldValue("status", e.target.value)}
            >
              <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
              <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
            </Select>
          </FormControl>
        )}

        <LoadingButton
          loading={data ? updateLoading : createLoading}
          size="large"
          type="submit"
          variant="contained"
          sx={{ fontSize: 13 }}
        >
          {data ? "update agent" : "add Agent"}
        </LoadingButton>
      </Stack>
    </Fragment>
  );
};

export default AgentForm;

import { Avatar, Box, Menu, MenuItem } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { logout } from '@modules/authentication/utils';
import { useSelector } from 'react-redux';
import { useMe } from '@modules/authentication/hooks/useMe';

export default function ProfileMenuButton({ showName = false }) {

	const navigate = useNavigate()
	const user = useSelector((store) => store.auth.userData)
	const verifAuth = useMe()

	const [anchorEl, setAnchorEl] = useState(null);
	const userMenuOpen = Boolean(anchorEl);
	const id = userMenuOpen ? 'simple-popover' : undefined;

	// ================= COMPONENT =================
	return (
		<Fragment>
			<Box
				onClick={(event) => setAnchorEl(event.currentTarget)}
				sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
			>
				{showName && !verifAuth?.isLoading && user?.first_name &&
					<Box mr={1} textTransform='capitalize'>
						{`${user?.first_name} ${user?.last_name}`}
					</Box>
				}
				<Avatar
					aria-describedby={id}
					alt="Remy Sharp"
					src={require('../../assets/images/avatar/default.png')}
					sx={{ width: 36, height: 36, mx: 'auto' }}
				/>
			</Box>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={userMenuOpen}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{ 'aria-labelledby': 'basic-button' }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<MenuItem onClick={() => navigate('/')}>Home</MenuItem>
				<MenuItem onClick={() => navigate('/settings')}>Settings</MenuItem>
				<MenuItem sx={{ backgroundColor: 'red', color: 'white' }} onClick={logout}>Logout</MenuItem>
			</Menu>
		</Fragment>
	)
}

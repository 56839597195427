import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Divider, Grid, Box } from '@mui/material';
import { SwipeableDrawer, useMediaQuery } from '@mui/material'

const DashboardSwipeable = (props) => {
  const { children, isOpen, onClose, title, width = '25vw' } = props

  const smallScreen = useMediaQuery('(max-width:900px)');

  return (
    <SwipeableDrawer anchor='right' open={isOpen} onOpen={() => console.log('Drawer opened')} onClose={onClose}>
      <Box sx={{ width: smallScreen ? '100vw' : width, py: 10 }} role="dialog">
        <Grid container spacing={2} px={2}>
          <Grid item xs={9} textTransform="capitalize">
            {title}
          </Grid>

          <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} fontSize='small' />
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        <Box padding={2}>
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

export default DashboardSwipeable
import { useMutation } from 'react-query';
import { exportTransactionsAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useExportTransaction = () => { 

    const mutation = useMutation({
        mutationKey: 'export_transaction',
        mutationFn: exportTransactionsAPI,
        onError: (error) => errorHandler(error) 
    })

    return mutation
}
 
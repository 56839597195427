import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Chart from "react-apexcharts";
import { Paper, Typography } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PulseText from '@components/PulseText';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function GeneralCharts({ chartDatas, loading, startDate, endDate, setStartDate, setEndDate }) {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => setValue(newValue);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}


      </div>
    );
  }

  const XdataCollection = chartDatas?.financesCollect?.data?.map(data => `${data?.provider} (${data?.currency})`)
  const YdataCollection = chartDatas?.financesCollect?.data?.map(data => Math.abs(data?.total_amount))

  const XdataCashouts = chartDatas?.financesCashout?.data?.map(data => `${data?.provider} (${data?.currency})`)
  const YdataCashouts = chartDatas?.financesCashout?.data?.map(data => Math.abs(data?.total_amount))

  const XdataDisbursement = chartDatas?.financesDisbursement?.data?.map(data => data?.currency)
  const YdataDisbursement = chartDatas?.financesDisbursement?.data?.map(data => Math.abs(data?.total_amount))

  //==================== COMPONENT ==========================

  const statList = [
    {
      options: {
        chart: { id: "collection" },
        xaxis: { categories: XdataCollection }
      },
      series: [
        {
          name: "amount",
          data: YdataCollection
        }
      ],
      title: 'collection',
      index: 0
    },
    {
      options: {
        chart: { id: "cashout" },
        xaxis: { categories: XdataCashouts }
      },
      series: [
        {
          name: "amount",
          data: YdataCashouts
        }
      ],
      title: 'cashout',
      index: 1
    },
    {
      options: {
        chart: { id: "disbursement" },
        xaxis: { categories: XdataDisbursement }
      },
      series: [
        {
          name: "amount",
          data: YdataDisbursement
        }
      ],
      title: 'disbursement',
      index: 2
    },
  ]

  return (
    <Paper sx={{ width: '100%', minHeight: "32.7em" }}>
      <Box sx={{ borderBottom: 1, pt: 2, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={<PulseText>Collections</PulseText>} value={0} />
          <Tab label={<PulseText>Cashouts</PulseText>} value={1} />
          <Tab label={<PulseText>Deposits</PulseText>} value={2} />
          <Box sx={{ display: 'flex', mr: 1, justifyContent: 'end', textAlign: 'right', width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  value={startDate}
                  label="Start date"
                  format='DD/MM/YYYY'
                  onChange={(newValue) => setStartDate(newValue)}
                />
                <DatePicker
                  value={endDate}
                  label="End date"
                  format='DD/MM/YYYY'
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Tabs>
      </Box>
      <Box pt={4}>
        {
          statList.map(stat =>
            <CustomTabPanel key={stat?.title} value={value} index={stat?.index}>
              {stat?.options?.xaxis?.categories?.length || loading ?
                <Chart
                  options={stat?.options}
                  series={stat?.series}
                  type="line"
                  width="100%"
                  height='400'
                />
                :
                <Box width="100%" my={2} textAlign="center">
                  <QueryStatsIcon sx={{ mt: 12, fontSize: 90, color: 'gray' }} />
                  <Typography mt={2} variant="h5" color="grey">No {stat?.title} found for this application for this period</Typography>
                </Box>
              }
            </CustomTabPanel>
          )
        }
      </Box>
    </Paper>
  )
}

import React, { useState } from 'react'
import { useFormik } from 'formik';
import { EmailOutlined } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Alert, Box, Button, Container, FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import Logo from '@components/Logo';
import CustomLink from '@components/link';
import { SECRET_PASS } from '@configs/keys';
import PulseText from '@components/PulseText';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import { hideEmail } from '@modules/authentication/utils/helpers';
import { useResendOtp } from '@modules/authentication/hooks/useResendOtp';
import { useVerifyEmail } from '@modules/authentication/hooks/useVerifyEmail';
import { verifyCodeValidation } from '@modules/authentication/utils/validations';

const VerifyEmail = () => {
  const { email } = useParams()
  const navigate = useNavigate()
  const { mutate, isLoading } = useVerifyEmail()
  const [errorMessage, setErrorMessage] = useState('')
  const { mutate: resendMutate, isLoading: resendIsLoading } = useResendOtp()

  const decrypt = (input) => {
    const removeKey = input.replace(SECRET_PASS, '')
    return window.atob(removeKey)
  }

  const formik = useFormik({
    initialValues: {
      code: '',
      email
    },
    validationSchema: verifyCodeValidation,
    onSubmit: values => {
      const data = {
        otp: values?.code.toString(),
        email: decrypt(email)
      }
      // FIRST CHECK
      mutate(data, {
        onSuccess: () => {

          // SECOND CHECK
          mutate(data, {
            onSuccess: () => { },
            onError: (error) => {
              if (error?.response?.data?.status === 410) {
                toast.success("Your account has been successfully verified")
                setTimeout(() => navigate("/login"), 3000)
              }
              else setErrorMessage(errorHandler(error))
            }
          })

        },
        onError: (error) => {
          if (error?.response?.data?.status === 400) {
            const message = "Incorrect verification code"
            setErrorMessage(message)
            toast.error(message)

          } else {
            setErrorMessage(errorHandler(error))
          }

        }
      })
    }
  })

  const resendOtpCode = () => {
    const getEncryptedMailFromStorage = localStorage.getItem('3O8S82q6')
    const registeredEmail = decrypt(getEncryptedMailFromStorage)
    resendMutate(registeredEmail, {
      onSuccess: () => {
        toast.success("Code has been sent, please check your email")
      },
      onError: () => {
        toast.error('Something went wrong, try again later')
      }
    })
  }

  //======================== COMPONENT ==========================

  if (localStorage.getItem('3O8S82q6')) {
    return (
      <section className='main-container'>
        <Container sx={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1em", p: { xs: "2em", md: "0em" }, }}>

          <Logo height={50} />

          <ToastContainer />

          <Box
            component="form"
            sx={{
              mb: 1, mt: 3, p: "2em 1em 1em 1em", background: '#ffffff', borderRadius: 2,
              width: {
                xs: '100%', sm: "400px",
                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: ".5em"
              }
            }}
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ textAlign: "center", display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant='h5' sx={{ fontWeight: "bold" }}>
                Verify your email
              </Typography>
              <EmailOutlined fontSize='large' color='primary' />
            </Box>
            <Typography textAlign="center" mb={1} sx={{ color: "GrayText" }}>
              We've sent a link to your email address:
              <Typography fontWeight="bold">{hideEmail(decrypt(email))}</Typography>
            </Typography>
            <Typography sx={{ color: "GrayText", textAlign: "center" }}>
              In this email there is a 6 digit code. Please enter the code to continue.
            </Typography>
            {errorMessage && <Alert sx={{ mb: 1 }} severity="error"><PulseText>{errorMessage}</PulseText></Alert>}

            <OutlinedInput
              sx={{
                display: "none"
              }}
              id='email'
              type='hidden'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="code" size='small'>Code</InputLabel>
              <OutlinedInput
                type="number"
                size='small'
                id="code"
                name="code"
                value={formik.values.code}
                error={!!formik.touched.code && !!formik.errors.code}
                helpertext={formik.errors.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Code"
                placeholder='******'
              />
            </FormControl>
            <CustomButton
              disabled={resendIsLoading}
              type='submit'
              loading={isLoading}
              fullWidth
              size='large'
              sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
            >
              {isLoading ? 'Verifying...' : 'Verify'}
            </CustomButton>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
              <Typography>Didn't receive an email?</Typography>
              <Button color='secondary' onClick={resendOtpCode}>Resend</Button>
            </Stack>
          </Box>
          <CustomLink to="/login" color="secondary" label="Skip for now" />
        </Container>
      </section>
    )
  } else {
    return <Navigate to="/" />
  }
}

export default VerifyEmail
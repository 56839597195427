import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Box, Container, IconButton, InputAdornment, Typography } from '@mui/material'
import Logo from '@components/Logo';
import CustomLink from '@components/link';
import PulseText from '@components/PulseText';
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { errorHandler } from '@configs/errorConfig';
import { useLogin } from '@modules/authentication/hooks/useLogin';
import { loginValidation } from '@modules/authentication/utils/validations';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate()

  const onShow = () => setShowPassword((show) => !show);
  const { mutate, isLoading } = useLogin()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidation,
    onSubmit: values => {

      const datas = {
        username: values?.email,
        password: values?.password
      }

      mutate(datas, {
        onSuccess: () => {
          navigate('/')
        },
        onError: (error) => {
          if (error?.response?.data?.status === 401) {
            const message = 'Incorrect email or password !'
            setErrorMessage(errorHandler(error, true, message))

          } else {
            alert('test')
            errorHandler(error)
          }
        }
      })
    }
  })

  return (
    <section className='main-container'>
      <ToastContainer />
      <Container style={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "3em" }}>
        <Logo height={50} />

        <Container maxWidth='sm' sx={{
          my: 1, py: 5, background: '#fff', borderRadius: 2,
          width: {
            xs: '100%', sm: "500px",
          }
        }}>
          <Typography variant="h5" sx={{ mb: "1em", textAlign: 'center', fontWeight: "bold" }}>
            Login
          </Typography>
          {errorMessage && <Alert sx={{ mb: 4 }} severity="error"><PulseText>{errorMessage}</PulseText></Alert>}
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '100%' },
            }}
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <CustomInput
              name="email"
              label="Email"
              type='email'
              required
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helpertext={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter your email'
            />
            <CustomInput
              sx={{ mt: 4, mb: 1 }}
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              required
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              helpertext={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter your password'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onShow}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box sx={{ textAlign: "right" }}>
              <CustomLink to="/forgot-password" label="Forgot Password?" color="secondary" sx={{ fontSize: 15 }} />
            </Box>
            <CustomButton
              type='submit'
              loading={isLoading}
              fullWidth
              size='large'
              sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
            >
              {isLoading ? 'Checking...' : 'Sign in'}
            </CustomButton>

            <Typography textAlign="center" mt={3}>
              Don't have an account? <CustomLink to="/signup" color="secondary" label="Sign Up" />
            </Typography>
          </Box>
        </Container>

      </Container>
    </section>
  )
}

export default Login
import React from 'react'
import { Grid, Typography } from '@mui/material'

const TextInfo = ({ title, value }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography>{value}</Typography>
    </Grid>
  )
}

export default TextInfo
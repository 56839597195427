import { useMutation } from 'react-query';
import { toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';

export const useToggleActiveApplication = () => { 

    const mutation = useMutation({
        mutationKey: 'toggleActiveApp',
        mutationFn: toggleActiveApplicationAPI,
        onError: (error) => errorHandler(error)
    })

return mutation
 }
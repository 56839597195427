import { useMutation, useQueryClient } from 'react-query';
import { createCompanyAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useCreateCompany = () => {
    const client = useQueryClient()

    const mutation = useMutation({
        mutationKey: ['createCompany'],
        mutationFn: createCompanyAPI,
        onError: (error) => errorHandler(error),
        onSuccess: () => client.invalidateQueries({ queryKey: ["companies"] }),
    })
    return mutation
}
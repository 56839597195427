import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function SkeletonChildrenDemo(props) {

  return (
    <div>
        <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width="100%">
        <div style={{ paddingTop: '60%' }} />
        </Skeleton>
    </div>
  );
}

SkeletonChildrenDemo.propTypes = {
  loading: PropTypes.bool,
};

export default function ApplicationSkeleton() {
  return (
    <Grid container py={4} spacing={4}>
      {
        [1,2,3,4,5,6].map(item => 
          <Grid key={ item } item xs={12} md={4}>
            <SkeletonChildrenDemo loading />
          </Grid> 
        )
      }
    </Grid>
  );
}

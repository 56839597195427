import { useMutation } from 'react-query';
import { createCategoryAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useCreateCategory = () => { 

  const mutation = useMutation({
    mutationKey: 'create_category',
    mutationFn:createCategoryAPI,
    onError: (error) => errorHandler(error) 
  })

  return mutation
 }
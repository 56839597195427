import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import DataGrid from "@components/datagrid";
import AddIcon from "@mui/icons-material/Add";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/joy";
import {
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CreateDis from "../../../modules/disbursement/components/createDis";
import ModalConfirmDeposit from "../../../modules/disbursement/components/ModalConfirmDeposit";
import ViewDisbursementDatails from "../../../modules/disbursement/components/viewDisbursementDatails";
import { useGetAllDisbursement } from "../../../modules/disbursement/hooks/useGetAllDisbursement";
import {
  depositStatusList,
  depositTypeList,
} from "../../../modules/disbursement/utils";
import { diburseColumn } from "../../../modules/disbursement/utils/columns";
import ModalConfirmTransfert from "../../../modules/Inter-transfer/components/ModalConfirm";

const Disbursement = () => {
  const smallScreen = useMediaQuery("(max-width:900px)");
  const { appID } = useParams();
  const [isTabModal, setIsTabModal] = useState({
    open: false,
    data: {},
  });
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState();
  const [depositType, setDepositType] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const [isConfirmTransfertModal, setIsConfirmTransfertModal] = useState({
    open: false,
    data: {},
  });

  const onCloseConfirmTransfert = () => {
    setIsConfirmTransfertModal({
      data: {},
      open: false,
    });
  };

  const confirmTransfertProps = {
    open: isConfirmTransfertModal,
    handleClose: onCloseConfirmTransfert,
  };

  const [isConfirmDepositModal, setIsConfirmDepositModal] = useState({
    open: false,
    data: {},
  });
  const onCloseConfirmDeposit = () => {
    setIsConfirmDepositModal({
      data: {},
      open: false,
    });
  };
  const confirmDepositProps = {
    open: isConfirmDepositModal,
    handleClose: onCloseConfirmDeposit,
  };

  const [isDetailModal, setIsDetailModal] = useState({
    data: {},
    open: false,
  });

  const filters = {
    page: currentPage,
    limit: rowsPerPage,
    status: status === "ALL" ? null : status,
    deposit_type: depositType === "ALL" ? null : depositType,
    search,
  };

  const onFilterChange = () => {
    setCurrentPage(0);
    setRowsPerPage(10);
  };

  const {
    data: AllDisburse,
    isLoading: isLoadingAllDisburs,
    refetch,
    isError,
  } = useGetAllDisbursement(filters);

  const closeDetailModal = () => {
    setIsDetailModal({
      data: {},
      open: false,
    });
  };

  const columnProps = {
    setIsDetailModal,
    setIsConfirmDepositModal,
    setIsConfirmTransfertModal,
  };
  const viewDetailsModal = {
    isDetailModal,
    closeDetailModal,
  };

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onNextPage: () => setCurrentPage((page) => page + 1),
    onPreviousPage: () => setCurrentPage((page) => page - 1),
    onFirstPage: () => setCurrentPage(0),
    onLastPage: () => setCurrentPage(AllDisburse?.data?.meta?.totalPages - 1),
    count: AllDisburse?.data?.meta?.totalItems || 1,
  };

  const onSearch = (e) => {
    setCurrentPage(0);
    setRowsPerPage(10);
    setStatus();
    setSearch(e.target.value);
  };

  const searchOptions = {
    title: "Search by transaction ID",
    value: search,
    onSearch,
  };

  const filterOptionsList = [
    {
      label: "Status",
      options: depositStatusList,
      value: status,
      onFilterChange: (e) => {
        onFilterChange();
        setStatus(e.target.value);
      },
    },
    {
      label: "Deposit type",
      options: depositTypeList,
      value: depositType,
      onFilterChange: (e) => {
        onFilterChange();
        setDepositType(e.target.value);
      },
    },
  ];

  const breadCrumb = [
    {
      label: "Disbursement",
      link: `/${appID}/disbursement`,
      icon: <MonetizationOnOutlinedIcon fontSize="small" />,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onReset = () => {
    setCurrentPage(0);
    setRowsPerPage(10);
    setStatus();
    setSearch();
    setDepositType();
    refetch();
  };

  const handleClose = () => {
    setIsTabModal({
      data: {},
      open: false,
    });
  };
  const createDisProps = {
    open: isTabModal.open,
    handleClose,
    value,
    handleChange,
    setIsConfirmTransfertModal,
    setIsConfirmDepositModal,
    handleCloseDeposit: onCloseConfirmDeposit,
  };

  useEffect(() => {
    refetch();
  }, [currentPage, rowsPerPage, status, depositType, search]);

  const CheckIfDataIsArray = (data) => {
    if (Array.isArray(data)) {
      return data;
    } else return [];
  };

  return (
    <section>
      <Fragment>
        <Stack
          direction="row"
          mb={4}
          mt={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomizedBreadcrumbs links={breadCrumb} />
          <Grid item xs={12} md={6} textAlign="right">
            <ButtonGroup
              fullWidth={smallScreen}
              size="small"
              variant="outlined"
            >
              <Box>
                <Button
                  color="primary"
                  onClick={() => navigate(`new`)}
                  variant="contained"
                  sx={{ boxShadow: "none" }}
                  startIcon={<AddIcon />}
                >
                  Disbursement
                </Button>
              </Box>

              <CreateDis {...createDisProps} />
            </ButtonGroup>
          </Grid>
        </Stack>
        <ViewDisbursementDatails {...viewDetailsModal} />
        <ModalConfirmTransfert {...confirmTransfertProps} />
        <ModalConfirmDeposit {...confirmDepositProps} />

        {
          !isError ? (
            <DataGrid
              data={CheckIfDataIsArray(AllDisburse?.data?.items)}
              isLoading={isLoadingAllDisburs}
              paginationOptions={paginationOptions}
              searchOptions={searchOptions}
              filterOptionsList={filterOptionsList}
              column={diburseColumn({ ...columnProps })}
              head={"History"}
            />
          ) : (
            <Box textAlign="center">
              <Typography variant="h5">No data found</Typography>
              <CustomButton
                onClick={onReset}
                loading={isLoadingAllDisburs}
                startIcon={<RefreshIcon fontSize="large" />}
                size="large"
                variant="contained"
                sx={{ mt: 2, fontSize: 13 }}
              >
                Refresh
              </CustomButton>
            </Box>
          )
          // null
        }
      </Fragment>
    </section>
  );
};

export default Disbursement;

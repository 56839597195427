import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { formatDate } from "../../pos/utils";

const DetailsModal = ({ open, handleClose }) => {
  const additionalDetailsArray = [
    {
      title: "name",
      value: open?.data?.name,
    },
    {
      title: "slug",
      value: open?.data?.slug,
    },
    {
      title: "created_at",
      value: open?.data?.created_at
        ? formatDate(open?.data?.created_at)
        : "N/A",
    },
    {
      title: "Updated At",
      value: open?.data?.lastChangedDateTime
        ? formatDate(open?.data?.lastChangedDateTime)
        : "N/A",
    },
  ];
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        textTransform="capitalize"
        fontSize="xl"
        id="alert-dialog-title"
      >
        Additional Detail
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Grid container gap={4}>
          {additionalDetailsArray?.map((items, i) => (
            <Grid xs={4} item key={i}>
              <Typography fontSize="sm" color={grey[500]}>
                {items?.title?.replaceAll("_", " ")}
              </Typography>
              <Typography>{items?.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" textTransform="capitalize">
          close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsModal;

import { Divider, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Field } from 'formik'
import React, { useEffect, useState } from 'react'
import DataGrid from '@components/datagrid';
import DetailInput from '../../../components/Input-Detail';
import { confirmColumns } from '../utils/columns';
import { formatDate } from '../../../utils';
import Checkbox from '@mui/material/Checkbox';
import Installments from './installments';
import InvoiceErrors from './invoice-errors';


export const FieldValue = ({ label, value }) => {
  return (<Stack sx={{
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  }}>
    <Typography fontWeight="bold">{label}</Typography>
    <Typography fontWeight="bold">
      {value}
    </Typography>
  </Stack>)
}

const ComfirmInvoice = ({ details, errors, setFieldValue }) => {
  const {
    client_name,
    client_email,
    client_phone_number,
    type,
    currency,
    due_date,
    items,
    number_of_installments,
  } = details;

  const [showVAT, setShowVAT] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)
  const [showShipping, setShowShipping] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [vat, setVat] = useState(0)
  const [total, setTotal] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalFees, setTotalFees] = useState(0)
  const [installments, setInstallments] = useState([])


  const handleShipping = (event) => {
    setShowShipping(event.target.checked);
    setFieldValue("shipping", 0)
    setShipping(0)
  };

  const handleVAT = (event) => {
    setShowVAT(event.target.checked);
    setFieldValue("vat", 0)
    setVat(0)
  };

  const handleDiscount = (event) => {
    setShowDiscount(event.target.checked);
    setFieldValue("discount", 0)
    setDiscount(0)
  };

  const generateInstallment = () => {

    const installments = [];
    const totalSubTotal = items.reduce((total, row) => total + parseFloat(row.amount || 0), 0);
    const installmentAmount = totalSubTotal / number_of_installments;
    const isDecimal = installmentAmount % 1 !== 0;

    function roundUpAmount(installmentAmount) {
      const decimalPart = installmentAmount % 1;
      return Math.trunc(Math.floor(installmentAmount) + (decimalPart * number_of_installments))
    }

    for (let i = 0; i < number_of_installments; i++) {
      if (isDecimal && i === 0) {
        installments.push({ title: "", amount: roundUpAmount(installmentAmount), due_date: null });
      } else {
        installments.push({ title: "", amount: Math.floor(installmentAmount), due_date: null });
      }
    }

    const totalInstallmentAmount = installments.reduce((total, installment) => total + installment.amount, 0);

    const difference = totalSubTotal - totalInstallmentAmount
    if (difference > 0 && number_of_installments > 1 && installments?.[0]?.amount) {
      installments[0].amount += difference;
    }

    return installments;
  }

  useEffect(() => {
    const fees = calculateTotalFees();
    setTotalFees(fees)
  }, [vat, discount, shipping])

  useEffect(() => {
    const totalAmount = calculateTotalAmount()
    setTotal(totalAmount)
  }, [totalFees, subTotal, vat, discount, shipping])

  useEffect(() => {
    calculateSubTotal()
  }, [vat, discount, shipping, subTotal, details])

  useEffect(() => {
    const result = generateInstallment()
    setInstallments(result)
  }, [number_of_installments])

  const calculateSubTotal = () => {
    const result = items.reduce((total, row) => total + parseFloat(row.amount || 0), 0)
    setSubTotal(result)
    setFieldValue("subtotal", result)
  };

  const calculateTotalAmount = () => {
    return subTotal + totalFees;
  };

  const calculateTotalFees = () => {
    const result = vat + shipping - discount;
    return result
  };

  //================== component =======================

  return (
    <Stack>
      <Typography variant='h5' fontWeight="bold">Preview Invoice & Confirm</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item sx={12} md={8}>
          <Grid container spacing={2}>
            <Grid item sx={12} md={4} rowSpacing={2}>
              <DetailInput fontSize="1rem" label="client name" value={client_name} />
            </Grid>
            <Grid item sx={12} md={4}>
              <DetailInput fontSize="1rem" label="client email" noramlText value={client_email} />
            </Grid>
            <Grid item sx={12} md={4}>
              <DetailInput fontSize="1rem" label="Client phone number" noramlText value={client_phone_number} />
            </Grid>
            <Grid item sx={12} md={4}>
              <DetailInput fontSize="1rem" label="Payment type" noramlText value={type} />
            </Grid>
            <Grid item sx={12} md={4}>
              <DetailInput fontSize="1rem" label="currency" value={currency} />
            </Grid>
            <Grid item sx={12} md={4}>
              <DetailInput fontSize="1rem" label="due date" value={formatDate(due_date)} />
            </Grid>
            <Grid item sx={12} md={12}>
              <Stack gap={2}>
                <Accordion defaultExpanded={true} variant="outlined">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography fontWeight="bold">Items</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack maxHeight="250px" sx={{ overflowY: "scroll" }}>
                      <DataGrid
                        column={confirmColumns}
                        data={items}
                        showPagination={false}
                        tableSize="small"
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Grid>
            {number_of_installments > 1
              ? <Grid item sx={12} md={12}>
                <Stack gap={2}>
                  <Accordion defaultExpanded={true} variant="outlined">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content-2"
                      id="panel1-header2"
                    >
                      <Typography fontWeight="bold">Installments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack maxHeight="300px" sx={{ overflowY: "scroll" }}>
                        <Installments installments={installments} setFieldValue={setFieldValue} />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </Grid> : null}
          </Grid>
        </Grid>
        <Grid item sx={12} md={4}>
          <Stack gap={1}>
            <Stack sx={{
              width: { sx: "100%" },
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",

            }}>
              <Stack px={1} py={1}>
                <FieldValue
                  label="VAT"
                  value={vat}
                />
                <FieldValue
                  label="Shipping"
                  value={shipping}
                />
                <FieldValue
                  label="Discount"
                  value={discount}
                />
                <Divider sx={{ my: .3 }} />
                <Stack>
                  <FormGroup>
                    <FormControlLabel
                      checked={showVAT}
                      onChange={handleVAT}
                      control={<Checkbox />}
                      label="VAT" />
                    <FormControlLabel
                      checked={showShipping}
                      onChange={handleShipping}
                      control={<Checkbox />}
                      label="Shipping" />
                    <FormControlLabel
                      checked={showDiscount}
                      onChange={handleDiscount}
                      control={<Checkbox />}
                      label="Discount" />
                  </FormGroup>
                </Stack>
                <Divider sx={{ my: .3 }} />
                <FieldValue
                  label="Total Fees"
                  value={totalFees}
                />
                <FieldValue
                  label="Sub Total"
                  value={subTotal}
                />
              </Stack>

            </Stack>
            <Stack sx={{
              width: "100%",
              bgcolor: "#e6e6e6",
              borderRadius: "5px",
              mb: 2
            }} >
              <Stack px={1} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h5' fontWeight="bold">Total</Typography>
                <Typography variant='h4' fontWeight="bold" color={total < 0 ? "red" : "initial"}>
                  {total}
                </Typography>
              </Stack>
            </Stack>
            {showVAT && <Field name="vat">
              {({ field }) => (
                <TextField
                  {...field}
                  name="vat"
                  size='small'
                  type='number'
                  label="VAT Fee"
                  placeholder="Enter VAT fee"
                  onChange={(event) => {
                    const value = parseFloat(event.target.value) || 0;
                    setFieldValue("vat", value)
                    setVat(value);
                  }}
                />
              )}
            </Field>}
            {showShipping && <Field name="shipping">
              {({ field }) => (
                <TextField
                  {...field}
                  size='small'
                  name="shipping"
                  type='number'
                  label="Shipping Fee"
                  placeholder="Enter Shipping fee"
                  onChange={(event) => {
                    const value = parseFloat(event.target.value) || 0;
                    setFieldValue("shipping", value)
                    setShipping(value);
                  }}
                />
              )}
            </Field>}
            {showDiscount && <Field name="discount">
              {({ field }) => (
                <TextField
                  {...field}
                  size='small'
                  name="discount"
                  type='number'
                  label="Discount Fee"
                  placeholder="Enter Discount fee"
                  onChange={(event) => {
                    const value = parseFloat(event.target.value) || 0;
                    setFieldValue("discount", value)
                    setDiscount(value);
                  }}
                />
              )}
            </Field>}
            {Object.keys(errors).length > 0 && <InvoiceErrors errors={errors} />}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ComfirmInvoice
import React from 'react'
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

import TableLoader from '@components/TableLoader';
import { documentListColumns } from '../utils/columnDocumentList';
import { validationListColumns } from '../utils/columnValidationList';

export default function LoaderKycDetail({setIsModalDetails}) {

  const columnProps = {
    setIsModalDetails,
  }
  return (
    <Stack component="form" gap={2} >
      <Grid container rowSpacing={1} columnSpacing={5}>

        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]?.map((row, key) =>
          <Grid key={key} item xs={12} md={6} lg={4}>
            <Typography component="div" key='h3' variant='h3'>
              <Skeleton />
            </Typography>
          </Grid>
        )}

      </Grid>
      <Box sx={{ mt: 3 }}>Documents list</Box>
      <Divider sx={{ mb: 1 }} />

      <TableLoader rows={2} columns={documentListColumns({...columnProps})} />
      <TableLoader rows={2} columns={validationListColumns} />
    </Stack>
  )
}

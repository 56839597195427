import { useMutation } from 'react-query'
import { posAgentApi } from '../exports';
import { useSelector } from 'react-redux';
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse';
import { toast } from 'react-toastify';

const useDeletePosAgent = () => {
  const mutationKey = ["deletePosAgent"];

  const selectedApp = useSelector((store) => store?.data?.selectedApp);
  const mutation = useMutation({
    mutationKey,
    mutationFn: (data) => {
        console.log({data})
       return posAgentApi.delete_POS_agent({application_id:selectedApp?.id,uuid:data?.uuid})
    },
    onError:(error)=>handleError(error),
    onSuccess:()=> toast.success(`agent deleted successfully`)
  });
  return mutation
}

export default useDeletePosAgent
import { useMutation } from 'react-query';
import { createProductAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useCreateProduct = () => { 

  const mutation = useMutation({
    mutationKey: 'create_product',
    mutationFn:createProductAPI,
    onError: (error) => errorHandler(error) 
  })

  return mutation
 }
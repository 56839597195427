import { gql, useMutation } from "@apollo/client"

export const useRegenerateToken = () => { 

    const mutation = useMutation(
        gql`
            mutation regenerateApplicationToken($id:String!){
                regenerateApplicationKeys(id:$id, mode:BOTH){
                    id
                    sandbox_key
                    live_key
                    type
                    description
                    mode
                }    
            }
        `,
    )

    return mutation
}


import { Box, Button, Chip, Divider, Grid, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

const ViewDisbursementDatails = ({ isDetailModal, closeDetailModal }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: "8px",
    // p: 4,
  };



  const created_at = new Date(isDetailModal?.data?.create_time)?.toLocaleDateString(
    "en-EN",
    {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  )
  return (
    <Modal
      open={isDetailModal.open}
      onClose={closeDetailModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ px: 4, py: 2, fontWeight: 700 }}
          id="modal-modal-title"
          variant="h4"
          component="h2"
        >
          Deposit Details
        </Typography>
        <Divider light />

        <Grid sx={{ px: 4, py: 2 }} container direction={"row"} spacing={4}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} item xs={4}>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Transaction ID
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.transaction_id}</Typography>
            </Box>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Amount Transfered
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.amount}</Typography>
            </Box>
            {isDetailModal?.data?.deposit_type !== 'CURRENCY_TRANSFER' && ( <Box>
            <Typography
              variant="span"
              sx={{ fontWeight: 700, color: grey[700] }}
            >
              Deposite Fee
            </Typography>
            <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.deposit_fee}</Typography>
          </Box>)}
           
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Created Time
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{created_at}</Typography>
            </Box>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} item xs={4}>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Deposit Type
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.deposit_type}</Typography>
            </Box>
            {isDetailModal?.data?.deposit_type !== 'CURRENCY_TRANSFER' && 
            (<Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Account Number
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.deposit_type === 'CURRENCY_TRANSFER' ? 'N/A' : isDetailModal?.data?.account_number}</Typography>
            </Box>)
}
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Account Bank
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.account_bank}</Typography>
            </Box>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                System note
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.system_note}</Typography>
            </Box>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} item xs={4}>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Credit currency
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.currency}</Typography>
            </Box>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Debit Currency
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.from_currency}</Typography>
            </Box>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Beneficiary Name
              </Typography>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>{isDetailModal?.data?.beneficiary_name}</Typography>

            </Box>
            <Box>
              <Typography
                variant="span"
                sx={{ fontWeight: 700, color: grey[700] }}
              >
                Status
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <Chip

                  variant="outlined"
                  color={
                    isDetailModal?.data?.status === "SUCCESS"
                      ? "success"
                      : isDetailModal?.data?.status === "WAIT_APPROVAL"
                        ? "info"
                        : isDetailModal?.data?.status === "FAILED"
                          ? "error"
                          : "info"
                  }
                  size="medium"
                  label={
                    isDetailModal?.data?.status === 'WAIT_APPROVAL'
                      ? 'PENDING' // Change label text for WAIT_APPROVAL
                      : isDetailModal?.data?.status
                  }
                />
              </Typography>
            </Box>
          </Grid>


        </Grid>
        <Divider light />
        <Box sx={{ py: 2, ml: "-3rem", px: 4, width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={closeDetailModal} variant="contained">Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewDisbursementDatails;


import { useQuery } from "react-query"
import { meAPI } from "../utils/api"
import { useDispatch } from "react-redux"
import { logout } from "../utils"
import { errorHandler } from "../../../configs/errorConfig"

export const useMe = () => { 
  const dispatch = useDispatch()

  const query = useQuery({
    queryKey:["me"],
    queryFn: async ()=> await meAPI(),
    onSuccess: (data) => {

      const user = data?.data

      //   Store user datas in redux
      dispatch({ 
        type: 'SET_USER_DATA', 
        payload: { userData : user }
      })

    },
    onError: (error) => {
      if(error?.response?.data?.status === 401 || error?.response?.data?.message === 'Invalid data sent for session_key') logout()
      errorHandler(error)
    }
  })

  return query
 }
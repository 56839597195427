

import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { errorHandler } from "@configs/errorConfig"
import {  getAppBalancePerCurrency } from '../utils/api';

export const useAppBalancePerCurrency = (currency) => {
    const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();
  const queryKey = ["appBalancePerCurrency", selectedApp];

  console.log({currency})
  let payload = {
    currency,
    app_id: selectedApp?.id
  }

  const query = useQuery({
    queryKey,    
    queryFn: async () => await getAppBalancePerCurrency({...payload}),
    onError: (error) => errorHandler(error) 
  })

  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);
  return query
}


import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

const DetailInput = ({ label, value, noramlText, fontSize }) => {
  return (
    <Stack alignItems={{ xs: "start" }} maxWidth={{ xs: "200px", md: "100%" }}>
      <Typography variant="body2" textTransform='capitalize' fontSize={13} color="text.secondary">
        {label}
      </Typography>
      <Box sx={{ fontSize: fontSize ?? "1.2rem", textTransform: noramlText ? 'initial' : 'capitalize' }}>{value ?? 'N/A'}</Box>
    </Stack>
  )
}

export default DetailInput
export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', };
  return date.toLocaleString('en-US', options);
}

export const chipColor = {
  ACTIVE: "success",
  SUCCESS: "success",
  CANCELLED: "error",
  PENDING: "warning",
  INITIATE: "info",
  INITIATED: "info",
  FAILED: "error"
}

export function getCurrentDate() {
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);

  const year = nextDay.getFullYear();
  const month = String(nextDay.getMonth() + 1).padStart(2, '0'); // Adding 1 as month is zero-based
  const day = String(nextDay.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


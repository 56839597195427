import React from "react";
import { Stack, TablePagination } from "@mui/material";

const DatagridPagination = ({ table, paginationOptions }) => {
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    table.setPageSize(value);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <section>
        {/* {table.getFilteredSelectedRowModel().rows.length}{" of "}
        {table.getFilteredRowModel().rows.length} row(s) selected. */}
      </section>
      {paginationOptions ? (
        <TablePagination
          component="div"
          count={paginationOptions?.count}
          page={paginationOptions?.currentPage}
          slotProps={{
            actions: {
              nextButton: {
                onClick: paginationOptions?.onNextPage,
              },
              previousButton: {
                onClick: paginationOptions?.onPreviousPage,
              },
              firstButton: {
                onClick: paginationOptions?.onFirstPage,
              },
              lastButton: {
                onClick: paginationOptions?.onLastPage,
              },
            },
          }}
          onPageChange={() => console.log("Page changed")}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          showFirstButton
          showLastButton
          rowsPerPage={paginationOptions?.rowsPerPage}
          onRowsPerPageChange={paginationOptions?.onRowsPerPageChange}
        />
      ) : (
        <TablePagination
          colSpan={3}
          onPageChange={() => console.log("page changed")}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          page={table.getState().pagination.pageIndex}
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={table.getState().pagination.pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          slotProps={{
            actions: {
              nextButton: {
                onClick: () => table.nextPage(),
              },
              previousButton: {
                onClick: () => table.previousPage(),
              },
            },
          }}
        />
      )}
    </Stack>
  );
};

export default DatagridPagination;

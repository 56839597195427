import React from 'react';
import { formatDate } from '../../../utils';
import { Stack, Typography } from '@mui/material';

const Detail = ({label, value}) => (
  <Stack direction="row" gap={1} alignItems="center" fontSize={18}>
      <Typography textTransform='capitalize' color="text.secondary" fontSize={18}>
        {label}:
      </Typography>
      <Typography sx={{ textTransform: 'capitalize', fontWeight:"bold" }} fontSize={18}>{value}</Typography>
  </Stack>);

const CategoryDetails = ({details}) => {
    const {name, slug, created_at, product }= details;

 //================= COMPONENT ==================   

  return (
    <Stack gap={3}>
      <Detail label="Category Name" value={name ?? '---'} />
      <Detail label="Slug" value={slug ?? '---'} />
      <Detail label="Product Count" value={product?.length} />
      <Detail label="Description" value={details?.description ?? "none"} />
      <Detail label="Created" value={formatDate(created_at)} />
    </Stack>
  )
}

export default CategoryDetails
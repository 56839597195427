import { Fragment } from "react";
import { pspWithLogo } from "@utils";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import * as Flags from "country-flag-icons/react/3x2";
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header";
import { countryList } from "@utils/countries";
import { Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ReplayIcon from '@mui/icons-material/Replay';

import { VisibilityOutlined } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export const diburseColumn = ({ setIsDetailModal, setIsConfirmDepositModal, setIsConfirmTransfertModal }) => [
  // {
  //     id: 'country',
  //     header: 'Country',
  //     accessorKey: 'country',
  //     cell: ({ row }) => {
  //       const value = row.original.country
  //       const country = countryList
  //         .filter(country => country.value === value)
  //         .map((code) => code);
  //       const code = country[0]?.value
  //       const label = country[0]?.label;
  //       const Flag = Flags[code]
  //       if (label === undefined && row.original) return (
  //         <Stack direction="row" gap={.4}>
  //           <LanguageOutlinedIcon fontSize="small" />
  //           <Typography>World</Typography>
  //         </Stack>
  //       )
  //       else return <Fragment><Flag width={20} title={label} /> {label}</Fragment>
  //     },
  //     filterFn: (row, id, value) => {
  //       return value.includes(row.getValue(id));
  //     },
  //   },
  {
    id: 'account_bank',
    header: 'Provider',
    accessorKey: 'account_bank',
    cell: ({ row }) => {
      const value = row.original.account_bank
      const newVal = row.original.deposit_type
      return pspWithLogo[value] || "INTER TRANSFERT"
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  // {
  //   id: 'account_number',
  //   header: 'Acc. Number',
  //   accessorKey: 'account_number',
  //   cell: ({ row }) => {
  //     const value = row.original.deposit_type === 'CURRENCY_TRANSFER' ? 'N/A' : row.original.account_number
  //     return value
  //   },
  // },
  {
    id: 'amount',
    header: 'Amount',
    accessorKey: 'amount',
    cell: ({ row }) => {
      const value = row.original.amount
      const currency = row.original.currency
      return value + " " + currency
    },
  },
  {
    id: 'beneficiary_name',
    header: 'Beneficiary Name',
    accessorKey: 'beneficiary_name',
    cell: ({ row }) => {
      const value = row.original.beneficiary_name;
      return value
    },
  },
  {
    id: 'from_currency',
    header: 'Debit Currency',
    accessorKey: 'from_currency',
    cell: ({ row }) => {
      const value = row.original.from_currency
      return value
    },
  },
  // {
  //   id: 'currency',
  //   header: 'Credit Currency',
  //   accessorKey: 'currency',
  //   cell: ({ row }) => {
  //     const value = row.original.currency
  //     return value
  //   },
  // },
  {
    id: 'deposit_type',
    header: 'Deposite Type',
    accessorKey: 'deposit_type',
    cell: ({ row }) => {
      const value = row.original.deposit_type
      return value
    },
  },
  {
    id: 'create_time',
    header: 'Date',
    accessorKey: 'create_time',
    cell: ({ row }) => {
      const value = row.original.create_time
      return new Date(value)?.toLocaleDateString(
        "en-EN",
        {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      )
    },
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    cell: ({ row }) => {
      const value = row.original.status
      // const currency = row.original.from_currency
      return <Chip
        variant="outlined"

        size="small"
        label={
          value === 'WAIT_APPROVAL'
            ? 'WAIT APPROVAL' // Change label text for WAIT_APPROVAL
            : value
        }
        color={
          value === "SUCCESS"
            ? "success"
            : value === "WAIT_APPROVAL"
              ? "info"
              : value === "FAILED"
                ? "error"
                : "info"
        }
      />
    },
  },
  {
    header: 'Action',
    cell: ({ row }) => {
      const data = row.original
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          {row.original.status === "WAIT_APPROVAL" && row.original.deposit_type === "MOBILE_MONEY" &&
            <IconButton
              color="primary"
              title="Verify this deposit"
              onClick={() => setIsConfirmDepositModal({ open: true, data })}
            >
              <CheckCircleOutlineIcon fontSize="small" />
            </IconButton>
          }
          {row.original.status === "WAIT_APPROVAL" && row.original.deposit_type !== "MOBILE_MONEY" &&
            <IconButton
              color="primary"
              title="Verify this Inter transert"
              onClick={() => setIsConfirmTransfertModal({ open: true, data })}
            >
              <CheckCircleOutlineIcon fontSize="small" />
            </IconButton>
          }
          <IconButton
            onClick={() => setIsDetailModal({ data, open: true })}
            title="View details"
          >
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Stack>
      )
    },
  },
]

import { errorHandler } from "../../../../configs/errorConfig"
import {useQuery} from 'react-query'
import { POS_Transactions } from "../api"
import { useSelector } from "react-redux";

const useGetPosTransactionStatistics = (queryData) => {
  const {agentId,refreshKey,start_date,
    end_date} = queryData
 const selectedApp = useSelector((store) => store.data.selectedApp);

    let postTransactionApi = new POS_Transactions()
  const queryKey = [`getPosTransactionStatistics-${selectedApp.id}`,refreshKey]
  const dataToSend = {
    ...(selectedApp.id && {app_id:selectedApp.id}),
    ...(agentId && {agent_id:agentId}),
    ...(start_date && {start_date}),
    ...(end_date && {end_date}),
  }
  const query = useQuery({
    queryKey,
    queryFn:async ()=> await postTransactionApi.get_POS_Transaction_Statistics(dataToSend),
    onError:(error)=>errorHandler(error)
  })

  return query
}

export default useGetPosTransactionStatistics
import React from 'react'
import { Chip } from '@mui/material';

const KycChip = ({ status }) => {
  const statuses = {
    VERIFIED: "success",
    NOT_VERIFIED: "warning",
    REJECTED: "error"
  }
  const label = Object.keys(statuses).find(key => key === status);

  return <Chip sx={{ minWidth: 60 }}
    size='small' label={label} color={statuses[status] ?? 'default'} />
}

export default KycChip
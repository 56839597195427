
const initialState = {
  applications: [],
  selectedApp: null,
}

// REDUCER
const dataReducer = (state = initialState, action) => {

  const actions = {

    SET_DATA: {
      ...state,
      applications: action.payload?.applications ?? state?.applications,
      selectedApp: action.payload?.selectedApp ?? state?.selectedApp,
    },
    
  }
  
  return actions[action.type] ?? state
} 

export { dataReducer }
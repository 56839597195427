import ApplicationDetailsTab from "../modules/application-detail/components/appDetailTab";
import TransferType from "../modules/disbursement/components/TransferType";
import DisburseData from "../modules/disbursement/components/disburseData";
import AgentHome from "../modules/pos/components/AgentHome";
import PosAgentDetails from "../modules/pos/components/posAgentDetails";
import ExportedDocs from "../views/pages/ExportedDocs";
import KycsHistory from "../views/pages/KycsHistory";
import AddKycs from "../views/pages/addKycs";
import ApplicationDetails from "../views/pages/applicationDetails";
import BalanceTransfert from "../views/pages/balanceTransfert";
import Kycs from "../views/pages/kyc";
import Links from "../views/pages/links";
import Payouts from "../views/pages/payouts";
import Finances from "../views/pages/balance";
import Cashouts from "../views/pages/cashouts";
import NotFound from "../views/pages/notFound";
import Overview from "../views/pages/overview";
import Invoices from "../views/pages/invoices";
import InvoiceForm from "../views/pages/invoiceForm";
import POS from "../views/pages/pos";
import ProductCategory from "../views/pages/product-category";
import Products from "../views/pages/products";
import Transactions from "../views/pages/transactions";
import Disbursement from "../views/pages/disbursement";
import Companies from "../views/pages/companies";
import ProductExtras from "../modules/product/components/Extras";
import PosAdditionalDetails from "../views/pages/pos-addional-details";
import PosAdditionalFees from "../views/pages/pos-additional-fees";

export const protectedRoutes = [
  {
    path: "/overview",
    element: <Overview />,
  },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/links",
    element: <Links />,
  },
  {
    path: "/payouts",
    element: <Payouts />,
  },
  {
    path: "/cashouts",
    element: <Cashouts />,
  },
  {
    path: "/invoices",
    element: <Invoices />,
  },
  {
    path: "/companies",
    element: <Companies />,
  },
  {
    path: "/invoices/manage",
    element: <InvoiceForm />,
  },
  {
    path: "/finances",
    element: <Finances />,
  },
  {
    path: "/kycs",
    element: <Kycs />,
  },
  {
    path: "/exportedDocs",
    element: <ExportedDocs />,
  },
  {
    path: "/disbursement",
    element: <Disbursement />,
  },
  {
    path: "/disbursement/new",
    element: <TransferType />,
  },
  {
    path: "/disbursement/new/single",
    element: <DisburseData />,
  },
  {
    path: "/balancetransfert",
    element: <BalanceTransfert />,
  },
  {
    path: "/pos",
    element: <></>,
  },
  {
    path: "/pos/general",
    element: <POS />,
  },
  {
    path: "/pos/agents",
    element: <AgentHome />,
  },
  {
    path: "/pos/agents/:agent_id",
    element: <PosAgentDetails />,
  },
  {
    path: "/pos/additional-fees",
    element: <PosAdditionalFees />,
  },
  {
    path: "/pos/additional-details",
    element: <PosAdditionalDetails />,
  },
  {
    path: "/kycsHistory/:kyc_id",
    element: <KycsHistory />,
  },
  {
    path: "/kycs/manage",
    element: <AddKycs />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/product-category",
    element: <ProductCategory />,
  },
  {
    path: "/product-extras",
    element: <ProductExtras />,
  },
  {
    path: "/application-detailsTab",
    element: <ApplicationDetailsTab />,
  },
  {
    path: "/application-details",
    element: <ApplicationDetails />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

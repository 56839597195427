import React from 'react'
import TableSortLabel from '@mui/material/TableSortLabel';

const DatagridColumnHeader = ({ label, column }) => {
  const isDesc = column.getIsSorted() === 'desc'
  const isAsc = column.getIsSorted() === 'asc'

  return (
    <TableSortLabel
      active={column.getIsSorted()}
      onClick={() => column.toggleSorting()}
      direction={isDesc ? "desc" : isAsc ? 'asc' : undefined} >
      {label}
    </TableSortLabel>
  )
}

export default DatagridColumnHeader
import axios from '@configs/axios'

export const getFinanceStatisticsAPI = async(selectedApp, filters) => {
  const { origin, start_date, end_date } = filters

  let url = `/reports/statistics/finances?app_id=${ selectedApp?.id }`

  if(origin) url = `${ url }&origin=${ origin }`
  if(start_date) url = `${ url }&start_date=${ start_date }`
  if(end_date) url = `${ url }&end_date=${ end_date }`

  const response = await axios.get(url)
  return response?.data
}

export const getBalanceStatisticsAPI = async(selectedApp) => {
  const response = await axios.get(`/reports/statistics/balance?app_id=${ selectedApp?.id }`)
  return response?.data
}

// export const getStatisticsAPI = async(selectedApp) => {
//   const response = await axios.get(`/reports/statistics/finances?origin=COLLECT&app_id=${ selectedApp?.id }&start_date=2024-01-01&end_date=2024-03-01`)
//   return response?.data
// }
import { Fragment, useState } from "react"
import CustomDatePicker from "@components/DatePicker";
import { Field, ErrorMessage } from 'formik';
import CustomInput from "@components/CustomInput";
import { Autocomplete, Divider, FormControlLabel, FormGroup, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import DynamicFields from "./dynamic-fields";
import { currencyList } from "../../../utils";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InvoiceErrors from "./invoice-errors";

const InvoiceInfo = ({ values, errors, setFieldValue, calculateRowAmount }) => {
  const payTypes = ["NORMAL", "INSTALLMENT"]
  const [partial, setPartial] = useState(values?.partial_payment ?? false)
  const [payType, setPayType] = useState(values?.type)

  const handlePartial = (event) => {
    if (payType === "INSTALLMENT") {
      setFieldValue('partial_payment', false)
      setPartial(false)
    }
    else {
      setPartial(event.target.checked)
      setFieldValue('partial_payment', event.target.checked)
    }
  };

  //============= Component =============

  return (
    <Fragment>
      <Grid container spacing={2}>
        {Object.keys(errors).length > 0 && <Stack sx={{ position: "absolute", right: -10, top: -80 }} width="300px" >
          <InvoiceErrors errors={errors} />
        </Stack>}
        <Grid item xs={12}>
          <Stack>
            <Typography variant='h5' fontWeight="bold">Create Invoice</Typography>
            <Stack mt={2} mb={1}>
              <Typography fontWeight="bold">Client Info</Typography>
            </Stack>
            <Grid container spacing={4} >
              <Grid item xs={12} md={4}>
                <Field name="client_name">
                  {({ field }) => (
                    <CustomInput
                      name="client_name"
                      {...field}
                      variant=""
                      label="Client Name"
                      placeholder="Enter client name"
                    />
                  )}
                </Field>
                <ErrorMessage className="err_message" name="client_name" component="div" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="client_email">
                  {({ field }) => (
                    <CustomInput
                      name="email"
                      {...field}
                      label="Client Email"
                      placeholder="Enter client email"
                    />
                  )}
                </Field>
                <ErrorMessage className="err_message" name="client_email" component="div" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="client_phone_number">
                  {({ field }) => (
                    <CustomInput
                      name="client_phone_number"
                      {...field}
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                    />
                  )}
                </Field>
                <ErrorMessage className="err_message" name="client_phone_number" component="div" />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography mb={-2} fontWeight="bold">Invoice Info</Typography>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} position="relative">
                  <Stack width={{ xs: "100%", md: "95%" }} position="absolute" bottom={0}>
                    <Field name="currency">
                      {({ field, form }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          options={currencyList}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField {...params} label="Currency" variant="outlined" />
                          )}
                          onChange={(_, value) => form.setFieldValue('currency', value)}
                        />
                      )}
                    </Field>
                    <ErrorMessage className="err_message" name="currency" component="div" />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography color="text.secondary">Due date</Typography>
                    <Field name="due_date">
                      {({ field }) => (
                        <CustomDatePicker
                          label="Due date"
                          value={values?.due_date}
                          onChange={(date) => {
                            field.onChange({ target: { value: date, name: field.name } })
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage className="err_message" name="due_date" component="div" />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} position="relative">
                  <Stack width={{ xs: "100%", md: "95%" }} position="absolute" bottom={0}>
                    <Field name="type">
                      {({ field, form }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          options={payTypes}
                          defaultValue="NORMAL"
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField {...params} label="Payment Type" variant="outlined" />
                          )}
                          onChange={(_, value) => {
                            form.setFieldValue('type', value)
                            setPayType(value)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage className="err_message" name="type" component="div" />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} position="relative">
                  <Stack width={{ xs: "100%" }} position="absolute" bottom={0}>
                    {payType !== "INSTALLMENT"
                      ? <Field name="partial_payment">
                        {({ field }) => (
                          <FormGroup >
                            <FormControlLabel
                              disabled={payType === "INSTALLMENT"}
                              control={
                                <Switch
                                  {...field}
                                  defaultChecked={payType === "NORMAL" ? partial : false}
                                  checked={payType !== "NORMAL" ? false : partial}
                                  onChange={(event) => {
                                    handlePartial(event)
                                  }}
                                />}
                              label="Partial Payment?"
                            />
                          </FormGroup>
                        )}
                      </Field>
                      : <Field name="number_of_installments">
                        {({ field }) => (
                          <CustomInput
                            name="number_of_installments"
                            label="No. of Installments"
                            placeholder="e.g 3"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    }
                  </Stack>
                </Grid>
              </Grid>
              <Stack>
                <Accordion defaultExpanded={true} variant="outlined">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography fontWeight="bold">Items</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <DynamicFields
                      values={values}
                      setFieldValue={setFieldValue}
                      calculateRowAmount={calculateRowAmount}
                    />
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Fragment >

  )
}

export default InvoiceInfo
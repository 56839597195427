

import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { errorHandler } from "@configs/errorConfig"
import { Disbursement, getAppBalanceCurrency, getBalancePerCurrency } from '../utils/api';

export const useGetAllAppBalance = () => {

    const disburseApi = new Disbursement()
    const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();
  const queryKey = ["allAppBalance", selectedApp];
  const query = useQuery({
    queryKey,    
    queryFn:async () => await  disburseApi.getappBalance(selectedApp),
    onError: (error) => errorHandler(error) 
  })

  
  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);
  return query
}

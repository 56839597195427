import React, { useState } from 'react'
import ReactPulseText from 'react-pulse-text'

// Other appearance values ----> "text-with-cursor" || "slideFromLeft"
// See more: https://kelsier90.github.io/React-Pulse-Text/    

export default function PulseText({ children, onEnd, showValue = false, noDelay = false, duration = 2000, iteration = 1, delay = 1000, appearance }) {
    const [showText, setShowText] = useState(showValue)

    const handlleEnd = () => {
        setShowText(true)
        if (onEnd) onEnd()
    }

    return (
        showText ? children
            :
            <ReactPulseText
                text={children}
                delay={noDelay ? 0 : delay}
                duration={duration}
                onEnd={handlleEnd}
                iterationCount={iteration}
            >
                <span className={appearance}>{children}</span>
            </ReactPulseText>
    )
}

import { useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

const CustomLink = ({to, label, color, sx={}}) => {
  const navigate = useNavigate()

  return (
    <MuiLink onClick={() => navigate(to)} color={color} sx={ [{cursor: "pointer" }, sx]} >
      {label}
    </MuiLink>
  )
}

export default CustomLink
import {
  DeleteOutline,
  DeleteOutlineOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { chipColor, formatDate } from ".";

const Navigate = ({ url }) => {
  const navigate = useNavigate();
  return navigate(`${url}`);
};

export const posColumn = ({ setIsDeleteAgent }) => {
  const Navigate = ({ url }) => {
    const navigate = useNavigate();

    return (
      <IconButton
        onClick={() => {
          navigate(url);
        }}
        title="View Details"
        sx={{ fontSize: ".8rem" }}
      >
        <VisibilityOutlined color="secondary" fontSize="small" />
      </IconButton>
    );
  };

  return [
    {
      id: "name",
      header: "Agent Name",
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.original.name;
        return value;
      },
    },
    {
      id: "username",
      header: "Agent username",
      accessorKey: "username",
      cell: ({ row }) => {
        const value = row.original?.username;
        return value ? value : "N/A";
      },
    },
    {
      id: "email",
      header: "Email",
      accessorKey: "email",
      cell: ({ row }) => {
        const value = row.original?.email;
        return value;
      },
    },
    {
      id: "status",
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => {
        const value = row.original?.status;
        const color = {
          INACTIVE: "error",
        };
        return (
          <Chip
            label={value}
            color={color[value] || "success"}
            variant="outlined"
          />
        );
      },
    },
    {
      header: "actions",
      cell: ({ row }) => {
        const uuid = row.original.uuid;
        return (
          <Stack direction={"row"} alignItems={"center"} gap={0.4}>
            <Navigate url={`${uuid}`} />
            <IconButton
              onClick={() =>
                setIsDeleteAgent({
                  uuid: row.original.uuid,
                  open: true,
                })
              }
              title="view details"
              sx={{ fontSize: ".8rem" }}
            >
              <DeleteOutlineOutlined fontSize="small" color="error" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
};

export const posAgentTransactionColumn = ({ setIsDetailsModal }) => {
  return [
    {
      id: "amount",
      header: "Amount",
      accessorKey: "amount",
      cell: ({ row }) => {
        const value = row.original.amount;
        const currency = row.original.currency;
        return value ? value + " " + currency : "N/A";
      },
    },
    {
      id: "mode",
      header: "Mode",
      accessorKey: "mode",
      cell: ({ row }) => {
        const value = row.original.mode;
        return value ? value : "N/A";
      },
    },
    {
      id: "phone_number",
      header: "Phone Number",
      accessorKey: "phone_number",
      cell: ({ row }) => {
        const value = row.original.phone_number;
        return value ? value : "N/A";
      },
    },
    {
      id: "transaction_id",
      header: "Transaction Id",
      accessorKey: "transaction_id",
      cell: ({ row }) => {
        const value = row.original.transaction_id;
        return value ? value : "N/A";
      },
    },
    {
      id: "reference_id",
      header: "Reference Id",
      accessorKey: "reference_id",
      cell: ({ row }) => {
        const value = row.original.reference_id;
        return value ? value : "N/A";
      },
    },
    {
      id: "status",
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => {
        const value = row.original.status;
        return value ? (
          <Chip label={value} color={chipColor[value]} variant="outlined" />
        ) : (
          "N/A"
        );
      },
    },
    {
      header: "actions",
      cell: ({ row }) => {
        return (
          <Stack direction={"row"} alignItems={"center"} gap={0.4}>
            <IconButton
              onClick={() =>
                setIsDetailsModal((prev) => ({
                  ...prev,
                  data: row.original,
                  open: true,
                }))
              }
              title="View Details"
              sx={{
                fontSize: ".8rem",
                display: "flex",
                gap: ".5rem",
                borderRadius: "8px",
              }}
            >
              <VisibilityOutlined fontSize="small" color="secondary" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
};

export const additionalColumns = [
  {
    title: "Name",
    key: "name",
    cell: (data) => data?.name,
  },
  {
    title: "Value",
    key: "value",
    cell: (data) => data?.value,
  },
];

export const transactionItemsColumns = [
  {
    title: "Name",
    key: "name",
    cell: (data) => data?.name,
  },
  {
    title: "Amount",
    key: "amount",
    cell: (data) => data?.amount,
  },
  {
    title: "Quantity",
    key: "quantity",
    cell: (data) => data?.quantity,
  },
  // {
  //   title: "Created At",
  //   key: "created_at",
  //   cell: (data) => formatDate(data?.created_at),
  // },
  // {
  //   title: "Updated At",
  //   key: "lastChangedDateTime",
  //   cell: (data) => formatDate(data?.lastChangedDateTime),
  // },
];

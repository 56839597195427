import React from 'react'
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const DatagridFilter = ({ filterOptions }) => {

  return (
    <FormControl sx={{ width: 200 }} size='small'>
      <InputLabel id="status" sx={{ textTransform: "capitalize" }}>
        {filterOptions?.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ width: 'auto' }}
        size='small'
        value={filterOptions?.value}
        onChange={filterOptions?.onFilterChange}
        input={<OutlinedInput label="Tag" />}
      >
        {
          filterOptions?.options.map((option, key) =>
            <MenuItem sx={{ textTransform: "capitalize" }} key={option.value ?? key} value={option.value}>
              <ListItemText sx={{ fontSize: "small" }} primary={option.label} />
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}

export default DatagridFilter
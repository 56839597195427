import React from 'react';
import DOMPurify from 'dompurify';

const SanitizedContent = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content);
  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
  );
};

export default SanitizedContent;
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'

import { useMe } from '@modules/authentication/hooks/useMe'
import { useApplications } from '@modules/application/hooks/useApplications';

export default function ProtectedRoutes() {
    const isAuthenticated = !!localStorage.getItem('token')
    const user = useSelector((store) => store.auth.userData)
    const { isLoading: isLoadingApp, data: apps } = useApplications(user?.id)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { appID } = useParams();
    useMe()

    useEffect(() => {
        if (!isLoadingApp && apps?.data?.length > 0 && appID) {
            const selectedApp = apps?.data?.find(app => app?.id === appID)

            if (selectedApp !== undefined) dispatch({ type: 'SET_DATA', payload: { selectedApp } })
            else navigate('/')
        }
    })
    
    return isAuthenticated ? <Outlet /> : <Navigate to='/login' />
}

export const updateInstallments = (totalAmount, numInstallments) => {
  // Calculate the original amount per installment
  const originalAmount = totalAmount / numInstallments;

  // Check if the original amount is a decimal
  const isDecimal = originalAmount % 1 !== 0;

  // Create the new installments array
  const installments = [];
  for (let i = 0; i < numInstallments; i++) {
    if (isDecimal && i === 0) {
      installments.push({ title: "", amount: Math.ceil(originalAmount) });
    } else {
      installments.push({ title: "", amount: Math.floor(originalAmount) });
    }
  }

  return installments;
}

export const statusList = [
  { label: 'All', value: '' },
  { label: 'Paid', value: 'PAID' },
  { label: 'UnPaid', value: 'UNPAID' }
]
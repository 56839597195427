import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstallmentChip from "./installment-chip";

const InstallmentsInfo = ({ installments }) => {

  //=============== Component ===================
  return (
    <Accordion defaultExpanded={true} variant="outlined">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Typography fontWeight="bold">Installments</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table size="small">
          <TableHead sx={{ bgcolor: "#e6fffe" }}>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell sx={{ textAlign: "right" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installments?.map((installment, index) => (
              <TableRow key={index}>
                <TableCell sx={{ minWidth: "200px", fontWeight: "bold" }}>
                  {installment?.title}
                </TableCell>
                <TableCell sx={{ maxWidth: "150px" }}>
                  {installment?.amount}
                </TableCell>
                <TableCell sx={{ maxWidth: "150px", textAlign: "right" }}>
                  <InstallmentChip status={installment?.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>

  );
};

export default InstallmentsInfo;

import React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GridViewIcon from "@mui/icons-material/GridView";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneySharpIcon from "@mui/icons-material/AttachMoneySharp";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import { Settings } from "@mui/icons-material";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ChecklistRtlTwoToneIcon from "@mui/icons-material/ChecklistRtlTwoTone";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

const navMenus = [
  {
    name: "Overview",
    link: "/overview",
    icon: <GridViewIcon />,
  },
  {
    name: "Links",
    link: "/links",
    icon: <OpenInNewIcon />,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: <MonitorHeartOutlinedIcon />,
  },
  {
    name: "Cashouts",
    link: "/cashouts",
    icon: <AttachMoneySharpIcon />,
  },
  {
    name: "Finances",
    link: "/finances",
    icon: <CurrencyExchangeOutlinedIcon />,
  },

  {
    name: "Payouts",
    link: "/payouts",
    icon: <CreditCardOutlinedIcon />,
  },
  {
    name: "KYCs",
    link: "/kycs",
    icon: <AssignmentOutlinedIcon />,
  },
  {
    name: "Invoices",
    link: "/invoices",
    icon: <ReceiptIcon />,
  },
  {
    name: "Disbursement",
    link: "/disbursement",
    icon: <PaidIcon />,
  },

  {
    name: "Catalog",
    link: null,
    icon: <InventoryTwoToneIcon />,
    sub: [
      {
        name: "Products",
        link: "/products",
        icon: <ShoppingCartIcon />,
      },
      {
        name: "Categories",
        link: "/product-category",
        icon: <CategoryOutlinedIcon />,
      },
    ],
  },
  {
    name: "P.O.S",
    link: null,
    icon: <AccountTreeIcon />,
    sub: [
      {
        name: "General",
        link: "/pos/general",
        icon: <BorderAllIcon />,
      },
      {
        name: "Agents",
        link: "/pos/agents",
        icon: <SupportAgentIcon />,
      },
      {
        name: "Additionals",
        link: null,
        icon: <ChecklistRtlTwoToneIcon />,
        menu: [
          {
            name: "Details",
            link: "/pos/additional-details",
            icon: <ChecklistRtlTwoToneIcon />,
          },
          {
            name: "Fees",
            link: "/pos/additional-fees",
            icon: <CreditScoreIcon />,
          },
        ],
      },
    ],
  },
  // {
  //   name: "P.O.S Extras",
  //   link: null,
  //   icon: <GradingIcon />,
  //   sub: [
  //     {
  //       name: "Details",
  //       link: "/pos/additional-details",
  //       icon: <ChecklistRtlTwoToneIcon />,
  //     },
  //     {
  //       name: "Fees",
  //       link: "/pos/additional-fees",
  //       icon: <CreditScoreIcon />,
  //     },
  //   ],
  // },
  {
    name: "Exported docs",
    link: "/exportedDocs",
    icon: <DescriptionOutlinedIcon />,
  },
  {
    name: "Settings",
    link: "/application-detailsTab",
    icon: <Settings />,
  },
  // {
  //     name: 'Inter-transfert',
  //     link: '/balancetransfert',
  //     icon: <MoveDown />
  // },+
];

export { navMenus };

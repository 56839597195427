import axios from '@configs/axios'

export const getCashoutsAPI = async (selectedApp, filters) => {
  let url = `/cash_outs?app_id=${selectedApp?.id}`

  filters && Object.keys(filters)?.forEach(filterKey => {
      const filterValue = filters[filterKey]
      if(filterValue) url = `${url}&${ filterKey }=${filterValue}`
  })

  const response = await axios.get(url)
  // const response = await axios.get(`/cash_outs`)
  return response?.data
}

export const requestCashoutAPI = async (input) => {
  const response = await axios.post('/register_cashout', input)
  return response?.data
}

export const verifyCashoutAPI = async (input) => {
  const response = await axios.post('/verify_cashout_request', input)
  return response?.data
}

export const resendCashoutOtpAPI = async (cashout_id) => {
  const response = await axios.post('/cashout/code/resend', { cashout_id })
  return response?.data
}

export const exportCashoutsAPI = async (input) => {
  const response = await axios.post(`/exports/cashouts`,{
     application_id: input.appID,
     start_date:input.start_date, 
     end_date:input.end_date
    })
  return response?.data
}

export const getExportedCashoutsAPI = async (selectedApp) => {
  const response = await axios.get(`/exports?type=CASHOUT&application_id=${selectedApp?.id}`)
  // const response = await axios.get(`/exports?type=CASHOUT`)
  return response?.data
}
import React from 'react'
import { Box, Grid, Typography, Skeleton } from '@mui/material'

export default function LoaderAddKyc() {

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 5 }}>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_industry'>Business industry</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>

      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_website'>Business website</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_country'>Business country</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_type'>Business type</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>

      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_city'>Business city</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_description'>Business description</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_address'>Business address</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_customer_support_name'>Business customer support name</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_customer_support_phone'>Business customer support phone</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Typography fontSize={15} variant='h6'>
            <label htmlFor='business_customer_support_email'>Business customer support email</label>
          </Typography>
        </Box>
        <Typography component="div" key='h3' variant='h3'>
          <Skeleton />
        </Typography>
      </Grid>

    </Grid>
  )
}

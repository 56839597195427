export const selectValues = [
  {
    value: "",
    text: "select balance",
  },
  {
    value: "Shooping store: XAF 12 000 ",
    text: "Shooping store: XAF 12 000 ",
  },
  {
    value: "Chicken House: USD 45 000",
    text: "Chicken House: USD 45 000",
  },
];

export const countries = [
  { code: 'CM', currency: 'XAF' },
  { code: 'US', currency: 'USD' },
  { code: 'CA', currency: 'CAD' },
  { code: 'GB', currency: 'GBP' },
  { code: 'JP', currency: 'JPY' },
  { code: 'AU', currency: 'AUD' },
];

export const initialValuesInterTransfer = {
  accountBalance: { Balance: "", currency: "select Balance" },
  from_currency: "",
  amount: 0,
  to_currency: "",
  selectedCountry: { currency: "" ,code:"" },
  description: ""
};
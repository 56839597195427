import { gql, useMutation } from "@apollo/client"

export const useSubscribePsp = () => { 

    const mutation = useMutation(
        gql`
            mutation addPspToApplication($id: String!, $pspId: Int!){
                addPspToApplication(id:$id, pspId:$pspId){
                    user_id
                    name
                    description
                    status
                    mode
                    type
                    sandbox_key
                    live_key 
                    psp{
                        logo
                        shortcode
                    }
                }
            }
        `,
    )

    return mutation
}


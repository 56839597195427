import React, { useEffect, useState } from "react";
import CustomInput from "@components/CustomInput";
import CustomButton from "@components/CustomButton";

import { FormControl, FormHelperText, Stack } from "@mui/material";
import useCreateConfig from "../utils/hooks/useCreateConfig";
import { useFormik } from "formik";
import { posConfigSchema, posUpdateConfigSchema } from "../utils/validation";
import useUpdatePosConfig from "../utils/hooks/useUpdatePosConfig";
import { toast } from "react-toastify";

const PosForm = ({data,onClose,setisUpdate}) => {

  const { mutate, data:response, isLoading } = useCreateConfig();
  const {mutate:update, data:updateResponse, isLoading:isUpdating, isSuccess:isUpdateSuccess} = useUpdatePosConfig(data?.uuid)

  const onSubmit = (inputs) => {
    mutate(inputs,{
      onSuccess:()=>{
        toast.success(`P.O.S Config created successfully`)
        onClose()
      }
    });
  };

  useEffect(() => {
   if(data){
    setisUpdate(true)
   }
  }, [data])
  

  const onUpdate = (inputs) => {
    update(inputs,{
      onSuccess:()=>
      {
      toast.success('P.O.S Config Updated successfully')
      onClose()
      setisUpdate(false)
      }
    });
  };
 

  const formik = useFormik({
    initialValues: data ? {
      name:"",
      location:"",
      pos_ref:""
    } : {
      name: "",
      location: "",
    },
    validationSchema:data ? posUpdateConfigSchema :  posConfigSchema,
    onSubmit: data ? onUpdate : onSubmit,
  });

  useEffect(() => {
   if(data){
    formik.setFieldValue('name',data?.name)
    formik.setFieldValue('location',data?.location)
    formik.setFieldValue('pos_ref',data?.pos_ref)
   }
  }, [data])

  // useEffect(() => {
  //   if(isUpdateSuccess){
  //    toast.success('Pos updated successfully')
  //    onClose()
  //    setisUpdate(false)
  //   }
  //  }, [isUpdateSuccess])
  

  return (
    <Stack gap={2} component="form" onSubmit={formik.handleSubmit}>
      <FormControl>
        <CustomInput
          name="name"
          label="POS Name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helpertext={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          placeholder="Enter POS Name"
        />
        {formik.errors?.name && formik.touched?.name && (
          <FormHelperText fullWidth error>
            <strong>{formik.errors?.name}</strong>
          </FormHelperText>
        )}
      </FormControl>

      <FormControl>
        <CustomInput
          name="location"
          label="POS Address"
          type="text"
          value={formik.values.location}
          onChange={formik.handleChange}
          error={formik.touched.location && !!formik.errors.location}
          helpertext={formik.touched.location && formik.errors.location}
          onBlur={formik.handleBlur}
          placeholder="Enter POS Address"
        />
        {formik.errors?.location && formik.touched?.location && (
          <FormHelperText fullWidth error>
            <strong>{formik.errors?.location}</strong>
          </FormHelperText>
        )}
      </FormControl>
      {
        data && <FormControl>
        <CustomInput
          name="pos_ref"
          label="POS Reference"
          type="text"
          value={formik.values.pos_ref}
          onChange={formik.handleChange}
          error={formik.touched.pos_ref && !!formik.errors.pos_ref}
          helpertext={formik.touched.pos_ref && formik.errors.pos_ref}
          onBlur={formik.handleBlur}
          placeholder="Enter POS Address"
        />
        {formik.errors?.pos_ref && formik.touched?.pos_ref && (
          <FormHelperText fullWidth error>
            <strong>{formik.errors?.pos_ref}</strong>
          </FormHelperText>
        )}
      </FormControl>
      }
      

      <CustomButton
        loading={data ? isUpdating : isLoading}
        size="large"
        variant="contained"
        sx={{ fontSize: 13 }}
        type="submit"
      >
        {data ? "update" : "add"} pos
      </CustomButton>
    </Stack>
  );
};

export default PosForm;

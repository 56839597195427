import axios from "@configs/axios";
//company endpoints

export const getCompaniesAPI = async (selectedApp) => {
  const response = await axios.get(`/invoice-company?app_id=${selectedApp?.id}`)
  return response?.data
}

export const createCompanyAPI = async (inputs) => {
  const response = await axios.post(`/invoice-company`, inputs)
  return response?.data
}

export const getCompanyAPI = async (appID) => {
  const response = await axios.get(`/invoice-company/by-app/${appID}`)
  return response?.data
}

export const updateCompanyAPI = async (companyID, inputs) => {
  const response = await axios.patch(`/invoice-company/${companyID}`, inputs)
  return response?.data
}


export const getInvoicesAPI = async (appID, page, rowsPerPage, status) => {
  const response = await axios.get(`/invoice`, {
    params: {
      status,
      page,
      limit: rowsPerPage,
      application_id: appID,
    }
  })
  return response?.data
}

export const createInvoiceAPI = async (inputs) => {
  const response = await axios.post(`/invoice`, inputs)
  return response?.data
}

export const getInvoiceAPI = async (invoiceID) => {
  const response = await axios.get(`/invoice/${invoiceID}`)
  return response?.data
}


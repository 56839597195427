import React, { Fragment, useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from 'react-toastify';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { Box, Button, ButtonGroup, Grid, Typography, useMediaQuery } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DataGrid from '@components/datagrid'
import AlertDialog from '@components/AlertDialog';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import { deletePayoutAPI } from '@modules/payouts/utils/api';
import { payoutsColumns } from '@modules/payouts/utils/columns'
import { usePayouts } from '@modules/payouts/hooks/usePayouts';
import DashboardSwipeable from '@components/DashboardSwipeable';
import PayoutForm from '@modules/payouts/components/payout-form';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import UpdatePayout from '@modules/payouts/components/update-payout';
import ValidatePayout from '@modules/payouts/components/validate-payout';

const Payouts = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [openUpdateModal, seOpenUpdateModal] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [verifyModalOpen, setVerifyModalOpen] = useState(false)
	const [selectedPayoutID, setSelectedPayoutID] = useState()
	const [selectedPayout, setSelectedPayout] = useState()
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [search, setSearch] = useState('')
	const { appID } = useParams();
	const smallScreen = useMediaQuery('(max-width:900px)');
	const remove = useMutation((payoutID) => deletePayoutAPI(payoutID))

	const filters = {
		page: currentPage + 1,
		limit: rowsPerPage,
		search,
	}

	const { data, isLoading, refetch } = usePayouts(appID, filters)
	const payouts = data?.data

	const paginationOptions = {
		currentPage,
		rowsPerPage,
		onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
		onNextPage: () => setCurrentPage(page => page + 1),
		onPreviousPage: () => setCurrentPage(page => page - 1),
		onFirstPage: () => setCurrentPage(0),
		onLastPage: () => setCurrentPage(payouts?.meta?.totalPages - 1),
		count: payouts?.meta?.totalItems || 1
	}

	const onSearch = (e) => {
		setCurrentPage(0)
		setRowsPerPage(10)
		setSearch(e.target.value)
	}

	const searchOptions = {
		title: 'Search by payout name',
		value: search,
		onSearch
	}

	const onReset = () => {
		setCurrentPage(0)
		setRowsPerPage(10)
		setSearch()
		refetch()
	}

	const getPayout = (payout, edit, verify) => {
		if (edit) {
			seOpenUpdateModal(true)
			setSelectedPayout(payout)
		}
		else if (!edit) {
			if (verify) {
				setSelectedPayout(payout)
				setVerifyModalOpen(true)
			} else {
				setDeleteModalOpen(true)
				setSelectedPayoutID(payout.id)
			}
		}
	}

	const handleDelete = (payoutID) => {
		remove.mutate(payoutID, {
			onSuccess: () => {
				toast.success('Payout deleted succefully')
				setDeleteModalOpen(false)
			},
			onError: (error) => {
				errorHandler(error)
			}
		})
	}

	useEffect(() => {
		refetch()
	}, [currentPage, rowsPerPage, search])

	const breadCrumb = [
		{
			label: 'Payouts',
			link: `/${appID}/payouts`,
			icon: <CreditCardOutlinedIcon fontSize="small" />
		}
	]

	return (
		<Fragment>

			<ToastContainer />

			<Grid mb={4} mt={3} container rowSpacing={2}>
				<Grid item xs={12} md={6}>
					<CustomizedBreadcrumbs links={breadCrumb} />
				</Grid>

				<Grid item xs={12} md={6} textAlign='right'>
					<ButtonGroup fullWidth={smallScreen} size='small' variant='outlined'>
						<Button color='primary' onClick={() => setIsOpen(true)} variant='contained' sx={{ boxShadow: "none" }} startIcon={<AddIcon />}>
							Create payout
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			{
				payouts?.items?.length > 0 || search || isLoading ?
					<DataGrid
						data={payouts?.items}
						head="Payouts"
						paginationOptions={paginationOptions}
						searchOptions={searchOptions}
						column={payoutsColumns(getPayout)}
						onClick={getPayout}
						isLoading={isLoading}
						dataType='payouts'
					/>
					:
					<Box textAlign="center">
						<Typography variant='h5'>No payout found</Typography>
						<CustomButton onClick={onReset} loading={isLoading} startIcon={<RefreshIcon fontSize='large' />} size='large' variant='contained' sx={{ mt: 2, fontSize: 13 }}>
							Refresh
						</CustomButton>
					</Box>
			}

			<DashboardSwipeable
				isOpen={isOpen}
				title="create payout"
				onClose={() => setIsOpen(false)}
			>
				<PayoutForm onClose={() => setIsOpen(false)} />
			</DashboardSwipeable>

			{selectedPayout ?
				<UpdatePayout
					open={openUpdateModal}
					payoutData={selectedPayout}
					onClose={() => seOpenUpdateModal(false)}
				/> : null
			}

			<ValidatePayout
				open={verifyModalOpen}
				payoutData={selectedPayout}
				onClose={() => setVerifyModalOpen(false)}
			/>

			<AlertDialog
				open={deleteModalOpen}
				color="error"
				disabled={remove.isLoading}
				text="Are you sure you want to delete this Payout acccount"
				action={() => { handleDelete(selectedPayoutID) }}
				onClose={() => setDeleteModalOpen(false)}
			/>
		</Fragment>
	)
}

export default Payouts
import React from 'react'
import { LoadingButton } from '@mui/lab'

export default function CustomButton({ loading = false, disabled, onClick = null, color = 'primary', type = 'button', variant = 'contained', startIcon = <></>, endIcon = <></>, fullWidth = false, size = 'small', sx = {}, children }) {
  return (
    <LoadingButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      color={color}
      loading={loading}
      loadingPosition='start'
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      sx={sx}
    >
      {children}
    </LoadingButton>
  )
}

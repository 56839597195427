import React, { Fragment, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Grid, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DetailInput from '@components/Input-Detail';
import Spinner from '@components/Spinner';
import { useState } from 'react';
import { formatDate } from '../../../utils';


export default function CompanyDetails({ open, onClose, company }) {

  const [companyDetail, setCompanyDetail] = useState();

  useEffect(() => {
    if (company) setCompanyDetail(company)
  }, [company, open])

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      open={open}
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Company Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <img
              src={companyDetail?.logo ?? require('../../../assets/images/avatar/default_pic.jpg')}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "8px"
              }}
              alt="company logo" />
          </Grid>
          <Grid item xs={12} md={7} height="100%">
            <Stack gap={3}>
              <Grid container>
                <Grid item xs={6}>
                  <DetailInput label="Company Name" value={companyDetail?.name} />
                </Grid>
                <Grid item xs={6}>
                  <DetailInput label="Phone Number" value={companyDetail?.phone_number} />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DetailInput label="Email" noramlText value={companyDetail?.email} />
                </Grid>
                <Grid item xs={6}>
                  <DetailInput label="Created" value={formatDate(companyDetail?.created_at)} />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
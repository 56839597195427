import { IconButton, Stack } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DeleteOutline } from "@mui/icons-material";
import PayoutChips from "../components/payout-chips"
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header"
import { pspWithLogo } from "../../../utils";

export const payoutsColumns = (getPayout) => [
  {
    id: 'payout_name',
    header: 'payout name',
    accessorKey: 'payout_name',
  },
  {
    id: 'status',
    header: 'status',
    accessorKey: 'status',
    cell: (info) => {
      const status = info.getValue()
      const isVerified = status === 'VERIFIED'
      return <PayoutChips isVerified={isVerified} />
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'customer_mobile_money_name',
    header: 'customer name',
    accessorKey: 'customer_mobile_money_name',
    cell: ({ row }) => {
      const mobileMoneyName = row.original?.customer_mobile_money_name;
      const customerName = row.original?.customer_name;
      if (customerName) return customerName
      else return mobileMoneyName
    },
  },
  {
    id: 'payout_type',
    header: 'Provider',
    accessorKey: 'payout_type',
    cell: ({ row }) => pspWithLogo[row?.original?.payout_type] || pspWithLogo['WORLD_VISA'],
  },
  {
    id: 'payout_data',
    header: 'payout number',
    accessorKey: 'payout_data',
    numeric: true,
  },
  {
    id: 'create_time',
    header: ({ column }) => (
      <DatagridColumnHeader label="Date" column={column} />
    ),
    accessorKey: 'create_time',
    cell: (info) => {
      const date = info.getValue();
      return new Date(date)?.toLocaleDateString("en-US", {
        year: 'numeric', month: 'long', day: 'numeric'
      })
    }
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          {row?.original?.status !== 'VERIFIED' &&
            <IconButton title="Verify this payout" onClick={() => getPayout(row.original, false, true)}>
              <CheckCircleOutlineIcon fontSize="small" color="primary" />
            </IconButton>
          }
          <IconButton title="Delete this payout" onClick={() => getPayout(row.original, false, false)}>
            <DeleteOutline fontSize="small" color="error" />
          </IconButton>
        </Stack>
      )
    }
  },
]
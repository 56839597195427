import React from 'react'
import { useFormik } from 'formik'
import Dialog from '@mui/material/Dialog';
import { Button, Stack } from '@mui/material';
import CustomInput from '@components/CustomInput';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '@components/CustomButton';
import { toast, ToastContainer } from 'react-toastify';
import DialogContent from '@mui/material/DialogContent';

import { regexProvider } from '@utils'
import { useUpdatePayout } from '../hooks/useUpdatePayout';


const UpdatePayout = ({ onClose, open, payoutData }) => {
  const editPayout = useUpdatePayout()

  //================= VALIDATION =================

  const validate = values => {
    const errors = {}
    const RIBRegExp = /^\d+$/

    if (payoutData.provider_type === 'MOBILE_MONEY') {
      if (!values || !values.payout_data) {
        errors.payout_data = 'Phone is required'
      } else if (!regexProvider[values.payout_type].test(values?.payout_data)) {
        errors.payout_data = `Invalid phone number for provider`
      }

    } else {

      if (!values.payout_data) {
        errors.payout_data = 'RIB number is required'
      } else if (!RIBRegExp.test(values.payout_data)) {
        errors.payout_data = 'The RIB should be a number ! No other caracter allowed'
      }
    }

    return errors
  }

  //=============== SENDING REQUEST ============================

  const formik = useFormik({
    initialValues: {
      payout_data: payoutData.payout_data,
      payout_type: payoutData.payout_type
    },
    validate,
    onSubmit: (values) => {

      const payout = {
        id: payoutData.id,
        inputs: {
          payout_data: values.payout_data,
          payout_type: values.payout_type
        }
      }

      editPayout.mutate(payout, {
        onSuccess: () => {
          toast.success(`Payout updated succefully!`)
          onClose()
        }
      })
    }
  })

  //====================== COMPONENT =============================

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{ textAlign: 'center' }}
    >
      <ToastContainer />
      <DialogTitle id="update-payout-dialog-title">Update Payout</DialogTitle>
      <DialogContent>
        <Stack gap={2} mt={.7} width={{ md: "400px" }}>
          <CustomInput
            label="Payout Number"
            type='number' id='payout_data'
            name="payout_data"
            value={formik.values.payout_data}
            placeholder={payoutData.provider_type === 'MOBILE_MONEY' ? "Payout Number" : "RIB Number"}
            onChange={formik.handleChange}
            helpertext={formik.touched.payout_data && formik.errors.payout_data}
            onBlur={formik.handleBlur}
          />
          <Stack direction="row" gap={.5} justifyContent="end">
            <Button onClick={onClose} color="inherit" disabled={editPayout.isLoading}>Cancel</Button>
            <CustomButton
              onClick={() => formik.handleSubmit()}
              disabled={editPayout.isLoading}
              type='submit'
              variant="contained"
              loading={editPayout.isLoading}
              size='medium'
              sx={{ textTransform: editPayout.isLoading ? 'capitalize' : 'uppercase' }}
            >
              {editPayout.isLoading ? 'Updating...' : 'Update'}
            </CustomButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog >
  )
}

export default UpdatePayout
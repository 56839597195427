import axios from "@configs/axios";

// product API

export const getProductsAPI = async ({ selectedApp, page, limit }) => {
  const params = {
    ...(selectedApp && { app_id: selectedApp }),
    ...(limit && { limit }),
    ...(page ? { page: page + 1 } : { page: 1 }),
  };
  const response = await axios.get(`/products?`, {
    params,
  });
  return response?.data;
};

export const createProductAPI = async (inputs) => {
  const response = await axios.post(`/products/create`, inputs);
  return response?.data;
};

export const updateProductAPI = async (inputs) => {
  const id = inputs?.id;
  const values = inputs?.values;
  const response = await axios.patch(`/products/${id}`, values);
  return response?.data;
};

export const uploadAPI = async (data) => {
  const response = await axios.post(`/products/${data?.id}/upload`, data.file);
  return response?.data;
};

export const deleteProductAPI = async (productID) => {
  const response = await axios.delete(`/products/${productID}`);
  return response?.data;
};

// category API
export const getCategoriesAPI = async (selectedApp, filters) => {
  console.log(filters);
  const params = {
    ...(selectedApp && { app_id: selectedApp?.id }),
    ...(filters?.limit && { limit: filters?.limit }),
    ...(filters?.page ? { page: filters?.page + 1 } : { page: 1 }),
  };
  const response = await axios.get(`/product-category`, {
    params,
  });
  return response?.data;
};

export const createCategoryAPI = async (inputs) => {
  const response = await axios.post(`/product-category`, inputs);
  return response?.data;
};

export const updateCategoryAPI = async (inputs) => {
  const id = inputs.id;
  delete inputs.id;
  const response = await axios.patch(`/product-category/${id}`, inputs);
  return response?.data;
};

export const deleteCategoryAPI = async (categoryID) => {
  const response = await axios.delete(`/product-category/${categoryID}`);
  return response?.data;
};

import { useMutation } from 'react-query';
import { editKycsAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useEditKycs = () => { 

  const mutation = useMutation({
    mutationKey: 'editKycs',
    mutationFn: editKycsAPI,
    onError: (error) => errorHandler(error) 
  })

return mutation
 }
import { Box, IconButton, Stack } from "@mui/material"
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { errorHandler } from "../../../configs/errorConfig";
import { downloadDocAPI } from "./api";
import { toast } from "react-toastify";

const onDownloadDoc = (file) => {
    toast.info('The download is processing')
    downloadDocAPI(file).then(response => {
        const fileLink = document.createElement("a")

        fileLink.href = response?.data?.document
        fileLink.setAttribute("download", file)
        document.body.appendChild(fileLink)

        fileLink.click()

    }).catch(error => errorHandler(error))
}

export const exportedDocsColumns = [
    {
        id: 'id',
        header: 'ID',
        accessorKey: 'id',
    },
    {
        id: 'key',
        header: 'Document',
        accessorKey: 'key',
        cell: info => <Box sx={{ display: 'flex', alignItems: 'end' }}><DescriptionOutlinedIcon size='25' sx={{ mr: 1 }} />{ info.getValue()?.split('/')[3] }</Box>
    },
    {
      id: 'createdAt',
      header: ({ column }) => <DatagridColumnHeader label="Date" column={column} />,
      accessorKey: 'createdAt',
      cell: (info) => {
        const date = info.getValue();
        return new Date(date)?.toLocaleDateString("en-US", {
          year: 'numeric', month: 'long', day: 'numeric'
        })
      },
      disablePadding: false,
    },
    {
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <Stack direction="row">
                <IconButton onClick={() => onDownloadDoc(row?.original?.id)}>
                    <DownloadIcon 
                        fontSize="small" 
                        color="primary"
                    />
                </IconButton>
            </Stack>
        )
    },
]
import { AttachMoney } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SendIcon from '@mui/icons-material/Send';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReactCountryFlag from "react-country-flag";
import { countries, initialValuesInterTransfer, selectValues } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useAppBalance } from "../hooks/useAppBalance";
import { useAppBalancePerCurrency } from "../hooks/useAppPerCurrency";
import { useBalance } from "../hooks/useBalance";
import { useGetRate } from "../hooks/useGetRate";
import { useFormik } from "formik";
import { validationSchemaInterTrans } from "../utils/validation";
import { grey, red } from "@mui/material/colors";
import { useInitialiseCurrencyTransfer } from "../hooks/useInitialiseCurrencyTransfer";
import { useSelector } from "react-redux";
import { TransfertConfirmValues, convertCountries, currencies_limit, depositConfirmValues } from "../../disbursement/utils";
import { TransfertconfirmDeposit, confirmDeposit, confirmTransfert } from "../../disbursement/utils/validation";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { countryCurrencies, countryNames, currencyToCountryCode, supportedCurrencies } from "../../../utils";

const InterTransForm = ({ setIsConfirmTransfertModal, handleClose }) => {
  // const [accountBalance, setAccountBalance] = useState({
  //   Balance:""
  // });
  const [amountToTransfer, setAmountToTransfer] = useState("");
  const [beneficiaryNumber, setBeneficiaryNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryDescription, setBeneficiaryDescription] = useState("");
  const [inputValue, setInputValue] = useState(null);
  const [resData, setresData] = useState(null)

  const [convertedData, setConvertedData] = useState({

    "from_currency": "XAF",
    "to_currency": "GHS",
    "amount": 15,
    "transfert_fees": 1.2,
    "converted_amount": 0.30144,
    "exchange_rate": 0.020096

  })
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const params = useParams();
  const navigate = useNavigate();
  const { appID } = params;
  // console.log({ appID });
  const fee = 5;
  const handleTransfer = () => {
    console.log("Transferring...");
    console.log("Amount: ", amountToTransfer);
    console.log("Beneficiary Number: ", beneficiaryNumber);
    console.log("Beneficiary Name: ", beneficiaryName);
    console.log("Beneficiary Description: ", beneficiaryDescription);
  };

  const { data: AppBalance, isLoading, refetch } = useAppBalance();

  const { data: BalancePerCurrency } = useAppBalancePerCurrency("GHS")
  const { data: Balance } = useBalance()

  const { mutate: mutateData, isLoading: Load } = useInitialiseCurrencyTransfer();

  // mutate(getRateData)

  const selectedApp = useSelector((store) => store.data.selectedApp);
  const onSubmit = (data) => {

    const sendData = {
      from_currency: data?.accountBalance?.currency,
      to_currency: data?.selectedCountry?.currency,
      amount: Number(data.amount),
      description: data?.description,
      application_id: selectedApp?.id,

    }
    // console.log({ sendData })

    mutateData(sendData, {
      onSuccess: (res) => {
        toast.success("Transfert initiated successfully")
        setIsConfirmTransfertModal({
          data: res?.data,
          open: true
        })
        formik.resetForm()
        // handleClose()
      },
      onError: (err) => {
        // console.log(err.isError);
        if (err?.response?.data?.message) {
          const errorMsg = err?.response?.data?.message
          toast.error(errorMsg)
        } else
          toast.error(err?.message)
      }
    })
  }


  const formik = useFormik({
    initialValues: initialValuesInterTransfer,
    validationSchema: validationSchemaInterTrans,
    onSubmit
  })


  const getRateData = {
    to_currency: formik.values.accountBalance?.currency,
    from_currency: formik.values.selectedCountry?.currency,
  }

  const { data: TransferRate, isLoading: getRateLoading, refetch: getRateInfo } = useGetRate(getRateData)
  // console.log({TransferRate})

  useEffect(() => {
    getRateInfo()
  }, [formik.values])


  const supportedCurrenciesArray = Object.entries(supportedCurrencies).map(([code, currency]) => ({
    code: currencyToCountryCode[code],
    currency,
  }));

  // function roundUp(data)
  const goBack = () => {
    navigate(-1)
  }
  const currencyOptions = convertCountries(countryCurrencies)
  const amountToSend = formik.values.amount * TransferRate?.data.exchange_rate
  return (

    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontSize: "0.8rem",
              fontWeight: "700",
              marginBottom: ".5rem",
            }}
            id="balance"
          >
            Choose Balance to transfer from
          </InputLabel>
          <FormControl sx={{ mb: 1 }} fullWidth>
            <Select
              required
              id="accountBalance"
              name="accountBalance"
              size="small"
              sx={{
                h: "10px",
              }}
              value={formik.values.accountBalance}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.setFieldValue("accountBalance", {
                  Balance: selectedValue.Balance,
                  currency: selectedValue.currency,
                });
              }}
              renderValue={(selected) => {
                if (selected?.Balance === "") {
                  return <div style={{ color: "gray" }}>select balance</div>;
                }
                return selected?.Balance + " " + selected?.currency;
              }}
            //
            >
              {[
                { Balance: "", currency: "select Balance" },
                ...(AppBalance?.data || []),
              ].map((items, i) => (
                <MenuItem key={i} value={items}>
                  {items?.Balance} {items?.currency}
                </MenuItem>
              ))}
            </Select>
            {formik.errors?.accountBalance?.Balance &&
              formik.touched?.accountBalance?.Balance && (
                <FormHelperText fullWidth error>
                  <strong>{formik.errors?.accountBalance?.Balance}</strong>
                </FormHelperText>
              )}
          </FormControl>
          <Stack sx={{ my: 1 }} spacing={{ xs: 1, sm: 2, md: 2 }}>
            <InputLabel
              sx={{
                fontSize: "0.8rem",
                fontWeight: "700",
                marginBottom: ".5rem",
              }}
            >
              How much do you want to send?
            </InputLabel>
          </Stack>
          <Stack sx={{ mb: 1 }} container direction={"row"} spacing={2}>
            <div>
              <FormControl>
                <InputLabel id="toCurrency">Select Currency</InputLabel>
                <Select
                  size="small"
                  labelId="toCurrency"
                  id="toCurrency"
                  name="toCurrency"
                  label="Select Currency"
                  value={formik.values.selectedCountry}
                  onChange={(e, value) => {
                    const selectedValue = e.target.value
                    formik.setFieldValue("selectedCountry", {
                      currency: selectedValue.currency,
                      code: selectedValue.code
                    })
                  }}
                  renderValue={(selected) => {
                    return (<Box sx={{ fontSize: '.9rem' }}>{`${selected?.currency} ${" "} ${selected?.code && `(${selected?.code})`}`}</Box>)
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      // height: '28px',
                      width: "90px" // Set your custom height
                      // border: '1px solid #ced4da', // Set your custom border style
                    }
                  }}
                >
                  {
                    (currencyOptions || [])
                      .map((items, i) => (
                        <MenuItem sx={{ fontSize: ".9rem", display: "felx", gap: "8px" }} key={i} value={items}>
                          <ReactCountryFlag countryCode={items.code} svg /> {" "}  {items?.currency}{" "} {`(${countryNames[items?.code]})`}
                        </MenuItem>
                      ))}
                </Select>

                {formik.errors?.selectedCountry?.currency &&
                  formik.touched?.selectedCountry?.currency && (
                    <FormHelperText fullWidth error>
                      <strong>
                        {formik.errors?.selectedCountry?.currency}
                      </strong>
                    </FormHelperText>
                  )}
              </FormControl>
            </div>
            <div style={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }} fullWidth>

                <TextField
                  size="small"
                  disabled={formik.values.accountBalance.Balance ? false : true}
                  fullWidth
                  name="amount"
                  type="number"
                  label={`Amount To Transfer ${formik.values.selectedCountry.currency &&
                    currencies_limit[formik.values.selectedCountry.currency] && `max : ${currencies_limit[formik.values.selectedCountry.currency].max_deposit_amount} , min : ${currencies_limit[formik.values.selectedCountry.currency].min_deposit_amount}`}`}
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PaymentsOutlinedIcon />
                        {/* <Box sx={{ ml: 1 }}>
                          Amount
                        </Box> */}
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.errors?.amount && formik.touched?.amount && (
                  <FormHelperText fullWidth error>
                    <strong>{formik.errors?.amount}</strong>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </Stack>
          {formik.values.amount !== 0 &&
            formik.values.selectedCountry.currency &&
            formik.values.accountBalance && (
              <Alert sx={{ my: 2 }} severity="info">
                <AlertTitle>Exchange Rate</AlertTitle>
                {getRateLoading ? (
                  <div>loading...</div>
                ) : (
                  <Typography>

                    {formik.values.amount}{" "}
                    <strong>{TransferRate?.data.from_currency}</strong>
                    {" = "}
                    <strong>{TransferRate?.data.to_currency}</strong>{" "}
                    {amountToSend} {" at "}{" "}
                    {TransferRate?.data.exchange_rate} {"exchange rate"}
                  </Typography>
                )}
              </Alert>
            )}
          <FormControl fullWidth>
            <TextField
              multiline
              rows={4}
              fullWidth
              name="description"
              margin="normal"
              label="Description (optional)"
              value={formik.values.description}
              onChange={(e) => formik.setFieldValue("description", e.target.value)}
            />
            {formik.errors?.description && formik.touched?.description && (
              <FormHelperText fullWidth error>
                <strong>{formik.errors?.description}</strong>
              </FormHelperText>
            )}
          </FormControl>
          <div style={{ display: "flex", gap: "1rem", justifyContent: "end" }}>
            <Button
              size="small"
              sx={{ mt: 2, px: "2rem" }}
              variant="text"
              // color="secondary"
              onClick={goBack}
            >
              cancel
            </Button>
            <LoadingButton
              endIcon={<SendIcon />}
              color="primary"
              sx={{ mt: 2, textAlign: "end" }}
              loading={Load}
              loadingPosition="end"
              size="small"
              variant="contained"
              type="submit"
            >
              <span>Proceed</span>
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </form>

  );
};

export default InterTransForm;

import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Container, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import HomeHeader from '@components/HomeHeader';
import AlertDialog from '@components/AlertDialog';
import { errorHandler } from '@configs/errorConfig';
import DashboardSwipeable from '@components/DashboardSwipeable';
import ApplicationForm from '@modules/home/components/ApplicationForm';
import ApplicationItem from '@modules/home/components/ApplicationItem';
import { useApplications } from '@modules/application/hooks/useApplications';
import { useDeleteApplication } from '@modules/home/hooks/useDeleteApplication';
import ApplicationNotFound from '@modules/application/components/application-not-found';
import ApplicationSkeleton from '@modules/application/components/application-skeleton';

const Home = () => {

	const smallScreen = useMediaQuery('(max-width:900px)');
	const [isOpen, setIsOpen] = useState(false)
	const [deleteOpen, setDeleteOpen] = useState(false)
	const [selectedApp, setSelectedApp] = useState()
	const user = useSelector((store) => store.auth.userData)
	const apps = useSelector((store) => store.data.applications)
	const { isLoading, refetch: refetchApplications } = useApplications(user?.id)

	const [deleteApplication, { error, loading }] = useDeleteApplication()

	const onDelete = () => {
		deleteApplication({ variables: { id: selectedApp?.id } }).then(() => {
			refetchApplications()
			setDeleteOpen(false)
			toast.success(`Application ${selectedApp?.name} deleted successfully`)
		}).catch(() => errorHandler(error))
	}
	// ================= COMPONENT =================
	return (
		<section className='main-container'>
			<ToastContainer />

			<HomeHeader />

			<Container sx={{ mb: 5 }}>
				<Typography variant="h5" sx={{ mt: 7, fontWeight: 'bold', textTransform: 'capitalize' }} gutterBottom>
					Application List
				</Typography>

				<Divider sx={{ mb: 3, mt: 1 }} />

				<Grid mb={2} container rowSpacing={2}>
					<Grid item xs={12} sx={{ textAlign: 'right' }}>
						<Button color='primary' fullWidth={smallScreen} onClick={() => setIsOpen(true)} variant="contained" startIcon={<AddIcon />}>
							Create application
						</Button>
					</Grid>
				</Grid>

				<Grid container py={4} spacing={4}>
					{
						(isLoading && apps?.length === 0) ? <ApplicationSkeleton /> 
						:
							apps?.length === 0 ? <ApplicationNotFound /> 
							:
								<Fragment>
									{apps?.map((app) =>
										<Grid item key={app?.id} xs={12} md={4}>
											<ApplicationItem
												refetchApplications={refetchApplications}
												onDelete={onDelete}
												application={app}
												setDeleteOpen={setDeleteOpen}
												setSelectedApp={setSelectedApp}
											/>
										</Grid>
									)}
								</Fragment>
					}
				</Grid>
			</Container >

			{/* dialogs */}
			<AlertDialog
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				action={onDelete}
				loading={loading}
				color="error"
				text={<span>Are you sure you want to delete <span style={{ fontWeight: 'bold' }}>{selectedApp?.name}</span> ?</span>}
			/>

			<DashboardSwipeable
				isOpen={isOpen}
				title="Create an application"
				onClose={() => setIsOpen(false)}
			>
				<ApplicationForm
					refetchApplication={refetchApplications}
					setIsOpen={setIsOpen}
				/>
			</DashboardSwipeable>
		</section>
	);
}

export default Home
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { errorHandler } from "@configs/errorConfig";
import { getAllDisbursement } from "../utils/api";

export const useGetAllDisbursement = (filters) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();

  console.log(filters);

  const queryKey = [
    `getAllDisbursement-${selectedApp?.id}-${filters?.page}-${filters?.limit}-${filters?.status}-${filters?.deposit_type}-${filters?.search}`,
    selectedApp,
  ];

  const query = useQuery({
    queryKey,
    queryFn: async () => await getAllDisbursement(selectedApp, filters),
    onError: (error) => errorHandler(error),
  });

  useEffect(() => {
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);
  return query;
};

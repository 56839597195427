import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query"
import { getAssociatedPspAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"

export const useGetAssociatedPsp = () => {  

	const selectedApp = useSelector((store) => store.data.selectedApp);
	const queryClient = useQueryClient(); // Utilisez useQueryClient pour accéder au client de requête

	// Utilisez une clé de requête dynamique pour suivre les modifications de selectedApp  
	const queryKey = ["associated_providers", selectedApp];

	const query = useQuery({
		queryKey,    
		queryFn: async () => await getAssociatedPspAPI(selectedApp?.id),
		onError: (error) => errorHandler(error) 
	})

	// Utilisez useEffect pour invalider manuellement la requête lorsque selectedApp change
	useEffect(() => {    
		queryClient.invalidateQueries(queryKey);
	}, [selectedApp]);

	return query;

}

import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import DataGrid from '@components/datagrid';
import { cashoutsColumns } from '@modules/cashouts/utils/columns'
import { useCashouts } from '@modules/cashouts/hooks/useCashouts';
import VerifyCashout from '@modules/cashouts/components/verify-cashout';
import CashoutDetails from '@modules/cashouts/components/cashout-details';
import { transactionColumns } from '@modules/transactions/utils/columns';
import GeneralCharts from '@modules/overview/components/Charts/GeneralCharts';
import BalanceHistory from '@modules/overview/components/balance-history';
import { useTransactions } from '@modules/transactions/hooks/useTransactions';
import TransactionDetails from '../../../modules/transactions/components/transaction-details';
import { useFinanceStatistics } from '../../../modules/overview/hooks/useFinanceStatistics';
import { useBalanceStatistics } from '../../../modules/overview/hooks/useBalanceStatistics';
import dayjs from 'dayjs';
import { statusList } from '../../../modules/transactions/utils';

export default function Overview() {
  const [verifyModalOpen, setVerifyModalOpen] = useState(false)
  const [selectedCashout, setSelectedCashout] = useState()
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [transactionDetailsModalOpen, setTransactionDetailsModalOpen] = useState(false)
  const { data: balances, isLoading: balanceLoading } = useBalanceStatistics()
  const [statusTransactions, setStatusTransactions] = useState()
  const [searchTransactions, setSearchTransactions] = useState()

  const filtersTransactions = {
    page: 1,
    limit: 10,
    status: statusTransactions,
    search: searchTransactions,
  }
  const filtersCashouts = {
    page: 1,
    limit: 10,
  }

  const { data: dataCashouts, isLoading: isLoadingCashouts } = useCashouts(filtersCashouts)
  const { data, isLoading: isLoadingTransactions } = useTransactions(filtersTransactions)

  const cashouts = dataCashouts?.data

  const filterOptionsList = [
    {
      label: 'Status',
      options: statusList,
      value: statusTransactions,
      onFilterChange: (e) => setStatusTransactions(e.target.value)
    }
  ]

  const onSearchTransactions = (e) => {
    setStatusTransactions()
    setSearchTransactions(e.target.value)
  }

  const searchTransactionsOptions = {
    title: 'Search by transaction ID',
    value: searchTransactions,
    onSearch: onSearchTransactions
  }

  const [startDate, setStartDate] = useState(dayjs().startOf('month'))
  const [endDate, setEndDate] = useState(dayjs().endOf('month'))

  const collectFilters = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    origin: 'COLLECT'
  }

  const cashoutFilters = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    origin: 'CAHSOUT'
  }

  const disbursementFilters = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    origin: 'DISBURSEMENT'
  }

  const { data: financesCashout, isLoading: loadingCashout, refetch: refetchCashout } = useFinanceStatistics(cashoutFilters)
  const { data: financesCollect, isLoading: loadingCollect, refetch: refetchCollect } = useFinanceStatistics(collectFilters)
  const { data: financesDisbursement, isLoading: loadingDisbursement, refetch: refetchDisbursement } = useFinanceStatistics(disbursementFilters)

  const getCashout = (cashout, verify) => {
    setSelectedCashout(cashout)
    if (verify) setVerifyModalOpen(true)
    else setDetailsModalOpen(true)
  }

  useEffect(() => {
    refetchCashout()
    refetchCollect()
    refetchDisbursement()
  }, [startDate, endDate])
  // ================= COMPONENT =================

  return (
    <Fragment>

      <Grid mb={4} mt={1} container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} md={8}>

          <GeneralCharts
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            chartDatas={{ financesCollect, financesCashout, financesDisbursement }}
            loading={loadingCollect || loadingCashout || loadingDisbursement}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <CurrentBalance currentBalance={accountBalance} appBalance={applicationBalance} loading={statsLoading} /> */}
          <BalanceHistory balances={balances?.data} loading={balanceLoading} />
        </Grid>
      </Grid>
      {
        (data?.data?.transactions?.items?.length > 0 || isLoadingTransactions || searchTransactions || statusTransactions) &&
        <DataGrid
          column={transactionColumns(setSelectedTransaction, setDetailsModalOpen)}
          head="Recent transactions"
          dataType='transactions'
          isLoading={isLoadingTransactions}
          showPagination={false}
          searchOptions={searchTransactionsOptions}
          filterOptionsList={filterOptionsList}
          data={data?.data?.transactions?.items}
        />
      }
      <Box mt={3}>
        {
          (cashouts?.items?.length > 0 || isLoadingCashouts) &&
          <DataGrid
            data={cashouts?.items}
            hidePadding
            head="Recent cashouts"
            isLoading={isLoadingCashouts}
            showPagination={false}
            column={cashoutsColumns(getCashout)}
            onClick={getCashout}
            dataType='cashouts'
          />
        }
      </Box>

      {
        selectedCashout &&
        <CashoutDetails
          cashoutData={selectedCashout}
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
        />
      }

      {transactionDetailsModalOpen &&
        <TransactionDetails
          open={transactionDetailsModalOpen}
          onClose={() => setTransactionDetailsModalOpen(false)}
          transaction={selectedTransaction}
        />
      }

      <VerifyCashout
        open={verifyModalOpen}
        cashoutData={selectedCashout}
        onClose={() => setVerifyModalOpen(false)}
      />
    </Fragment >
  )
}
import React from 'react'
import { useFormik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, FormHelperText } from '@mui/material';
import Logo from '@components/Logo';
import CustomLink from '@components/link';
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { useResetPassword } from '@modules/authentication/hooks/useResetPassword';
import { resetPasswordValidation } from '@modules/authentication/utils/validations';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { email } = useParams()
  const navigate = useNavigate()
  const { mutate, isLoading } = useResetPassword()

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      code: '',
      new_password: ''
    },
    validationSchema: resetPasswordValidation,
    onSubmit: values => {
      const data = {
        code: values.code,
        new_password: values.new_password,
        email
      }

      mutate(data, {
        onSuccess: (data) => {
          toast.success(data.data.message)
          setTimeout(() => {
            navigate("/login")
          }, 2000)
        },
        onError: (error) => {
          toast.error(error?.response?.data.message)
        }
      })
    }
  })

  return (
    <section className='main-container'>
      <Container sx={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1em" }}>
        <Logo height={50} />

        <ToastContainer />

        <Box
          component="form"
          sx={{
            mb: 1, mt: 3, p: "2em 1em 1em 1em", background: '#ffffff', borderRadius: 2,
            width: {
              xs: '100%', sm: "400px",
              display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: ".5em"
            }
          }}
          onSubmit={formik.handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Typography variant='h5' sx={{ textAlign: "center", fontWeight: "bold" }}>
            Reset Password 🔒
          </Typography>
          <Typography textAlign="center" mb={1} sx={{ color: "GrayText" }}>
            {email}
          </Typography>
          <Typography textAlign="center" mb={1} sx={{ color: "GrayText" }}>
            Your new password must be different from previously used passwords
          </Typography>

          <CustomInput
            name="code"
            value={formik.values.code}
            error={!!formik.touched.code && !!formik.errors.code}
            helpertext={formik.errors.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Otp Code"
            placeholder="Enter Code"
          />

          <FormControl sx={{ mb: 1 }} fullWidth variant="outlined" error={!!formik.errors.new_password}>
            <InputLabel htmlFor="password" size='small'>New Password</InputLabel>
            <OutlinedInput
              size='small'
              id="new_password"
              name="new_password"
              value={formik.values.new_password}
              error={!!formik.touched.new_password && !!formik.errors.new_password}
              helpertext={formik.errors.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              placeholder='Enter your new password'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.new_password && formik.errors.new_password && <FormHelperText error>{formik.errors.new_password}</FormHelperText>}
          </FormControl>
          <CustomButton
            disabled={isLoading}
            type='submit'
            loading={isLoading}
            fullWidth
            size='large'
            sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
          >
            {isLoading ? 'Resting...' : 'Reset'}
          </CustomButton>
          <Box sx={{ width: "100%" }}>
            <Typography textAlign="right" mt={1} >
              Didn't receive an code? <CustomLink to="/" color="secondary" label="Resend" />
            </Typography>
          </Box>
        </Box>
        <CustomLink color="secondary" to="/login" label="Back to login" />
      </Container>
    </section>
  )
}

export default ResetPassword
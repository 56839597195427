import React, { Fragment } from 'react'
import { Skeleton } from '@mui/material'

export default function BalanceLoader() {
	return (
		<Fragment>
			{[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) =>
				<Skeleton key={key} height={48} animation="wave" />
			)}
		</Fragment>
	)
}


const types={
    PAYMENTS_COLLECTION: 'Collection',
    LINKS: 'Link'
}

const modes={
    SANDBOX: 'Sandbox',
    PRODUCTION: 'Live'
}

const status={
    ACTIVE: 'success',
    INACTIVE: 'error',
    SUSPENDED: 'default'
}


export { types, modes, status }
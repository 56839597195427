import { Alert, Stack } from '@mui/material'
import { ErrorMessage } from 'formik'
import React from 'react'

const InvoiceErrors = ({ errors }) => {
  return (
    <Alert severity="error">
      <Stack>
        <Stack>
          * <ErrorMessage name="client_name" />
        </Stack>
        <Stack>
          * <ErrorMessage name="client_email" />
        </Stack>
        <Stack>
          * <ErrorMessage name="client_phone_number" />
        </Stack>
        <Stack>
          * <ErrorMessage name="due_date" />
        </Stack>
        <Stack>
          * <ErrorMessage name="currency" />
        </Stack>
        {!!errors?.items && <Stack>* Make sure all required fields on items are filled</Stack>}
      </Stack>
    </Alert>
  )
}

export default InvoiceErrors
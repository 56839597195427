import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { toast, ToastContainer } from 'react-toastify';
import DialogContent from '@mui/material/DialogContent';
import { Button, Stack, Typography } from '@mui/material';

import Spinner from '@components/Spinner';
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { useValidatePayout } from '../hooks/useValidatePayout';
import { useResendPayoutOtp } from '../hooks/useResenPayoutOtp';

const ValidatePayout = ({ onClose, open, payoutData }) => {

  const { mutate, isLoading } = useValidatePayout()
  const { mutate: resendCode, isLoading: otpIsCodeLoading } = useResendPayoutOtp()

  const submitSMSValidation = yup.object().shape({
    sms_code: yup.string().min(6).max(6).required('OTP is required')
  })


  //================= RESENDING OTP REQUEST=================

  const resendOtp = (payoutID) => {
    resendCode(payoutID, {
      onSuccess: () => {
        toast.success('OTP code sent successfully')
      }
    })
  }

  //=============== SENDING OTP VALIDATION REQUEST ============================

  const handleSubmitCode = async (values) => {

    const payload = {
      payout_id: payoutData.id,
      code: values.sms_code
    }
    mutate(payload, {
      onSuccess: (data) => {
        toast.success(data?.message)
        onClose()
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      sms_code: ''
    },
    validationSchema: submitSMSValidation,
    onSubmit: (values) => handleSubmitCode(values)
  })

  //====================== COMPONENT =============================

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <ToastContainer />
      <DialogTitle id="update-payout-dialog-title" sx={{ textAlign: 'center' }}>Verify Payout Account</DialogTitle>
      <DialogContent>
        <Typography textAlign='center'>Check your mailbox to get the verification code</Typography>

        {
          otpIsCodeLoading ?
            <Spinner /> 
          :
            <Stack gap={1} mt={.7} width={{ md: "400px" }}>
              <CustomInput
                label="OTP Code"
                id='payout_data'
                name="sms_code"
                value={formik.values.sms_code}
                placeholder="xxxxxx"
                onChange={formik.handleChange}
                helpertext={formik.touched.sms_code && formik.errors.sms_code}
                onBlur={formik.handleBlur}
              />
              <Stack direction="row" alignItems="center" justifyContent='center' gap={0} sx={{ width: "100%" }}>
                <Typography fontSize="small" textAlign='center'>Didn't received the verification code?</Typography>
                <Button color='secondary' sx={{ fontSize: "small", textTransform: "capitalize" }} onClick={() => { resendOtp(payoutData?.id) }}>Resend</Button>
              </Stack>
              <Stack direction="row" gap={.5} justifyContent="end">
                <Button onClick={onClose} color="inherit" disabled={isLoading}>Cancel</Button>
                <CustomButton
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoading}
                  type='submit'
                  variant="contained"
                  loading={isLoading}
                  size='medium'
                  sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
                >
                  {isLoading ? 'Verifying...' : 'Verify'}
                </CustomButton>
              </Stack>
            </Stack>
        }
      </DialogContent>
    </Dialog >
  )
}

export default ValidatePayout

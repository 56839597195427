import { useMutation, useQueryClient } from 'react-query';
import { Disbursement, toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';
import { useSelector } from 'react-redux';

export const useConfirmTransfert = () => {
    const queryClient = useQueryClient();
    const diburseapi = new Disbursement()
    const mutation = useMutation({
        mutationKey: 'confirmTransfert',
        mutationFn: diburseapi.confirmTransfert,
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllDisbursement"])
        }

    })

    return mutation
}
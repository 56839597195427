import * as React from 'react';
import Card from '@mui/material/Card';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Badge, Box, Button, CircularProgress, Grid, Menu, MenuItem } from '@mui/material';

import theme from '@src/theme';
import { formatDate } from '@utils';
import { modes, status, types } from '../../utils';
import { useToggleActiveApplication } from '../../hooks/useToggleActiveApplication';
import PulseText from '../../../../components/PulseText';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
})
);

const ITEM_HEIGHT = 48;

export default function ApplicationItem({ application, setSelectedApp, setDeleteOpen, refetchApplications }) {
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { mutate, isLoading: isLoadingActive } = useToggleActiveApplication()

  const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onToggleStatusClick = (selectedApp) => {
    handleClose()
    mutate(selectedApp, {
      onSuccess: () => {
        refetchApplications()
        toast.success(`${selectedApp?.name} is now ${selectedApp?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`)
      }
    })
  }

  const onDeleteClick = (selectedApp) => {
    handleClose()
    setDeleteOpen(true)
    setSelectedApp(selectedApp)
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        sx={{ filter: (application?.status === "SUSPENDED") ? 'grayScale(100%)' : 'initial' }}
        avatar={
          <Badge color={status[application?.status]} overlap="circular" badgeContent=" ">
            <Avatar
              sx={{ bgcolor: application?.status === 'SUSPENDED' ? 'lightgray' : theme.palette.primary.main }}
              alt={application?.name}
              src='#Profile image here if exists'
            />
          </Badge>
        }
        action={
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {isLoadingActive ? <CircularProgress size={20} color="inherit" /> : <MoreVertIcon />}
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{ 'aria-labelledby': 'long-button' }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  minWidth: '13ch',
                },
              }}
            >
              <MenuItem disabled={application?.status === 'SUSPENDED'} onClick={() => onToggleStatusClick(application)}>
                {application?.status === 'ACTIVE' ? 'Desactivate' : 'Activate'}
              </MenuItem>
              <MenuItem disabled={application?.status === 'SUSPENDED'} onClick={() => navigate(`${application?.id}/application-details`)}>
                Settings
              </MenuItem>
              <MenuItem sx={{ background: 'red', color: '#fff' }} onClick={() => onDeleteClick(application)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <Box fontWeight='bold'>
            {application?.name} - {' '}
            <span 
              style={{ 
                color: application?.status === 'ACTIVE' ? 'green' : application?.status === 'INACTIVE' ? 'red' : 'gray', 
                textTransform: 'lowercase' 
              }}
            >
              { application?.status }
            </span>
          </Box>
        }
        subheader={formatDate(application?.created_at)}
      />
      <CardContent>
        <Grid mb={2} container columnSpacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" fontSize={13} color="text.secondary">
              Type
            </Typography>
            <Box>{types[application?.type]}</Box>
          </Grid>

          <Grid item xs={6} textAlign='right'>
            <Typography variant="body2" fontSize={13} color="text.secondary">
              Mode
            </Typography>
            <Box>{modes[application?.mode]}</Box>
          </Grid>
        </Grid>
        <Typography variant="body2" textAlign='justify' color="text.secondary">
          <PulseText>{ application?.description }</PulseText>
        </Typography >
      </CardContent >
      <CardActions disableSpacing>
        <Button variant='contained' sx={{ mb: 1 }} size='small' disabled={application?.status === "SUSPENDED"} component={Link} to={`${application?.id}/overview`}>
          MORE
        </Button>
        {((application?.kyc && application?.validations?.length === 0) || !application?.kyc) &&
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <InfoOutlinedIcon color={application?.status === "SUSPENDED" ? 'error' : application?.kyc ? 'info' : 'warning'} />
          </ExpandMore>
        }
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

          {application?.status === "SUSPENDED" &&
            <Alert sx={{ mb: 2 }} severity="error">
              Your application has been suspended for reasons of violation of the terms of service. Contact support for more information.
            </Alert>
          }
          <Alert severity={application?.kyc ? 'info' : "warning"}>
            {application?.kyc ? 'KYC validation in progress...' : 'No KYC submitted for this application'}
          </Alert>
          {!application?.kyc && application?.status !== "SUSPENDED" &&
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Button
                sx={{ textTransform: 'capitalize' }}
                fullWidth
                variant='outlined'
                component={Link}
                to={`${application?.id}/kycs/manage`}
                startIcon={<DescriptionIcon />}
              >
                Click here to submit KYC
              </Button>
            </Box>
          }
        </CardContent>
      </Collapse>
    </Card >
  );
}

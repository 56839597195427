import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Grid, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { amountWithCurrency } from '@src/utils';
import DetailInput from '@components/Input-Detail';


export default function PaymentLinkDetails({ open, onClose, paymentLinkDetail }) {
  const { decription: description, currency, img_url, link, short_link, id, min_amount, amount, product_name, mode } = paymentLinkDetail;

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        fullWidth
        open={open}
        maxWidth="md"
      >

        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Payment Link Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,

          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img
                src={img_url}
                style={{
                  width: "100%",
                  height: "300px",
                  borderRadius: "8px"
                }}
                alt="product" />
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Stack gap={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DetailInput label="product name" value={product_name} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailInput label="mode" value={mode} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DetailInput label="amount" value={amountWithCurrency(amount, currency[0])} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailInput label="min amount" value={amountWithCurrency(min_amount, currency[0])} />
                  </Grid>
                </Grid>

                <DetailInput label="link id" value={id} />
                <DetailInput label="payment link" value={short_link ?? link} />
                <DetailInput label="Description" value={description ?? "none"} />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' autoFocus onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
import { useQuery, useQueryClient } from "react-query";
import { getCategoriesAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useCategories = (filters) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();

  console.log(filters);

  const queryKey = ["product-category", selectedApp?.id];

  const query = useQuery({
    queryKey,
    queryFn: () => getCategoriesAPI(selectedApp, filters),
    onError: (error) => errorHandler(error),
  });

  useEffect(() => {
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp?.id]);

  return query;
};

import { useMutation } from 'react-query';
import { resetPasswordAPI } from "../utils/api";
import { errorHandler } from '../../../configs/errorConfig';

export const useResetPassword = () => { 

  const mutation = useMutation({
    mutationKey: 'reset-password',
    mutationFn: resetPasswordAPI,
    onError: (error) => {
      errorHandler(error)
    },
  })

  return mutation
}
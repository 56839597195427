import React, { Fragment, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { FileUpload } from '@components/FIleUpload';
import { Alert, Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Divider } from '@mui/material';

import theme from '@src/theme';
import DataGrid from '@components/datagrid';
import { kycCountryValues } from '@src/utils';
import HelpAlert from '@components/HelpAlert';
import { countryList } from '@utils/countries';
import { uploadedFilesColumns } from '../utils/columnUploadedFiles';

const CategoryItem = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	height: 50,
	paddingLeft: 25,
	cursor: 'pointer',
	lineHeight: '60px',
	display: 'flex',
	minWidth: 200
}));

const categories = [
	{
		label: 'Selfie',
		value: 'selfie'
	},
	{
		label: 'Passport or ID Card',
		value: 'passport_or_id_card'
	},
	{
		label: 'Tax declaration',
		value: 'tax_declaration'
	},
	{
		label: 'Certificate of negotiation',
		value: 'certificate_of_negotiation'
	},
	{
		label: 'Non conviction',
		value: 'non_conviction'
	},
	{
		label: 'Other',
		value: 'other'
	},
]

export default function Documents({ files, setFiles, setDocsFilled }) {
	const [selectedCountry, setSelectedCountry] = useState();
	const [docCategory, setDocCategory] = useState()

	const fileUploadProp = {
		accept: '.pdf, .doc, .txt, .jpg, .png, .jpeg',
		onChange: (event) => {
			onUpload(event)
		},
		onDrop: (event) => {
			onUpload(event)
		},
	}

	const onUpload = (event) => {
		const uploadedFiles = [event.target?.files?.[0]]

		uploadedFiles?.forEach((file) => {

			const fileExtention = file?.type?.split('/')[0]

			if (!docCategory) {
				toast.error('Please Select a category for your document')
				return
			}

			if (!selectedCountry) {
				toast.error('Please Select the country you want to submit your document for')
				return
			}

			if (fileExtention !== 'image' && docCategory === 'selfie') {
				toast.error('Please only images are accepted for selfie')
				return
			}

			if (fileExtention !== 'application' && docCategory !== 'selfie') {
				toast.error('Please only documents are accepted')
				return
			}

			if (Math.round(file.size / 100) / 10 > 5120) {
				toast.error('Please your file size should not exceed 5mb')

			} else {
				// Replace an existing file having the same document type in the same country
				const existingFileRemoved = files.filter(file => {
					return (file?.document_type !== docCategory || (file?.country_code !== selectedCountry.value))
				})

				setFiles([
					...existingFileRemoved,
					...uploadedFiles.map((file) => {
						return Object.assign(file, { document_type: docCategory }, { country_code: selectedCountry.value })
					})
				])
				setDocsFilled(true)
			}
		})
	}


	const handleRemoveFile = fileToRemove => {
		const filtered = files.filter(file => {
			setDocsFilled(false)
			return (file.name !== fileToRemove.name || file.document_type !== fileToRemove.document_type || file?.country_code !== fileToRemove.country_code)
		})
		setFiles(filtered)
	}

	useEffect(() => {
		// Check if At least selfie AND passport/ID_card are filled for at least 1 country before allowing the user to submit 
		kycCountryValues.map((country) => {
			let selfie = false
			let passport = false

			if (files.length >= 2) {
				const filled = files.map((file) => {
					if ((file.country_code === country.value) && file.document_type === 'selfie') selfie = true
					if ((file.country_code === country.value) && file.document_type === 'passport_or_id_card') passport = true
					if (selfie === true && passport === true) {
						setDocsFilled(true)
						return true
					}
					return false
				})
				if (!filled) {
					setDocsFilled(false)
					return false
				}
			}
			return false
		})
	})

	return (
		<Fragment>
			<HelpAlert sx={{ mb: 2 }}>
				{`First, select the country you want to submit KYC for.
				Then, select the category of the document you want to upload and upload it.
				Finally, click on SAVE KYC.`}
			</HelpAlert>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<Autocomplete
							value={selectedCountry}
							onChange={(event, selectedOption) => { setSelectedCountry(selectedOption) }}
							disablePortal
							id="country"
							size='small'
							options={countryList}
							fullWidth
							renderInput={(params) => <TextField {...params} label="Country" />}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={8}>
					<FormLabel id="category-buttons-group">Select a category before uploading your file</FormLabel>
					<RadioGroup
						sx={{ mt: 1 }}
						row
						name="category-buttons-group"
						id='category-buttons-group'
					>
						<Grid container spacing={3}>
							{categories.map((category, key) =>
								<Grid item key={key} xs={12} lg={6}>
									<label htmlFor={category.value}>
										<CategoryItem
											elevation={1}
											sx={{
												backgroundColor: docCategory === category.value ? theme.palette.primary.main : '#fff',
												color: docCategory === category.value ? 'white' : 'initial',
												fontWeight: 'bold'
											}}
										>
											<FormControlLabel
												onClick={() => setDocCategory(category.value)}
												value={category.value}
												control={<Radio color='default' id={category.value} />}
												label={category.label}
											/>
										</CategoryItem>
									</label>
								</Grid>
							)}
							{/* {otherCategory} */}
						</Grid>
					</RadioGroup>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormLabel id="files-group">Upload your file</FormLabel>
					<FileUpload sx={{ height: 115, mt: 1 }} setFiles={setFiles} {...fileUploadProp} />
				</Grid>
			</Grid>

			{files.length > 0 && <Divider sx={{ my: 4 }} />}

			{files.length > 0 &&
				<Grid container spacing={2} sx={{ mb: 4 }}>
					<Grid item xs={12}>
						<DataGrid
							hidePadding
							showSearch={false}
							data={files}
							query='type'
							rowSize='small'
							tableSize='small'
							column={uploadedFilesColumns(handleRemoveFile)}
							showPagination={false}
						/>
					</Grid>
				</Grid>
			}

			<Alert sx={{ mb: 11, mt: files.length ? 0 : 5 }} severity="warning">
				After submitting the Informations, we will check them for validation. The verification will take less than 72 hours.
			</Alert>

		</Fragment>
	)
}

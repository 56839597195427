import { useQuery, useQueryClient } from "react-query";
import { getProductsAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useProducts = (filters) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const queryClient = useQueryClient();

  const params = {
    ...filters,
  };

  const keyString = new URLSearchParams(params).toString();
  const queryKey = ["products", selectedApp?.id, keyString];

  const query = useQuery({
    queryKey,
    queryFn: () => getProductsAPI({ selectedApp: selectedApp?.id, ...filters }),
    onError: (error) => errorHandler(error),
  });

  useEffect(() => {
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);

  return query;
};

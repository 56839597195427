import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { getApplicationsAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"

export const useApplications = (userID) => {
  const dispatch = useDispatch()

  const query = useQuery({
    queryKey: ["applications", userID],
    queryFn: async () => userID && await getApplicationsAPI(userID),
    onSuccess: (data) => {
      const apps = data?.data

      // Store user datas in redux
      dispatch({
        type: 'SET_DATA',
        payload: { applications: apps }
      })

    },
    onError: (error) => {
      errorHandler(error)
    },
  })

  return query
}
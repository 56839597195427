import { gql, useMutation } from '@apollo/client';

export const useDeleteApplication = () => { 

  const mutation = useMutation(
    gql` mutation removeApplication($id: String!){
            removeApplication(id:$id)
        }
    `
  )

  return mutation
}




import React, { useEffect, useState } from 'react'
import DashboardSwipeable from '@components/DashboardSwipeable';
import PosForm from './pos-form';
import PosConfigDetails from './posConfigDetails';

const PosConfig = ({swippable,onClose, refetch, onPOSClose}) => {


  const [isUpdate, setisUpdate] = useState(false)


   console.log({data:swippable})
   const detailsProps = {
    data:swippable?.data,
    onPOSClose:onClose,
    refetch
   }

   const formDataUpdate= {
    data:swippable?.update,
    onClose,
    setisUpdate
   }

   useEffect(() => {
    if (typeof refetch === 'function') {
        refetch();
    }
}, [isUpdate, refetch]);
   

  return (
    <DashboardSwipeable
    isOpen={swippable?.open}
    title="Pos Config"
    onClose={onClose}
  >
    { swippable?.data?.length > 0 ? <PosConfigDetails {...detailsProps} /> :  <PosForm {...formDataUpdate} />  }
   
  </DashboardSwipeable>
  )
}

export default PosConfig
import * as Yup from 'yup'

const invoiceSchema = Yup.object().shape({
  client_name: Yup.string().required('client name is required'),
  client_email: Yup.string().email().required('client email is required'),
  client_phone_number: Yup.string().required('phone number is required'),
  partial_payment: Yup.boolean().default(false),
  number_of_installments: Yup.number().required().default(2).min(2, "atleast 2 installments required"),
  type: Yup.string().required('Please choose type').default("NORMAL"),
  currency: Yup.string().required('currency is required'),
  due_date: Yup.date().test('dueDate', 'Due date must be greater than today', function (value) {
    const issued_date = new Date();
    const condition = issued_date && value && value > issued_date;
    return condition;
  }).required('due date is required'),
  items: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('required'),
      price: Yup.number().required('required'),
      quantity: Yup.number().required('required')
        .default(1)
        .min(1, "must be 1 or greater")
        .test('MustOne', 'not zero', function (value) {
          return value && value > 0;
        }).required('required'),
      amount: Yup.number()
        .test('amountTest', 'zero as default', function (value) {
          if (!value) return 0;
          else return value;
        })
        .required('required'),
    })
  ),
});

const confirmSchema = Yup.object().shape({
  installments: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      amount: Yup.number()
        .test('amountTest', 'zero as default', function (value) {
          if (!value) return 0;
          else return value;
        }),
      due_date: Yup.date().test('dueDate', 'Due date must be greater than today', function (value) {
        const issued_date = new Date();
        const condition = issued_date && value && value > issued_date;
        return condition;
      }).required('due date is required'),
    })
  ),

  vat: Yup.number().default(0),
  shipping: Yup.number().default(0),
  discount: Yup.number().default(0),
  subtotal: Yup.number().default(0)
});

export const invoiceValidationSchema = (actualStep, type) => {
  if (actualStep === 1) return invoiceSchema
  else if (actualStep === 2 && type === "INSTALLMENT") return confirmSchema

}

export const companySchema = Yup.object().shape({
  name: Yup.string().required('company name is required'),
  email: Yup.string().email().required('company email is required'),
  phone_number: Yup.string().required('company phone number is required'),
});

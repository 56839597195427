import * as  Yup from 'yup'

const amountRegex = /^[1-9][0-9]*$/

export const cashoutValidation = (payoutType) => {
    return Yup.object().shape({
        amount: Yup.string().required('Please enter an amount').matches(amountRegex, { message: 'Amount  must be grater than or equal to 1' }),
        cashout_data: payoutType === 'MOBILE_MONEY' ? Yup.string().required('A phone number is required') : Yup.string().required('RIB is required'),
        payout_account_id: Yup.string().required('Payout type is required'),
    })
}


import { useMutation } from 'react-query';
import { forgotPasswordAPI } from "../utils/api";
import { errorHandler } from '../../../configs/errorConfig';

export const useForgotPassword = () => { 

  const mutation = useMutation({
    mutationKey: 'forgot-password',
    mutationFn: forgotPasswordAPI,
    onError: (error) => {
      errorHandler(error)
    },
    
  })

  return mutation
}
import React, { Fragment } from 'react'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useParams } from 'react-router-dom'
import { useOneKycs } from '@modules/kycs/hooks/useOneKyc'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

import KycDetail from '@modules/kycs/components/KycDetail';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import LoaderKycDetail from '@modules/kycs/components/LoaderKycDetail';

export default function KycsHistory() {
  const { appID } = useParams()
  const { kyc_id } = useParams()

  const { data, isLoading } = useOneKycs(kyc_id)
  const kyc = data?.data

  const breadCrumb = [
    {
      label: 'KYCs',
      link: `/${appID}/kycs`,
      icon: <AssignmentOutlinedIcon fontSize="small" />
    },

    {
      label: 'Pending modifications',
      link: `/${appID}/kycsHistory/${kyc_id} `,
      icon: <NoteAltOutlinedIcon fontSize="small" />
    },
  ]

  // ================= COMPONENT =================
  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />

        {!isLoading &&
          <Link to={`/${appID}/kycs/manage`}>
            <Button
              color='primary'
              variant="contained"
              sx={{ boxShadow: "none" }}
              startIcon={kyc ? <EditIcon /> : <AddIcon />}
            >
              {kyc ? 'Edit kyc' : 'Add Kyc'}
            </Button>
          </Link>
        }
      </Stack>

      <Paper sx={{ p: 7 }}>
        {(isLoading || kyc) ?
          isLoading ?
            <LoaderKycDetail />
            :
            <KycDetail
              isLoading={isLoading}
              selectedKyc={kyc}
              isHistory
            />
          :
          <Box width="100%" my={2} textAlign="center">
            <PlagiarismIcon fontSize='large' />
            <Typography variant="h5" color="grey">No KYC found for this application</Typography>
          </Box>
        }
      </Paper>
    </Fragment>
  )
}

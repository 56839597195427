import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

export default function LoadingTable({ tableLoaderColumns }) {
    return (
        <Table>
            <TableHead>
                <TableRow sx={{ background: 'rgb(230,230,230)' }}>
                    {
                        tableLoaderColumns?.map((column, key) =>
                            <TableCell key={column?.id ?? key} sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                {typeof column?.header === 'string' ? column?.header : column?.id.replace('_', ' ')}
                            </TableCell>
                        )
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {[1, 2, 3, 4, 5, 6]?.map((row) =>
                    <TableRow key={row} >
                        {
                            tableLoaderColumns?.map((column, key) =>
                                <TableCell key={column?.id ?? key} sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                    <Skeleton animation='wave' height={40} variant='text' />
                                </TableCell>
                            )
                        }
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

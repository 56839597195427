import { Dialog, DialogTitle, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getKycDoc } from '../utils/api'
import { errorHandler } from '../../../configs/errorConfig'

const ViewModalKyc = ({ image, onClose, open }) => {
  const [newUrl, setNewUrl] = useState("");
  const onViewKycDoc = (fileKey) => {
    getKycDoc(fileKey)
      .then((data) => {
        console.log({ DocData: data });
        const { document_url } = data?.data;
        console.log({ document_url });
        setNewUrl(document_url);
      })
      .catch((error) => errorHandler(error));
  };

  useEffect(() => {
    if (image) {
      onViewKycDoc(image);
    }
  }, [image]);
  const docs = [
    { uri:newUrl}, //ocal File
  ];
  console.log({newUrl})
  return (
    <Dialog onClose={onClose} maxWidth="lg" fullWidth={true} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <Divider />
     <img 
     alt=''
src={newUrl}
     />
     <iframe src={newUrl} width="100%" height="500px"></iframe>
    </Dialog>
  );
}

export default ViewModalKyc
import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import LoadingDots from '.'
import { grey } from '@mui/material/colors'

const LoadingData = ({title}) => {
  return (
    <Fragment>
    <Box
      sx={{
        fontWeight: "600",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1.3rem",
        my: "8rem",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 600, color: grey[600] }}>
        {title}...
      </Typography>
      <Box my={"4rem"}>
        <LoadingDots />
      </Box>
    </Box>
  </Fragment>
  )
}

export default LoadingData
import React, { Fragment } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'

export default function AssociatedPspLoader() {
  return (
    <Fragment>
      {[1, 2, 3].map(item =>
        <Box mt={5} key={item}>
          <Skeleton variant="rectangular" height={50} />
          <Stack flexDirection="row" flexWrap="wrap" gap={2} my={2} width="100%">
            {[1, 2].map(item =>
              <Skeleton key={item} variant="rectangular" sx={{ width: { xs: "100%", sm: "300px" }, borderRadius: 1 }} height={80} />
            )}
          </Stack>
        </Box>
      )}

    </Fragment>
  )
}

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Stack,
} from "@mui/material";
import useGetPosConfigById from "../utils/hooks/useGetPosConfigById";
import { grey } from "@mui/material/colors";
import { formatDate } from "../utils";
import { useEffect, useState } from "react";
import PosConfig from "./posConfig";
import DeletePosConfigModal from "./deletePosConfigModal";

const PosConfigDetails = ({ data, refetch, onPOSClose }) => {

  const updateDataObject = {
    uuid: data?.[0]?.uuid,
    name: data?.[0]?.name,
    location: data?.[0]?.location,
    pos_ref: data?.[0]?.pos_ref
  }


  const [isDeleteModal, setIsDeleteModal] = useState({
    open: false,
    uuid: ""
  })
  const closeDeleteModal = () => setIsDeleteModal(prev => ({
    ...prev,
    open: false
  }))

  const deleteModalProps = {
    open: isDeleteModal,
    handleClose: closeDeleteModal
  }


  const [isData, setIsData] = useState([])
  const [isUpdateSwipper, setIsUpdateSwipper] = useState({
    data: null,
    update: {},
    open: false
  })
  const closeUpdateSwipper = () => setIsUpdateSwipper(prev => ({
    ...prev,
    open: false
  }))
  const updateSwipperProps = {
    swippable: isUpdateSwipper,
    onClose: closeUpdateSwipper,
    refetch
  }

  useEffect(() => {
    if (data) {
      setIsData(data)
    }
  }, [data])



  const configDetails = isData.length > 0 ? isData[0] : null;

  if (!configDetails) {
    return <div>Loading...</div>;
  }

  const {
    created_at,
    lastChangedDateTime,
    name,
    location,
    pos_ref,
  } = configDetails


  const dataDetails = [
    {
      value: name,
      title: "P.O.S Name",
    },
    {
      value: location ? location : "N/A",
      title: "POS Location",
    },
    {
      value: pos_ref,
      title: "P.O.S Reference",
    },
    {
      value: created_at ? formatDate(created_at) : "N/A",
      title: "Creation Date",
    },
    {
      value: lastChangedDateTime ? formatDate(lastChangedDateTime) : "N/A",
      title: "lastly Updated At",
    },
  ];




  return (
    <Box h="100%">
      <DeletePosConfigModal {...deleteModalProps} />
      <PosConfig {...updateSwipperProps} />
      <Grid container spacing={4}>
        {dataDetails.map((item, i) => (
          <Grid key={i} item xs={8}>
            <Box sx={{ fontSize: ".9rem" }} color={grey[800]}>{item?.title}</Box>
            <Box sx={{ textTransform: "capitalize", mt: ".7rem", fontWeight: 600 }}>{item?.value}</Box>
          </Grid>
        ))}
      </Grid>
      <Stack direction={"row"} gap={"1rem"} justifyContent={"flex-end"} my={"3rem"}>
        <Button
          color="primary"

          onClick={() =>
            setIsUpdateSwipper(prev => ({
              ...prev,
              update: updateDataObject,
              open: true,
              onPOSClose
            }))
          }
          variant="contained"
          sx={{ boxShadow: "none", w: "100%" }}
        >
          Update POS
        </Button>

      </Stack>
    </Box>
  );
};

export default PosConfigDetails;

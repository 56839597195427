import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import FormHelperText from '@mui/material/FormHelperText';
import { isValidPhoneNumber } from 'react-phone-number-input';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Autocomplete, Box, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import Logo from '@components/Logo';
import CustomLink from '@components/link';
import { SECRET_PASS } from '@configs/keys';
import { countryList } from '@utils/countries';
import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { errorHandler } from '@configs/errorConfig';
import { useRegister } from '@modules/authentication/hooks/useRegister';
import { registerValidation } from '@modules/authentication/utils/validations';


const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const { mutate, isLoading } = useRegister()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('3O8S82q6')
  })

  const countryArray = countryList.map(countries => countries.label)

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setConfirmPassword((show) => !show);

  const registerUser = (values) => {

    if (isValidPhoneNumber(values.phone_number)) {
      const { first_name, last_name, email, phone_number, country, password, } = values;

      var trimmedPhoneNumber = phone_number.replace(/ /g, '')

      const data = { first_name, last_name, email, phone_number: trimmedPhoneNumber, country, password, picture: "" }

      mutate(data, {
        onSuccess: () => {

          // encrypt email and store in the local storage, and pass it to the url parameter
          const encryptMail = window.btoa(email)
          localStorage.setItem('3O8S82q6', encryptMail)
          toast.success('Please check your email to get your verification code')
          navigate(`/verify-user/${SECRET_PASS}${encryptMail}`)
        },
        onError: (error) => {
          if (error?.response?.data?.status === 409) {
            toast.error('This user already exists, please login')
          } else errorHandler(error)
        }
      })

    } else {
      toast.error('Invalid phone number !')
      return
    }
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "",
      password: "",
      confirm_pass: ""
    },
    validationSchema: registerValidation,
    onSubmit: (values) => {
      registerUser(values)
    }
  })

  return (
    <section className='main-container'>
      <Container sx={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: { xs: '10em', md: "0" }, gap: "2em" }}>
        <ToastContainer />

        <Logo height={50} />

        <Container maxWidth='sm' sx={{ my: 1, p: 5, background: '#ffffff', borderRadius: 2 }}>

          <Typography variant="h5" sx={{ mb: ".4em", textAlign: 'center', fontWeight: "bold" }}>
            Getting started with Payunit
          </Typography>
          <Typography sx={{ color: "gray", mb: "2em", textAlign: 'center' }}>
            Make your App management easy and fun!
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2, my: 3 }}>

              <CustomInput
                name="first_name"
                value={formik.values.first_name}
                error={formik.touched.first_name && !!formik.errors.first_name}
                helpertext={formik.touched.first_name && formik.errors.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="First Name"
                placeholder="Enter first name" />

              <CustomInput
                name="last_name"
                value={formik.values.last_name}
                error={formik.touched.last_name && !!formik.errors.last_name}
                helpertext={formik.touched.last_name && formik.errors.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Last Name"
                placeholder="Enter last name" />
            </Box>

            <CustomInput
              name="email"
              helpertext={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Email"
              placeholder='Enter your email'
            />

            <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2, my: 3, }} >
              <FormControl fullWidth>
                <Autocomplete
                  size='small'
                  id="country"
                  name='country'
                  value={formik.values.country}
                  options={["", ...countryArray]}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('country', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Country"
                    error={formik.touched.country && !!formik.errors.country}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                  }
                />
              </FormControl>

              <MuiTelInput size="small"
                name='phone_number'
                value={formik.values.phone_number}
                defaultCountry='CM'
                variant="outlined"
                error={formik.touched.phone_number && formik.errors.phone_number}
                onChange={(newValue) => {
                  formik.setFieldValue('phone_number', newValue);
                }} />

            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
                my: 3
              }}
            >
              <FormControl fullWidth variant="outlined" error={!!formik.errors.password}>
                <InputLabel htmlFor="password" size='small'>Password</InputLabel>
                <OutlinedInput
                  size='small'
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  error={formik.touched.password && !!formik.errors.password}
                  placeholder='Enter your password'
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.password && formik.errors.password && <FormHelperText error>{formik.errors.password}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth variant="outlined" error={!!formik.errors.confirm_pass}>
                <InputLabel htmlFor="password" size='small'>Confirm Password</InputLabel>
                <OutlinedInput
                  size='small'
                  id="confirm_pass"
                  name="confirm_pass"
                  value={formik.values.confirm_pass}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Password"
                  error={formik.touched.confirm_pass && !!formik.errors.confirm_pass}
                  placeholder='Confirm password'
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.confirm_pass && formik.errors.confirm_pass && <FormHelperText error>{formik.errors.confirm_pass}</FormHelperText>}
              </FormControl>
            </Box>

            <CustomButton
              onClick={() => formik.handleSubmit()}
              loading={isLoading}
              fullWidth
              size='large'
              sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
            >
              {isLoading ? 'Signing up...' : 'Sign up'}
            </CustomButton>

            <Typography textAlign="center" mt={3} >
              Already have an account? <CustomLink to="/login" color="secondary" label="Login" />

            </Typography>
          </Box>
        </Container>
      </Container >
    </section>
  )
}

export default SignUp
import { createTheme } from "@mui/material";


const theme = createTheme({
    palette: {
        primary: {
            main: '#00BFB3',
            contrastText:"white"
        },
        secondary: {
            main: '#ed6c02',
        },
        info: {
            main: '#0052cc',
        },
        phoneField:{
            padding:"0"
        }
    },
});

export default theme;


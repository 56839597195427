import React from 'react'
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const DatagridSearch = ({ searchOptions, table, query, searchTitle }) => {
  return (
    <Box sx={{ border: '1px solid lightgray', px: 1, width: 'fit-content', borderRadius: 1 }} component="form">

      <IconButton aria-label="search icon">
        <SearchIcon />
      </IconButton>
      {
        searchOptions ?
          <InputBase
            placeholder={searchOptions?.title}
            value={searchOptions?.value}
            onChange={searchOptions?.onSearch}
          />
          :
          <InputBase
            placeholder={searchTitle}
            value={(table.getColumn(query)?.getFilterValue()) ?? ""}
            onChange={(event) =>
              table.getColumn(query)?.setFilterValue(event.target.value)
            }
          />
      }
    </Box>
  )
}

export default DatagridSearch
import React from 'react'
import CustomButton from '@components/CustomButton';
import { Box, Stack, Typography } from '@mui/material'

export default function AssociatedPspItem({ provider, singleApp, onToggleSubscribe }) {

  return (
    <Box
      sx={{
        bgcolor: "white",
        border: '1px solid rgb(200,200,200)',
        borderRadius: 1,
        p: 3,
        width: { xs: "100%", sm: "300px" }
      }}
    >
      <Stack direction="row" gap={1} justifyContent="space-between" alignItems="start">
        <img alt='provider logo' width={60} src={provider?.logo} height={60} />
        <Stack justifyContent="space-between" width="100%">
          <Typography variant="h6" fontWeight="bold" noWrap> {provider?.name}</Typography>
          {singleApp?.psp && (singleApp?.psp.some(psp => Number(psp?.id) === Number(provider?.id))) ?
            <CustomButton
              type='submit'
              fullWidth
              size='small'
              color='warning'
              variant='contained'
              sx={{ textTransform: 'uppercase' }}
              onClick={onToggleSubscribe}
            >
              Unsubscribe
            </CustomButton>
            :
            <CustomButton
              type='submit'
              fullWidth
              size='small'
              color='primary'
              variant='contained'
              sx={{ textTransform: 'uppercase' }}
              onClick={onToggleSubscribe}
            >
              Subscribe
            </CustomButton>
          }
        </Stack>
      </Stack>
    </Box>
  )
}

import { useSelector } from "react-redux";
import { posConfigApi } from "../exports";
import { useMutation, useQueryClient } from "react-query";

const useDeletePosConfig = () => {
 const client = useQueryClient()

  const mutationKey = ["useDeletePosConfig"];
  const selectedApp = useSelector((store) => store.data.selectedApp);

  const mutation = useMutation({
    mutationKey,
    mutationFn: (data) =>
      posConfigApi.delete_Pos_Config({
        ...data,
        application_id: selectedApp.id,
      }),
      onSuccess: () => {
        client.invalidateQueries()
    },
  });

  return mutation;
};

export default useDeletePosConfig;
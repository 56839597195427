import { gql, useMutation } from '@apollo/client';

export const useCreateApplication = () => { 

  const mutation = useMutation(
    gql` mutation CreateApplications( 
        $user_id: String!,
        
        $name: String!,
        
        $description: String!,
        
        $type: ApplicationTypes!,
        ){
            CreateApplications(createApplicationInput:{user_id:$user_id, name:$name, description:$description, type:$type}){
                user_id
                name
                description
                status
                mode
                type    
            }
        }
    `
  )

  return mutation
}




import React from 'react'
import { Skeleton } from '@mui/material'
import DataGrid from '@components/datagrid'

export default function TableLoader({ columns, rows = 6 }) {
    const tableColumns = []
    const tableRows = new Array(rows)

    columns?.forEach((column) => {
        tableColumns.push(
            {
                id: column?.id,
                header: column?.header ?? null,
                accessorKey: column?.accessorKey ?? 'undefined',
                cell: <Skeleton animation='wave' height={40} variant='text' />,
            }
        )
    })

    return (
        <DataGrid
            data={tableRows}
            hidePadding
            showSearch={false}
            query='username'
            searchTitle='Search by beneficiary'
            column={tableColumns}
            filterColumn="opt_verification_status"
            filterTitle="Status"
            dataType='cashouts'
            showPagination={false}
        />
    )
}

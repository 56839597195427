import { Grid, Skeleton } from '@mui/material'
import React from 'react'

export default function BalanceSkeleton() {
  return (
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(value =>
      <Grid key={value} item xs={12} sm={4} lg={2}>
        <Skeleton
          variant="rectangular"
          width='100%'
          height={120}
        />
      </Grid>
    )
  )
}

import React from 'react'
import './loaderStyle.css'

const LoadingDots = () => {
  return (
    <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
  )
}

export default LoadingDots
import { useMutation, useQueryClient } from 'react-query';
import { Disbursement, initialiseCurrencyTransfer, toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';
import { useSelector } from 'react-redux';

export const useInitialiseCurrencyTransfer = () => {


    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationKey: 'initialDeposite',
        mutationFn: initialiseCurrencyTransfer,
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllDisbursement"])
        }
    })

    return mutation
}
import { useMutation, useQueryClient } from 'react-query';
import { Disbursement, toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';
import { useSelector } from 'react-redux';

export const useConfirmDeposit = () => {
    const queryClient = useQueryClient();

    const diburseapi = new Disbursement()
    const mutation = useMutation({
        mutationKey: 'confirmDeposit',
        mutationFn: diburseapi.confirmDep,
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllDisbursement"])
        }
    })

    return mutation
}
import { gql, useMutation } from "@apollo/client"

export const useEditApplication = () => { 

    const mutation = useMutation(
        gql`
            mutation updateApplication( 
            $id: String!,
            
            $name: String!,
            
            $description: String!,
            
            ){
                updateApplication(updateApplicationInput:{id:$id, name:$name, description:$description}){
                    id
                    name
                    description
                }
            }
        `,
    )

    return mutation
}


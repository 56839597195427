import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import LoadingData from "./customLoader/LoadingData";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddLinkIcon from "@mui/icons-material/AddLink";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import { getCurrentDate } from "../utils";
import DatePickerComponent from "./datePicker";
import dayjs from "dayjs";

const AgentCashBalance = ({
  data,
  isLoading,
  isError,
  name,
  statsRefetch,
  setRefreshKey,
  setFilter,
}) => {
  const [start_date, setStartDate] = useState(dayjs("2024-01-01"));
  const [end_date, setEndDate] = useState(dayjs(getCurrentDate()));

  useEffect(() => {
    if (typeof setFilter === "function") {
      setFilter((prev) => ({
        ...prev,
        start_date,
        end_date,
      }));
    }
  }, [start_date, end_date, setFilter]);

  const renderIcons = (key) => {
    const icons = {
      MOBILE_MONEY: MobileScreenShareIcon,
      PAYMENT_LINK: AddLinkIcon,
      CARD: CreditCardIcon,
      QR_CODE: QrCodeScannerIcon,
      CASH: LocalAtmOutlinedIcon,
      MOBILE_MONEY_LOCAL: SendToMobileOutlinedIcon,
    };

    return icons[key] ? icons[key] : LocalAtmOutlinedIcon;
  };

  const iconsColor = {
    MOBILE_MONEY: "#bb6b00",
    PAYMENT_LINK: "#09bc8a",
    CARD: "#9984d4",
    QR_CODE: "#839788",
    CASH: "#ff773d",
    MOBILE_MONEY_LOCAL: "#27187e",
  };

  const startDateProps = {
    date: start_date,
    setDate: setStartDate,
    label: "Start Date",
  };
  const endDateProps = {
    date: end_date,
    setDate: setEndDate,
    label: "End Date",
  };

  if (isLoading) {
    return (
      <Box sx={{ py: "12rem", bgcolor: grey[50] }} textAlign={"center"}>
        <LoadingData title={`${name} Statistics Loading`} />
      </Box>
    );
  }

  const _checkIfDataexist = (data) => {
    if (isError) return true;
    else if (data?.length <= 0) return true;
    else return false;
  };

  const noData = (
    <Box>
      <Box sx={{ py: "12rem", bgcolor: grey[50] }} textAlign={"center"}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No Data Avialable
        </Typography>
      </Box>
    </Box>
  );

  console.log(data?.data?.data);

  return (
    <Fragment>
      <Box bgcolor={grey[100]} py={"1rem"}>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, mx: "1rem", color: grey[600] }}
          >
            {name} Statistics
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          sx={{ px: "8rem" }}
          justifyContent={"end"}
          gap={2}
        >
          {/* filters */}

          <DatePickerComponent {...startDateProps} />
          <DatePickerComponent {...endDateProps} />
        </Stack>
        {_checkIfDataexist(data?.data?.data) ? (
          <>{noData}</>
        ) : (
          <Grid container sx={{ my: "1rem", mx: "8rem" }} gap={"4rem"}>
            {data?.data?.data?.map((items, i) => {
              const Icon = renderIcons(items?.mode);

              console.log(Icon);
              return (
                <Grid
                  items
                  xs={3}
                  sx={{ position: "relative", bgcolor: "white", py: ".8rem" }}
                  key={i}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    px={"1rem"}
                  >
                    <Box
                      sx={{
                        boxShadow: 8,
                        display: "flex",
                        bgcolor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "4rem",
                        transform: "translateX(-50%)",
                        height: "4rem",
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: "2.5rem",
                          color: iconsColor[items?.mode],
                        }}
                      />
                    </Box>

                    <Box sx={{ textAlign: "end" }}>
                      <Typography
                        sx={{
                          fontSize: ".8rem",
                          fontWeight: "600",
                          color: grey[600],
                        }}
                      >
                        mode
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {items?.mode}
                      </Typography>
                    </Box>
                  </Stack>
                  <Divider sx={{ my: "1rem" }} />

                  <Stack
                    my={"2rem"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontweight: 800, textAlign: "center" }}
                    >
                      {items?.total_amount + " " + items?.currency}
                    </Typography>
                    <Typography color={grey[600]}>total amount</Typography>
                  </Stack>

                  <Divider sx={{ my: "1rem" }} />
                  <Stack
                    direction={"row"}
                    gap={2}
                    alignItems={"center"}
                    px={"1rem"}
                  >
                    <Typography sx={{ fontSize: ".8rem", color: grey[600] }}>
                      Total transaction
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {items?.total_transaction}{" "}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </Fragment>
  );
};

export default AgentCashBalance;

import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cards from "./cards";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const TransferType = () => {

  const [isClicked, setIsClicked] = useState(null)
  const navigate = useNavigate()
  console.log({ isClicked })

  const handleNavigate = () => {
    if (isClicked === 1) {
      navigate('single?type=mobilemoney')
    } else if (isClicked === 2) {
      navigate('single?type=currencyTransfer')
    }
  }



  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Box sx={{ height: "100%" }}>
        <Paper sx={{ height: "89vh" }}>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", px: "2rem" }}>
            <Box
              onClick={() => navigate(-1)}
              sx={{
                cursor: 'pointer',
                w: '4rem',
                h: "4rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: grey[100],
                zIndex: 2,
                '&:hover': {
                  backgroundColor: grey[300]
                }
              }}>
              <CloseIcon />
            </Box>

            <Typography
              sx={{ fontWeight: "600", textTransform: "capitalize", p: "1rem" }}
            >
              Make a transfer
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: grey[50] }} />
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              padding: "2rem",
              alignItems: "center",
              justifyContent: "center",
              transform: "translateY(-10%)",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "600", textTransform: "capitalize", p: "2rem", mb: "4rem", color: grey[600] }}
            >
              how would you like to make your transfer?
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              {cardData.map((item, i) => (
                <div key={i}>
                  <Cards
                    {...item}
                    i={i + 1}
                    isClicked={isClicked}
                    onClick={() => setIsClicked(i + 1)}
                  />
                </div>
              ))}
            </Box>
            <Box>
              <Button
                disabled={isClicked ? false : true}
                onClick={handleNavigate}
                variant="contained"
                color={"secondary"}
                sx={{ mt: "4rem" }}
              >
                start transfer
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

const cardData = [
  {
    heading: "Transfer to Mobile money",
    description:
      "Send money to a mobile phone number seamlessly using Mobile Money Transfer.",
  },
  {
    heading: "Transfer between currency balances",
    description:
      "This type of transfer allows you to send money between your balances",
  },

];

export default TransferType;

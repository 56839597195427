import React, { Fragment, useEffect, useState } from "react";
import DataGrid from "@components/datagrid";
import { Box, Stack, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import useGetAllPOSTransactions from "../utils/hooks/useGetAllPOSTransactions";
import { posAgentTransactionColumn } from "../utils/columns";
import AgentTransDetailsModal from "./agentTransDetailsModal";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import DatePickerComponent from "./datePicker";
import dayjs from "dayjs";
import { getCurrentDate } from "../utils";

const AllTransactions = ({ agentId }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [start_date, setStartDate] = useState(dayjs("2024-01-01"));
  const [end_date, setEndDate] = useState(dayjs(getCurrentDate()));
  const [mode, setMode] = useState("ALL");

  const startDateProps = {
    date: start_date,
    setDate: setStartDate,
    label: "Start Date",
  };
  const endDateProps = {
    date: end_date,
    setDate: setEndDate,
    label: "End Date",
  };

  const [isDetailsModal, setIsDetailsModal] = useState({
    data: {},
    open: false,
  });

  const closeDetailsModal = () =>
    setIsDetailsModal((prev) => ({
      ...prev,
      open: false,
    }));

  const detailsModalProps = {
    open: isDetailsModal,
    handleClose: closeDetailsModal,
  };

  const columnProps = {
    setIsDetailsModal,
  };

  const [filter, setFilter] = useState({
    page: null,
    mode: null,
    limit: null,
    start_date: null,
    end_date: null,
    agent_id: agentId,
  });

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      page: currentPage,
      limit: rowsPerPage,
      mode: mode === "ALL" ? "" : mode,
      start_date,
      end_date,
      agent_id: agentId,
    }));
  }, [currentPage, rowsPerPage, start_date, end_date, mode, agentId]);

  const { data, isLoading, refetch } = useGetAllPOSTransactions(filter);

  const reset = () => {
    setCurrentPage(0);
    setRowsPerPage(10);
    setStartDate(dayjs("2024-01-01"));
    setEndDate(dayjs(getCurrentDate()));
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onFirstPage: () => setCurrentPage(0),
    count: data?.data?.data?.meta?.totalItems || 1,
    onNextPage: () => setCurrentPage((page) => page + 1),
    onPreviousPage: () => setCurrentPage((page) => page - 1),
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onLastPage: () => setCurrentPage(data?.data?.data?.meta?.totalPages - 1),
  };

  const filterElements = [
    {
      element: <DatePickerComponent {...startDateProps} />,
    },
    {
      element: <DatePickerComponent {...endDateProps} />,
    },
  ];

  const filterOptionsList = [
    {
      label: "Mode",
      value: mode,
      onFilterChange: (e) => setMode(e.target.value),
      options: [
        {
          label: "MOBILE_MONEY",
          value: "MOBILE_MONEY",
        },
        {
          label: "QR_CODE",
          value: "QR_CODE",
        },
        {
          label: "CARD",
          value: "CARD",
        },
        {
          label: "CASH",
          value: "CASH",
        },
        {
          label: "PAYMENT_LINK",
          value: "PAYMENT_LINK",
        },
        {
          label: "ALL MODES",
          value: "ALL",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <AgentTransDetailsModal {...detailsModalProps} />
      <Fragment>
        <Stack direction={"row"} gap={3} justifyContent={"end"}></Stack>
        <DataGrid
          data={data?.data?.data?.items}
          isLoading={isLoading}
          paginationOptions={paginationOptions}
          column={posAgentTransactionColumn({ ...columnProps })}
          head={`${agentId ? `${"Agents's"}` : `POS`} Transactions`}
          dateFilterList={filterElements}
          filterOptionsList={filterOptionsList}
        />
      </Fragment>
    </Fragment>
  );
};

export default AllTransactions;

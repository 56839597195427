import { useMutation } from 'react-query';
import { changePasswordAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useChangePassword = () => { 

  const mutation = useMutation({
    mutationKey: 'changePassword',
    mutationFn: changePasswordAPI,
    onError: (error) => errorHandler(error) 
  })

  return mutation
 }
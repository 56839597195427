
import { pspWithLogo } from "@utils"
import DatagridColumnHeader from '@components/datagrid/datagrid-column-header'
import TransactionChips from "../components/transaction-chips"
import { Typography } from "@mui/material"
import Chip from '@mui/material/Chip';

export const transactionColumns = (setTransaction, setModalOpen) => [ 
  {
    id: 'transaction_id',
    header: 'Transaction ID',
    accessorKey: 'transaction_id',
    numeric: false,
    cell: (info) => <Typography color='gray'>{ info.getValue() ? `# ${info.getValue()}` : '...' }</Typography>
  },
  {
    id: 'transaction_status',
    header: 'status',
    accessorKey: 'transaction_status',
    cell: (info) => {
      const status = info.getValue()
      return <TransactionChips status={status} />
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    disablePadding: false,
  },
  {
    id: 'transaction_gateway',
    header: 'payment gateway',
    accessorKey: 'transaction_gateway',
    cell: (info) => {
      return pspWithLogo[info.getValue()] ?? '...'
    },
  },

  {
    id: 'transaction_amount',
    header: ({ column }) => (
      <DatagridColumnHeader label="Amount" column={column} />
    ),
    accessorKey: 'transaction_amount',
    cell: ({ row }) => {
      const amount = row?.original?.transaction_amount;
      const currency = row?.original?.transaction_currency;
      return `${ new Intl.NumberFormat().format(amount) } ${currency}`
    },
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'receiver_id',
    header: 'Source ID',
    accessorKey: 'receiver_id',
    footer: 'receiver_id',
    disablePadding: false,
    label: 'receiver_id',
    cell: (info) => <Typography color='gray'>{ info.getValue() ? `# ${info.getValue()}` : '...' }</Typography>
  },
  {
    id: 'transaction_fee',
    header: 'fees',
    accessorKey: 'transaction_fee',
    footer: 'transaction_fee',
    numeric: true,
    disablePadding: false,
    label: 'transaction_fee',
  },
  {
    id: 'create_time',
    header: ({ column }) => (
      <DatagridColumnHeader label="Date" column={column} />
    ),
    accessorKey: 'create_time',
    cell: (info) => {
      const date = info.getValue();
      return  new Date(date)?.toLocaleDateString("en-US", {
        year: 'numeric', month: 'long', day: 'numeric'
      })
    },
    disablePadding: false,
  },
  {
    id: 'Action',
    header: ({ column }) => (
      <DatagridColumnHeader label="Action" column={column} />
    ),
    cell: ({ row }) => <Chip onClick={ () => { setTransaction(row.original); setModalOpen(true) } } variant="outlined" sx={{ color: 'primary' }} label="View Details" />,
  },
]


import React from 'react'
import { Container, Paper, Typography } from '@mui/material'

export default function NotFound() {
	return (
		<Container sx={{ display: 'flex' }}>
			<Paper sx={{ textAlign: 'center', mt: 10, p: 5 }}>
				<img alt='404 error' width='60%' src='/404.jpg' />
				<Typography variant="h4" mt={2} textTransform='uppercase' fontWeight='bold'>Page not found</Typography>
			</Paper>
		</Container>
	)
}

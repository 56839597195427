import ItemsInfo from "./items-info";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { formatDate } from "../../pos/utils";
import InstallmentsInfo from "./installment-info";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import InvoiceChip from "./invoice-chip";
import { Fragment, useState } from "react";
import Payments from "./payments";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { INVOICE_PAYMENT_URL } from '../../../configs/base_url';


export const FieldValue = ({ label, value }) => {
  return (<Stack sx={{
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  }}>
    <Typography color="#b5b5b5">{label}</Typography>
    <Typography fontWeight="bold">
      {value}
    </Typography>
  </Stack>)
}

export const FieldDisplay = ({ label, value, }) => {
  return (<Stack sx={{
    gap: 2,
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
  }}>
    <Typography color="#00BFB3" fontWeight="bold">{label}</Typography>
    <Typography>{value}</Typography>
  </Stack>)
}


const InvoiceDetails = ({ open, handleClose, selectedInvoice }) => {
  const [openPayments, setOpenPayments] = useState(false)

  const onLinkCopy = () => {
    toast.info(`Link copied !`, {
      autoClose: 3000,
      theme: 'dark'
    })
  }

  const invoice = selectedInvoice;
  const client = invoice?.client;
  const company = invoice?.company;

  console.log(invoice)

  //============= Component =============

  return (
    <Fragment>
      {<Payments open={openPayments} handleClose={() => setOpenPayments(false)} payments={invoice?.payments} />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" color="red">
          <Stack direction="row" justifyContent="space-between">
            <Stack gap={1}>
              <Typography variant='h5' fontWeight="bold">INVOICE</Typography>
              <img
                src={company?.logo ?? require('../../../assets/images/avatar/default_pic.jpg')}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "8px"
                }}
                alt="company logo" />
            </Stack>
            <Stack alignItems="end">
              <Stack width="fit-content" mb={1}>
                <InvoiceChip status={invoice?.status} filled />
              </Stack>
              <Typography fontSize={18} fontWeight="bold" textTransform="capitalize">{company?.name}</Typography>
              <Typography>{company?.phone_number}</Typography>
              <Typography>{company?.email}</Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack>
                <Stack mt={2} mb={1} direction="row" justifyContent="space-between" sx={{
                  width: "100%",
                  pl: 1,
                }}>
                  <Stack>
                    <Typography fontWeight="bold" textTransform="uppercase" color="#00BFB3">Bill To</Typography>
                    <Stack mt={1} gap={1}>
                      <Typography>{client?.name}</Typography>
                      <Typography>{client?.phone_number}</Typography>
                      <Typography>{client?.email}</Typography>
                      <Button variant="contained" size="small" onClick={() => setOpenPayments(true)}> View Payments</Button>
                    </Stack>
                  </Stack>
                  <Stack alignItems="end" gap={1}>
                    <FieldDisplay label="Type" value={invoice?.type} />
                    <FieldDisplay label="Ref" value={invoice?.ref} />
                    <FieldDisplay label="Partial Payment" value={invoice?.partial_payment ? "Yes" : "No"} />
                    <FieldDisplay label="Date Created" value={formatDate(invoice?.created_at)} />
                    <FieldDisplay label="Due Date" value={formatDate(invoice?.due_date)} />
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" pl={1}>
                  <Typography textOverflow="ellipsis" noWrap width="50wv"><strong>Payment link :</strong> {`${INVOICE_PAYMENT_URL}/${invoice?.uuid}`} </Typography>
                  <CopyToClipboard text={`${INVOICE_PAYMENT_URL}/${invoice?.uuid}`} onCopy={onLinkCopy}>
                    <IconButton color="primary" aria-label="copy to clipboard">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item sx={12} md={12}>
                    <Stack pt={3} gap={2} borderTop="1px dotted #ccc">
                      <ItemsInfo items={invoice?.items} />
                    </Stack>
                  </Grid>

                  {invoice?.installments.length > 0
                    ? <Grid item sx={12} md={12}>
                      <InstallmentsInfo installments={invoice?.installments} />
                    </Grid> : null}

                  <Grid item sx={12} md={12}>
                    <Stack gap={2} alignItems="flex-end">
                      <Stack width="40%" gap={1}>
                        <FieldValue
                          label="Sub Total"
                          value={`${invoice?.sub_total} ${invoice?.currency}`}
                        />
                        <FieldValue
                          label="Total Amount"
                          value={`${invoice?.remaining_amount} ${invoice?.currency}`}
                        />
                        <FieldValue
                          label="Amount Paid"
                          value={`${invoice?.amount_paid} ${invoice?.currency}`}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item sx={12} md={12}>
                    <Stack gap={2} alignItems="flex-end" borderTop="1px dotted #ccc">
                      <Stack alignItems="flex-end" width="40%">
                        <Stack sx={{
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                          <Typography fontWeight="bold" fontSize="1.3rem">Balance</Typography>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontWeight="bold" fontSize="1.5em">
                              {invoice?.remaining_amount}
                            </Typography>
                            <Typography fontWeight="bold" fontSize="1rem">
                              {invoice?.currency}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default InvoiceDetails
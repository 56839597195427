import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DropDownNav = ({
  link,
  appID,
  handleClick,
  icon,
  name,
  linkOpen,
  setLinkOpen,
  sub,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [setBg, setsetBg] = useState(false);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={`menu-nav 
          }`}
      sx={{ width: "90%", mb: 0.5, mx: "auto", py: 0 }}
    >
      <ListItemButton
        sx={{
          justifyContent: "initial",
          px: 2.5,
        }}
        onClick={() => {
          if (link) navigate(`/${appID}${link}`);
          handleClick();
        }}
      >
        <ListItemIcon sx={{}}>
          {React.cloneElement(icon, {
            sx: { color: location.pathname.includes(link) && "#F79623" },
          })}
        </ListItemIcon>
        <ListItemText primary={name} />
        {linkOpen ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setLinkOpen(false);
            }}
          >
            <ExpandLess
              sx={{
                xIndex: 40,
                color: location.pathname.includes(link) && "#c2781f",
              }}
            />
          </IconButton>
        ) : (
          <ExpandMore
          // sx={{color:location.pathname.includes(link) && "#c2781f"}}
          />
        )}
      </ListItemButton>
      <Collapse in={linkOpen} timeout="auto" unmountOnExit>
        <List>
          {sub?.map((item, i) =>
            item?.menu?.length > 0 ? (
              <ListItem key={i}>
                <ListItemButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickMenu}
                  startIcon={React.cloneElement(item?.icon)}
                  sx={{
                    backgroundColor: setBg && "#FEF3E6",
                    borderRadius: setBg && "5px",
                    border: setBg && "1px solid #F79623",
                  }}
                >
                  <ListItemIcon>{React.cloneElement(item?.icon)}</ListItemIcon>
                  <ListItemText primary={item?.name} />
                </ListItemButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {" "}
                  {item?.menu?.map((items, i) => (
                    <MenuItem
                      key={"menu" + i}
                      sx={{
                        my: 0,
                        p: 0,
                        backgroundColor:
                          location.pathname.includes(items?.link) && "#FEF3E6",
                        borderRadius:
                          location.pathname.includes(items?.link) && "5px",
                        border:
                          location.pathname.includes(items?.link) &&
                          "1px solid #F79623",
                      }}
                      onClick={() => {
                        navigate(`/${appID}${items?.link}`);
                        setsetBg(location.pathname.includes(items?.link));
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {React.cloneElement(items?.icon)}
                        </ListItemIcon>
                        <ListItemText primary={items?.name} />
                      </ListItemButton>
                    </MenuItem>
                  ))}
                </Menu>
              </ListItem>
            ) : (
              <ListItem
                key={i}
                onClick={() => {
                  if (item?.link) {
                    navigate(`/${appID}${item?.link}`);
                  } else {
                    console.log("no link");
                  }
                }}
              >
                <ListItemButton
                  sx={{
                    my: 0,
                    backgroundColor:
                      location.pathname.includes(item?.link) && "#FEF3E6",
                    borderRadius:
                      location.pathname.includes(item?.link) && "5px",
                    border:
                      location.pathname.includes(item?.link) &&
                      "1px solid #F79623",
                  }}
                  onClick={() => {
                    navigate(`/${appID}${item?.link}`);
                  }}
                >
                  <ListItemIcon>{React.cloneElement(item?.icon)}</ListItemIcon>
                  <ListItemText primary={item?.name} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Collapse>
    </List>
  );
};

export default DropDownNav;

// ** Router Components
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom'
import { unprotectedRoutes } from './unprotectedRoutes';
import ProtectedRoutes from '../components/ProtectedRoutes';
import Layout from '../components/Layout';
import Home from '../views/pages/Home';
import UnprotectedRoutes from '../components/UnprotectedRoutes';
import 'react-toastify/dist/ReactToastify.css';
import AccountSetting from '../views/pages/account-setting';
import ApplicationDetails from '../views/pages/applicationDetails';
import { ToastContainer } from 'react-toastify';

const Router = () => {

	return (
			<Fragment>
				<ToastContainer/>
			<Routes>
				{/* Authenticated routes */}
				<Route element={ <ProtectedRoutes /> }>
					<Route
						exact
						path='/'
						element={ <Home /> }
					/>
					<Route
						exact
						path='/settings'
						element={ <AccountSetting /> }
					/>
					<Route
						exact
						path='/:appID/application-details'
						element={ <ApplicationDetails /> }
					/>
					<Route
						exact
						path='/:appID/*'
						element={ <Layout /> }
					/>
				</Route>
				{/* Non authenticated routes */}
				<Route element={ <UnprotectedRoutes /> }>
					{
						unprotectedRoutes.map((route, key) =>
							<Route
								exact
								key={ key }
								path={ route.path }
								element={ route.element }
							/>
						)
					}
				</Route>
						</Routes>
			</Fragment>
	)
}

export default Router
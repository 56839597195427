import React from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux';
import { posAgentApi } from '../exports';
import { toast } from 'react-toastify';
import { errorHandler } from '../../../../configs/errorConfig';

const useCreateAgent = (pos_id) => {
 const mutationKey = ["useCreateAgent"]
 
 const selectedApp = useSelector((store) => store.data.selectedApp);
 const mutation = useMutation({
    mutationKey,
    mutationFn:(data)=>{ return posAgentApi.create_POS_Agents({...data, application_id:selectedApp.id},pos_id)},
    onError:(error)=>errorHandler(error)
 })

 return mutation
}

export default useCreateAgent
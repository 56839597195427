import axios from '@configs/axios'

export const getPayoutsAPI = async (appID, filters) => {
  let url = `/payouts?app_id=${appID}`
  filters && Object.keys(filters)?.forEach(filterKey => {
    const filterValue = filters[filterKey]
    if (filterValue) url = `${url}&${filterKey}=${filterValue}`
  })


  const response = await axios.get(url)
  return response?.data
}

export const addPayoutAPI = async (data) => {
  const response = await axios.post('/register_payout', data)
  return response?.data
}

export const updatePayoutAPI = async (data) => {
  const response = await axios.patch(`/update_payout/${data.id}`, data.inputs)
  return response?.data
}

export const validatePayoutAPI = async (data) => {
  const response = await axios.post('/validate_payout', data)
  return response?.data
}

export const deletePayoutAPI = async (payoutID) => {
  const response = await axios.delete(`/payout/${payoutID}`)
  return response?.data
}

export const resendPayoutOtpAPI = async (payoutID) => {
  const response = await axios.post('/payout/code/resend', { payout_id: payoutID })
  return response?.data
}
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Stack, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import InstallmentChip from './installment-chip';
import { formatDate } from '../../pos/utils';



const Payments = ({ open, handleClose, payments }) => {
  //============= Component =============

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-payment-title"
      aria-describedby="alert-payment-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-payment-title" color="red">
        {payments?.length > 0 && <Typography variant='h5' fontWeight="bold">Payments</Typography>}
      </DialogTitle>
      <DialogContent>
        {payments?.length > 0 ?
          <Table size="small">
            <TableHead sx={{ bgcolor: "#e6fffe" }}>
              <TableRow>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Status</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Date Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
                    {item?.transaction_id}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "right" }}>
                    {item?.amount} {item?.currency}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <InstallmentChip status={item?.status} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {formatDate(item?.created_at)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          : <Stack textAlign="center">
            <Typography variant='h5' fontWeight="bold">Oops, No Payments</Typography>
            <Typography>No payments have been made for this invoice</Typography>
          </Stack>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          {payments?.length > 0 ? "close" : "ok, i understand"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Payments
import { useMutation } from 'react-query';
import { uploadAPI } from "../utils/api"
import { errorHandler } from "@configs/errorConfig"

export const useUpload = () => { 

    const mutation = useMutation({
      mutationKey: 'upload_product_image',
      mutationFn:uploadAPI,
      onError: (error) => errorHandler(error) 
    })

    return mutation
   }
import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { amountWithCurrency } from '@src/utils';
import DetailInput from '@components/Input-Detail';
import { pspWithLogo } from '../../../utils';
import TransactionChips from './transaction-chips';

export default function TransactionDetails({ open, onClose, transaction }) {

    const dataList = [
        {
            label: 'Mode',
            value: transaction?.transaction_mode
        },
        {
            label: 'Amount',
            value: amountWithCurrency(transaction?.transaction_amount, transaction?.transaction_currency)
        },
        {
            label: 'Provider Transaction Key',
            value: transaction?.provider_transaction_key
        },
        {
            label: 'Fee',
            value: transaction?.transaction_fee
        },
        {
            label: 'Transaction Type',
            value: transaction?.transaction_type
        },
        {
            label: 'Currency',
            value: transaction?.transaction_currency
        },
        {
            label: 'Application',
            value: transaction?.transaction_request_data?.transaction_metadata?.application?.name
        },
        {
            label: 'Status',
            value: <TransactionChips status={transaction?.transaction_status} />
        },
        {
            label: 'Transaction Notify Url',
            value: transaction?.transaction_notify_url
        },
        {
            label: 'Transaction Real Amount',
            value: transaction?.transaction_real_amount
        },
        {
            label: 'Transaction Gateway',
            value: pspWithLogo[transaction?.transaction_gateway]
        },
        {
            label: 'Source Id',
            value: transaction?.receiver_id
        },
        {
            label: 'Create Time',
            value: new Date(transaction?.create_time)?.toLocaleDateString("en-US", {
                year: 'numeric', month: 'long', day: 'numeric'
            })
        },
        {
            label: 'System Notification',
            value: transaction?.message
        },
    ]

    return (
        <Fragment>
            <Dialog
                onClose={onClose}
                fullWidth
                open={open}
                maxWidth="md"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Transaction Details
                    <Box>
                        <Typography color='gray' component='h6'>
                            # {transaction?.transaction_id}
                        </Typography>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        {dataList?.map((data, key) =>
                            <Grid key={key} item xs={12} md={6} lg={4}>
                                <DetailInput label={data?.label} value={data?.value ?? 'N/A'} />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' autoFocus onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
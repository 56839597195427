import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Typography, } from '@mui/material';
import CustomInput from '@components/CustomInput';
import { FileUpload } from '@components/FIleUpload';
import CustomButton from '@components/CustomButton';
import { useParams } from 'react-router-dom';
import { errorHandler } from "@configs/errorConfig"
import { companySchema } from '../utils/validation';
import { useSelector } from 'react-redux';
import { useUpdateCompany } from '../hooks/useUpdateCompany';
import { useCreateCompany } from '../hooks/useCreateCompany';

export default function CompanyForm({ onClose, isClosed, company, setImageUrl, imageUrl, isEdit, isError, setNotify }) {
  const { appID } = useParams();
  const user = useSelector((store) => store.auth.userData)
  const create = useCreateCompany()
  const update = useUpdateCompany(company?.uuid);

  const { getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      // Restrict file size to 5mb
      acceptedFiles.forEach((file) => {
        const fileExtention = file.type.split('/')[0]

        if (fileExtention !== 'image') {
          toast.error('Please only images are accepted')
          return
        }

        else if (Math.round(file.size / 100) / 10 > 5120) {
          toast.error('Please your file size should not exceed 5mb')
          return
        }
        else setImageUrl(file);
      })
    },
    multiple: false
  })

  const handleSubmitCompany = (values) => {

    const global = {
      name: values.name,
      application_id: appID,
      user_id: user.id,
      email: values.email,
      phone_number: values.phone_number,
    }

    if (company && isEdit) delete global.user_id

    const formData = new FormData()
    if (imageUrl) formData.append('image', imageUrl)

    for (const key in global) {
      formData.append(key, global[key])
    }

    if (company && isEdit) {
      update.mutate(global, {
        onSuccess: () => {
          toast.success(`Company info Updated!`)
          onClose()
        },
        onError: (error) => {
          errorHandler(error)
        }
      })
    } else {
      create.mutate(formData, {
        onSuccess: () => {
          if (isError) setNotify(true);
          else toast.success(`Company Created!`);
          onClose()
        }
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: ''
    },
    validationSchema: companySchema,
    onSubmit: values => handleSubmitCompany(values)
  })

  useEffect(() => {
    if (company) {
      formik.setFieldValue('name', company.name)
      formik.setFieldValue('email', company.email)
      formik.setFieldValue('phone_number', company.phone_number)
    }

  }, [appID, company, isClosed])

  useEffect(() => {
    if (!isClosed) {
      formik.resetForm()
    }

  }, [isClosed])

  let isLoading = update.isLoading || create.isLoading

  //=================== COMPONENT =============================

  return (
    <Stack component="form" gap={2} onSubmit={formik.handleSubmit}>
      <CustomInput
        name="name"
        value={formik.values.name}
        error={formik.touched.name && !!formik.errors.name}
        helpertext={formik.touched.name && formik.errors.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Company Name"
        label={isEdit ? "" : "Company Name"}
      />

      <CustomInput
        name="phone_number"
        value={formik.values.phone_number}
        error={formik.touched.phone_number && !!formik.errors.phone_number}
        helpertext={formik.touched.phone_number && formik.errors.phone_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Phone Number"
        label="Phone Number"
      />

      <CustomInput
        name="email"
        value={formik.values.email}
        error={formik.touched.email && !!formik.errors.email}
        helpertext={formik.touched.email && formik.errors.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Company email"
        label="Company email"
      />

      <Stack gap={1}>
        <Typography variant="h6" fontSize="medium" color="GrayText">Logo</Typography>
        <FileUpload
          accept="image/*"
          text="image"
          {...getInputProps()}
        />
        {imageUrl &&
          <Stack gap={1}>
            {<Typography color="primary" >{imageUrl?.name}</Typography>}
          </Stack>}
        {/* {updateImg ? <Stack gap={1}>
                    {<Typography color="primary" >{updateImg}</Typography>}
                </Stack> : null} */}

      </Stack>
      <CustomButton
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
        fullWidth
        size='large'
        sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
      >
        {isLoading ? 'Submitting...' : isEdit && company ? 'Edit Company' : 'Add Company'}
      </CustomButton>
    </Stack>
  )
}

import { Link } from 'react-router-dom';
import ProfileMenuButton from './ProfileMenuButton';
import { Box, Container, Grid } from '@mui/material';

import themeConfig from '../../configs/themeConfig';

export default function HomeHeader() {
  // ================= COMPONENT =================
  return (
    <Box>
      <Container>
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <Link to='/'>
              <img width={80} height={80} src={themeConfig.app.appLogoImage} alt='logo' />
            </Link>
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'right', mt: 2 }} item xs={6}>
            <Box>
              <ProfileMenuButton showName />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

import { Chip } from '@mui/material'

const TransactionChips = ({ status }) => {
  const statuses = {
    SUCCESS: "success",
    CANCELLED: "error",
    PENDING: "warning",
    INITIATE: "info",
    FAILED: "error"
  }

  const label = Object.keys(statuses).find(key => key === status);

  return <Chip sx={{ width: 100 }} size='small' label={label} color={statuses[status]} variant="outlined" />
}

export default TransactionChips
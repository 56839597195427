import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';

export const FileUpload = ({
	text,
	accept,
	imageButton = false,
	hoverLabel = <><Box>Click or drag to upload file</Box><Box sx={{ color: 'gray' }}>Accepts {text ?? "pdf"} file type - Max file size : 5 MB</Box></>,
	dropLabel = 'Drop files here',
	backgroundColor = '#fff',
	image: { url = '/logo.svg' } = {},
	onChange,
	onDrop,
	multiple,
	sx={}
}) => {
	const [labelText, setLabelText] = useState(hoverLabel)
	const [isDragOver, setIsDragOver] = useState(false)
	const [isMouseOver, setIsMouseOver] = useState(false)
	const stopDefaults = (e) => {
		e.stopPropagation()
		e.preventDefault()
	}
	const dragEvents = {
		onMouseEnter: () => {
			setIsMouseOver(true)
		},
		onMouseLeave: () => {
			setIsMouseOver(false)
		},
		onDragEnter: (e) => {
			stopDefaults(e)
			setIsDragOver(true)
			setLabelText(dropLabel)
		},
		onDragLeave: (e) => {
			stopDefaults(e)
			setIsDragOver(false)
			setLabelText(hoverLabel)
		},
		onDragOver: stopDefaults,
		onDrop: (e) => {
			stopDefaults(e)
			setLabelText(hoverLabel)
			setIsDragOver(false)
			onDrop(e)
		},
	}

	const handleChange = (event) => {
		onChange(event)
	}
	// ================= COMPONENT =================
	return (
		<>
			<input
				onChange={handleChange}
				accept={accept}
				style={{ display: 'none' }}
				id="file-upload"
				type="file"
				multiple={multiple}
			/>

			<label
				htmlFor="file-upload"
				{...dragEvents}
				sx={{
					cursor: 'pointer',
					textAlign: 'center',
					display: 'flex',
					'&:hover p,&:hover svg,& img': {
						opacity: 1,
					},
					'& p, svg': {
						opacity: 1,
					},
					'&:hover img': {
						opacity: 0.3,
					},

					'& img': {
						opacity: 0.3,
					},
				}}
			>
				<Box
					bgcolor={backgroundColor}
					sx={[{ pointerEvents: 'none', width: '100%', py: 5, border: '1px dashed gray', borderRadius: 2 }, sx]}
				>

					{(!imageButton || isDragOver || isMouseOver) && (
						<Box
							sx={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							<BackupIcon sx={{ color: 'gray' }} fontSize="large" />
							<Typography>{labelText}</Typography>
						</Box>
					)}
				</Box>
			</label>
		</>
	)
}

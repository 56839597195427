import axios from '@configs/axios'

export const getApplicationsAPI = async(userID)=>{
  const response = await axios.get(`/application/user/${userID}`)
  return response?.data
}

export const createApplicationAPI = async(datas)=>{
  const response = await axios.post('/users/v1/login', datas)
  return response?.data
}
export const getAssociatedPspAPI = async(id)=>{
  const response = await axios.get(`/application/${id}/associated`)
  return response?.data
}

import { useApplications } from "@modules/application/hooks/useApplications";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack, SwipeableDrawer, useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import themeConfig from "../../configs/themeConfig";
import DropDownNav from "../../modules/Layouts/Components/DropDownNav";
import { protectedRoutes } from "../../router/protectedRoutes";
import NotFound from "../../views/pages/notFound";
import StringAvatar from "../Avatar/StringAvatar";
import Footer from "../Footer";
import ProfileMenuButton from "../HomeHeader/ProfileMenuButton";
import { navMenus } from "../Navbar";
import PulseText from "../PulseText";
import ToggleModeButton from "../ToggleModeButton";

const drawerWidth = 240;

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...closedMixin(theme),
  "& .MuiDrawer-paper": closedMixin(theme),
}));

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((store) => store.auth.userData);
  const { isLoading: isLoadingApp, data: apps } = useApplications(user?.id);
  const selectedApp = useSelector((store) => store.data.selectedApp);
  const { appID } = useParams();

  const smallScreen = useMediaQuery("(max-width:900px)");
  const phoneScreen = useMediaQuery("(max-width:400px)");

  const [open, setOpen] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);

  const onApplicationChange = (selectedApp) => {
    const selectedAppLink = location.pathname.replace(appID, selectedApp?.id);
    navigate(selectedAppLink);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const [linkOpen, setLinkOpen] = React.useState(false);
  const [productMenuOPen, setProductMenuOPen] = React.useState(false);
  const [extraMenu, setExtraMenu] = React.useState(false);

  const handleClick = () => {
    setLinkOpen(!linkOpen);
  };
  const handleProductMenu = () => {
    setProductMenuOPen(!productMenuOPen);
  };
  const handleExtraMenu = () => {
    setExtraMenu(!extraMenu);
  };

  const checkListType2 = ({ name, key, link, sub, icon }) => {
    const listType = {
      "P.O.S": (
        <DropDownNav
          appID={appID}
          handleClick={handleClick}
          icon={icon}
          link={link}
          linkOpen={linkOpen}
          name={name}
          setLinkOpen={setLinkOpen}
          sub={sub}
          key={key}
        />
      ),
      Catalog: (
        <DropDownNav
          appID={appID}
          handleClick={handleProductMenu}
          icon={icon}
          link={link}
          linkOpen={productMenuOPen}
          name={name}
          setLinkOpen={setProductMenuOPen}
          sub={sub}
          key={key}
        />
      ),
      "P.O.S Extras": (
        <DropDownNav
          appID={appID}
          handleClick={handleExtraMenu}
          icon={icon}
          link={link}
          linkOpen={extraMenu}
          name={name}
          setLinkOpen={setExtraMenu}
          sub={sub}
          key={key}
        />
      ),
    };

    return (
      listType[name] || (
        <ListItem
          key={key}
          disablePadding
          sx={{ width: "90%", mb: 0.5 }}
          className={`menu-nav ${
            location.pathname.includes(link) && "focused-nav"
          }`}
          onClick={() => navigate(`/${appID}${link}`)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "initial",
              px: 2.5,
            }}
          >
            {showMenuIcon && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <PulseText
                  onEnd={() => setShowMenuIcon(true)}
                  noDelay
                  duration={4000}
                  appearance="slideFromLeft"
                >
                  {name}
                </PulseText>
              }
              sx={{ opacity: 1 }}
            />
          </ListItemButton>
        </ListItem>
      )
    );
  };

  const list = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{ paddingTop: 9 }}>
        {navMenus.map((menu, key) => {
          return (
            <ListItem
              key={key}
              disablePadding
              className={`${
                location.pathname.includes(menu.link) && "focused-mobile-nav"
              }`}
              onClick={() => navigate(`/${appID}${menu.link}`)}
            >
              <ListItemButton>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
  // ================= COMPONENT =================

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          background: "white",
          flexDirection: "row",
          boxShadow: "0",
          color: "black",
          borderBottom: "1px solid lightgray",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", pl: 2 }}>
          {selectedApp && <StringAvatar name={selectedApp?.name} />}
          <Typography variant="h6" noWrap component="div" sx={{ mt: 1, ml: 5 }}>
            {!phoneScreen && (
              <Link to="/">
                <img width={80} src={themeConfig.app.appLogoImage} alt="logo" />
              </Link>
            )}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <Box sx={{ display: "flex", float: "inline-end", gap: "15px" }}>
            <ProfileMenuButton />
            <ToggleModeButton />
          </Box>

          {smallScreen && (
            <IconButton sx={{ marginRight: 3 }} onClick={toggleDrawer(!open)}>
              {open ? <ClearIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <SwipeableDrawer
            anchor={"top"}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </Box>
      </AppBar>
      {!smallScreen && (
        <Drawer variant="permanent">
          <List sx={{ pt: 9, pl: 1, textAlign: "center" }}>
            {selectedApp &&
              apps?.data?.length > 0 &&
              apps?.data
                ?.filter((app) => app?.id !== selectedApp?.id)
                ?.map((app) => (
                  <ListItem
                    onClick={() => onApplicationChange(app)}
                    key={app?.id}
                    disablePadding
                    sx={{ display: "block", textAlign: "center" }}
                  >
                    <StringAvatar
                      color="gray"
                      bgColor="#fff"
                      borderColor="lightgray"
                      name={app?.name}
                    />
                  </ListItem>
                ))}
            {/* <Box sx={{ position: 'fixed', bottom: 50, mx: 1 }}>
              <ProfileMenuButton />
            </Box> */}
          </List>
        </Drawer>
      )}
      {!smallScreen && (
        <List
          className="nav-bar"
          sx={{
            flexDirection: "column",
            background: "#ffffff",
            display: "flex",
            minWidth: 240,
            borderRight: "1px solid lightgray",
            paddingTop: 10,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {navMenus.map((menu, key) => {
            const data = {
              key,
              name: menu?.name,
              link: menu?.link,
              icon: menu?.icon,
              sub: menu?.sub,
            };

            return checkListType2({ ...data });
          })}
        </List>
      )}
      <Footer />
      <Box component="main" className="main-container" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box
          className="content-body"
          sx={{
            overflow: "auto",
            px: phoneScreen ? 0 : 2,
            height: "93%",
            fontFamily: "Arial",
          }}
        >
          <ToastContainer />

          {!selectedApp || isLoadingApp ? (
            <Stack
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img alt="Loading data" src="/loader.gif" width={170} />
            </Stack>
          ) : (
            <Box sx={{ mb: 10 }}>
              <Routes>
                {protectedRoutes.map((route, key) => (
                  <Route
                    exact
                    key={key}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

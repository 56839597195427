import { Box, Menu, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CustomButton from '@components/CustomButton';

const ExportSegment = (props) => {
  return (
    <Box component="form">
      <CustomButton
        type='button'
        startIcon={<FileUploadOutlinedIcon />}
        id="export-button"
        aria-haspopup="true"
        onClick={props.handleClick}
        aria-expanded={props.open ? 'true' : undefined}
        aria-controls={props.open ? 'export-menu' : undefined}
        variant={props.buttonVariant ?? 'outlined'}
        size='small'
        sx={{ textTransform: props.isLoading ? 'capitalize' : 'uppercase' }}
      >
        {props.isLoading ? 'Exporting...' : 'Export'}
      </CustomButton>

      <Menu
        id="export-menu"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="column" px={2} gap={2} mt={3}>
            <DatePicker
              value={props.startDate}
              label="Start date"
              format="YYYY-MM-DD"
              onChange={(newValue) => props.setStartDate(newValue)}
            />
            <DatePicker
              value={props.endDate}
              label="End date"
              format="YYYY-MM-DD"
              onChange={(newValue) => props.setEndDate(newValue)}
            />
            <CustomButton
              type="submit"
              id="export-button"
              aria-controls={props.open ? 'export-menu' : undefined}
              aria-haspopup="true"
              loading={props.isLoading}
              aria-expanded={props.open ? 'true' : undefined}
              onClick={props.onExport}
              variant='contained'
              size='small'
              sx={{ textTransform: props.isLoading ? 'capitalize' : 'uppercase' }}
            >
              {props.isLoading ? 'Exporting...' :
                props.startDate || props.endDate ? "Export" : "Export  All"}
            </CustomButton>
          </Stack>
        </LocalizationProvider>
      </Menu>
    </Box>
  )
}

export default ExportSegment
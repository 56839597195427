import { useMutation, useQueryClient } from 'react-query';
import { Disbursement, toggleActiveApplicationAPI } from '../utils/api';


export const useGetDepositFees = () => {
    const diburseapi = new Disbursement()
    const mutation = useMutation({
        mutationKey: 'getDepositFees',
        mutationFn: diburseapi.depositFees,
        staleTime: 3000,
    })

    return mutation
}
import React from 'react'
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material'

const CustomInput = ({ label, size, name, value, helperText, disabled = false, fullWidth = false, placeholder, sx = {}, type = 'text', error, rest, onChange, onBlur, autoFocus, endAdornment = <></>, required = false }) => {
  return (
    <FormControl sx={sx} fullWidth error={error}>
      <InputLabel htmlFor={name} size={size ?? 'small'}>{label}</InputLabel>
      <OutlinedInput
        size={size ?? 'small'}
        name={name}
        value={value}
        {...rest}
        id={name}
        type={type ?? 'text'}
        label={label}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        autoFocus={autoFocus}
        required={required}
        fullWidth={fullWidth}
        error={!!error}
        disabled={disabled}
        endAdornment={endAdornment}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default CustomInput
import { useMutation, useQueryClient } from "react-query";
import { POS_Config } from "../api";
import { useSelector } from "react-redux";
import { errorHandler } from "../../../../configs/errorConfig";

const useCreateConfig = () => {
    const posConfigApi = new POS_Config()
 const mutationKey = "useCreateConfig"
 const client = useQueryClient()
 const selectedApp = useSelector((store) => store.data.selectedApp);



 const mutation = useMutation({
    mutationKey,
    mutationFn:async (data)=> await posConfigApi.create_POS_Config({...data,application_id:selectedApp.id}),
    onSuccess: () => {
        client.invalidateQueries()
    },
    onError:(error)=>errorHandler(error)

 })
 return mutation

 
}

export default useCreateConfig
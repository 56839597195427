import axios from '@configs/axios'

export const getPaymentLinksAPI = async(selectedApp) => {
  const response = await axios.get(`/payment_links/all?application_id=${ selectedApp?.id }`)
  return response?.data
}

export const createPaymentLinkAPI = async(inputs) => {
  const response = await axios.post(`/payment_links/create`,inputs)
  return response?.data
}

export const deletePaymentLinksAPI = async(linkID) => {
  const response = await axios.delete(`/payment_links/delete`,{
    data:{link:[linkID]}
  })
  return response?.data
}
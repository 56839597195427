import { Box, IconButton, Stack } from "@mui/material"
import { countryWithFlag } from "@utils";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header";
import { getKycDoc } from "./api";
import { errorHandler } from "../../../configs/errorConfig";
import DownloadIcon from '@mui/icons-material/Download';

const renderFilePreview = file =>
    file?.document_type === 'selfie' ?
        <PhotoSizeSelectActualOutlinedIcon size='25' />
        :
        <DescriptionOutlinedIcon size='25' />


const onViewKycDoc = (fileKey) => {
    getKycDoc(fileKey).then(data => {

        const { document_url } = data?.data

        window.open(document_url, '_blank')

    }).catch(error => errorHandler(error))
}

export const documentListColumns = ({setIsModalDetails})=> [
    {
        id: 'country_code',
        header: 'Country',
        accessorKey: 'country_code',
        cell: ({ row }) => countryWithFlag[row?.original?.country_code]
    },
    {
        id: 'document_key',
        header: 'File Name',
        accessorKey: 'document_key',
        cell: ({ row }) =>
            <Box display='flex' alignItems='center'>
                <Box>{renderFilePreview(row?.original)}</Box>
                <Box sx={{ ml: 1 }}>
                    <Box>{row?.original?.document_key?.split('/')[1]?.split('-')[1] ?? row?.original?.document_key}</Box>
                </Box>
            </Box>
    },
    {
        id: 'document_type',
        header: 'Document Type',
        accessorKey: 'document_type',
        cell: ({ row }) =>
            <Box sx={{ ml: 1 }}>
                <Box>{row?.original?.name ?? row?.original?.document_type}</Box>
            </Box>
    },
    {
        id: 'created_at',
        header: ({ column }) => <DatagridColumnHeader label="Date" column={column} />,
        accessorKey: 'created_at',
        cell: (info) => {
            const date = info.getValue();
            return new Date(date)?.toLocaleDateString("en-US", {
                year: 'numeric', month: 'long', day: 'numeric'
            })
        },
        disablePadding: false,
    },
    {
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => {
            return (<Stack direction="row">
                <IconButton onClick={() => {
                    // setIsModalDetails({
                    //     open: true,
                    //     data: row?.original?.document_key
                    // })
                    onViewKycDoc(row?.original?.document_key)
                }}>
                    <DownloadIcon
                        fontSize="small"
                        color="primary"
                    />
                </IconButton>
            </Stack>)
        },
    }
]

import axios from '@configs/axios'

export const getKycsAPI = async(selectedApp) => {
  const response = await axios.get(`/kyc/get-details?application_id=${ selectedApp?.id }&user_id=${ selectedApp?.user_id }`)
  // const response = await axios.get(`/kyc/kycs/get`)
  return response?.data
}

export const getOneKycAPI = async(selectedApp, kyc_id) => {
  const response = await axios.get(`/kyc/${ kyc_id }/edit/get`)
  return response?.data
}

export const addKycsAPI = async(data) => {
  const response = await axios.post('/kyc/submit-kyc-and-docs', data)
  return response?.data
}

export const editKycsAPI = async(data) => {
  const response = await axios.post(`/kyc/update/${ data?.id }/kyc-docs`, data?.values)
  return response?.data
}

export const getKycDoc = async(key) => {
  const encodedKey = encodeURIComponent(key)
  const response = await axios.get(`/kyc/documents/${encodedKey}/preview`)
  return response?.data
}


import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './router';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './AppolloClient/client';
import * as Sentry from "@sentry/react"
import { BrowserTracing } from '@sentry/browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const queryClient = new QueryClient()

Sentry.init({
  dsn: "https://b7d736881511429fb27fee13f71f626c@sentry-app.payunit.net/24",
  integrations: [new BrowserTracing()],

  beforeSend(event) {
    if (event.exception) Sentry.showReportDialog({ eventId: event.event_id })
    return event
  },
  tracesSampleRate: 1.0
})

const FallbackComponent = () => <div>An error has occured</div>

function App() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
import { gql, useQuery } from "@apollo/client"
import { useSelector } from "react-redux";

export const useApplicationPsp = () => { 

	const selectedApp = useSelector((store) => store.data.selectedApp);

    const mutation = useQuery( 
        gql`
            query ($id: String!) {
                SingleApplication(id: $id) {
                    name
                    user_id
                    id
                    sandbox_key
                    live_key
                    type
                    description
                    mode
                    status
                    created_at
                    updated_at
                    psp{
                        name
                        shortcode
                        id
                        logo
                        status
                    }
                }
            }
        `,
        {
            variables: { id: selectedApp?.id }
        },
    )
  
    return mutation
  }

import { Box, Modal, Paper, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import KeyIcon from '@mui/icons-material/Key';
// import CustomTabPanel from '@components/CustomTabPanel';
import MobileMoney from '@modules/disbursement/components/mobileMoney';
import BankPayment from '@modules/disbursement/components/bankAccount';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { green, grey } from '@mui/material/colors';
import InterTransForm from '../../Inter-transfer/components/interTransForm';
import { MoveDown } from '@mui/icons-material';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const CreateDis = ({ open, handleClose, value, handleChange, setIsConfirmTransfertModal, setIsConfirmDepositModal, handleCloseDeposit }) => {
  const interTransfertProps = {
    setIsConfirmTransfertModal,
    handleClose
  }
  const momoProps = {
    setIsConfirmDepositModal,
    handleClose
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 560,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    pb: 2
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ w: '100%' }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box sx={style} >
        <Box sx={{ display: 'flex', w: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-modal-title" variant="h5" sx={{ fontWeight: 700 }} component="h1">
            Initiate a Transaction
          </Typography>
          <Box onClick={handleClose} sx={{ width: 'fit-content', height: 'fit-content', position: 'absolute', top: '6px', right: '6px' }}>
            <CloseOutlinedIcon onClose={handleClose} sx={{ cursor: 'pointer', p: '4px', borderRadius: '8px', '&:hover': { bgcolor: grey[100], color: "#00BFB3" } }} />
          </Box>
        </Box>
        <Box sx={{ w: "90%", bgcolor: grey[50] }}>
          <Tabs

            value={value}
            onChange={handleChange}
            sx={{
              mt: "1rem",
              // width:"60%",
              "& .MuiTab-root": {
                minHeight: "1px",
                px: 0,
                mr: '1rem'
                // Adjust the padding value as needed
              },
            }}
            aria-label=""
          >
            <Tab sx={{ fontSize: '.8rem' }} icon={<SendToMobileOutlinedIcon />} iconPosition="start" label="Mobile Transfer" />
            {/* <Tab icon={<AccountBalanceWalletOutlinedIcon />} iconPosition="start" label="Bank Transfer" /> */}
            <Tab icon={<MoveDown />} iconPosition="start" label="Inter Balance Transfert" />
          </Tabs>
        </Box>

        <Paper elevation={0}>
          <CustomTabPanel value={value} index={0}>
            <MobileMoney {...momoProps} />
          </CustomTabPanel>

          {/* <CustomTabPanel value={value} index={1}>
            <BankPayment />
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={1}>
            <InterTransForm {...interTransfertProps} />
          </CustomTabPanel>
        </Paper>
      </Box>
    </Modal>

  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default CreateDis

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { useMutation } from "react-query";
import DataGrid from "@components/datagrid";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "@components/AlertDialog";
import TableLoader from "@components/TableLoader";
import { errorHandler } from "@configs/errorConfig";
import CategoryIcon from "@mui/icons-material/Category";
import { deleteProductAPI } from "@modules/product/utils/api";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DashboardSwipeable from "@components/DashboardSwipeable";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { productColumns } from "@modules/product/utils/columns";
import { useProducts } from "@modules/product/hooks/useProducts";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import ProductForm from "@modules/product/components/product-form";
import ProductDetails from "@modules/product/components/product-details";

const Products = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [updateImg, setUpdateImg] = useState(undefined);
  const [productDetails, setProductDetails] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState();
  const { appID } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
  });
  console.log(filters);
  const { data, isLoading, refetch } = useProducts(filters);
  const remove = useMutation((productID) => deleteProductAPI(productID));

  const products = data?.data.items;

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: currentPage,
      limit: rowsPerPage,
    }));
  }, [rowsPerPage, currentPage]);

  console.log(filters);

  useEffect(() => {
    refetch();
  }, [filters]);

  const breadCrumb = [
    {
      label: "Products",
      link: `/${appID}/products`,
      icon: <ShoppingCartIcon fontSize="small" />,
    },
  ];

  const handleDelete = (productID) => {
    remove.mutate(productID, {
      onSuccess: () => {
        refetch();
        toast.success("Product deleted succefully");
        setDeleteModalOpen(false);
      },
      onError: (error) => {
        errorHandler(error);
      },
    });
  };

  const onClose = () => {
    setEdit(false);
    setOpen(false);
    setImageUrl(undefined);
    setUpdateImg(undefined);
    setProductDetails(null);
    setDeleteModalOpen(false);
    setDetailsModalOpen(false);
    setSelectedProductID(null);
  };

  const getProduct = (data, view, isEdit, isDelete) => {
    setProductDetails(data);
    console.log(data?.uuid);
    if (view) setDetailsModalOpen(true);
    else if (isEdit) {
      setEdit(true);
      setOpen(true);
    } else if (isDelete) {
      setSelectedProductID(data?.uuid);
      setDeleteModalOpen(true);
    } else return null;
  };

  console.log(currentPage);

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onNextPage: () => {
      setCurrentPage((page) => page + 1);
    },
    onPreviousPage: () => {
      setCurrentPage((page) => page - 1);
    },
    onFirstPage: () => setCurrentPage(0),
    onLastPage: () => setCurrentPage(data?.data?.meta?.totalPages - 1),
    count: data?.data?.meta?.totalItems || 1,
  };

  //================ COMPONENT ================
  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
        mt={3}
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <ButtonGroup size="small" variant="outlined">
          {/* <Button color='secondary' onClick={()=>navigate(`/${appID}/product-category`)} variant='contained' startIcon={<CategoryIcon />} sx={{ boxShadow: "none" }}>
            Categories
          </Button> */}
          <Button
            color="primary"
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{ boxShadow: "none" }}
            startIcon={<AddIcon />}
          >
            Add Product
          </Button>
        </ButtonGroup>
      </Stack>
      {!products ? (
        <TableLoader columns={productColumns(getProduct)} />
      ) : products?.length ? (
        <DataGrid
          data={products}
          head="Products"
          searchTitle="Search product name"
          query="name"
          // filterColumn="mode"
          // filterTitle="Mode"
          // statuses={status}
          paginationOptions={paginationOptions}
          dataType="links"
          column={productColumns(getProduct)}
          onClick={getProduct}
        />
      ) : (
        <Typography variant="h5" textAlign="center">
          No product found
        </Typography>
      )}

      {productDetails ? (
        <ProductDetails
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
          productDetail={productDetails}
        />
      ) : null}

      <AlertDialog
        open={deleteModalOpen}
        disabled={remove.isLoading}
        loading={remove.isLoading}
        onClose={() => setDeleteModalOpen(false)}
        text="Are you sure to delete this product data?"
        color="error"
        action={() => {
          handleDelete(selectedProductID);
        }}
      />

      {/*================ Product Form ================*/}
      <DashboardSwipeable
        isOpen={open}
        onClose={onClose}
        title={
          edit ? (
            <Typography variant="h5" fontWeight="bold">
              Update Product
            </Typography>
          ) : (
            <Typography variant="h5" fontWeight="bold">
              Add Product
            </Typography>
          )
        }
      >
        <ProductForm
          onClose={onClose}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          updateImg={updateImg}
          setUpdateImg={setUpdateImg}
          isClosed={open}
          data={productDetails}
          isEdit={edit}
        />
      </DashboardSwipeable>
    </Fragment>
  );
};

export default Products;

import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Card, Chip, Divider, Grid, Stack } from "@mui/material";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { useParams } from "react-router-dom";
import useGetPosAgentsById from "../utils/hooks/useGetPosAgentsById";
import PosConfig from "./posConfig";
import { grey } from "@mui/material/colors";
import { chipColor, getCurrentDate } from "../utils";
import AgentTransactions from "./agentTransactions";
import AgentCashBalance from "./AgentCashBalance";
import LoadingDots from "./customLoader";
import CreateAgent from "./createAgent";
import useGetPosTransactionStatistics from "../utils/hooks/useGetPosTransactionStatistics";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import AllTransactions from "./AllTransactions";
import RegeneratePasswordModal from "./RegeneratePasswordModal";

const PosAgentDetails = () => {
  const params = useParams();

  const { agent_id, appID } = params;

  const { data, isLoading, isError, error, refetch } =
    useGetPosAgentsById(agent_id);

  const [refreshKey, setRefreshKey] = useState(0);
  const [generatePasswordModal, setGeneratePasswordModal] = useState({
    open: false,
    data: null,
  });

  const closeGeneratedPasswordModal = () =>
    setGeneratePasswordModal({
      open: false,
      data: null,
    });

  const [filter, setFilter] = useState({
    agentId: null,
    refreshKey: null,
    start_date: null,
    end_date: null,
  });

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      agentId: agent_id,
      refreshKey,
    }));
  }, [agent_id, refreshKey]);

  const {
    data: statsData,
    isLoading: statsLoading,
    isError: statsIsError,
    error: statsError,
    refetch: statsRefetch,
  } = useGetPosTransactionStatistics(filter);

  const statisticsProps = {
    data: statsData,
    isLoading: statsLoading,
    isError: statsIsError,
    refreshKey,
    setRefreshKey,
    statsRefetch,
    name: "Agent",
    setFilter,
  };

  const [isUpdateAgent, setIsUpdateAgent] = useState({
    open: false,
    data: {},
  });

  useEffect(() => {
    statsRefetch();
  }, [filter]);

  const closeUpdateAgent = () => {
    setIsUpdateAgent((prev) => ({
      ...prev,
      open: false,
    }));
  };

  let isAgentData;
  if (data) {
    if (data) {
      isAgentData = data?.data?.data?.response;
    }
  }

  useEffect(() => {
    refetch();
  }, [isUpdateAgent]);

  const agentDetails = [
    {
      title: "#id",
      value: isAgentData?.uuid,
    },
    {
      title: "Agent Name",
      value: <Box sx={{ fontWeight: "600" }}>{isAgentData?.name}</Box>,
    },
    {
      title: "Agent Username",
      value: isAgentData?.username,
    },
    {
      title: "Agent Email",
      value: isAgentData?.email,
    },
    {
      title: "POS Reference",
      value: isAgentData?.pos_config?.pos_ref ?? "n/a",
    },
    {
      title: "Status",
      value: (
        <Chip
          color={chipColor[isAgentData?.status]}
          label={isAgentData?.status}
        />
      ),
    },
  ];

  const updateAgentProps = {
    swippable: {
      ...isUpdateAgent,
      pos_id: isAgentData?.pos_config?.uuid,
    },
    onClose: closeUpdateAgent,
  };

  const [isPosConfig, setIsPosConfig] = useState({
    data: [{}],
    open: false,
  });

  const handleClosePosConfig = () => {
    setIsPosConfig((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const posConfigProps = {
    swippable: isPosConfig,
    onClose: handleClosePosConfig,
  };

  const breadCrumb = [
    {
      label: "POS",
      link: `/${appID}/pos`,
      icon: <AccountTreeOutlinedIcon fontSize="small" />,
    },
    {
      label: "Agents",
      link: `/${appID}/pos/agents`,
      icon: <SupportAgentIcon fontSize="small" />,
    },
  ];

  const transactionProps = {
    agentId: isAgentData?.uuid,
  };

  if (isLoading) {
    return (
      <Fragment>
        <Box
          sx={{
            fontWeight: "600",
            fontSize: "1.3rem",
            my: "8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              my: "2rem",
              fontSize: "2.5rem",
              fontWeight: "600",
              color: grey[700],
            }}
          >
            Agents Data Loading...
          </Box>
          <LoadingDots />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ToastContainer />
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <CreateAgent {...updateAgentProps} />
        <Stack gap={1} direction="row">
          <Button
            color="primary"
            onClick={() =>
              setIsUpdateAgent((prev) => ({
                ...prev,
                data: {
                  uuid: isAgentData?.uuid,
                  name: isAgentData?.name,
                  email: isAgentData?.email,
                  status: isAgentData?.status,
                  username: isAgentData?.username,
                },
                open: true,
              }))
            }
            variant="contained"
            sx={{ boxShadow: "none" }}
          >
            Update Agent
          </Button>
        </Stack>
      </Stack>
      <PosConfig {...posConfigProps} />
      <Card sx={{ p: "1rem" }}>
        <Stack sx={{ mx: "1rem" }}>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ fontWeight: "600", fontSize: "1.4rem" }}>
              Agent Details
            </Box>
            <Button
              color="secondary"
              onClick={() => {
                setGeneratePasswordModal({
                  open: true,
                  data: {
                    uuid: isAgentData?.uuid,
                    name: isAgentData?.name,
                  },
                });
              }}
              variant="outlined"
              sx={{ boxShadow: "none" }}
            >
              Regenerate password
            </Button>
          </Stack>
          <Divider sx={{ my: "1rem" }} />
        </Stack>
        <Grid container spacing={4} padding={2}>
          {agentDetails.map((items, i) => (
            <Grid key={i} item xs={4}>
              <Box
                sx={{ fontSize: ".8rem", fontWeight: "600", color: grey[600] }}
              >
                {items?.title}
              </Box>
              <Box sx={{ mt: ".6rem" }}>{items?.value}</Box>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: "1rem" }} />
        <Box>
          <AgentCashBalance {...statisticsProps} />
        </Box>
        <Divider sx={{ my: "1rem" }} />

        <Box>
          <AllTransactions {...transactionProps} />
        </Box>
      </Card>
      <RegeneratePasswordModal
        open={generatePasswordModal}
        handleClose={closeGeneratedPasswordModal}
      />
    </Fragment>
  );
};

export default PosAgentDetails;

import { countryWithFlag } from "@utils";

export const validationListColumns = [
    {
        id: 'country_code',
        header: 'Country',
        accessorKey: 'country_code',
        cell: ({ row }) => countryWithFlag[row.original.country_code]
    },
    {
        id: 'level',
        header: 'Level',
        accessorKey: 'level'
    },
    {
        id: 'status',
        header: 'status',
        accessorKey: 'status',
    },
]
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { useMutation } from "react-query";
import DataGrid from "@components/datagrid";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "@components/AlertDialog";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { errorHandler } from "@configs/errorConfig";
import TableLoader from "@components/TableLoader";
import { deleteCategoryAPI } from "../../../modules/product/utils/api";
import DashboardSwipeable from "@components/DashboardSwipeable";
import CustomizedBreadcrumbs from "@components/CustomBreadcrumbs";
import { useCategories } from "../../../modules/product/hooks/useCategories";
import ProductCategoryForm from "../../../modules/product/components/category-form";
import { categoryColumns } from "../../../modules/product/utils/columns";
import CategoryDetails from "../../../modules/product/components/category-details";

const ProductCategory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState();
  const { appID } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
  });
  const { data, isLoading, refetch } = useCategories(filters);
  const remove = useMutation((categoryID) => deleteCategoryAPI(categoryID));
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log(data?.data?.meta);

  const categories = data?.data.items;

  const breadCrumb = [
    {
      label: "Categories",
      link: `/${appID}/product-category`,
      icon: <CategoryIcon fontSize="small" />,
    },
  ];

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: currentPage,
      limit: rowsPerPage,
    }));
  }, [rowsPerPage, currentPage]);

  const onClose = () => {
    setEdit(false);
    setIsOpen(false);
    setCategoryDetails(null);
    setDeleteModalOpen(false);
    setDetailsModalOpen(false);
  };

  const handleDelete = (categoryID) => {
    remove.mutate(`${categoryID}?app_id=${appID}`, {
      onSuccess: () => {
        refetch();
        toast.success("Category deleted");
        setDeleteModalOpen(false);
      },
      onError: (error) => {
        errorHandler(error);
      },
    });
  };

  const getCategory = (data, view, edit, isDelete) => {
    setCategoryDetails(data);
    if (view) setDetailsModalOpen(true);
    if (edit) setEdit(true);
    else if (isDelete) {
      setSelectedProductID(data.uuid);
      setDeleteModalOpen(true);
    } else return null;
  };

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onNextPage: () => {
      setCurrentPage((page) => page + 1);
    },
    onPreviousPage: () => {
      setCurrentPage((page) => page - 1);
    },
    onFirstPage: () => setCurrentPage(0),
    onLastPage: () => setCurrentPage(data?.data?.meta?.totalPages - 1),
    count: data?.data?.meta?.totalItems || 1,
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
        mt={3}
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        <ButtonGroup size="small" variant="outlined">
          {/* <Button
            color="secondary"
            onClick={() => navigate(`/${appID}/products`)}
            variant="contained"
            startIcon={<ShoppingCartIcon />}
            sx={{ boxShadow: "none" }}
          >
            Products
          </Button> */}
          <Button
            color="primary"
            onClick={() => setIsOpen(true)}
            variant="contained"
            sx={{ boxShadow: "none" }}
            startIcon={<AddIcon />}
          >
            Add Category
          </Button>
        </ButtonGroup>
      </Stack>
      {!categories ? (
        <TableLoader columns={categoryColumns(getCategory)} />
      ) : categories?.length ? (
        <DataGrid
          data={categories}
          head="Categories"
          searchTitle="Search category name"
          query="name"
          // filterColumn="mode"
          // filterTitle="Mode"
          // statuses={status}
          paginationOptions={paginationOptions}
          dataType="links"
          column={categoryColumns(getCategory)}
          onClick={getCategory}
        />
      ) : (
        <Typography variant="h5" textAlign="center">
          No product found
        </Typography>
      )}

      <AlertDialog
        open={deleteModalOpen}
        disabled={remove.isLoading}
        loading={remove.isLoading}
        onClose={onClose}
        text="Are you sure to delete this product data?"
        color="error"
        action={() => {
          handleDelete(categoryDetails.uuid);
        }}
      />

      <DashboardSwipeable
        isOpen={isOpen}
        title={
          <Typography variant="h5" fontWeight="bold">
            Add Category
          </Typography>
        }
        onClose={onClose}
      >
        <ProductCategoryForm
          onClose={onClose}
          isClosed={isOpen ? false : true}
          data={setCategoryDetails}
          isEdit={false}
        />
      </DashboardSwipeable>

      <DashboardSwipeable
        isOpen={edit}
        title={
          <Typography variant="h5" fontWeight="bold">
            Update Category
          </Typography>
        }
        onClose={onClose}
      >
        <ProductCategoryForm
          onClose={onClose}
          isClosed={edit ? false : true}
          data={categoryDetails}
          isEdit={edit}
        />
      </DashboardSwipeable>

      {categoryDetails && detailsModalOpen && (
        <DashboardSwipeable
          isOpen={detailsModalOpen}
          title={
            <Typography variant="h5" fontWeight="bold">
              Category Details
            </Typography>
          }
          onClose={onClose}
        >
          <CategoryDetails details={categoryDetails} />
        </DashboardSwipeable>
      )}
    </Fragment>
  );
};

export default ProductCategory;

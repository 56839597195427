import { useMutation } from 'react-query';
import { Disbursement, toggleActiveApplicationAPI } from '../utils/api';
import { errorHandler } from '../../../configs/errorConfig';
import { useSelector } from 'react-redux';

export const useResendCode = () => {
    const diburseapi = new Disbursement()
    const mutation = useMutation({
        mutationKey: 'resendCode',
        mutationFn: diburseapi.resendCode,
        onError: (error) => errorHandler(error)
    })

    return mutation
}
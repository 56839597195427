import { useMutation, useQueryClient } from 'react-query';
import { createInvoiceAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useCreateInvoice = () => {
    const client = useQueryClient()

    const mutation = useMutation({
        mutationKey: ['createInvoice'],
        mutationFn: createInvoiceAPI,
        onError: (error) => errorHandler(error),
        onSuccess: () => client.invalidateQueries({ queryKey: ["invoices"] }),
    })
    return mutation
}
import * as React from 'react';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { emphasize, styled } from '@mui/material/styles';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const BreadcrumbItem = ({ icon, label, link }) => {
  const smallScreen = useMediaQuery('(max-width:900px)');

  return (
    <Link to={link}>
      {
        smallScreen ?
          <Link underline="hover" color="inherit" href={link}>
            {label}
          </Link>
          :
          <StyledBreadcrumb
            label={label}
            sx={{ background: '#fff', cursor: 'pointer', p: 2 }}
            icon={icon}
          />
      }
    </Link>
  )
}

export default function CustomizedBreadcrumbs({ links }) {

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadcrumbItem
        icon={<HomeIcon fontSize="small" />}
        label='Home'
        link='/'
      />
      {links && links?.map((link, key) =>
        <BreadcrumbItem
          key={key}
          icon={link?.icon}
          label={link?.label}
          link={link?.link}
        />
      )
      }
    </Breadcrumbs>
  );
}
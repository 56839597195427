import { countryCurrencies, regexProvider } from "../../../utils";

export const momoInitialValues = {
  balance: {
    currency: "",
    Balance: "",
  },
  toCurrency: {
    currency: "",
    code: "",
  },
  amount: "",
  beneficiary_name: "",
  // account_number: "",

  account_number: "",
  // country: "",
  // country: {
  //   code: "",
  //   country: "",
  // },
  // account_bank: "",
  account_bank: {
    name: "",
    code: "",
  },
};
export const depositConfirmValues = {
  desposit_message: "",
  verification_code: "",
};
export const TransfertConfirmValues = {
  verification_code: "",
};

export function convertCountries(obj) {
  const countryCurrenciesArray = Object.entries(obj).map(
    ([code, currency]) => ({
      code,
      currency,
    })
  );
  return countryCurrenciesArray;
}
export function convertCountry(obj) {
  const country = Object.entries(obj).map(([code, country]) => ({
    code,
    country,
  }));
  return country;
}
export function convertProvider(obj) {
  const provider = Object.entries(obj).map(([name, text]) => ({
    name,
  }));
  return provider;
}

export const depoTypeOptions = [
  { name: "MOBILE_MONEY", value: "MOBILE_MONEY" },
  { name: "BANK_TRANSFER", value: "BANK_TRANSFER" },
  { name: "CURRENCY_TRANSFER", value: "CURRENCY_TRANSFER" },
];

export function getProviderObjects(countryCode) {
  // const keys = Object.keys(regexProvider);
  // const matchingObjects = [];

  // for (const key of keys) {
  //     const countryCodeRegex = new RegExp(`^${countryCode}`, 'i');

  //     if (countryCodeRegex.test(key)) {
  //         matchingObjects.push({ name: key });
  //     }
  // }

  // return matchingObjects;
  // const keys = Object.keys(regexProvider);
  // const allProviders = keys.map((key) => ({ name: key }));
  // return allProviders;
  const keys = Object.keys(regexProvider);
  const allProviders = keys.map((key) => {
    const code = key.split("_")[0]; // Replace with the actual property containing the country code
    return { name: key, code };
  });
  return allProviders;
}

export const currencies_limit = {
  XAF: {
    max_deposit_amount: 500000,
    min_deposit_amount: 5000,
  },
  XOF: {
    max_deposit_amount: 500000,
    min_deposit_amount: 5000,
  },
  TZS: {
    max_deposit_amount: 1000000,
    min_deposit_amount: 10000,
  },
  GHS: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  KES: {
    max_deposit_amount: 150000,
    min_deposit_amount: 100,
  },
  UGX: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  RWF: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  SLE: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  USD: {
    max_deposit_amount: 1000,
    min_deposit_amount: 1,
  },
  EUR: {
    max_deposit_amount: 1000,
    min_deposit_amount: 1,
  },
  EGP: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  ZAR: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  MAD: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  MWK: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  MZN: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
  ZMW: {
    max_deposit_amount: 10000,
    min_deposit_amount: 100,
  },
};

export const filterObjectsWithXAFCurrency = (array, element) => {
  // Use the filter method to filter objects with "currency" equal to "XAF"
  return array.filter((item) => item.currency === element);
};

// export const invertedCountryCurrencies = {};

// for (const key in countryCurrencies) {
//   if (countryCurrencies.hasOwnProperty(key)) {
//     const value = countryCurrencies[key];
//     invertedCountryCurrencies[value] = key;
//   }
// }

export const depositStatusList = [
  { label: "Pending", value: "PENDING" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Success", value: "SUCCESS" },
  { label: "Failed", value: "FAILED" },
  { label: "Initiate", value: "INITIATE" },
  { label: "Wait Approval", value: "WAIT_APPROVAL" },
  { label: "All Status", value: "ALL" },
];

export const depositTypeList = [
  { label: "Mobile Money", value: "MOBILE_MONEY" },
  { label: "Bank Transfer", value: "BANK_TRANSFER" },
  { label: "Currency Transfert", value: "CURRENCY_TRANSFER" },
  { label: "All Deposit Types", value: "ALL" },
];

export function transformToObjectArray(input) {
  return [].concat(
    ...Object.entries(input).map(([currency, codes]) =>
      codes.map((code) => ({
        currency,
        code,
      }))
    )
  );
}

export function filterObjectsByCurrency(objects, targetCurrency) {
  return objects.filter((obj) => obj.currency === targetCurrency);
}

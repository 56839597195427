import { gql, useMutation } from "@apollo/client"

export const useUnsubscribePsp = () => { 

    const mutation = useMutation(
        gql`
            mutation removePspFromApplication($id: String!, $pspId: Int!){
                removePspFromApplication(id: $id, pspId: $pspId){
                    name
                    id
                    psp{
                        name
                        id
                    }
                }
            }
        `,
    )

    return mutation
}


import React, { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DashboardSwipeable from '@components/DashboardSwipeable';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import DataGrid from '@components/datagrid';
import { Alert, Button, ButtonGroup, Paper, Typography } from '@mui/material';
import { invoiceColumns } from '@modules/invoices/utils/columns';
import InvoiceDetails from '@modules/invoices/components/invoice-details';
import CompanyDetails from '@modules/invoices/components/company-details';
import CompanyForm from '../../../modules/invoices/components/company-form';
import { useGetCompany } from '../../../modules/invoices/hooks/useGetCompany';
import Company from '../../../modules/invoices/components/company';
import NotifyAddInvoice from '../../../modules/invoices/components/notify-add-invoice';
import { useGetInvoices } from '../../../modules/invoices/hooks/useGetInvoices';
import { statusList } from '../../../modules/invoices/utils';
import Spinner from '../../../components/Spinner';

const Invoices = () => {
  const { appID } = useParams();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [notify, setNotify] = useState(false)
  const [isError, setIsError] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [status, setStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [companyDetailOpen, setCompanyDetailOpen] = useState(false);
  const { data: company } = useGetCompany(appID);
  const { data, isLoading, refetch } = useGetInvoices(currentPage, rowsPerPage, status)
  const invoices = data?.data.items?.map((invoice) => {
    return {
      ...invoice,
      client_name: invoice.client.name
    }
  })

  const filterOptionsList = [
    {
      label: 'Status',
      options: statusList,
      value: status,
      onFilterChange: (e) => setStatus(e.target.value)
    }
  ]

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onNextPage: () => setCurrentPage(page => page + 1),
    onPreviousPage: () => {
      if (currentPage > 0) setCurrentPage(page => page - 1)
      else return
    },
    onFirstPage: () => setCurrentPage(1),
    onLastPage: () => setCurrentPage(data?.data?.meta?.totalPages - 1),
    count: data?.data?.meta?.totalItems || 1
  }

  const navigate = useNavigate();

  const onClose = () => {
    setOpen(false)
    setIsError(false)
  }

  const action = () => {
    navigate(`/${appID}/invoices/manage`)
    onClose();
  }

  let companyProps = {
    open,
    setOpen,
    isEdit,
    setCompanyDetailOpen,
  }

  const breadCrumb = [
    {
      label: 'Invoices',
      link: `/${appID}/invoices`,
      icon: <ReceiptIcon fontSize="small" />
    }
  ]
  useEffect(() => {
    if (company?.data) {
      setIsEdit(true);
    }
  }, [company?.data, appID])
  useEffect(() => {
    refetch()
  }, [currentPage, rowsPerPage, status])

  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />

        {!isLoading &&
          <Stack gap={2} direction="row">
            <ButtonGroup size='small' variant='outlined'>
              <Button
                color="primary"
                variant="contained"
                sx={{ boxShadow: "none" }}
                onClick={() => {
                  if (company?.data) navigate(`/${appID}/invoices/manage`);
                  else {
                    setOpen(true)
                    setIsError(true)
                  }
                }}
                startIcon={<AddIcon />}
              >
                Add Invoice
              </Button>

              <Company {...companyProps} />

            </ButtonGroup>
          </Stack>
        }
      </Stack>

      {
        isLoading ?
          <Stack height="200px" alignItems="center" justifyContent="center">
            <Spinner />
          </Stack>
          :
          invoices?.length > 0 ?
            <DataGrid
              query="client_name"
              data={invoices}
              dataType='invoices'
              searchTitle='Search client name'
              filterTitle="Status"
              filterColumn="status"
              paginationOptions={paginationOptions}
              filterOptionsList={filterOptionsList}
              statuses={["PAID", "UNPAID"]}
              column={invoiceColumns(setSelectedInvoice, setDetailsModalOpen)}
            />
            :
            <Paper sx={{ py: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Stack>
                <Typography
                  variant='h5'
                  color="grey"
                  textAlign="center"
                  fontSize="2.5rem"
                >
                  No Invoice Found
                </Typography>

              </Stack>
            </Paper>
      }

      {/* //============== Invoice detail ================= */}

      <InvoiceDetails
        open={detailsModalOpen}
        selectedInvoice={selectedInvoice}
        handleClose={() => setDetailsModalOpen(false)}
      />

      {/* //============== Company detail ================= */}

      {companyDetailOpen && appID ?
        <CompanyDetails
          company={company?.data}
          open={companyDetailOpen}
          onClose={() => setCompanyDetailOpen(false)}
        /> : null}

      {/* //============== Notify ================= */}

      <NotifyAddInvoice
        open={notify}
        action={action}
        onClose={() => setNotify(false)}
      />

      { /*================ Company Form ================*/}
      <DashboardSwipeable
        isOpen={open}
        onClose={onClose}
        title={
          company?.data && isEdit ? <Typography variant='h5' fontWeight="bold">Update Compnay</Typography>
            : <Typography variant='h5' fontWeight="bold">Add Company</Typography>
        }
      >
        {isError && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            Please configure the company information used for creating invoices.
          </Alert>
        )}
        <CompanyForm
          isClosed={open}
          isEdit={isEdit}
          onClose={onClose}
          isError={isError}
          imageUrl={imageUrl}
          setIsEdit={setIsEdit}
          setNotify={setNotify}
          company={company?.data}
          setImageUrl={setImageUrl}
        />
      </DashboardSwipeable>
    </Fragment>
  )
}

export default Invoices
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, Stack, Tooltip, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { grey } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';

const TemporaryPasswordHandler = ({modal,onClose}) => {
  const msg = "temporary password copied to clipboard "

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(true)
     navigator.clipboard.writeText(modal?.temp);
  }
  return (
    <Fragment>
      <Dialog
        open={modal.open}
        onClose={()=>{
          setIsClicked(false)
          onClose()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            id="alert-dialog-description"
          >
            <Typography variant="h6" color="secondary" sx={{ mt: ".8rem" }}>
              Agent Temporary password
            </Typography>
            <Stack
              direction={"row"}
              gap={".8rem"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ border: `1px solid ${grey[200]}`, px: "1rem", my: isClicked ? "1rem" : "1.8rem" }}
              onClick={handleClick}
            >
              <Typography
                variant="h4"
                sx={{ textAlign: "center", fontWeight: "600" }}
              >
                {modal?.temp}
              </Typography>
              <Tooltip title="Copy temporary password" placement="right-start">
                <ContentCopyIcon sx={{ fontSize: "2rem", cursor: "pointer" }} />
              </Tooltip>
            </Stack>
            {isClicked && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                {msg}
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            setIsClicked(false)
            onClose()
            }}>cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default TemporaryPasswordHandler
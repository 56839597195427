import React from "react";
import { useMutation } from "react-query";
import { additionalDetailsApi } from "../utils/api";
import { useSelector } from "react-redux";
import { errorHandler } from "../../../configs/errorConfig";

const useDeleteAdditionalDetails = () => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useMutation({
    mutationKey: ["DeleteAdditionalDetails"],
    mutationFn: ({ uuid }) =>
      additionalDetailsApi?.delete(selectedApp?.id, uuid),
    onError: (error) => errorHandler(error),
  });
};

export default useDeleteAdditionalDetails;

import {useQuery} from 'react-query'
import { posAgentApi, posTransactionApi } from '../exports'
import { errorHandler } from '../../../../configs/errorConfig'
import { useSelector } from 'react-redux';

const useGetPosAgentsById = (id) => {
 const selectedApp = useSelector((store) => store.data.selectedApp);

 const queryKey = ["getPosAgentById",id]

 const query = useQuery({
    queryKey,
    queryFn:()=>posAgentApi.get_POS_Agent_By_Id(selectedApp.id,id),
    onError:(error)=> errorHandler(error)
 })

 return query
}

export default useGetPosAgentsById
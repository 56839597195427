

import { useSelector } from "react-redux"
import axios from "../../../configs/axios"



export const getBalance = async(app)=>{
  //   let url = 'balance/app'
  // const response = await axios.get(`${url}?app_id=${app?.id}`)
  // return response?.data
}

export const getBalancePerCurrency = async(app)=>{
    let url = 'balance/app/currencies'
  const response = await axios.get(`${url}?app_id=${app?.id}`)
  return response?.data
}

export const getAppBalancePerCurrency = async(query)=>{
    const {currency,app_id} = query
    console.log({currency})
    let url = 'balance/app/currency?' 
    if(currency){
        url = `${url}&currency=${currency}`
    }
    if(app_id){
        url = `${url}&app_id=${app_id}`
    }
    const response = await axios.get(`${url}`)
    return response?.data
}

export const getRate = async (sendData) => {
  const {from_currency, to_currency} = sendData 

 
  let url = `deposit/transferts/currency/rates?`
  if (from_currency) {
    url = `${url}&from_currency=${from_currency}`
}
if (to_currency) {
  url = `${url}&to_currency=${to_currency}`
}

  const response = await axios.get(`${url}`)
  return response?.data
}

export const initialiseCurrencyTransfer = async(data) => {

  let url = `deposit/transferts/currency`

  const response = await axios.post(`${url}`,data)
  return response?.data
}
import { errorHandler } from "../../../../configs/errorConfig"
import { useQuery, useQueryClient,queryCache } from 'react-query'
import { POS_Agent } from "../api"
import { posAgentApi } from "../exports"
import { useSelector } from "react-redux"
import { useEffect } from "react"


const useGetAllPosAgents = () => {

 const selectedApp = useSelector((store) => store.data.selectedApp);

  const queryKey = [`useGetAllPosAgents-${selectedApp?.id}`,selectedApp]
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ()=> posAgentApi.get_All_POS_Agent(selectedApp?.id),
    onError:(error)=>errorHandler(error)
  })

  useEffect(() => {    
    queryClient.invalidateQueries(queryKey);
  }, [selectedApp]);

  return query
}

export default useGetAllPosAgents


import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import DataGrid from '@components/datagrid';
import { useParams } from 'react-router-dom';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomButton from '@components/CustomButton';
import ExportSegment from '@components/ExportSegment';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import { transactionColumns } from '@modules/transactions/utils/columns';
import { useTransactions } from '@modules/transactions/hooks/useTransactions';
import { useExportTransaction } from '@modules/transactions/hooks/useExportTransaction';
import TransactionDetails from '../../../modules/transactions/components/transaction-details';
import { statusList } from '../../../modules/transactions/utils';

export default function Transactions() {
  const { appID } = useParams();
  const { mutate, isLoading: isExporting } = useExportTransaction()
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [status, setStatus] = useState()
  const [search, setSearch] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const selectedApp = useSelector((store) => store.data.selectedApp);

  const filters = {
    page: currentPage + 1,
    limit: rowsPerPage,
    status,
    search,
  }

  const { data, isLoading, refetch } = useTransactions(filters)

  const paginationOptions = {
    currentPage,
    rowsPerPage,
    onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
    onNextPage: () => setCurrentPage(page => page + 1),
    onPreviousPage: () => setCurrentPage(page => page - 1),
    onFirstPage: () => setCurrentPage(0),
    onLastPage: () => setCurrentPage(data?.data?.transactions?.meta?.totalPages - 1),
    count: data?.data?.transactions?.meta?.totalItems || 1
  }

  const filterOptionsList = [
    {
      label: 'Status',
      options: statusList,
      value: status,
      onFilterChange: (e) => setStatus(e.target.value)
    }
  ]

  const onSearch = (e) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setStatus()
    setSearch(e.target.value)
  }

  const searchOptions = {
    title: 'Search by transaction ID',
    value: search,
    onSearch
  }

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setStartDate(null)
    setEndDate(null)
  };

  useEffect(() => {
    setStartDate(null)
    setEndDate(null)
  }, [])

  const breadCrumb = [
    {
      label: 'Transactions',
      link: `/${appID}/transactions`,
      icon: <MonitorHeartOutlinedIcon fontSize="small" />
    }
  ]

  const onReset = () => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch()
    setStatus()
    refetch()
  }

  const onExportTransactions = () => {
    mutate({
      start_date: startDate?.toISOString(),
      end_date: endDate?.toISOString(),
      selectedApp
    }, {
      onSuccess: () => {
        toast.success('Transactions exported successfully', { autoClose: 2000 })
        handleClose()

        toast.info('See those files in the Exported docs menu', {
          autoClose: 4000,
          delay: 2000
        })
      }
    })
  }
  useEffect(() => {
    refetch()
  }, [currentPage, rowsPerPage, status, search])

  let exportProps = {
    buttonVariant: "contained",
    handleClick,
    handleClose,
    open,
    anchorEl,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onExport: onExportTransactions,
    isLoading: isExporting
  }

  // ================= COMPONENT =================
  return (
    <Fragment>
      <Stack direction="row" alignItems='center' justifyContent='space-between' mb={4} mt={3}>
        <CustomizedBreadcrumbs links={breadCrumb} />
        {data?.data?.transactions?.items?.length > 0 && <ExportSegment {...exportProps} />}
      </Stack>
      {
        data?.data?.transactions?.items?.length > 0 || isLoading || search || status ?
          <DataGrid
            column={transactionColumns(setSelectedTransaction, setDetailsModalOpen)}
            head="Transactions"
            dataType='transactions'
            isLoading={isLoading}
            paginationOptions={paginationOptions}
            searchOptions={searchOptions}
            filterOptionsList={filterOptionsList}
            data={data?.data?.transactions?.items}
          />
          :
          <Box textAlign="center">
            <Typography variant='h5'>No transaction found</Typography>
            <CustomButton onClick={onReset} loading={isLoading} startIcon={<RefreshIcon fontSize='large' />} size='large' variant='contained' sx={{ mt: 2, fontSize: 13 }}>
              Refresh
            </CustomButton>
          </Box>
      }
      {detailsModalOpen ?
        <TransactionDetails
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
          transaction={selectedTransaction}
        /> : null
      }
    </Fragment>
  );
}
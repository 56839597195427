import { gql, useMutation } from '@apollo/client';

export const useUpdateApplicationMode = () => { 

  const mutation = useMutation(
    gql`mutation updateApplicationMode($id: String!, $mode: ApplicationMode!){
      updateApplicationMode(id:$id, mode:$mode){
          id
          user_id
          name
          description
          mode
      }
    }
  `
  )

  return mutation
}



import { useMutation } from 'react-query';
import { verifyUserAPI } from "../utils/api";

export const useVerifyEmail = () => { 

  const mutation = useMutation({
    mutationKey: 'verify',
    mutationFn:verifyUserAPI,
  })

  return mutation
 }
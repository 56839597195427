import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import useDeletePosConfig from "../utils/hooks/useDeletePosConfig";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { LoadingButton } from "@mui/lab";
import useDeletePosAgent from "../utils/hooks/useDeletePosAgent";
import { toast } from "react-toastify";

const DeletePosAgent = ({ modal, handleClose }) => {
  const { mutate, data, isLoading, isSuccess } = useDeletePosAgent();
  const [loading, setLoading] = useState(false)

  const handleDelete = (uuid) => {
    setLoading(true)
    mutate({ uuid });
  };

  useEffect(() => {
    let timeoutId;

  if (isSuccess) {
    timeoutId = setTimeout(() => {
      setLoading(false)
      handleClose();
    }, 3000); 
  }

  return () => {
    clearTimeout(timeoutId); 
  };
  }, [isSuccess]);

  return (
    <Fragment>
      <Dialog
        open={modal.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete P.O.S Agent"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            id="alert-dialog-description"
          >
            <WarningAmberIcon sx={{ fontSize: "3rem" }} color="secondary" />
            <Typography variant="h5" color="secondary" sx={{ mt: ".8rem" }}>
              Warning!!!
            </Typography>
            <Typography sx={{ textAlign: "center", mt: "1rem" }}>
              You are about to delete this POS Config <br />
              Are you sure you want to proceed?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            onClick={() => handleDelete(modal?.uuid)}
          >
            delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeletePosAgent;

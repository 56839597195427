import { useMutation, useQueryClient } from 'react-query';
import { resendPayoutOtpAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useResendPayoutOtp = () => {

	const client = useQueryClient()
	const mutation = useMutation({
    mutationKey:'resend_payout_otp',
    mutationFn:resendPayoutOtpAPI,
    onError: (error) => errorHandler(error),
		onSuccess: () => {
			client.invalidateQueries(["payouts"])
		},
	})

	return mutation
}
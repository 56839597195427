import React, { Fragment, useEffect } from 'react';
import CustomDatePicker from "@components/DatePicker";
import { ErrorMessage, Field, FieldArray } from 'formik';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';


const Installments = ({ installments, setFieldValue }) => {

  useEffect(() => {
    if (installments?.length > 0) {
      setFieldValue('installments', installments);
    }
  }, [installments?.length])

  //=============== component =================== 

  return (
    <FieldArray name="installment">
      {() => {
        //============ component =================
        return (
          <Fragment>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {installments?.map((installment, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ minWidth: "200px" }}>
                      <Field name={`installments[${index}].title`}>
                        {({ field }) => (
                          <TextField variant="outlined" {...field} fullWidth size='small' />
                        )}
                      </Field>
                      <ErrorMessage className='err_message' name={`installments[${index}].title`} component="div" />
                    </TableCell>
                    <TableCell sx={{ maxWidth: "150px" }}>
                      <Field name={`installments[${index}].amount`}>
                        {({ field }) => (
                          <TextField variant="outlined" type='number' {...field} size='small' onChange={(event) => {
                            const amount = parseFloat(event.target.value) || 0;
                            setFieldValue(`installments[${index}].amount`, amount);
                          }} />
                        )}
                      </Field>
                      <ErrorMessage className='err_message' name={`installments[${index}].amount`} component="div" />
                    </TableCell>
                    <TableCell sx={{ maxWidth: "150px" }}>
                      <Field name={`installments[${index}].due_date`}>
                        {({ field }) => (
                          <CustomDatePicker
                            label="Due date"
                            onChange={(date) => {
                              field.onChange({ target: { value: date, name: field.name } })
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage className='err_message' name={`installments[${index}].due_date`} component="div" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )
      }}
    </FieldArray>
  )
};

export default Installments;
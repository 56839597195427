import React, { Fragment } from 'react'
import moment from 'moment'
import { Paper, Stack, Typography } from '@mui/material'
import BalanceLoader from './balance-loader';
import BalanceItem from '@modules/overview/components/balance-item';

const BalanceHistory = ({ balances, loading }) => {

  return (
    <Fragment>
      <Paper sx={{ p: 3, minHeight: "30em" }}>
        <Typography sx={{ mb: 2 }} variant="h6">Balance History</Typography>
        <Stack gap={loading ? 1 : 2} height="24.8em" justifyContent="center" overflow="scroll" className='balance-history' pr={1}>
          {
            loading ?
              <BalanceLoader />
              :
              balances?.length > 0 ?
                balances.map((balance, key) =>
                  <BalanceItem key={key}
                    amount={balance.provider_balance}
                    currency={balance.currency}
                    duration={moment(new Date(balance.create_time)).fromNow()}
                    color={balance.action_type === "CREDIT" ? "green" : "red"}
                  />
                )
                :
                <Typography variant='h6' textAlign="center">No data found</Typography>
          }
        </Stack>
      </Paper>
    </Fragment>
  )
}

export default BalanceHistory
import { useMutation } from 'react-query';
import { createPaymentLinkAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useCreatePaymentLink = () => { 

  const mutation = useMutation({
    mutationKey: 'create_link',
    mutationFn:createPaymentLinkAPI,
    onError: (error) => errorHandler(error) 
  })

  return mutation
 }
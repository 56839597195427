import { useMutation, useQueryClient } from 'react-query';
import { requestCashoutAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useAddCashout = () => { 
  const client = useQueryClient()
  
  const mutation = useMutation({
    mutationKey: 'register_cashout',
    mutationFn:requestCashoutAPI,
    onSuccess:()=> client.invalidateQueries(),
    onError: (error) => errorHandler(error) 
  })

return mutation
 }
import { Box } from '@mui/material'
import React from 'react'

export default function BalanceItem({ balance }) {
  return (
    <Box
      style={{
        boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        background: '#fff',
        padding: "0rem 2rem",
        position: "relative",
      }}
    >
      <Box>
        <Box
          style={{
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            backgroundColor: "#508991",
            width: "fit-content",
            padding: ".3rem 1rem",
            color: "rgb(240, 240, 240)",
            borderRadius: "4px",
            position: "absolute",
            top: "-1rem",
          }}
        >
          <span
            style={{
              fontSize: "1.3rem",
              fontWeight: "800",
              color: "white",
            }}
          >
            {balance?.currency}
          </span>
        </Box>
        <Box
          style={{
            padding: "2rem 0",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Box>
            <Box
              style={{
                fontWeight: "800",
                fontSize: "1.5rem",
                color: 'rgb(100,100,100)'
              }}
            >
              {`${new Intl.NumberFormat().format(balance?.Balance)}`}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import axios from "../../../configs/axios";

class AdditionalInfoApi {
  create(app_id, data) {
    console.log({ ...data, app_id });
    const result = axios.post(`pos/additional-details`, {
      ...data,
      application_id: app_id,
    });

    return result;
  }
  getAll(app_id, filter) {
    const params = {
      ...(app_id && { app_id }),
      ...(filter?.name && { name: filter?.name }),
      ...(filter?.page ? { page: filter?.page + 1 } : { page: 1 }),
    };
    return axios.get(`pos/additional-details`, {
      params,
    });
  }

  getOne(uuid, app_id) {
    const params = {
      ...(app_id && { app_id }),
    };
    return axios.get(`pos/additional-details/` + uuid, {
      params,
    });
  }

  update(data, uuid) {
    return axios.patch(`pos/additional-details/` + uuid, data);
  }
  delete(app_id, uuid) {
    const params = {
      ...(app_id && { app_id }),
    };
    return axios.delete(`pos/additional-details/` + uuid, {
      params,
    });
  }
}

export const additionalDetailsApi = new AdditionalInfoApi();

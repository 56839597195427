import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';

import { modes } from '@src/utils';
import { currencyListOptions } from '@src/utils';
import CustomInput from '@components/CustomInput';
import { FileUpload } from '@components/FIleUpload';
import CustomButton from '@components/CustomButton';
import { linkValidation } from '../utils/validations';
import { usePaymentLink } from '../hooks/usePaymentLinks';
import { useCreatePaymentLink } from '../hooks/useCreatePaymentLink';


export default function PaymentLinkForm({ onClose, isClosed }) {
  const [imageUrl, setImageUrl] = useState([])
  const application = useSelector((store) => store.data.selectedApp)
  const { mutate, isLoading } = useCreatePaymentLink()
  const { refetch } = usePaymentLink()

  const isModifiables = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' }
  ]

  const { getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      // Restrict file size to 5mb
      acceptedFiles.forEach((file) => {
        const fileExtention = file.type.split('/')[0]

        if (fileExtention !== 'image') {
          toast.error('Please only images are accepted')
          return
        }

        if (Math.round(file.size / 100) / 10 > 5120) {
          toast.error('Please your file size should not exceed 5mb')

        } else setImageUrl(file)
      })
    },
    multiple: false
  })


  const handleCreateLink = (values) => {

    if (!imageUrl || imageUrl === '' || imageUrl === null) {
      toast.error('Please upload your CNI')
      return
    }

    const global = {
      product_name: values.product_name,
      application_id: application.id,
      amount: values.amount,
      min_amount: values.min_amount ? values.min_amount : values.amount,
      description: values.description,
      currency: values.currency,
      isModifiable: values.isModifiable,
      mode: values.mode,
      link_img: imageUrl
    }

    const formData = new FormData()

    for (const key in global) {
      formData.append(key, global[key])
    }

    mutate(formData, {
      onSuccess: () => {
        toast.success(`${values.product_name} payment link created successfully!`)
        refetch()
        onClose()
      }
    })

  }

  const formik = useFormik({
    initialValues: {
      product_name: '',
      application_id: '',
      amount: '',
      min_amount: '',
      description: '',
      currency: '',
      isModifiable: '',
      mode: '',
    },
    validationSchema: linkValidation,
    onSubmit: values => handleCreateLink(values)
  })

  useEffect(() => {
    if (isClosed) {
      formik.resetForm()
    }
  },[isClosed]);

  //=================== COMPONENT =============================

  return (
    <Stack component="form" gap={2} >

      <ToastContainer />

      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label" size="small">Payment mode</InputLabel>
        <Select
          size="small"
          name="mode"
          label="Select payment mode"
          value={formik.values.mode}
          onChange={(option) => formik.setFieldValue("mode", option.target.value)}
          onBlur={formik.handleBlur}
        >
          {modes.map((mode) => (
            <MenuItem key={mode.label} value={mode.value}>{mode.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomInput
        name="product_name"
        value={formik.values.product_name}
        error={formik.touched.product_name && !!formik.errors.product_name}
        helpertext={formik.touched.product_name && formik.errors.product_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Product Name"
        label="Product Name"
      />

      <CustomInput
        name="amount"
        value={formik.values.amount}
        error={formik.touched.amount && !!formik.errors.amount}
        helpertext={formik.touched.amount && formik.errors.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Amount"
        label="Amount" />

      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label" size="small">Currency</InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="currency"
          error={formik.touched.currency && !!formik.errors.currency}
          value={formik.values.currency}
          label="Currency"
          onChange={(option) => formik.setFieldValue("currency", option.target.value)}
        >
          {currencyListOptions?.map((currency) => (
            <MenuItem key={currency.label} value={currency.value}>{currency.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label" size="small">Can the buyer modify this amount?</InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name='isModifiable'
          value={formik.values.isModifiable}
          label="Can the buyer modify this amount?"
          onChange={(option) => formik.setFieldValue("isModifiable", option.target.value)}
        >
          {isModifiables.map((option, key) => (
            <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {formik.values.isModifiable ? <CustomInput
        name="min_amount"
        value={formik.values.min_amount}
        error={formik.touched.min_amount && !!formik.errors.min_amount}
        helpertext={formik.touched.min_amount && formik.errors.min_amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Min amount"
        label="Min amount"
      /> : null}

      <Stack gap={1}>
        <Typography variant="h6" fontSize="medium" color="GrayText">Product Image</Typography>
        <FileUpload
          accept="image/*"
          text="image"
          setFiles={setImageUrl}
          {...getInputProps()}
        />
        {imageUrl ?
          <Stack gap={1}>
            {<Typography color="primary" >{imageUrl.name}</Typography>}
          </Stack> : null}
      </Stack>

      <TextField
        id="descripton"
        label="Product Description"
        name='description'
        value={formik.values.description}
        error={formik.touched.description && !!formik.errors.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        multiline
        rows={4}
      />

      <CustomButton
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
        fullWidth
        size='large'
        sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
      >
        {isLoading ? 'generating...' : 'generate link'}
      </CustomButton>
    </Stack>
  )
}

import Close from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useRegeneratePassword from "../utils/hooks/useRegeneratePassword";
import { LoadingButton } from "@mui/lab";
import { errorHandler } from "../../../configs/errorConfig";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { grey } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";

const RegeneratePasswordModal = ({ open, handleClose }) => {
  console.log(open);
  const [newPassord, setNewPassord] = useState("");
  const msg = "temporary password copied to clipboard ";
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    navigator.clipboard.writeText(newPassord);
  };
  const { mutate, isLoading } = useRegeneratePassword(open?.data?.uuid);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle fontSize="xl" id="alert-dialog-title">
        Regenerate Agents Password
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          width="100%"
        >
          <Typography fontSize=".8rem" color={grey[500]}>
            Regenerate Password For
          </Typography>
          <Typography fontWeight="600" fontSize="1.3rem">
            {open?.data?.name}
          </Typography>
        </Box>
        {!!newPassord ? (
          <>
            <Divider sx={{ my: 3, color: grey[500] }} />
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Regenerated Passord
              <Stack
                direction={"row"}
                gap={".8rem"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  border: `1px solid ${grey[200]}`,
                  px: "1rem",
                  my: isClicked ? "1rem" : "1.8rem",
                }}
                onClick={handleClick}
              >
                <Tooltip
                  title="Copy temporary password"
                  placement="right-start"
                >
                  <ContentCopyIcon
                    sx={{ fontSize: "2rem", cursor: "pointer" }}
                  />
                </Tooltip>
                <Typography fontSize="2rem">{newPassord}</Typography>
              </Stack>
              {isClicked && (
                <Alert
                  sx={{
                    textTransform: "capitalize",
                  }}
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                >
                  {msg}
                </Alert>
              )}
            </Alert>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          sx={{
            width: "100%",
          }}
          onClick={() => {
            mutate(
              {},
              {
                onSuccess: (data) => {
                  console.log(data);
                  console.log(data?.data?.data?.temporaryPassword);
                  setNewPassord(data?.data?.data?.temporaryPassword);
                },
                onError: (error) => errorHandler(error),
              }
            );
          }}
        >
          Regenerate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RegeneratePasswordModal;

import React from 'react'
import Chip from '@mui/material/Chip'

const InvoiceChip = ({ status, filled }) => {
    const statuses = {
        PAID: "success",
        UNPAID: "error",
        PARTIALLY_PAID: "warning",

    }

    const label = Object.keys(statuses).find(key => key === status);

    return <Chip sx={{ maxWidth: "100%", fontWeight: "bold" }} size='small' label={label} color={statuses[status]} variant={filled ? "filled" : "outlined"} />
}

export default InvoiceChip
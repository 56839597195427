import { useMutation, useQueryClient } from 'react-query';
import { resendCashoutOtpAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useResendCashoutOtp = () => {

	const client = useQueryClient()
  
	const mutation = useMutation({
    mutationKey:'resend_cashout_otp',
    mutationFn:resendCashoutOtpAPI,
    onError: (error) => errorHandler(error),
		onSuccess: () => {
			client.invalidateQueries(["cashouts"])
		},
	})

	return mutation
}
import { IconButton, Stack, Typography } from "@mui/material"
import { VisibilityOutlined } from "@mui/icons-material";
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DatagridColumnHeader from "@components/datagrid/datagrid-column-header"

export const linkColumns = (getPaymentLink) => [
  {
    id: 'product_name',
    header: 'Product Name',
    accessorKey: 'product_name',
  },
  {
    id: 'mode',
    header: 'Mode',
    accessorKey: 'mode',
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'amount',
    header: ({ column }) => (
      <DatagridColumnHeader label="Amount" column={column} />
    ),
    accessorKey: 'amount',
    cell: ({ row }) => {
      const currency = row.original.currency[0];
      const amount = row.original.amount;
      return `${ new Intl.NumberFormat().format(amount) } ${currency}`
    },
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'link',
    header: 'Payment Link',
    accessorKey: 'link',
    cell: ({ row }) => {
      return <Stack maxWidth="300px">
        <Typography noWrap sx={{ textDecoration: 'underline' }}><a rel="noreferrer" href={ row?.original?.link } target="_blank">{  row?.original?.short_link || row?.original?.link }</a></Typography>
      </Stack>
    }
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <IconButton title="View details" onClick={() => getPaymentLink(row.original, true, false, false)}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
          <IconButton 
            title="Share this link" 
            color="primary" 
            onClick={() => getPaymentLink(row.original, false, true, false)}
          >
            <ShareIcon fontSize="small" />
          </IconButton>
          <IconButton 
            title="Delete this link" 
            color="primary" 
            onClick={() => getPaymentLink(row.original, false, false, true)}
          >
            <DeleteOutlineIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  },
]
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import { Box, Button, ButtonGroup, Stack, Typography, useMediaQuery } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DataGrid from '@components/datagrid'
import DashboardSwipeable from '@components/DashboardSwipeable';
import { cashoutsColumns } from '@modules/cashouts/utils/columns'
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import { useCashouts } from '@modules/cashouts/hooks/useCashouts';
import VerifyCashout from '@modules/cashouts/components/verify-cashout';
import CashoutDetails from '@modules/cashouts/components/cashout-details';
import { useExportCashout } from '@modules/cashouts/hooks/useExportCashout';
import RequestCashoutForm from '@modules/cashouts/components/request-cashout-form';
import ExportSegment from '@components/ExportSegment';
import CustomButton from '../../../components/CustomButton';

const Cashouts = () => {
	const [isOpen, setIsOpen] = useState(false)
	const { appID } = useParams();
	const smallScreen = useMediaQuery('(max-width:900px)');
	const [verifyModalOpen, setVerifyModalOpen] = useState(false)
	const [detailsModalOpen, setDetailsModalOpen] = useState(false)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [selectedCashout, setSelectedCashout] = useState()
	const [anchorEl, setAnchorEl] = useState(null);
	const { mutate, isLoading: isExporting } = useExportCashout()

	const filters = {
		page: currentPage + 1,
		limit: rowsPerPage,
	}

	const { data, isLoading, refetch } = useCashouts(filters)
	const cashouts = data?.data

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const paginationOptions = {
		currentPage,
		rowsPerPage,
		onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value)),
		onNextPage: () => setCurrentPage(page => page + 1),
		onPreviousPage: () => setCurrentPage(page => page - 1),
		onFirstPage: () => setCurrentPage(0),
		onLastPage: () => setCurrentPage(cashouts?.meta?.totalPages - 1),
		count: cashouts?.meta?.totalItems || 1
	}

	const handleClose = () => {
		setAnchorEl(null);
		setStartDate(null)
		setEndDate(null)
	};

	useEffect(() => {
		setStartDate(null)
		setEndDate(null)
	}, [])

	const getCashout = (cashout, verify) => {
		setSelectedCashout(cashout)
		if (verify) setVerifyModalOpen(true)
		else setDetailsModalOpen(true)
	}

	const breadCrumb = [
		{
			label: 'Cashouts',
			link: `/${appID}/cashouts`,
			icon: <AttachMoneySharpIcon fontSize="small" />
		}
	]

	const onExportCashouts = () => {
		mutate({
			start_date: startDate?.toISOString(),
			end_date: endDate?.toISOString(),
			appID
		}, {
			onSuccess: () => {
				handleClose()
				toast.success('Cashouts exported successfully', {
					autoClose: 2000
				})

				toast.info('See those files in the Exported docs menu', {
					autoClose: 4000,
					delay: 2000
				})
			}
		})
	}

	const onReset = () => {
		setCurrentPage(0)
		setRowsPerPage(10)
		refetch()
	}

	let exportProps = {
		handleClick,
		handleClose,
		open,
		anchorEl,
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		onExport: onExportCashouts,
		isLoading: isExporting
	}

	useEffect(() => {
		refetch()
	}, [currentPage, rowsPerPage])

	// ================= COMPONENT =================
	return (
		<Fragment>
			<Stack direction="row" alignItems='center' justifyContent='space-between' mb={4} mt={3}>
				<CustomizedBreadcrumbs links={breadCrumb} />
				<ButtonGroup size='small' variant='outlined'>

					<ExportSegment {...exportProps} />

					<Button color='primary' onClick={() => setIsOpen(true)} sx={{ boxShadow: "none" }} variant='contained' startIcon={<AddIcon />}>
						{smallScreen ? 'Cashout' : 'Cashout request'}
					</Button>
				</ButtonGroup>
			</Stack>

			{
				cashouts?.items?.length > 0 || isLoading ?
					<DataGrid
						data={cashouts?.items}
						hidePadding
						head="Cashouts"
						isLoading={isLoading}
						column={cashoutsColumns(getCashout)}
						onClick={getCashout}
						paginationOptions={paginationOptions}
						dataType='cashouts'
					/>
					:
					<Box textAlign="center">
						<Typography variant='h5'>No cashout found</Typography>
						<CustomButton onClick={onReset} loading={isLoading} startIcon={<RefreshIcon fontSize='large' />} size='large' variant='contained' sx={{ mt: 2, fontSize: 13 }}>
							Refresh
						</CustomButton>
					</Box>
			}

			<DashboardSwipeable
				isOpen={isOpen}
				title="request a cashout"
				onClose={() => setIsOpen(false)}
			>
				<RequestCashoutForm
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
				/>
			</DashboardSwipeable>

			{
				selectedCashout ?
					<CashoutDetails
						cashoutData={selectedCashout}
						open={detailsModalOpen}
						onClose={() => setDetailsModalOpen(false)}
					/> : null
			}

			<VerifyCashout
				open={verifyModalOpen}
				cashoutData={selectedCashout}
				onClose={() => setVerifyModalOpen(false)}
			/>

		</Fragment >

	)
}

export default Cashouts
import React, { useState } from 'react'
import {
    Paper, Stack, Typography, Tabs, Tab, Container, Box, useMediaQuery
} from '@mui/material'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GridViewIcon from '@mui/icons-material/GridView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';

import HomeHeader from '@components/HomeHeader';
import CustomTabPanel from '@components/CustomTabPanel';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import ApplicationForm from '@modules/home/components/ApplicationForm';
import AppDetail from '@modules/application-detail/components/appDetail';
import AssociatedPsp from '@modules/application-detail/components/associated-psp';
import { useGetAssociatedPsp } from '@modules/application/hooks/useGetAssociatedPsp';
import { useApplicationPsp } from '@modules/application-detail/hooks/useApplicationPsp';

const ApplicationDetailsTab = () => {
    const [value, setValue] = useState(0);
    const selectedApp = useSelector((store) => store.data.selectedApp)
    const { data: associatedData, isLoading, refetch: refetchAssociatedPsp } = useGetAssociatedPsp()
    const { data: singleApp, refetch: refetchApplicationPsp } = useApplicationPsp()
    const { appID } = useParams();
    const smallScreen = useMediaQuery('(max-width:600px)');

    const breadCrumb = [
        {
            label: `${selectedApp?.name ?? ''} settings`,
            link: `/${appID}/application-details`,
            icon: <GridViewIcon fontSize="small" />
        }
    ]

    return (
        <section className='main-container'>
            <ToastContainer />

            <Container>
                <Stack direction="row" alignItems='center' justifyContent='space-between' mb={2} mt={3}>
                    <CustomizedBreadcrumbs links={breadCrumb} />
                </Stack>

                <Tabs
                    value={value}
                    onChange={(e, newValue) => setValue(newValue)}
                    aria-label=""
                >
                    <Tab icon={<InfoOutlinedIcon />} iconPosition="start" label={smallScreen ? '' : 'Application detail'} />
                    <Tab icon={<ViewInArOutlinedIcon />} iconPosition="start" label={smallScreen ? '' : 'Subscribed PSPs'} />
                    <Tab icon={<EditOutlinedIcon />} iconPosition="start" label={smallScreen ? '' : 'Edit app'} />
                </Tabs>

                <Box sx={{ pb: 10 }}>
                    <Paper>
                        <CustomTabPanel value={value} index={0}>
                            <AppDetail />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            <AssociatedPsp
                                associatedData={associatedData?.data}
                                isLoading={isLoading}
                                refetchAssociatedPsp={refetchAssociatedPsp}
                                refetchApplicationPsp={refetchApplicationPsp}
                                singleApp={singleApp?.SingleApplication}
                            />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={2}>
                            <Typography variant="h5" mb={2}>Edit application {selectedApp?.name}</Typography>
                            <ApplicationForm edit />
                        </CustomTabPanel>
                    </Paper>
                </Box>
            </Container>
        </section>
    )
}

export default ApplicationDetailsTab
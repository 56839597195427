import React from 'react'
import Chip from '@mui/material/Chip'

const CashoutChips = ({ status }) => {
  const statuses = {
    VALIDATED: "success",
    NOT_VERIFIED: "warning",
    PENDING: "info",
    REJECTED: "error",
    VERIFIED: "success",

  }

  const label = Object.keys(statuses).find(key => key === status);

  return <Chip sx={{ maxWidth: "100%" }} size='small' label={label} color={statuses[status]} variant="outlined" />

}

export default CashoutChips
import axios from "../../../configs/axios";

export const getAllDisbursement = async (app, filters) => {
  let url = `deposit/all`;

  let params = {
    ...(filters.page ? { page: filters.page + 1 } : { page: 1 }),
    ...(filters.limit && { limit: filters.limit }),
    ...(filters.status && { status: filters.status }),
    ...(filters.deposit_type && { deposit_type: filters.deposit_type }),
    ...(filters.search && { search: filters.search }),
    ...(app.id && { app_id: app.id }),
  };

  const response = await axios.get(url, { params });

  return response?.data;
};

export class Disbursement {
  getAllDisbursement = async (app) => {
    let url = "deposit/all";
    const response = await axios.get(`${url}?app_id=${app?.id}`);
    return response?.data;
  };

  getDepExchangeRate = async (app) => {
    const { from_currency, to_currency } = app;
    let url = "deposit/transferts/currency/rates?";

    if (from_currency) {
      url = `${url}&from_currency=${from_currency}`;
    }
    if (to_currency) {
      url = `${url}&to_currency=${to_currency}`;
    }

    const response = await axios.get(`${url}`);
    return response?.data;
  };

  getDepById = async (data) => {
    const { id, app_id } = data;
    let url = `deposit/all/${id}?`;

    if (app_id) {
      url = `${url}&app_id=${app_id}`;
    }
    const response = await axios.get(`${url}`);
    return response?.data;
  };

  initializeDep = async (data) => {
    const res = await axios.post(`deposit/initialize/dashboard`, data);
    return res?.data;
  };
  resendCode = async (data) => {
    const res = await axios.post(`deposit/resend`, data);
    return res?.data;
  };

  confirmDep = async (data) => {
    const res = axios.post(`deposit/confirm/dashboard`, data);
    return res;
  };
  confirmTransfert = async (data) => {
    const res = axios.post(`deposit/transferts/currency/confirm`, data);
    return res;
  };
  depositFees = async (data) => {
    const res = axios.post(`deposit/fees`, data);
    return res;
  };
  getappBalance = async (data) => {
    const res = axios.get(`balance/app/currencies?app_id=${data.id}`);
    return res;
  };
  getSupportedApiDeposit = async () => {
    const res = axios.get(`deposit/countries/supported`);
    return res;
  };
}

import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import CustomButton from '@components/CustomButton';

const AlertDialog = (props) => {
  const { open, onClose, text, color, action, disabled, loading } = props

  // ================= COMPONENT =================
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        sx={{ textAlign: 'center' }}
      >
        <DialogContent>
          <WarningAmberIcon fontSize="large" color="warning" />
          <DialogTitle id="alert-dialog-title">
            {text}
          </DialogTitle>
        </DialogContent>
        <DialogActions disableSpacing >
          <Button onClick={onClose} sx={{ mr: 1 }} color="inherit" disabled={disabled}>Cancel</Button>
          <CustomButton
            type='button'
            onClick={action}
            color={color}
            disabled={disabled || loading}
            variant="contained"
            size='medium'
            sx={{ textTransform: loading ? 'capitalize' : 'uppercase' }}
          >
            {loading ? 'Processing...' : 'Confirm'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog
import React from "react";
import { useQuery } from "react-query";
import { additionalFeesApi } from "../utils/api";
import { useSelector } from "react-redux";

const useGetAllAdditionalFees = (filter) => {
  const selectedApp = useSelector((store) => store.data.selectedApp);
  return useQuery({
    queryKey: ["GetAllAdditionalFees"],
    queryFn: () => additionalFeesApi.getAll(selectedApp?.id, filter),
  });
};

export default useGetAllAdditionalFees;

import React, { Fragment, useState } from "react";
import Stack from "@mui/material/Stack";
import DataGrid from "../../../components/datagrid";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DashboardSwipeable from '@components/DashboardSwipeable';
import AlertDialog from '@components/AlertDialog';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Typography } from "@mui/material";
import CustomizedBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { companyColumns } from "../../../modules/invoices/utils/columns";
import { toast } from "react-toastify";
import CompanyForm from "../../../modules/invoices/components/company-form";
import CompanyDetails from "../../../modules/invoices/components/company-details";
import { errorHandler } from "../../../configs/errorConfig";
import { useGetCompanies } from "../../../modules/invoices/hooks/useGetCompanies";

const Companies = () => {
  const navigate = useNavigate()
  const { appID } = useParams();
  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState(undefined);
  const [updateImg, setUpdateImg] = useState(undefined);
  const [companyID, setCompanyID] = useState()
  const [selectedCompany, setSelectedCompany] = useState();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const { data: companies, isLoading } = useGetCompanies();


  const onClose = () => {
    setEdit(false)
    setOpen(false)
    setImageUrl(undefined)
    setUpdateImg(undefined)
    setSelectedCompany(null)
    setDetailsModalOpen(false)
    setCompanyID(null)
  }

  const getCompany = (data, view, isEdit) => {
    setSelectedCompany(data)
    if (view) setDetailsModalOpen(true)
    else if (isEdit) {
      setEdit(true)
      setOpen(true)
    }
    else return null
  }

  const data = [
    {
      id: 1,
      name: "Stan Tech",
      phone_number: "678234324",
      email: "stantech@tech.com",
      logo: "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1716422400&semt=sph",
    },
  ];

  const breadCrumb = [
    {
      label: "Companies",
      link: `/${appID}/companies`,
    },
  ];


  return (
    <Fragment>
      <Stack
        direction="row"
        mb={4}
        mt={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomizedBreadcrumbs links={breadCrumb} />
        {/* {!isLoading && ( */}

        <ButtonGroup size='small' variant='outlined'>
          <Button
            color="primary"
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            Add Company
          </Button>
          <Button
            color="secondary"
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={() => {
              setDetailsModalOpen(true)
              setCompanyID("1a964f7b-5d03-44b5-a680-a791ef2d4998")
            }}
          >
            Get Company Details
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={() => {
              setOpen(true)
              setEdit(true)
              setCompanyID("1a964f7b-5d03-44b5-a680-a791ef2d4998")
            }}
            startIcon={<AddIcon />}
          >
            Update Company
          </Button>
          <Button
            color='secondary'
            variant="outlined"
            onClick={() => navigate(`/${appID}/invoices`)}
            sx={{ boxShadow: "none" }}
            startIcon={<ReceiptIcon />}
          >
            Invoices
          </Button>
        </ButtonGroup>
        {/* // )} */}
      </Stack>

      <DataGrid
        query="name"
        head="Companies"
        isLoading={isLoading}
        searchTitle="Search company name"
        column={companyColumns(getCompany)}
        data={data}
      />

      {detailsModalOpen && companyID ?
        <CompanyDetails
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
          companyID={companyID}
        /> : null}

      { /*================ Product Form ================*/}
      <DashboardSwipeable
        isOpen={open}
        onClose={onClose}
        title={
          edit ? <Typography variant='h5' fontWeight="bold">Update Compnay</Typography>
            : <Typography variant='h5' fontWeight="bold">Add Company</Typography>
        }
      >
        <CompanyForm
          onClose={onClose}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          isClosed={open}
          companyID={companyID}
          isEdit={edit}
        />
      </DashboardSwipeable>
    </Fragment>
  );
};

export default Companies;

import axios from "@configs/axios";

export class POS_Transactions {
  getPOS_Transaction({
    status,
    mode,
    start_date,
    end_date,
    currency,
    agent_id,
    page,
    limit,
    application_id,
  }) {
    const params = {
      ...(status && { status }),
      ...(mode && { mode }),
      ...(start_date && { start_date: start_date?.toISOString() }),
      ...(end_date && { end_date: end_date?.toISOString() }),
      ...(currency && { currency }),
      ...(agent_id && { agent_id }),
      ...(limit && { limit }),
      ...(application_id && { app_id: application_id }),
      ...(page ? { page: page + 1 } : { page: 1 }),
    };

    return axios.get("/pos/transactions", {
      params,
    });
  }
  get_POS_Transaction_Statistics(query) {
    const { app_id, agent_id, start_date, end_date } = query;

    const params = {
      ...(app_id && { app_id }),
      ...(agent_id && { agent_id }),
      ...(start_date && { start_date: start_date.toISOString() }),
      ...(end_date && { end_date: end_date.toISOString() }),
    };
    return axios.get("pos/transactions/reports/statistics", {
      params,
    });
  }
  get_POS_Transaction_By_TransactionId(id) {
    return axios.get(`/pos/transactions/${id}`);
  }
  get_POS_Transaction_By_ReferenceId(ref_id) {
    return axios.get(`pos/transactions/reference/:${ref_id}`);
  }
}

export class POS_Config {
  create_POS_Config(data) {
    return axios.post(`/pos/pos-config`, data);
  }

  update_Pos_Config(id, data) {
    return axios.patch(`/pos/pos-config/${id}`, data);
  }
  delete_Pos_Config({ application_id, uuid }) {
    const params = {
      ...(application_id && { app_id: application_id }),
    };
    return axios.delete(`pos/pos-config/${uuid}`, {
      params,
    });
  }

  getPOS_Config({ page, limit, application_id, name, location }) {
    const params = {
      ...(page && { page }),
      ...(limit && { limit }),
      ...(name && { name }),
      ...(location && { location }),
      ...(application_id && { app_id: application_id }),
    };

    return axios.get("/pos/pos-config", {
      params,
    });
  }

  get_POS_Config_By_Id(id) {
    return axios.get(`/pos/pos-config/${id}`);
  }
}

export class POS_Agent {
  get_All_POS_Agent(id) {
    const page = 1;
    const limit = 10;
    const params = {
      ...(page && { page }),
      ...(limit && { limit }),
      ...(id && { app_id: id }),
    };
    return axios.get("/pos/agents", {
      params,
    });
  }
  create_POS_Agents(data, id) {
    return axios.post(`/pos/agents`, data);
  }

  update_POS_Agents(data, id) {
    return axios.patch(`/pos/agents/${id}`, data);
  }

  get_POS_Agent_By_Id(app_id, id) {
    return axios.get(`/pos/agents/${id}`, {
      params: {
        app_id,
      },
    });
  }
  regeneratePassword(uuid, data) {
    return axios.patch(`pos/agents/${uuid}/regenerate-password`, data);
  }
  delete_POS_agent({ application_id, uuid }) {
    const params = {
      ...(application_id && { app_id: application_id }),
    };
    return axios.delete(`/pos/agents/${uuid}`, {
      params,
    });
  }
}

import * as Yup from 'yup'

const kycValidations = (actualStep) => {

    const kycTwoSchema = Yup.object().shape({
        business_industry: Yup.string().required('required'),
        business_website: Yup.string().required('required'),
        business_description: Yup.string().required('required'),
        business_country: Yup.string().required('required'),
        business_city: Yup.string().required('required'),
        business_address: Yup.string().required('required'),
        business_type: Yup.string().required('required'),
        business_customer_support_name: Yup.string().required('required'),
        business_customer_support_phone: Yup.string().required('required').max(15, 'phone number can not be more than 15 characters'),
        business_customer_support_email: Yup.string().required('required').email('Invalid email')
    })

    const promoterSchema = Yup.object().shape({
        business_promoter_name: Yup.string().required('required'),
        business_promoter_phone: Yup.string().required('required').max(15, 'phone number can not be more than 15 characters'),
        business_promoter_email: Yup.string().required('required').email('Invalid email')
    })

    if(actualStep === 0) return kycTwoSchema
    else if (actualStep === 1) return promoterSchema
    
    return Yup.object().shape({})
}

export { kycValidations }
import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, FormControl, InputLabel, MenuItem, Select, Typography, Divider, Autocomplete, TextField } from '@mui/material';

import CustomInput from '@components/CustomInput';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import { useAddCashout } from '../hooks/useAddCashout';
import { cashoutValidation } from '../utils/valdations';
import { usePayouts } from '../../payouts/hooks/usePayouts';
import { useGetBalanceFee } from '../../service-account/hooks/useGetBalanceFee';
import { countryCurrencies, currencyListOptions } from '@src/utils';
import { useGetAssociatedPsp } from '../../application/hooks/useGetAssociatedPsp';

export default function RequestCashoutForm({ onClose, isOpen }) {
  const [selectedPayout, setSelectedPayout] = useState({})
  const [payoutTypes, setPayoutTypes] = useState([])
  const [allProviders, setAllProviders] = useState([])
  const [provider, setProvider] = useState()
  const [paymentType, setPaymentType] = useState()
  const [currency, setCurrency] = useState()
  const [currencies, setCurrencies] = useState(currencyListOptions)
  const application = useSelector((store) => store.data.selectedApp)
  const [search, setSearch] = useState('')

  const filters = {
    page: 1,
    limit: 100,
    search,
  }

  const { data: payouts, refetch: refetchPayouts } = usePayouts(application?.id, filters)

  const { data: psps, isLoading: isPspLoading, error, isFetched: isPspFetched } = useGetAssociatedPsp(application?.id)
  const { data: balanceFee, status: feeStatus, refetch } = useGetBalanceFee(selectedPayout?.value)
  const { mutate, isLoading } = useAddCashout()

  //============= GET VERIFIED PAYOUTS =================

  const onSearch = (e) => {
    setSearch(e.target.value)
  }

  const handlePayoutChange = (payout) => {
    console.log(payout);
    setSelectedPayout(payout)
    setPaymentType(payout.type);
    formik.setFieldValue("payout_account_id", payout.value)
    refetch()
  }

  const onCurrencyChange = (currency) => setCurrency(currency.target.value)

  const onProviderChange = (provider) => {
    setProvider(provider)
    refetch()
    var currencyList = currencyListOptions
    if (provider?.country_code !== 'WORLD') {
      currencyList = currencyListOptions?.filter(currency => currency.value === countryCurrencies[provider.country_code])
    }
    setCurrencies(currencyList);
  }

  //==================== FORMIK REQUEST =================

  const formik = useFormik({
    initialValues: {
      cashout_data: "",
      payout_account_id: "",
      amount: "",
    },
    validationSchema: cashoutValidation(paymentType),
    onSubmit: (values) => {
      const data = {
        amount: Number(values.amount),
        payout_account_id: values.payout_account_id,
        cashout_data: values.cashout_data,
        application_id: application?.id,
        collect_provider_id: provider.value,
        currency: currency
      }

      mutate(data, {
        onSuccess: () => {
          toast.success(`Cashout created successfully!`)
          onClose()
        }
      })
    }
  })

  useEffect(() => {
    if (payouts?.data) {
      const verifiedPayouts = payouts?.data?.items?.filter(item => item.status === "VERIFIED")

      const customVerifiedPayouts = verifiedPayouts?.map(item => ({
        value: item.id,
        label: item.payout_name,
        type: item.provider_type
      }))

      setPayoutTypes(customVerifiedPayouts)
    }
  }, [payouts?.data?.items, isOpen, application])


  useEffect(() => {
    if (psps?.data) {
      const providers = psps.data.providers?.map(item => ({
        value: item.shortcode,
        label: item.name,
        country_code: item.country_code
      }))

      setAllProviders(providers)
    }
    else if (error) errorHandler(error)
  }, [application?.id, payouts?.data, selectedPayout])

  useEffect(() => {
    refetchPayouts()
    console.log(search);
  }, [search])

  //============== COMPONENT ========================

  return (
    <Stack component="form" gap={2} >
      <ToastContainer />
      <FormControl fullWidth >
        <Autocomplete
          value={selectedPayout?.label}
          onChange={(_event, selectedPayout) => handlePayoutChange(payoutTypes.find(payout => payout?.label === selectedPayout))}
          onBlur={formik.handleBlur}
          id="payout_account_id"
          label=""
          defaultValue=""
          disableClearable
          options={payoutTypes.map(payout => payout?.label)}
          size='small'
          fullWidth
          renderInput={(params) => <TextField onChange={onSearch} {...params} l placeholder='Payout Type' />}
        />
      </FormControl>
      {selectedPayout ?
        <Fragment>
          {allProviders?.length > 0 && !isPspLoading && isPspFetched ?
            <FormControl fullWidth >
              <Autocomplete
                disableClearable
                value={provider}
                onChange={(_event, selectedOption) => onProviderChange(selectedOption)}
                onBlur={formik.handleBlur}
                id="fromAccount"
                defaultValue=""
                options={allProviders?.map(psp => (
                  {
                    label: psp?.label || '',
                    value: psp?.value || '',
                    country_code: psp?.country_code || ''
                  }
                ))}
                size='small'
                fullWidth
                renderInput={(params) => <TextField {...params} placeholder='From account' />}
              />
            </FormControl> : <Typography color="gray" fontSize="1.2rem">Loading...</Typography>}
          <CustomInput name="cashout_data"
            placeholder={paymentType === 'MOBILE_MONEY' ? 'Phone Number' : 'RIB'}
            label={paymentType === 'MOBILE_MONEY' ? 'Phone Number' : 'RIB'}
            value={formik.values.cashout_data}
            error={!!formik.touched.cashout_data && !!formik.errors.cashout_data}
            helpertext={!!formik.touched.cashout_data && formik.errors.cashout_data}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Stack direction="row" gap={2}>
            <FormControl fullWidth>
              <InputLabel size='small' id="country-error-label">Currency</InputLabel>
              <Select
                size='small'
                labelId="currency"
                id="currency"
                name='currency'
                value={currency}
                label="Currency"
                onChange={onCurrencyChange}
              >
                {!isPspLoading && currencies ? currencies.map((currency, key) => (
                  <MenuItem key={key} value={currency.value}>{currency.label}</MenuItem>
                )) : <MenuItem value="">No options yet</MenuItem>
                }
              </Select>
              {/* <FormHelperText>Error</FormHelperText> */}
            </FormControl>

            <CustomInput
              name="amount"
              value={formik.values.amount}
              error={formik.touched.amount && !!formik.errors.amount}
              helpertext={formik.touched.amount && formik.errors.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter the amount"
              label="Amount" />
          </Stack>
        </Fragment> : null}
      {/* isCashoutLoading */}
      {isLoading ?
        <Stack sx={{ mt: 2, width: "100%", color: "gray" }} alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" size="small" />
        </Stack> :
        <Fragment>
          <Divider />
          {feeStatus === "loading" ?
            <Stack sx={{ mt: 2, width: "100%", color: "gray" }} alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" size="small" />
            </Stack> :
            <Stack direction={{ md: "row" }} alignItems={{ md: "center" }} justifyContent={{ md: "space-between" }}>
              <Stack alignItems={{ xs: "center", md: "start" }}>
                <Typography variant='h6' color="gray" fontSize="1rem">Withdrawal Percentage</Typography>
                <Typography fontWeight="bold" fontSize="1.5rem">{balanceFee?.data?.fees ?? 0}%</Typography>
              </Stack>
              {
                Number(formik.values.amount) > 1 &&
                <Stack>
                  <Typography variant='h6' color="gray" fontSize="1rem">Cashout Fee</Typography>
                  <Typography fontWeight="bold" fontSize="1.5rem">{
                    new Intl.NumberFormat().format(Number(formik.values.amount) * (Number(balanceFee?.data?.fees ?? 0) / 100))
                  } {currency}</Typography>
                </Stack>
              }
            </Stack>}
        </Fragment>}

      <CustomButton
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
        fullWidth
        size='large'
        sx={{ mt: 2, textTransform: isLoading ? 'capitalize' : 'uppercase' }}
      >
        {isLoading ? 'requesting...' : 'request'}
      </CustomButton>
    </Stack>
  )
}

import React from "react";
import { useMutation } from "react-query";
import { additionalFeesApi } from "../utils/api";
import { errorHandler } from "../../../configs/errorConfig";

const useUpdateAdditionalFees = (id) => {
  return useMutation({
    mutationKey: ["UpdateAdditionalFees", id],
    mutationFn: (data) => additionalFeesApi.update(data, id),
    onError: (error) => errorHandler(error),
  });
};

export default useUpdateAdditionalFees;

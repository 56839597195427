import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import { useMutation } from 'react-query';
import DataGrid from '@components/datagrid'
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from '@components/AlertDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, ButtonGroup, Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { downloadCSV } from '@src/utils';
import { errorHandler } from '@configs/errorConfig';
import CustomButton from '@components/CustomButton';
import TableLoader from '@components/TableLoader';
import DashboardSwipeable from '@components/DashboardSwipeable';
import ShareDialog from '@modules/payment-link/components/share';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import { linkColumns } from '@modules/payment-link/utils/columns';
import { deletePaymentLinksAPI } from '@modules/payment-link/utils/api';
import { usePaymentLink } from '@modules/payment-link/hooks/usePaymentLinks';
import PaymentLinkForm from '@modules/payment-link/components/PaymentLinkForm';
import PaymentLinkDetails from '@modules/payment-link/components/payment-link-details';

const Links = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [paymentLinks, setPaymentLinks] = useState([])
  const [paymentDetails, setPaymentDetails] = useState()
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedPaymentLinkID, setSelectedPaymentLinkID] = useState()
  const { appID } = useParams();
  const { data, isLoading, refetch } = usePaymentLink()
  const remove = useMutation((linkID) => deletePaymentLinksAPI(linkID))

  const breadCrumb = [
    {
      label: 'Links',
      link: `/${appID}/links`,
      icon: <OpenInNewIcon fontSize="small" />
    }
  ]

  const allLinkData = (data) => {
    if (data) {
      const live = data?.live
      const sandbox = data?.sandbox
      const mergedData = live?.concat(sandbox)
      setPaymentLinks(mergedData)
    }
  }

  const status = [
    { label: "sandbox", value: "test" },
    { label: "live", value: "live" },
  ]

  const handleDelete = (linkID) => {
    remove.mutate(linkID, {
      onSuccess: () => {
        refetch()
        toast.success('Link deleted succefully')
        setDeleteModalOpen(false)
      },
      onError: (error) => {
        errorHandler(error)
      }
    })
  }

  useEffect(() => {
    allLinkData(data?.data)
  }, [data])

  const getPaymentLink = (data, view, share, isDelete) => {
    setPaymentDetails(data)
    if (view) setDetailsModalOpen(true)
    else if (share) setShareModalOpen(true)
    else if (isDelete) {
      setSelectedPaymentLinkID(data.id)
      setDeleteModalOpen(true)
    }
    else return null
  }

  return (
    <Fragment>

      <Stack direction="row" alignItems='center' justifyContent='space-between' mb={4} mt={3}>
        <CustomizedBreadcrumbs links={breadCrumb} />
        <ButtonGroup size='small' variant='outlined'>
          <CustomButton
            type='button'
            startIcon={<FileUploadOutlinedIcon />}
            onClick={() => downloadCSV(paymentLinks, 'payments')}
            variant='outlined'
            size='small'
          >
            Export
          </CustomButton>
          <Button color='primary' onClick={() => setIsOpen(true)} variant='contained' sx={{ boxShadow: "none" }} startIcon={<AddIcon />}>
            Create Link
          </Button>
        </ButtonGroup>
      </Stack>
      {
        isLoading ?
          <TableLoader columns={linkColumns(getPaymentLink)} />
          :
          paymentLinks?.length ?
            <DataGrid
              data={paymentLinks}
              head="Links"
              searchTitle='Search product name'
              query='product_name'
              filterColumn="mode"
              filterTitle="Mode"
              statuses={status}
              dataType='links'
              column={linkColumns(getPaymentLink)}
              onClick={getPaymentLink}
            />
            :
            <Typography variant='h5' textAlign="center">No link found</Typography>
      }

      {paymentDetails ?
        <PaymentLinkDetails
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
          paymentLinkDetail={paymentDetails}
        /> : null}

      <AlertDialog
        open={deleteModalOpen}
        disabled={remove.isLoading}
        loading={remove.isLoading}
        onClose={() => setDeleteModalOpen(false)}
        text="Are you sure to delete this link?"
        color="error"
        action={() => { handleDelete(selectedPaymentLinkID) }}
      />

      {paymentDetails ? <ShareDialog
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        link={paymentDetails.link}
      /> : null}

      <DashboardSwipeable
        isOpen={isOpen}
        title="Create a payment link"
        onClose={() => setIsOpen(false)}
      >
        <PaymentLinkForm onClose={() => setIsOpen(false)} isClosed={isOpen? false: true} />
      </DashboardSwipeable>
    </Fragment>
  )
}

export default Links
import React from 'react'
import { Button, Menu, Stack } from '@mui/material';

const Company = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(null);
    props.setOpen(true)
  };

  const handleOpenDetail = () => {
    setAnchorEl(null);
    props.setCompanyDetailOpen(true)

  };

  return (
    <div>
      <Button
        color='secondary'
        variant="contained"
        type='button'
        id="company-button"
        aria-controls={open ? 'company-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ boxShadow: "none" }}
      >
        Company config
      </Button>

      <Menu
        id="company-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'company-button',
        }}
      >

        <Stack px={1} gap={1}>
          <Button
            variant="contained"
            color='secondary'
            onClick={handleOpen}
            sx={{ boxShadow: "none", fontWeight: "bold" }}
          >
            {props.isEdit ? "Edit" : "Add"} Company
          </Button>
          {props.isEdit && <Button
            onClick={handleOpenDetail}
            sx={{ boxShadow: "none", fontWeight: "bold" }}
          >
            View Details
          </Button>}
        </Stack>
      </Menu>
    </div>
  )
}

export default Company
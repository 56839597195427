import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import CustomInput from "@components/CustomInput";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { createAdditionalDetailsValidationSchema } from "../utils/validation";
import useCreateAdditionalDetails from "../hooks/useCreateAdditionalDetails";
import { errorHandler } from "../../../configs/errorConfig";
import { toast } from "react-toastify";
import useUpdateAdditionalDetails from "../hooks/useUpdateAdditionalDetails";

const CreateDetailsModal = ({ open, handleClose }) => {
  const { mutate, isLoading } = useCreateAdditionalDetails();
  const { mutate: update, isLoading: updating } = useUpdateAdditionalDetails(
    open?.data?.id
  );
  const _isUpdate = open?.data && Object.keys(open?.data)?.length > 0;

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: createAdditionalDetailsValidationSchema,
    onSubmit: (data) => {
      if (_isUpdate) {
        update(data, {
          onSuccess: () => {
            toast.success("additional details Updated");
            formik.resetForm();
            handleClose();
          },
          onError: (error) => errorHandler(error),
        });
      } else {
        mutate(data, {
          onSuccess: () => {
            toast.success("additional details created");
            formik.resetForm();
            handleClose();
          },
          onError: (error) => errorHandler(error),
        });
      }
    },
  });

  console.log(open?.data?.name);
  useEffect(() => {
    if (_isUpdate) {
      formik.setFieldValue("name", open?.data?.name);
    }
  }, [open?.data, _isUpdate]);

  console.log(formik.values.name);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open.open}
      onClose={handleClose}
      component="form"
      onSubmit={formik.handleSubmit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        textTransform="capitalize"
        fontSize="xl"
        id="alert-dialog-title"
      >
        {_isUpdate ? "update" : "Add New"} Additional Detail
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <FormControl
          fullWidth
          width="100%"
          sx={{
            my: 2,
          }}
        >
          <CustomInput
            name="name"
            label="Name"
            type="text"
            width="100%"
            value={formik.values.name}
            error={formik.touched.name && !!formik.errors.name}
            helpertext={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter Agent Email"
          />
          {formik.errors?.name && formik.touched?.name && (
            <FormHelperText fullWidth error>
              <strong>{formik.errors?.name}</strong>
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={[isLoading, updating]?.includes(true)}
          variant="contained"
          sx={{
            width: "100%",
          }}
          type="submit"
          textTransform="capitalize"
        >
          {_isUpdate ? "Update" : "Add"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDetailsModal;

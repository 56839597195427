import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from '@mui/material';

import CustomInput from '@components/CustomInput';
import CustomButton from '@components/CustomButton';
import { errorHandler } from '../../../../configs/errorConfig';
import { applicationValidation } from '../../utils/validations';
import { useCreateApplication } from '../../../application/hooks/useCreateApplication';
import { useEditApplication } from '../../../application/hooks/useEditApplication';

export default function ApplicationForm({ refetchApplication, setIsOpen, edit = false }) {
    const smallScreen = useMediaQuery('(max-width:900px)');
    const [createApplication, { error, loading }] = useCreateApplication()
    const [editApplication, { error: errorEdit, loading: loadingEdit }] = useEditApplication()
    const user = useSelector((store) => store.auth.userData)
    const selectedApp = useSelector((store) => store.data.selectedApp)

    const formik = useFormik({
        initialValues: {
            application_name: '',
            application_type: '',
            application_description: ''
        },
        validationSchema: applicationValidation,
        onSubmit: (values) => {
            const datas = {
                name: values?.application_name,
                type: values?.application_type,
                description: values?.application_description,
                user_id: user?.id
            }

            if (edit) {
                editApplication({ variables: { id: selectedApp?.id, name: datas?.name, description: datas?.description } }).then(() => {
                    toast.success(`Application ${selectedApp?.name} updated successfully`)
                }).catch(() => {
                    errorHandler(errorEdit)
                })

            } else {

                createApplication({ variables: datas }).then(() => {
                    refetchApplication()
                    setIsOpen(false)
                    formik.resetForm()
                    toast.success(`Application ${values?.application_name} created successfully`)

                }).catch(() => {
                    if (error?.message === `Server response was missing for query 'CreateApplications'.`) {
                        toast.error('Application already exist')
                    } else if (error?.message) {
                        toast.error(error?.message)
                    } else {
                        toast.error('Application already exist')
                    }
                })
            }
        }
    })

    useEffect(() => {
        if (edit) {
            formik.setFieldValue('application_name', selectedApp?.name)
            formik.setFieldValue('application_type', selectedApp?.type)
            formik.setFieldValue('application_description', selectedApp?.description)
        }
    }, [])

    return (
        <Stack
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
            component="form"
            gap={2}
        >
            <CustomInput
                fullWidth={smallScreen}
                id="application_name"
                name="application_name"
                placeholder="Enter Application Name"
                label="Application Name"
                value={formik.values.application_name}
                error={formik.touched.application_name && !!formik.errors.application_name}
                helpertext={formik.touched.application_name && formik.errors.application_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            {!edit &&
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Application type</InputLabel>
                    <Select
                        labelId="type"
                        id="application_type"
                        name="application_type"
                        size='small'
                        label="Application type"
                        onChange={(event) => formik.setFieldValue('application_type', event.target.value)}
                        value={formik.values.application_type}
                        error={formik.touched.application_type && !!formik.errors.application_type}
                        helpertext={formik.touched.application_type && formik.errors.application_type}
                        onBlur={formik.handleBlur}
                    >
                        <MenuItem value='PAYMENTS_COLLECTION'>Payment collection</MenuItem>
                        <MenuItem value='LINKS'>Link</MenuItem>
                    </Select>
                    {formik.touched.application_type && !!formik.errors.application_type && <FormHelperText error>{formik.errors.application_type}</FormHelperText>}
                </FormControl>
            }
            <FormControl fullWidth>
                <TextField
                    fullWidth={smallScreen}
                    id="application_description"
                    label="Description"
                    name="application_description"
                    multiline
                    rows={3}
                    value={formik.values.application_description}
                    error={formik.touched.application_description && !!formik.errors.application_description}
                    helpertext={formik.touched.application_description && formik.errors.application_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.application_description && !!formik.errors.application_description && <FormHelperText error>{formik.errors.application_description}</FormHelperText>}
            </FormControl>

            <CustomButton
                type='submit'
                loading={loading || loadingEdit}
                fullWidth
                size='large'
                sx={{ mt: 2, textTransform: loading || loadingEdit ? 'capitalize' : 'uppercase' }}
            >
                {loading || loadingEdit ? 'Processing...' : edit ? 'Edit' : 'Create'}
            </CustomButton>
        </Stack>
    )
}

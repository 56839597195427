import React from 'react'
import { Box, Grid, TextField, Typography, Autocomplete, FormControl } from '@mui/material'

import { countryList } from '@utils/countries'
import { businessTypeOptions, businessTypes } from '../../../utils'

export default function BusinessDetail({ formKYC }) {
    return (
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 5 }}>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_industry'>Business industry</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business industry'
                    id='business_industry'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_industry}
                    error={formKYC.touched.business_industry && formKYC.errors.business_industry}
                    helperText={formKYC.touched.business_industry && formKYC.errors.business_industry}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_website'>Business website</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business website'
                    id='business_website'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_website}
                    error={formKYC.touched.business_website && formKYC.errors.business_website}
                    helperText={formKYC.touched.business_website && formKYC.errors.business_website}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_country'>Business country</label>
                    </Typography>
                </Box>

                <FormControl fullWidth>
                    <Autocomplete
                        value={formKYC.values.business_country}
                        onChange={(event, selectedOption) => { formKYC.setFieldValue('business_country', selectedOption?.label) }}
                        onBlur={formKYC.handleBlur}
                        error={formKYC.touched.business_country && formKYC.errors.business_country}
                        helperText={formKYC.touched.business_country && formKYC.errors.business_country}
                        id="country"
                        options={countryList}
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} placeholder='Business country' />}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_type'>Business type</label>
                    </Typography>
                </Box>

                <FormControl fullWidth>
                    <Autocomplete
                        value={businessTypes[formKYC.values.business_type] ?? formKYC.values.business_type}
                        onChange={(event, selectedOption) => { formKYC.setFieldValue('business_type', selectedOption?.label) }}
                        onBlur={formKYC.handleBlur}
                        error={formKYC.touched.business_type && formKYC.errors.business_type}
                        helperText={formKYC.touched.business_type && formKYC.errors.business_type}
                        id="business_type"
                        options={businessTypeOptions}
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} placeholder='Business type' />}
                    />
                </FormControl>

            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_city'>Business city</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business city'
                    id='business_city'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_city}
                    error={formKYC.touched.business_city && formKYC.errors.business_city}
                    helperText={formKYC.touched.business_city && formKYC.errors.business_city}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_description'>Business description</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    multiline
                    placeholder='Business description'
                    id='business_description'
                    variant='outlined'
                    size='small'
                    fullWidth
                    rows={2}
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_description}
                    error={formKYC.touched.business_description && formKYC.errors.business_description}
                    helperText={formKYC.touched.business_description && formKYC.errors.business_description}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_address'>Business address</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business address'
                    id='business_address'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_address}
                    error={formKYC.touched.business_address && formKYC.errors.business_address}
                    helperText={formKYC.touched.business_address && formKYC.errors.business_address}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_customer_support_name'>Business customer support name</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business customer support name'
                    id='business_customer_support_name'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_customer_support_name}
                    error={formKYC.touched.business_customer_support_name && formKYC.errors.business_customer_support_name}
                    helperText={formKYC.touched.business_customer_support_name && formKYC.errors.business_customer_support_name}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_customer_support_phone'>Business customer support phone</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='text'
                    placeholder='Business customer support phone'
                    id='business_customer_support_phone'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_customer_support_phone}
                    error={formKYC.touched.business_customer_support_phone && formKYC.errors.business_customer_support_phone}
                    helperText={formKYC.touched.business_customer_support_phone && formKYC.errors.business_customer_support_phone}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box>
                    <Typography fontSize={15} variant='h6'>
                        <label htmlFor='business_customer_support_email'>Business customer support email</label>
                    </Typography>
                </Box>
                <TextField
                    required
                    type='email'
                    placeholder='Business customer support email'
                    id='business_customer_support_email'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={formKYC.handleChange}
                    onBlur={formKYC.handleBlur}
                    value={formKYC.values.business_customer_support_email}
                    error={formKYC.touched.business_customer_support_email && formKYC.errors.business_customer_support_email}
                    helperText={formKYC.touched.business_customer_support_email && formKYC.errors.business_customer_support_email}
                />
            </Grid>

        </Grid>
    )
}

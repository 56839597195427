import { useMutation, useQueryClient } from 'react-query';
import { updatePayoutAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"


export const useUpdatePayout = () => {

	const client = useQueryClient()
	const mutation = useMutation(updatePayoutAPI,{
    onError: (error) => errorHandler(error),
		onSuccess: () => {
			client.invalidateQueries(["payouts"])
		},
	})

	return mutation
}
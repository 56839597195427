import { useEffect } from "react";
import { useSelector } from "react-redux";
import { errorHandler } from "@configs/errorConfig"
import { useQuery, useQueryClient } from "react-query"
import { getInvoicesAPI } from "../utils/api";

export const useGetInvoices = (page, rowsPerPage, status) => {

    const selectedApp = useSelector((store) => store.data.selectedApp);
    const queryClient = useQueryClient();

    const queryKey = ["invoices", selectedApp?.id];

    const query = useQuery({
        queryKey,
        queryFn: () => getInvoicesAPI(selectedApp?.id, page, rowsPerPage, status),
        onError: (error) => errorHandler(error)
    })

    useEffect(() => {
        queryClient.invalidateQueries(queryKey);
    }, [selectedApp?.id]);

    return query;

}
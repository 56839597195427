import React from "react";
import { Stack, Typography } from "@mui/material";

const InstallmentChip = ({ status }) => {
  const statuses = {
    SUCCESS: "green",
    FAILED: "red",
    CANCELLED: "red",
    SUCCESSFUL: "green",
    SUCCESSFULL: "green",
    INITIATED: "blue",
    PENDING: "orange",
    RETRY: "grey",
  };

  const label = Object.keys(statuses).find((key) => key === status);

  const checkStatus = (label) => {
    if (
      label === "SUCCESS" ||
      label === "SUCCESSFUL" ||
      label === "SUCCESSFULL"
    ) {
      return "Success";
    } else if (
      label === "FAILED" ||
      label === "CANCELLED" ||
      label === "SUCCESSFULL"
    ) {
      return "Success";
    } else return label;
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
      <Stack bgcolor={statuses[status]} width="10px" height="10px" borderRadius="50%" />
      <Typography fontWeight="bold" textTransform="uppercase">{checkStatus(label)}</Typography>
    </Stack>
  );
};

export default InstallmentChip;

import React, { Fragment } from 'react'
import { Grid, Skeleton } from '@mui/material'

export default function AppInfoLoader() {
  return (
    <Fragment>
      <Grid container my={2} rowSpacing={5} >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(element =>
          <Grid key={element} item xs={12} sm={6} md={4}>
            <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} width='80%' height={35} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

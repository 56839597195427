import React from 'react'
import { Box, Typography } from '@mui/material'

export default function Footer() {
  return (
    <Box sx={{ position: 'fixed', borderTop: '1px solid rgb(200,200,200)', width: '100%', p: 2, textAlign: 'left', bottom: 0, background: '#fff', zIndex: 5 }}>
      <Typography variant='small' >
        @{ new Date().getFullYear() } Payunit. All rights reserved.
      </Typography>
    </Box>
  )
}

import ForgotPassword from "../views/pages/authentification/forgotPassword";
import Login from "../views/pages/authentification/login";
import ResetPassword from "../views/pages/authentification/resetPassword";
import SignUp from "../views/pages/authentification/signUp";
import VerifyEmail from "../views/pages/authentification/verifyEmail";
import NotFound from "../views/pages/notFound";

export const unprotectedRoutes = [
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/signup',
        element: <SignUp />
    },
    {
        path: '/verify-user/:email',
        element: <VerifyEmail />
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/reset-password/:email',
        element: <ResetPassword />
    },
    {
        path: '*',
        element: <NotFound />
    }
]
import React from 'react'
import { Avatar, IconButton, Stack } from '@mui/material';


function getAvatarString(name) {
    return {
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1] ? name.split(' ')[1][0].toUpperCase() : name.split(' ')[0][1].toUpperCase() ?? ''}`,
    };
}

export default function StringAvatar({name, sx={}, bgColor='#FEF3E6', color='#000000', borderColor='#F79623'}) {
  // ================= COMPONENT =================
  return (
    <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        sx={sx}  
    >
        <Stack direction="row">
            <Avatar 
                {...getAvatarString(name)} 
                variant="square" 
                sx={{ 
                    background: bgColor, 
                    color,
                    border: '1px solid',
                    borderColor, 
                    borderRadius: 1
                }} 
            />
        </Stack>
    </IconButton>
  )
}

import { useMutation } from 'react-query';
import { updateCategoryAPI } from "../utils/api";
import { errorHandler } from "@configs/errorConfig"

export const useUpdateCategory = () => { 

  const mutation = useMutation({
    mutationKey: 'update_category',
    mutationFn:updateCategoryAPI,
    onError: (error) => errorHandler(error) 
  })

  return mutation
 }
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Step from '@mui/material/Step';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import EastIcon from '@mui/icons-material/East';
import CustomButton from '@components/CustomButton';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { kycValidations } from '@modules/kycs/utils/validations';
import CustomizedBreadcrumbs from '@components/CustomBreadcrumbs';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Box, Button, Container, Paper, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { useKycs } from '@modules/kycs/hooks/useKycs';
import Documents from '@modules/kycs/components/Documents';
import { useAddKycs } from '@modules/kycs/hooks/useAddKycs';
import { useEditKycs } from '@modules/kycs/hooks/useEditKycs';
import LoaderAddKyc from '@modules/kycs/components/LoaderAddKyc';
import PromoterDetail from '@modules/kycs/components/PromoterDetail';
import BusinessDetail from '@modules/kycs/components/BusinessDetail';
import { businessTypeOptions, businessTypes } from '../../../utils';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex', height: 22, alignItems: 'center', ...(ownerState.active && { color: '#784af4', }),
  '& .QontoStepIcon-completedIcon': { color: '#784af4', zIndex: 1, fontSize: 18 },
  '& .QontoStepIcon-circle': { width: 8, height: 8, borderRadius: '50%', backgroundColor: 'currentColor' },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 95deg, #00BFB3 0%, green 50%, #00BFB3 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 95deg, #00BFB3 0%, green 50%, #00BFB3 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1, color: '#fff', width: 50, height: 50, display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, #00BFB3 0%, #00BFB3 50%, #00BFB3 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, #00BFB3 0%, #00BFB3 50%, #00BFB3 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BusinessCenterIcon />,
    2: <ContactPhoneIcon />,
    3: <DescriptionIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ['Business Details', 'Promoter Details', 'Upload Documents'];

export default function AddKycs() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [files, setFiles] = useState([]);
  const [docsFilled, setDocsFilled] = useState(false)
  const [kycChecked, setKycChecked] = useState(false)
  const user = useSelector((store) => store.auth.userData)
  const { appID } = useParams();
  const selectedApp = useSelector((store) => store.data.selectedApp)
  const { mutate, isLoading } = useAddKycs()
  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useEditKycs()
  const { data, isLoading: isLoadingKyc } = useKycs()
  const kyc = data?.data

  const breadCrumb = [
    {
      label: 'KYCs',
      link: `/${appID}/kycs`,
      icon: <AssignmentOutlinedIcon fontSize="small" />
    },
    {
      label: 'Manage KYCs',
      link: `/${appID}/kycs/manage`,
      icon: <PostAddIcon fontSize="small" />
    },
  ]

  const initialValues = {
    business_name: '',
    business_industry: '',
    business_website: '',
    business_description: '',
    business_country: '',
    business_city: '',
    business_address: '',
    business_type: '',
    business_customer_support_name: '',
    business_customer_support_phone: '',
    business_customer_support_email: '',
    business_promoter_name: '',
    business_promoter_phone: '',
    business_promoter_email: ''
  }

  const formKYC = useFormik({
    initialValues,
    validationSchema: kycValidations(activeStep),
    onSubmit: async values => {

      if (activeStep === 2) {
        const formData = new FormData()
        const business_name = selectedApp?.name
        const {
          business_address,
          business_city,
          business_country, business_type,
          business_customer_support_email,
          business_customer_support_name,
          business_customer_support_phone,
          business_description,
          business_website,
          business_industry,
          business_promoter_email,
          business_promoter_name,
          business_promoter_phone,
        } = values

        formData.append('application_id', appID)
        formData.append('user_id', user?.id)
        formData.append('business_name', business_name)
        formData.append('business_industry', business_industry)
        formData.append('business_website', business_website)
        formData.append('business_description', business_description)
        formData.append('business_country', business_country?.value ?? business_country)
        formData.append('business_city', business_city)
        formData.append('business_address', business_address)
        formData.append('business_type', businessTypeOptions?.find(business => business?.label === (business_type?.value ?? business_type))?.value)
        formData.append('business_customer_support_name', business_customer_support_name)
        formData.append('business_customer_support_phone', business_customer_support_phone)
        formData.append('business_customer_support_email', business_customer_support_email)
        formData.append('business_promoter_name', business_promoter_name)
        formData.append('business_promoter_phone', business_promoter_phone)
        formData.append('business_promoter_email', business_promoter_email)
        formData.append('username', `${user?.first_name} ${user?.last_name}`)
        formData.append('email', user?.email)

        files.forEach((file) => { formData.append(`${file.document_type}_${file.country_code}`, file) })
        if (files.length < 2 && !kyc) {
          toast.error(`Please choose category and upload at least 2 documents`)
          return
        }

        if (!docsFilled && !kyc) {
          toast.error(`Selfie and Passport/ID card both should have been filled for at least 1 country`)
          return
        }

        if (kyc)
          mutateEdit({ id: kyc?.id, values: formData }, {
            onSuccess: () => {
              toast.success(`KYCs edited successfully!`)
              setTimeout(() => {
                navigate(`/${appID}/kycs`)
              }, 3000);
            }
          })

        else
          mutate(formData, {
            onSuccess: () => {
              toast.success(`KYCs added successfully!`)
              setTimeout(() => {
                navigate(`/${appID}/kycs`)
              }, 3000);
            }
          })
      } else {
        setActiveStep((active) => active + 1)
      }
    },
  });

  useEffect(() => {
    if (kyc) {
      formKYC.setFieldValue('business_name', kyc.business_name)
      formKYC.setFieldValue('business_industry', kyc.business_industry)
      formKYC.setFieldValue('business_website', kyc.business_website)
      formKYC.setFieldValue('business_description', kyc.business_description)
      formKYC.setFieldValue('business_country', kyc.business_country)
      formKYC.setFieldValue('business_city', kyc.business_city)
      formKYC.setFieldValue('business_address', kyc.business_address)
      formKYC.setFieldValue('business_type', businessTypes[kyc.business_type])
      formKYC.setFieldValue('business_customer_support_name', kyc.business_customer_support_name)
      formKYC.setFieldValue('business_customer_support_phone', kyc.business_customer_support_phone)
      formKYC.setFieldValue('business_customer_support_email', kyc.business_customer_support_email)
      formKYC.setFieldValue('business_promoter_name', kyc.business_promoter_name)
      formKYC.setFieldValue('business_promoter_phone', kyc.business_promoter_phone)
      formKYC.setFieldValue('business_promoter_email', kyc.business_promoter_email)
      setKycChecked(true)
    } else {
      formKYC.resetForm()
      setFiles([])
      setKycChecked(true)
    }
  }, [isLoading])

  return (
    <section>
      <Container spacing={4}>
        <Stack direction="row" alignItems='center' justifyContent='space-between' mb={4} mt={3}>
          <CustomizedBreadcrumbs links={breadCrumb} />
        </Stack>
        <Stepper sx={{ mt: 5 }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label) =>
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          )}
        </Stepper>

        <Paper sx={{ mt: 5, p: 5, minHeight: 600, position: 'relative' }}>
          {isLoadingKyc && !kycChecked ?
            <LoaderAddKyc formKYC={formKYC} />
            :
            <>
              {activeStep === 0 && <BusinessDetail formKYC={formKYC} />}
              {activeStep === 1 && <PromoterDetail formKYC={formKYC} />}
              {activeStep === 2 &&
                <Documents
                  docsFilled={docsFilled}
                  setDocsFilled={setDocsFilled}
                  files={files}
                  setFiles={setFiles}
                />
              }

              <Box mt={5} display='flex' justifyContent='space-between'>

                <Button onClick={() => { if (activeStep > 0) setActiveStep(activeStep - 1) }} variant='outlined' color='primary' startIcon={<KeyboardBackspaceIcon />}>
                  Back
                </Button>

                <CustomButton
                  onClick={formKYC.handleSubmit}
                  loading={isLoading || isLoadingEdit}
                  endIcon={activeStep !== 2 ? <EastIcon /> : <></>}
                  size='medium'
                  sx={{ textTransform: isLoading ? 'capitalize' : 'uppercase' }}
                >
                  {activeStep === 2 ? 'Save KYC' : 'Next'}
                </CustomButton>

              </Box>
            </>
          }
        </Paper>
      </Container>
    </section>
  );
}
import DatagridColumnHeader from '@components/datagrid/datagrid-column-header'
import { VisibilityOutlined } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Typography, IconButton, Stack } from "@mui/material"
import InvoiceChip from '../components/invoice-chip';
import { formatDate } from '../../../utils';

export const invoiceColumns = (setInvoice, setModalOpen) => [

  {
    id: 'ref',
    header: 'Invoice ref',
    accessorKey: 'ref',
    numeric: false,
    cell: (info) => <Typography textTransform="uppercase">{info.getValue()}</Typography>
  },
  {
    id: 'client_name',
    header: 'Client Name',
    accessorKey: 'client_name',
    cell: ({ row }) => <Typography textTransform="capitalize" color='gray'>{row.original.client_name}</Typography>

  },
  {
    id: 'total_amount',
    header: ({ column }) => (
      <DatagridColumnHeader label="Total Amount" column={column} />
    ),
    accessorKey: 'total_amount',
    cell: ({ row }) => {
      const amount = row.original.total_amount;
      const currency = row.original.currency;
      return `${new Intl.NumberFormat().format(amount)} ${currency}`
    },
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'type',
    header: 'Type',
    accessorKey: 'type',
    cell: (info) => <Typography color={info.getValue() === "NORMAL" ? "black" : "primary"} fontWeight="bold">{info.getValue()}</Typography>

  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => {
      const status = info.getValue()
      return <InvoiceChip status={status} />
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    disablePadding: false,
  },
  {
    id: 'created_at',
    header: ({ column }) => (
      <DatagridColumnHeader label="Date Created" column={column} />
    ),
    accessorKey: 'created_at',
    cell: (info) => {
      const date = info.getValue();
      return formatDate(date)
    },
    disablePadding: false,
  },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <IconButton title="View details" onClick={() => {
            setModalOpen(true);
            setInvoice(row.original)
          }}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Stack>
      )
    }
  },
]


export const confirmColumns = [
  {
    id: 'item',
    header: 'Name',
    accessorKey: 'name',
  },
  {
    id: 'price',
    header: 'Price',
    accessorKey: 'price',
  },
  {
    id: 'quantity',
    header: 'Qty',
    accessorKey: 'quantity',
  },
  {
    id: 'amount',
    header: 'Amount',
    accessorKey: 'amount',
    cell: ({ row }) => {
      return <Typography fontWeight="bold">{row.original.amount}</Typography>
    }
  },
]

export const installmentColumns = [
  {
    id: 'title',
    header: 'Title',
    accessorKey: 'title',
  },
  {
    id: 'amount',
    header: 'Amount',
    accessorKey: 'amount',
    cell: ({ row }) => {
      return <Typography fontWeight="bold">{row.original.amount}</Typography>
    }
  },
  {
    id: 'due_date',
    header: 'Due Date',
    accessorKey: 'due_date',
    cell: ({ row }) => {
      return <Typography fontWeight="bold">{formatDate(row.original.due_date)}</Typography>
    }
  },
]

export const companyColumns = (getCompany) => [
  {
    id: 'name',
    header: 'Company',
    accessorKey: 'name',
    cell: ({ row }) => {
      const image_url = row.original.logo;
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <img src={image_url ?? require('../../../assets/images/avatar/default_pic.jpg')} width={35} height={35} alt={image_url} />
          <Typography
            textTransform="capitalize"
            fontWeight="bold"
          >{row.original.name}
          </Typography>
        </Stack>
      )
    }
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
  },
  {
    id: 'phone_number',
    header: 'Phone Number',
    accessorKey: 'phone_number',
  },
  // {
  //   id: 'image_url',
  //   header: 'Logo',
  //   accessorKey: 'logo',
  //   cell: ({ row }) => {
  //     const image_url = row.original.logo;
  //     return <img src={image_url} width={35} height={35} alt={image_url} />
  //   },
  // },
  {
    id: 'action',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="center" gap={.4}>
          <IconButton title="View details" onClick={() => getCompany(row.original, true, false, false)}>
            <VisibilityOutlined fontSize="small" color="secondary" />
          </IconButton>
          <IconButton title="Edit company" onClick={() => getCompany(row.original, false, true, false)}>
            <ModeEditIcon fontSize="small" color="primary" />
          </IconButton>
        </Stack>
      )
    }
  },
]